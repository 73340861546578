/* eslint arrow-parens: "off" */
import _ from 'lodash';

function convertRolesAndStaffsToInterviewers(rolesAndStaffs) {
  const interviewers = [];

  if (_.isEmpty(rolesAndStaffs)) {
    return interviewers;
  }

  for (const [index, roleOrStaff] of rolesAndStaffs.entries()) {
    const isStaff = Object.keys(roleOrStaff).includes('company_staff');

    if (isStaff) {
      interviewers.push({ sequence: index, company_staff_id: roleOrStaff.company_staff.id });
    } else {
      interviewers.push({
        sequence: index,
        company_role_id: roleOrStaff.company_role?.id || roleOrStaff.company_role_id,
      });
    }
  }

  return interviewers;
}

function isNormalMeeting(meetingType) {
  return ['Phone call', 'Onsite interview', 'Video conference'].includes(meetingType);
}

export { convertRolesAndStaffsToInterviewers, isNormalMeeting };
