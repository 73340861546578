import request from '../utils/request';
import { APIHOST } from '../config';

const userInfoPath = '/v1/users/me';

// Position
const positionsPath = '/v1/positions/mine';

// Applicant
const stageListOfPosition = '/v1/positions/_digest_key_/stages';
const applicantsOfStagePath = '/v1/positions/:digest_key/applicants';

export async function fetchUserInfo() {
  return request('GET', APIHOST + userInfoPath);
}

export async function fetchPositions(data) {
  const {
    ids = [],
    location,
    department,
    brand,
    type,
    page,
    per_page,
    status,
    query,
    sort_key: sortKey,
    order,
    show_simple_list: showSimpleList,
  } = data;

  const payload = {
    page,
    per_page,
  };

  if (type === 'department' && department !== 'all') {
    payload.department = department;
  }
  if (type === 'location' && location !== 'all') {
    payload.location = location;
  }
  if (type === 'location' && brand) payload.brand = brand;
  if (status !== 'all') {
    payload.status = status;
  }

  // sort setting
  if (sortKey && order) {
    let key = sortKey;
    let orderValue = order;
    if (key === 'published' || key === 'url') key = 'status';
    orderValue = orderValue === 'ascend' ? 'asc' : 'desc';
    payload.order = `${key},${orderValue}`;

    if (type === 'department' && sortKey === 'department') delete payload.order;
    if (type === 'location' && sortKey === 'location') delete payload.order;
  }

  if (query) {
    payload.query = query;
  }

  if (ids.length > 0) {
    payload.ids = ids;
  }

  if (showSimpleList) {
    payload.show_simple_list = showSimpleList;
  }

  return request('GET', APIHOST + positionsPath, payload);
}

// Applicant
export async function fetchApplicantsOfStage(payload) {
  const {
    digestkey,
    stageId,
    page,
    perPage,
    status,
    rating_gte: ratingGte,
    query,
    sort_key: sortKey,
    order,
    filters,
    scheduling,
    tag_ids: tags = [],
  } = payload;

  const data = {
    page,
    per_page: perPage,
    position_stage_id: stageId,
    status,
    filters,
    scheduling,
    query,
    ...(tags.length > 0 ? { tag_ids: tags } : {}),
  };

  // 2019-01-02 Demo: The following line is no need, if query is empty it will be ignore
  // automatically in our HTTP lib axios
  // if (query) data.query = query;

  if (ratingGte || ratingGte === 0) {
    data.rating_gte = ratingGte;
  }

  if (sortKey && order) {
    let key = sortKey;
    let orderValue = order;
    // if (key === 'published' || key === 'url') key = 'status';
    orderValue = orderValue === 'ascend' ? 'asc' : 'desc';

    if (
      key === 'form_submission_status' ||
      key === 'offer_signing_status' ||
      key === 'online_quiz_result' ||
      key === 'background_check_status'
    ) {
      key = 'stage_status';
    }

    data.order = `${key},${orderValue}`;
  }

  const path = applicantsOfStagePath.replace(':digest_key', digestkey);
  return request('GET', APIHOST + path, data);
}
export async function fetchStageList(digestkey, showApplicantsCount = false) {
  const path = stageListOfPosition.replace('_digest_key_', digestkey);
  if (showApplicantsCount) {
    return request('GET', `${APIHOST}${path}?show_applicants_count=true`);
  } else {
    return request('GET', `${APIHOST}${path}`);
  }
}

export async function fetchAppSettings() {
  const path = '/v1/settings';
  return request('GET', APIHOST + path);
}

export async function getResetPwdToken() {
  const path = '/v1/users/reset_password_token';
  return request('GET', APIHOST + path);
}

export async function fetchCompanies() {
  const path = '/v1/users/companies';
  return request('GET', APIHOST + path);
}
