import { includes, findIndex } from 'lodash';

import {
  ActionType,
  ModuleSettingActionType,
  ModuleSettingType,
  QuestionType,
  AssigneeType,
  TaskItemWithConfigurationType,
  ModuleConfigurationActionType,
  ModuleConfigurationType,
  ModuleType,
  QuestionFieldType,
} from './interface';
import { NO_CONFIG_TASK_TYPES } from './constants';

export const getAction = ({
  moduleActions = [],
  actionType,
}: {
  moduleActions?: ModuleSettingActionType[] | ModuleConfigurationActionType[];
  actionType: ActionType;
}) => {
  if (!moduleActions || (moduleActions && moduleActions.length === 0)) {
    return null;
  }
  const moduleConfigurationAction = moduleActions as ModuleConfigurationActionType[];
  const moduleSettingAction = moduleActions as ModuleSettingActionType[];

  if (moduleConfigurationAction[0].type) {
    return moduleConfigurationAction.find((ma) => {
      return ma.type === actionType;
    });
  } else {
    return moduleSettingAction.find((ma) => {
      return ma.action_type === actionType;
    });
  }
};

export const getFields = ({
  questions = [],
  withSequence = true,
}: {
  questions: QuestionType[];
  withSequence?: boolean;
}) => {
  let allFields;
  if (withSequence) {
    allFields = questions.reduce((acc: unknown, current: QuestionType) => {
      let sequence = acc.length;
      const fields = current.fields.map((field) => {
        sequence += 1;
        return {
          ...field,
          sequence,
          category: current.category,
        };
      });
      // eslint-disable-next-line
      acc = [...acc, ...fields];
      return acc;
    }, []);
  } else {
    allFields = questions.reduce((acc: unknown, current) => {
      const fields = current.fields.map((field) => {
        return {
          ...field,
          category: current.category,
        };
      });
      // eslint-disable-next-line
      acc = [...acc, ...fields];
      return acc;
    }, []);
  }

  return allFields;
};

export const skipTaskConfiguration = ({ task }: { task: TaskItemWithConfigurationType }) => {
  let isTaskConfigurationDone = false;
  switch (task.type) {
    case 'wotc':
      isTaskConfigurationDone = true;
      break;
    default:
      isTaskConfigurationDone = false;
  }
  return isTaskConfigurationDone || includes(NO_CONFIG_TASK_TYPES, task.type);
};

export const getDefaultSettingOfModule = ({
  moduleConfiguration,
  skipPrepopulate = false,
}: {
  moduleConfiguration: ModuleConfigurationType;
  skipPrepopulate: boolean;
}): ModuleSettingType => {
  const { type: moduleType, name: moduleName } = moduleConfiguration;
  let settings: ModuleSettingType = {} as ModuleSettingType;
  switch (moduleType) {
    case ModuleType.W4: {
      settings = {
        id: moduleType,
        name: 'Form W-4',
        module_type: moduleType,
        config: {
          employer_name: '',
          employer_address: '',
          employer_ein: '',
        },
        actions: [
          {
            action_type: ActionType.W4_FORM,
            assignees: [] as AssigneeType[],
            name: 'Fill Form W-4',
            sequence: 0,
          },
        ],
      };
      break;
    }
    case ModuleType.FORMI9_COMPLIANCE_EVERIFY: {
      settings = {
        id: moduleType,
        name: moduleName,
        module_type: moduleType,
        actions: [
          {
            action_type: ActionType.E_VERIFY_SECTION_1,
            name: 'Section 1',
            description: 'The employee completes section 1',
            sequence: 0,
            assignees: [] as AssigneeType[],
          },
          {
            action_type: ActionType.E_VERIFY_SECTION_2,
            name: 'Section 2',
            description: 'The employer completes section 2',
            sequence: 1,
            assignees: [],
          },
        ],
      };
      break;
    }
    case ModuleType.I9: {
      settings = {
        id: moduleType,
        name: moduleName,
        module_type: moduleType,
        config: {
          employer_name: '',
          employer_address: '',
          employer_ein: '',
          skip_prepopulate: skipPrepopulate,
        },
        actions: [
          {
            action_type: ActionType.EMPLOYEE_FILL_I9_form,
            assignees: [] as AssigneeType[],
            name: 'Employee completes section 1',
            sequence: 0,
          },
          {
            action_type: ActionType.HR_FILL_I9_FORM,
            assignees: [] as AssigneeType[],
            name: 'Employer completes section 2',
            sequence: 1,
          },
          {
            action_type: ActionType.HR_SUBMIT_E_VERIFY_CASE,
            assignees: [] as AssigneeType[],
            name: 'Employer submits E-Verify case',
            sequence: 2,
          },
        ],
        has_active_e_verify_client: moduleConfiguration.has_active_e_verify_client,
      };
      break;
    }
    case ModuleType.ADP:
      settings = {
        id: moduleType,
        name: moduleName,
        module_type: moduleType,
        actions: [
          {
            action_type: ActionType.EMPLOYEE_FILL_ADP_FORM,
            name: 'Fill ADP form',
            sequence: 0,
            form: {
              questions: [] as QuestionFieldType[],
            },
          },
          {
            action_type: ActionType.HR_FILL_ADP_FORM,
            name: 'Fill ADP form',
            sequence: 1,
            form: {
              questions: [] as QuestionFieldType[],
            },
            assignees: [] as AssigneeType[],
          },
          {
            action_type: ActionType.REVIEW_AND_SEND_TO_ADP,
            name: 'Review and send to ADP',
            sequence: 2,
            assignees: [] as AssigneeType[],
          },
        ],
      };
      break;
    case ModuleType.COLLECT_INFO:
      settings = {
        id: moduleType,
        name: moduleName,
        module_type: moduleType,
        actions: [
          {
            description: 'Manager will review the collected information',
            assignees: [] as AssigneeType[],
            name: 'Review information',
            sequence: 1,
            action_type: ActionType.COLLECT_INFO_HR_FORM,
          },
          {
            description: 'Employee complete form',
            sequence: 0,
            name: 'Enter information',
            action_type: ActionType.COLLECT_INFO_EMPLOYEE_FORM,
            form: {
              questions: [] as QuestionFieldType[],
            },
            assignees: [] as AssigneeType[],
          },
        ],
      };
      break;
    case ModuleType.WOTC:
      settings = {
        id: moduleType,
        name: moduleName,
        module_type: moduleType,
        config: {
          wotc_company_id: moduleConfiguration.wotc_company?.id,
        },
        actions: [
          {
            description: 'Employee complete form',
            name: 'Fill WOTC form',
            sequence: 0,
            action_type: ActionType.EMPLOYEE_FILL_WOTC_FORM,
            form: {
              questions: [] as QuestionFieldType[],
            },
            assignees: [] as AssigneeType[],
          },
        ],
        wotc_company: moduleConfiguration.wotc_company,
      };
      break;
    case ModuleType.BASIC_INFORMATION:
      settings = {
        id: moduleType,
        name: moduleName,
        module_type: moduleType,
        sequence: 0,
        actions: [
          {
            description: 'Employee complete form',
            name: 'Enter basic information',
            sequence: 0,
            action_type: ActionType.EMPLOYEE_FILL_BASIC_FORM,
            form: {
              questions: [] as QuestionFieldType[],
            },
            assignees: [] as AssigneeType[],
          },
        ],
      };
      break;
    case ModuleType.DIRECT_DEPOSIT:
      settings = {
        id: moduleType,
        name: moduleName,
        module_type: moduleType,
        config: {
          required: false, // change default value to true after beta clean new_direct_deposit
          allow_multiple_accounts: true,
        },
        actions: [
          {
            description: 'Employee will fill the form',
            name: 'Direct deposit',
            sequence: 0,
            action_type: ActionType.DIRECT_DEPOSIT_FORM,
            form: {
              questions: [] as QuestionFieldType[],
            },
            assignees: [] as AssigneeType[],
          },
        ],
      };
      break;
    case ModuleType.US_STATE_TAX:
      settings = {
        id: moduleType,
        description:
          'Automatically selects the correct US state tax form for new employee to electronically complete so that you can withhold the correct state income tax from their pay.',
        name: moduleName,
        module_type: moduleType,
        actions: [
          {
            description: 'Employee fills in US state tax form and signs the document.',
            name: 'Fill state tax form',
            sequence: 0,
            action_type: ActionType.EMPLOYEE_FILL_US_STATE_TAX_FORM,
          },
        ],
      };
      break;
    case ModuleType.PAYCHEX:
      settings = {
        id: moduleType,
        name: moduleName,
        module_type: moduleType,
        config: {
          default_company_id: '',
        },
        actions: [
          {
            assignees: [] as AssigneeType[],
            description: 'HR will review the collected information',
            name: 'Complete Paychex form',
            sequence: 1,
            action_type: ActionType.HR_FILL_PAYCHEX_FORM,
            form: {
              questions: [] as QuestionFieldType[],
            },
          },
          {
            description: 'Employee complete form',
            sequence: 0,
            name: 'Paychex form for employee',
            action_type: ActionType.EMPLOYEE_FILL_PAYCHEX_FORM,
            form: {
              questions: [] as QuestionFieldType[],
            },
          },
          {
            description: 'Reviewer will fill verify and send the details to Paychex',
            sequence: 2,
            name: 'Review Paychex form',
            action_type: ActionType.REVIEW_AND_SEND_TO_PAYCHEX,
            assignees: [] as AssigneeType[],
          },
        ],
      };
      break;
    case ModuleType.COMPANY_DOCUMENTS:
      settings = {
        id: moduleType,
        name: moduleName,
        module_type: moduleType,
        company_document_templates: [],
        actions: [
          {
            description: 'HR fills and signs form',
            name: 'Complete document',
            assignees: [] as AssigneeType[],
            sequence: 0,
            action_type: ActionType.HR_SIGN_COMPANY_DOCUMENTS,
          },
          {
            description: 'Employee fills and signs form',
            name: 'Complete document',
            sequence: 1,
            action_type: ActionType.EMPLOYEE_SIGN_COMPANY_DOCUMENTS,
            assignees: [] as AssigneeType[],
          },
        ],
      };
      break;
    case ModuleType.EHX_WOTC:
      settings = {
        id: moduleType,
        name: moduleName,
        module_type: moduleType,
        actions: [
          {
            name: 'Fill WOTC form',
            description: 'Employee fills up the EHX WOTC form',
            sequence: 0,
            action_type: ActionType.EHX_WOTC_EMPLOYEE_FORM,
          },
        ],
      };
      break;
    default:
      break;
  }
  return settings;
};

export const getCurrentTaskIdx = ({
  targetTask,
  tasks,
}: {
  targetTask: TaskItemWithConfigurationType;
  tasks: TaskItemWithConfigurationType[];
}) => {
  const targetTaskIndex = findIndex(tasks, (t) => {
    return t.id === targetTask.id;
  });
  return targetTaskIndex;
};
