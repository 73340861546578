import { compact, constant, dropRight, times, zip } from 'lodash';

import constants from '@/utils/constants';

import request from '../utils/request';
import { APIHOST } from '../config';
import { toFormData } from '../utils';

const { ONBOARDING_MODULE_TYPES } = constants;

const stringifySort = ({
  sortKey = '',
  sortOrder = 'descend',
}: {
  sortKey?: string;
  sortOrder?: 'ascend' | 'descend';
}) => {
  const separator = ',';
  const keys = compact(sortKey.split(separator));
  let orders = compact(sortOrder.split(separator));
  const diff = keys.length - orders.length;

  if (diff > 0) {
    orders.push(...times(diff, constant('descend')));
  } else if (diff < 0) {
    orders = dropRight(orders, Math.abs(diff));
  }

  const order = zip(keys, orders)
    .join(separator)
    .replace(/ascend/g, 'asc')
    .replace(/descend/g, 'desc');

  return order;
};

export async function fetchOnboardingSummary() {
  const path = '/v1/company_onboarding_processes/summary';
  return request('GET', APIHOST + path);
}

export async function fetchEmployeeList(data, cancelToken) {
  const {
    companyId,
    page,
    perPage,
    status = [],
    processes = [],
    positions = [],
    query = '',
    sortKey,
    sortOrder,
  } = data;

  const params = {
    ...(positions.length > 0 && positions[0] !== 'all' && { position_id: positions }),
    ...(processes.length > 0 && processes[0] !== 'all' && { onboarding_process_id: processes }),
    ...(status.length > 0 && status[0] !== 'all' && { status }),
    ...(page && { page }),
    ...(perPage && { per_page: perPage }),
    ...(query && { query }),
    ...(sortKey && sortOrder && { order: stringifySort({ sortKey, sortOrder }) }),
  };

  const path = '/v1/companies/:company_nickname/employees'.replace(':company_nickname', companyId);
  return request('GET', APIHOST + path, params, null, cancelToken);
}

export async function fetchOnboardingModules() {
  const path = '/v1/onboarding_modules';
  return request('GET', APIHOST + path);
}

export async function fetchExternalConnectors() {
  const path = '/v1/onboarding/microservices/hr/metadatas';
  return request('GET', APIHOST + path);
}

export async function updateExternalConnectorRemoteConfig(payload) {
  const { uuid, microservice_configuration_uuid, microservice_configuration_snapshot } = payload;
  const path = `/v1/onboarding/microservices/hr/company_onboarding_modules/${uuid}/update_remote_config`;
  return request('PUT', APIHOST + path, { microservice_configuration_uuid, microservice_configuration_snapshot });
}

// Processes
export async function createOnboardingProcess(payload) {
  const { newProcessData } = payload;
  const modules = newProcessData.modules.map((module) => {
    if (module.moduleType === ONBOARDING_MODULE_TYPES.MICROSERVICE) {
      const { configurationSnapshot, metaDataId, configurationId, status, sequence, moduleType, name } = module;
      const newModule = {
        name,
        module_type: moduleType,
        sequence,
        microservice_metadata_id: metaDataId,
        microservice_configuration_uuid: configurationId,
        microservice_configuration_status: status,
        microservice_configuration_snapshot: configurationSnapshot,
      };
      return newModule;
    } else {
      const actions = module.actions.map((action) => {
        const newAction = {
          ...action,
          action_type: action.type || action.action_type,
        };
        delete newAction.type;
        return newAction;
      });
      return {
        ...module,
        actions,
      };
    }
  });
  const data = {
    company_onboarding_process: {
      name: newProcessData.name,
      modules,
    },
  };

  const path = '/v1/company_onboarding_processes';
  return request('POST', APIHOST + path, toFormData(data));
}

export async function createFormSubmission(payload) {
  const { digest_key, form_payload } = payload;

  const data = { onboarding_form_submission: { answers: form_payload } };

  const path = `/v1/onboarding_form_submissions/${digest_key}`;
  return request('PUT', APIHOST + path, data);
}

export async function sendAdpFormtoADP(payload) {
  const { action_id, form_payload } = payload;

  const data = { onboarding_form_submissions: form_payload };

  const path = `/v1/employee_onboarding_actions/${action_id}/send_to_adp`;

  return request('POST', APIHOST + path, data);
}

export async function fetchOnboardingProcesses(payload) {
  const { page, perPage, sortKey, sortOrder } = payload;
  const data = {
    page,
    per_page: perPage,
    ...(sortKey && sortOrder && { ...{ order: `${sortKey},${sortOrder === 'ascend' ? 'asc' : 'desc'}` } }),
  };
  const path = '/v1/company_onboarding_processes';
  return request('GET', APIHOST + path, data);
}

export async function getOnboardingModuleDetails(payload) {
  const { moduleType } = payload;
  const path = '/v1/onboarding_modules/:onboarding_module_type'.replace(':onboarding_module_type', moduleType);
  return request('GET', APIHOST + path);
}

export async function fetchOnboardingProcessSettings(payload) {
  const { processId, employee_id } = payload;
  const data = {
    employee_id,
  };
  const path = '/v1/company_onboarding_processes/:id'.replace(':id', processId);
  return request('GET', APIHOST + path, data);
}

export async function fetchOnboardingModuleSettings(payload) {
  const { moduleId } = payload;
  const path = '/v1/company_onboarding_modules/:id'.replace(':id', moduleId);
  return request('GET', APIHOST + path);
}

export async function createOnboardingModule(payload) {
  const { settings, processId } = payload;
  const actions = settings.actions.map((action) => {
    const newAction = {
      ...action,
      action_type: action.type || action.action_type,
    };
    delete newAction.type;
    return newAction;
  });
  const path = '/v1/company_onboarding_processes/:id/modules'.replace(':id', processId);
  return request('POST', APIHOST + path, toFormData({ company_onboarding_module: { ...settings, actions } }));
}

export async function createOnboardingModules(payload) {
  const { processId } = payload;
  let { modules } = payload;
  modules = modules.map((module) => {
    if (module.id) return module; // Don't need to touch the existing module
    if (module.moduleType === ONBOARDING_MODULE_TYPES.MICROSERVICE) {
      const { configurationSnapshot, metaDataId, configurationId, status, sequence, moduleType, name } = module;
      const newModule = {
        sequence,
        name,
        module_type: moduleType,
        microservice_metadata_id: metaDataId,
        microservice_configuration_uuid: configurationId,
        microservice_configuration_status: status,
        microservice_configuration_snapshot: configurationSnapshot,
      };
      return newModule;
    } else {
      const actions = module.actions.map((action) => {
        const newAction = {
          ...action,
          action_type: action.type || action.action_type,
        };
        delete newAction.type;
        return newAction;
      });
      return {
        ...module,
        actions,
      };
    }
  });
  const path = '/v1/company_onboarding_processes/:id/create_modules'.replace(':id', processId);
  return request('POST', APIHOST + path, toFormData({ company_onboarding_modules: modules }));
}

export async function deleteOnboardingModule(payload) {
  const { moduleId } = payload;
  const path = '/v1/company_onboarding_modules/:id'.replace(':id', moduleId);
  return request('DELETE', APIHOST + path);
}

// - Rename process/module
export async function updateOnboardingProcess(payload) {
  const { processId, updates } = payload;
  const path = '/v1/company_onboarding_processes/:id'.replace(':id', processId);
  return request('PUT', APIHOST + path, { company_onboarding_process: { ...updates } });
}

export async function updateOnboardingModule(payload) {
  const { moduleId, updates } = payload;
  const path = '/v1/company_onboarding_modules/:id'.replace(':id', moduleId);
  return request('PUT', APIHOST + path, { company_onboarding_module: { ...updates } });
}

export async function resetOnboardingModuleActions(payload) {
  const { moduleId, action_ids: actionIds } = payload;
  const path = '/v1/company_onboarding_modules/:id/reset_actions'.replace(':id', moduleId);
  return request('POST', APIHOST + path, { action_ids: actionIds });
}

export async function reorderOnboardingModules(payload) {
  const { processId, sequences } = payload;
  const path = '/v1/company_onboarding_processes/:id/reorder_modules'.replace(':id', processId);
  return request('POST', APIHOST + path, { sequences });
}

export async function updateEmployeeOnboardingModule(payload) {
  const { moduleId, config } = payload;
  const path = '/v1/employee_onboarding_modules/:id'.replace(':id', moduleId);
  return request('POST', APIHOST + path, { employee_onboarding_module: { config: { ...config } } });
}

export async function resendOnboardingModules(payload) {
  const { moduleId } = payload;
  const path = '/v1/employee_onboarding_modules/:id/resend'.replace(':id', moduleId);
  return request('POST', APIHOST + path);
}

export async function resendOnboardingActions(payload) {
  const { actionIds } = payload;
  const path = '/v1/employee_onboarding_actions/restart_actions';
  return request('POST', APIHOST + path, { actions_ids: actionIds });
}

export async function updateOnboardingAction(payload) {
  const { action } = payload;
  const path = '/v1/company_onboarding_actions/:id'.replace(':id', action.id);
  return request('PUT', APIHOST + path, toFormData({ company_onboarding_action: { ...action } }));
}

export async function startOnboardingProcess(payload) {
  const { employeeId, processId } = payload;
  const path = '/v1/employees/:id/onboard'.replace(':id', employeeId);
  return request('POST', APIHOST + path, { onboarding_process_id: processId });
}

export async function createEmployeeOnboardingProcess(payload) {
  const { employeeId, processId } = payload;
  const path = '/v1/employee_onboarding_processes';
  return request('POST', APIHOST + path, {
    employee_id: employeeId,
    company_onboarding_process_id: processId,
  });
}

export async function fetchEmployeeOnboardingProcess(payload) {
  const { processId, actionId } = payload;
  const path = '/v1/employee_onboarding_processes/:digest_key'.replace(':digest_key', processId);
  return request('GET', APIHOST + path, {
    ...(actionId && { onboarding_action_id: actionId }),
  });
}

export async function signCompanyDocument(payload) {
  const { employee_onboarding_action_digest_key, related_signature_id } = payload;
  const path = `/v1/onboarding/hr/employee_onboarding_actions/${employee_onboarding_action_digest_key}/employee_offer_signers/${related_signature_id}`;
  return request('PUT', APIHOST + path, { employee_offer_signer: { status: 'signed' } });
}

export async function fetchCompanyDoc(payload) {
  const { digestKey } = payload;
  const path = `/v1/onboarding/signing/hr/employee_onboarding_offers/${digestKey}/get_template_file`;
  return request('GET', APIHOST + path);
}

export async function changeOnboardingProcess(payload) {
  const { employeeId, processId } = payload;
  const path = '/v1/employees/:id/replace_process'.replace(':id', employeeId);
  return request('POST', APIHOST + path, { onboarding_process_id: processId });
}

export async function stopOnboardingProcess(payload) {
  const { digestKey } = payload;
  const path = '/v1/employee_onboarding_processes/:digest_key'.replace(':digest_key', digestKey);
  return request('DELETE', APIHOST + path);
}

export async function approveEmployeeOnboardingProcess(payload) {
  const { actionId, data } = payload;
  const path = '/v1/employee_onboarding_actions/:id/approve'.replace(':id', actionId);
  return request('POST', APIHOST + path, toFormData(data));
}

export async function checkProcessNameUniqueness(payload) {
  const { name } = payload;
  const path = '/v1/company_onboarding_processes/uniqueness';
  return request('GET', APIHOST + path, { name });
}

export async function browseEmployee(payload) {
  const { employeeId, searchParams = {} } = payload;
  const path = '/v1/employees/:id/browse'.replace(':id', employeeId);

  const { page, perPage, status, processes = [], positions = [], query = '' } = searchParams;

  const params = {
    ...(positions && positions.length > 0 && positions[0] !== 'all' && { position_id: positions }),
    ...(processes && processes.length > 0 && processes[0] !== 'all' && { onboarding_process_id: processes }),
    ...(status && status.length > 0 && status[0] !== 'all' && { status }),
    ...(page && { page }),
    ...(perPage && { per_page: perPage }),
    ...(query && { query }),
  };

  return request('GET', APIHOST + path, { ...params });
}

export async function updateEmployee(payload) {
  const {
    employeeId,
    employee: { employee_information_attributes: employeeInformationAttributes = {} },
  } = payload;

  const {
    id,
    basic_information: basicInformation,
    address,
    emergency_contact: emergencyContact,
    employment_details: employmentDetails,
    work_location: workLocation,
    compensation,
  } = employeeInformationAttributes;

  let attributes = { id };
  if (basicInformation) attributes = { ...attributes, basic_information: basicInformation };
  if (address) attributes = { ...attributes, address };
  if (emergencyContact) attributes = { ...attributes, emergency_contact: emergencyContact };
  if (employmentDetails) attributes = { ...attributes, employment_details: employmentDetails };
  if (workLocation) attributes = { ...attributes, work_location: workLocation };
  if (compensation) attributes = { ...attributes, compensation };

  const params = {
    employee: {
      employee_information_attributes: attributes,
    },
  };

  const path = '/v1/employees/:id'.replace(':id', employeeId);

  return request('PUT', APIHOST + path, params);
}

export async function removeEmployee(payload) {
  const { employeeId } = payload;
  const path = '/v1/employees/:id/suspend'.replace(':id', employeeId);
  return request('PATCH', APIHOST + path);
}

export async function reinstateEmployee(payload) {
  const { employeeId } = payload;
  const path = '/v1/employees/:id/reinstate'.replace(':id', employeeId);
  return request('PATCH', APIHOST + path);
}

export async function updateEmployeeInfo(payload) {
  const { id, ...data } = payload;

  const path = '/v1/employee_informations/:id'.replace(':id', id);

  return request('PUT', APIHOST + path, data);
}

export async function updateEmployeeStateTaxInfo(payload) {
  const { id, ...data } = payload;
  const path = '/v1/employee_state_income_taxes/:id'.replace(':id', id);

  return request('PUT', APIHOST + path, data);
}

export async function prepareHSTemplate(payload) {
  const { signers, templateFile } = payload;
  const data = {
    offer: {
      signers,
      ...(templateFile.url && { template_file_url: templateFile.url }),
      ...(!templateFile.url && { template_file: templateFile }),
    },
  };
  const path = '/v1/onboarding_offers/prepare_hello_sign_template';
  return request('POST', APIHOST + path, toFormData(data));
}

export async function getHSTemplate(payload) {
  const { id } = payload;

  const path = '/v1/onboarding_offers/:id/hello_sign_template'.replace(':id', id);
  return request('GET', APIHOST + path);
}

export async function createOnboardingAction(payload) {
  const { moduleId, actionData } = payload;
  const path = '/v1/company_onboarding_modules/:id/company_onboarding_actions'.replace(':id', moduleId);

  return request(
    'POST',
    APIHOST + path,
    toFormData({
      company_onboarding_action: {
        ...actionData,
      },
    }),
  );
}

export async function reorderOnboardingAction(payload) {
  const { sequences, moduleId } = payload;
  const path = '/v1/company_onboarding_modules/:id/reorder_actions'.replace(':id', moduleId);
  return request('POST', APIHOST + path, { sequences });
}

export async function reorderSigners(payload) {
  const { sequences, moduleId } = payload;
  const path = '/v1/company_onboarding_modules/:id/reorder_signers'.replace(':id', moduleId);
  return request('POST', APIHOST + path, { sequences });
}

export async function deleteAction(payload) {
  const { id } = payload;
  const path = '/v1/company_onboarding_actions/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function reorderActions(payload) {
  const { sequences, moduleId } = payload;
  const path = '/v1/company_onboarding_modules/:id/reorder_actions'.replace(':id', moduleId);
  return request('POST', APIHOST + path, { sequences });
}

export async function deleteProcess(payload) {
  const { id } = payload;
  const path = '/v1/company_onboarding_processes/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function duplicateProcess(payload) {
  const { id } = payload;
  const path = '/v1/company_onboarding_processes/:id/duplicate'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function downloadDocumentBundleable(payload) {
  const { bundleableType, bundleableId } = payload;
  const path = `/v1/onboarding/hr/document_bundleables/${bundleableId}?bundleable_type=${bundleableType}`;

  const cancelToken = undefined;
  const data = null;
  const responseType = 'blob';

  return request('GET', APIHOST + path, data, 'en', cancelToken, responseType);
}

export async function checkDocumentBundleableStatus(payload) {
  const { bundleableType, bundleableId } = payload;
  const path = `/v1/onboarding/hr/document_bundleables/${bundleableId}/check_status?bundleable_type=${bundleableType}`;

  return request('GET', APIHOST + path);
}

export async function fetchADPTemplates() {
  const path = '/v1/onboarding_modules/adp/templates';
  return request('GET', APIHOST + path);
}

export async function fetchADPTemplateFields(payload) {
  const { code, status } = payload;
  const path = `/v1/onboarding_modules/adp/templates/:code?onboarding_status=${status}`.replace(':code', code);
  return request('GET', APIHOST + path);
}

export async function getUsStateTaxFormSubmission(payload: { employee_onboarding_action_digest_key: string }) {
  const { employee_onboarding_action_digest_key } = payload;
  const path =
    '/v1/onboarding/us_state_tax/employee/employee_onboarding_actions/:employee_onboarding_action_digest_key/us_state_tax_form_submission'.replace(
      ':employee_onboarding_action_digest_key',
      employee_onboarding_action_digest_key,
    );
  return request('GET', APIHOST + path);
}

export async function downloadAllAttachments(payload) {
  const { employeeId } = payload;
  const locale = 'en';
  const cancelToken = null;
  const data = null;
  const responseType = 'blob';
  const path = '/v1/employees/:id/download_all_attachments'.replace(':id', employeeId);
  return request('GET', APIHOST + path, data, locale, cancelToken, responseType);
}

export async function wotcReadyCheck(payload) {
  const path = `/v1/employees/${payload.employeeId}/wotc_ready_check`;
  return request('GET', APIHOST + path);
}

export async function uscisReadyCheck(payload) {
  const path = `/v1/employees/${payload.employeeId}/uscis_ready_check`;
  return request('GET', APIHOST + path);
}

export async function sendOnboardingLink(payload) {
  const { digest_key, sendSMS: send_sms, sendEmail: send_email, message } = payload;

  const data = { send_sms, send_email, message };

  const path = `/v1/employee_onboarding_processes/${digest_key}/send_onboarding_link`;
  return request('POST', APIHOST + path, toFormData(data));
}

export async function updateI9FormSubmission(payload) {
  const { digestKey, data } = payload;
  const path = `${APIHOST}/v1/i9_form_submissions/${digestKey}`;
  return request('PUT', path, { data: { ...data } });
}

export async function generateI9SignUrl(payload) {
  const { digestKey } = payload;
  const path = `${APIHOST}/v1/i9_form_submissions/${digestKey}/sign`;
  return request('POST', path);
}

export async function markI9SignedUrl(payload) {
  const { digestKey } = payload;
  const path = `${APIHOST}/v1/i9_form_submissions/${digestKey}/signed`;
  return request('POST', path);
}

export async function fetchExternalConnectorsTaskUrl(payload) {
  const { actionUUID } = payload;
  const path = `/v1/onboarding/microservices/hr/employee_onboarding_actions/${actionUUID}`;
  return request('GET', APIHOST + path);
}

export async function markActionAsCompleted(payload) {
  const { digestKey } = payload;
  const path = `/v1/onboarding/hr/employee_onboarding_actions/${digestKey}/completed`;
  return request('PUT', APIHOST + path);
}

export async function fetchIntegrationStatus(payload) {
  const { company_id, integrations } = payload;
  const path = `/v1/onboarding/hr/companies/${company_id}/integration_status`;
  return request('POST', APIHOST + path, { integrations });
}

export async function markModuleManuallyCompleted(payload) {
  const { module_id } = payload;
  const path = `/v1/employee_onboarding_modules/${module_id}/manually_complete`;
  return request('POST', APIHOST + path);
}

export async function markModuleAsIncomplete(payload) {
  const { module_id } = payload;
  const path = `/v1/employee_onboarding_modules/${module_id}/mark_as_incomplete`;
  return request('POST', APIHOST + path);
}
