// TODO: too many lint issues here, fix them next time
/* eslint-disable */
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Text, colors } from '@helloworld1812/ws-components';
import styled, { css } from 'styled-components';

import { formatSSN } from '@/utils';
import PIIField from '@/components/pii-field';

import constants from '../../common/constants';
import { QuestionFieldType } from '../../../process-setup/interface';
import { EmployeeOnboardingAction } from '../../../interface';

export function validateNRIC(str) {
  if (str.length !== 9) return false;

  str = str.toUpperCase();

  let i;
  const icArray = [];
  for (i = 0; i < 9; i++) {
    icArray[i] = str.charAt(i);
  }

  icArray[1] = parseInt(icArray[1], 10) * 2;
  icArray[2] = parseInt(icArray[2], 10) * 7;
  icArray[3] = parseInt(icArray[3], 10) * 6;
  icArray[4] = parseInt(icArray[4], 10) * 5;
  icArray[5] = parseInt(icArray[5], 10) * 4;
  icArray[6] = parseInt(icArray[6], 10) * 3;
  icArray[7] = parseInt(icArray[7], 10) * 2;

  let weight = 0;
  for (i = 1; i < 8; i++) {
    weight += icArray[i];
  }

  const offset = icArray[0] == 'T' || icArray[0] == 'G' ? 4 : 0;
  const temp = (offset + weight) % 11;

  const st = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
  const fg = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];

  let theAlpha;
  if (icArray[0] == 'S' || icArray[0] == 'T') {
    theAlpha = st[temp];
  } else if (icArray[0] == 'F' || icArray[0] == 'G') {
    theAlpha = fg[temp];
  }

  return icArray[8] === theAlpha;
}

// validateEmail() https://stackoverflow.com/a/46181
export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getRegularExpression(countryCode, identityType, stateCode, identityConfig) {
  const countryInfoFound = identityConfig.identity_regexes.filter(
    (countryInfo) => countryInfo.country_code === countryCode,
  )[0];
  if (!countryInfoFound) return undefined;

  const identityInfoFound = countryInfoFound.identity_types.filter(
    (identityInfo) => identityInfo.value === identityType,
  )[0];
  if (!identityInfoFound) return undefined;

  // No state means there is no one more level nested (eg: none drive license identity type)
  if (stateCode === undefined) return identityInfoFound.regex;

  const stateInfoFound = identityInfoFound.states.filter((stateInfo) => stateInfo.state_code === stateCode)[0];

  if (!stateInfoFound) return undefined;

  return stateInfoFound.regex;
}

export const getErrorMessage = (value, rules) => {
  // Replace all the start white spaces to prevent by-passing the
  // required fields only with white space value like '  '
  // We only operate on string value for now
  if (typeof value === 'string') {
    value = value.replace(/^\s*/g, '');
  }
  if (value === '' || value === null || value === undefined) return 'This is a mandatory field.';
  // multiple_selection has empty array as default empty value,
  // need to check array length
  if (value.length === 0) return 'This is a mandatory field.';
  if (!rules || rules.length === 0 || rules[0] === null) return undefined;
  let message;
  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];
    const { category: categoryValue, action: actionValue, value: ruleValue, customErrorMessage } = rule;
    if (categoryValue === 'number') {
      if (!isNumeric(value)) {
        message = 'Please enter a valid number';
        break;
      } else if (actionValue === 'gte' && Number(value) < ruleValue) {
        message = `Please enter a number greater than or equal to ${ruleValue}`;
        break;
      } else if (actionValue === 'lte' && Number(value) > ruleValue) {
        message = `Please enter a number less than or equal to ${ruleValue}`;
      } else if (rule.gte || rule.lte) {
        if (Number(value) < rule.gte) {
          message = `Please enter a number greater than or equal to ${rule.gte}`;
          break;
        } else if (Number(value) > rule.lte) {
          message = `Please enter a number less than or equal to ${rule.lte}`;
          break;
        }
      }
    } else if (categoryValue === 'length') {
      if (actionValue === 'min' && value.length < ruleValue) {
        message = `Your response is too short. The minimum character limit is ${ruleValue}`;
        break;
      }
      if (actionValue === 'max' && value.length > ruleValue) {
        message = `Your response is too long. The maximum character limit is ${ruleValue}`;
        break;
      }
    }
    if (categoryValue === 'regex') {
      if (actionValue === 'match' && !new RegExp(ruleValue).test(value)) {
        message = customErrorMessage || 'Please enter a valid response';
        break;
      }
      if (actionValue === 'unmatch' && new RegExp(ruleValue).test(value)) {
        message = customErrorMessage || 'Please enter a valid response';
        break;
      }
    } else if (categoryValue === 'date') {
      const userInputDate = new Date(value);
      const ruleDateValue = new Date(ruleValue);
      const ruleDateMin = new Date(rule.min);
      const ruleDateMax = new Date(rule.max);
      if (actionValue === 'lt' && userInputDate >= ruleDateValue) {
        message = `Please enter a date before ${ruleValue}`;
        break;
      } else if (actionValue === 'lte' && userInputDate > ruleDateValue) {
        message = `Please enter a date on or before ${ruleValue}`;
        break;
      } else if (actionValue === 'gt' && userInputDate <= ruleDateValue) {
        message = `Please enter a date after ${ruleValue}`;
        break;
      } else if (actionValue === 'gte' && userInputDate < ruleDateValue) {
        message = `Please enter a date on or after ${ruleValue}`;
        break;
      } else if (actionValue === 'between' && (userInputDate < ruleDateMin || userInputDate > ruleDateMax)) {
        message = `Please enter a date on or between ${rule.min} and ${rule.max}`;
        break;
      } else if (actionValue === 'unbetween' && (userInputDate <= ruleDateMin || userInputDate >= ruleDateMax)) {
        message = `Please enter a date between ${rule.min} and ${rule.max}`;
        break;
      }
    }
  }
  return message;
};

export const getStates = (countryCode, identityType, identityConfig) => {
  const countryInfo = identityConfig.identity_regexes.filter(
    (countryInfo) => countryInfo.country_code === countryCode,
  )[0];
  if (!countryInfo) return [];

  const identityInfo = countryInfo.identity_types.filter((identityInfo) => identityInfo.value === identityType)[0];
  if (!identityInfo) return [];
  return identityInfo.states;
};

export const reformatSSN = (val) => {
  val = val.replace(/\D/g, '');
  val = val.replace(/^(\d{3})/, '$1-');
  val = val.replace(/-(\d{2})/, '-$1-');
  val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
  return val;
};

export const getEmployeeFormSubmission = (action: EmployeeOnboardingAction) => {
  const employeeFormSubmission =
    _.find(action.form_submissions, (s) => s.employee_onboarding_action.action_type === 'employee_fill_form') || {};

  return employeeFormSubmission;
};

export const preprocessingQuestions = (questions: QuestionFieldType[]) => {
  return questions.map((question) => {
    const { question_type: questionType } = question;
    if (question.answer) {
      if (questionType === 'attachment') {
        const answer = {
          ...question.answer,
          answer: {
            ...question.answer.answer,
            [question.id]: question.answer.answer.file_name,
          },
        };
        return {
          ...question,
          answer,
        };
      } else {
        return question;
      }
    }
    const answer = {
      answer: {
        [question.id]: (() => {
          let value = '';
          if (questionType === 'multiple_selection') {
            value = [];
          }
          return value;
        })(),
      },
    };
    if (question.question_type === 'dl') {
      answer.answer.state_code = '';
    }
    return {
      ...question,
      answer,
    };
  });
};

export const renderAnswers = (question: QuestionFieldType) => {
  const { question_type, answer } = question;
  let answerArray: string[];
  if (answer && answer.answer) {
    answerArray = Object.values(answer.answer);
  } else {
    return null;
  }
  let renderAnswers;
  switch (question_type) {
    case constants.QUESTION_TYPES.SHORT_ANSWERS:
    case constants.QUESTION_TYPES.PARAGRAPH_ANSWERS:
    case constants.QUESTION_TYPES.MULTIPLE_CHOICE_ANSWERS:
    case constants.QUESTION_TYPES.DROPDOWN_ANSWERS:
    case constants.QUESTION_TYPES.TELEPHONE_ANSWERS:
    case constants.QUESTION_TYPES.EMAIL_ANSWERS:
    case constants.QUESTION_TYPES.ID_NUMBER_ANSWERS: {
      if (answerArray[0].length === 0) return null;
      renderAnswers = answerArray[0];

      if (question.identity_type === 'ssn') {
        renderAnswers = <PIIField value={formatSSN(renderAnswers)} />;
      }

      break;
    }
    case constants.QUESTION_TYPES.FILE_UPLOADS: {
      const answers = answer.answer;
      const hyperlink = answers.attachment ? URL.createObjectURL(answers.attachment) : answers.url || answers.pdf_url;
      const originalFileName = answers.attachment
        ? answers.attachment.name
        : answers.file_name || answers.pdf_file_name;
      if (!hyperlink || !originalFileName) return null;
      renderAnswers = (
        <a href={hyperlink} target="_blank" rel="noreferrer">
          <Text color="blue500">{originalFileName}</Text>
        </a>
      );
      break;
    }
    case constants.QUESTION_TYPES.CHECKBOX_ANSWERS: {
      renderAnswers = answerArray[0].toString();
      break;
    }
    case constants.QUESTION_TYPES.DATE_ANSWERS: {
      if (!answerArray[0]) return;
      const date = moment(answerArray[0]);
      renderAnswers = date.format('LL');
      break;
    }
    default:
      renderAnswers = <div>Fail to render answers</div>;
  }
  return renderAnswers;
};

export const QuestionText = styled(Text).attrs({ fontSize: '14px', fontWeight: 'semibold' })`
  ${(props) =>
    props.isRequired &&
    css`
      &:after {
        content: '*';
        color: red;
        margin-left: 4px;
      }
    `}
`;
export const AnswerText = styled(Text).attrs({ fontSize: '14px', fontWeight: 'normal' })``;

export const ErrorText = styled(Text).attrs({ fontSize: '12px', fontWeight: 'semibold' })`
  color: ${({ theme }) => theme.colors.red500};
`;

export const isDriveLicenseQuestion = (question) => {
  return question.question_type === 'identity' && question.identity_type === 'dl';
};
