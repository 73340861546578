import * as services from '../../services/employeeOnboarding';

export const initialState = {
  employee: undefined,
  application: undefined, // this is for display the data in the info tab
  nextEmployeeId: undefined,
  prevEmployeeId: undefined,
  basicInformationModule: undefined,
  employeeAttachment: [],
};

export const reducers = {
  browseEmployeeSuccess(state, { payload }) {
    const { employee, next_employee_id: nextEmployeeId, prev_employee_id: prevEmployeeId } = payload;
    return {
      ...state,
      employee,
      ...(nextEmployeeId && { nextEmployeeId }),
      ...(prevEmployeeId && { prevEmployeeId }),
    };
  },
  updateEmployeeSuccess(state, { payload }) {
    const { employee } = payload;
    return {
      ...state,
      employee,
    };
  },
  updateEmployeeInfoSuccess(state, { payload }) {
    const { employee_information: employeeInfo } = payload;
    return {
      ...state,
      employee: {
        ...state.employee,
        employee_information: employeeInfo,
      },
    };
  },
  updateEmployeeStateTaxInfoSuccess(state, { payload }) {
    const { employee_state_income_tax: employeeStateTaxInfo } = payload;
    return {
      ...state,
      employee: {
        ...state.employee,
        employee_state_income_tax: employeeStateTaxInfo,
      },
    };
  },
  fetchBasicInformationModuleSuccess(state, { payload }) {
    const { company_onboarding_module: basicInformationModule = {} } = payload;
    return {
      ...state,
      basicInformationModule,
    };
  },

  resetEmployeeProfile() {
    return { ...initialState };
  },
};

export const effects = {
  *browseEmployee({ payload = {} }, { call, put }) {
    const response = yield call(services.browseEmployee, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'browseEmployeeSuccess',
      payload: response.data,
    });
    return response;
  },

  *updateEmployee({ payload = {} }, { call, put }) {
    const response = yield call(services.updateEmployee, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateEmployeeSuccess',
      payload: response.data,
    });
  },

  *removeEmployee({ payload = {} }, { call, put }) {
    const response = yield call(services.removeEmployee, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateEmployeeSuccess',
      payload: response.data,
    });
  },

  *reinstateEmployee({ payload = {} }, { call, put }) {
    const response = yield call(services.reinstateEmployee, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateEmployeeSuccess',
      payload: response.data,
    });
  },

  *updateEmployeeInfo({ payload = {} }, { call, put }) {
    const response = yield call(services.updateEmployeeInfo, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateEmployeeInfoSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *updateEmployeeStateTaxInfo({ payload = {} }, { call, put }) {
    const response = yield call(services.updateEmployeeStateTaxInfo, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateEmployeeStateTaxInfoSuccess',
      payload: response.data,
    });
  },

  *browseEmployeeWithoutUpdateNextAndPrev({ payload = {} }, { call, put }) {
    const response = yield call(services.browseEmployee, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'browseEmployeeSuccess',
      payload: { employee: response.data.employee },
    });
  },

  *fetchBasicInformationModule({ payload }, { call, put }) {
    const response = yield call(services.fetchOnboardingModuleSettings, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'fetchBasicInformationModuleSuccess',
      payload: response.data,
    });
    return response.data;
  },
  *downloadAllAttachments({ payload }, { call }) {
    const { employeeId } = payload;
    const response = yield call(services.downloadAllAttachments, {
      employeeId,
    });

    if (response.error) {
      throw response.error;
    }
    return response;
  },
};

export default {
  namespace: 'employeeProfile',
  state: initialState,
  reducers,
  effects,
};
