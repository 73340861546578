import { paths, components, operations } from './generated/ws-internal-api-definitions';

export type getKey<T, K> = K extends keyof T ? NonNullable<T>[K] : never;

export type WithRequired<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

export type ArrayElement<ArrType> = ArrType extends readonly (infer ElementType)[] ? ElementType : never;

export type WsInternalApiPaths = Omit<
  paths,
  | '/v1/er/bulk_imports'
  | '/v1/er/documents/{id}/upload'
  | '/v1/er/company_document_templates/{id}'
  | '/v1/er/company_document_templates/prepare_template'
  | '/v1/er/company_document_templates'
  | '/v1/onboarding/e_verify/hr/cases/{uuid}/scan_and_upload'
  | '/v1/user/{id}/permissions'
  | '/v1/user/{user_id}/permissions/check'
>;
export type WsInternalApiSchemas = components['schemas'];

export type EmployerTaxInfo = components['schemas']['employer_tax_info_with_inclusion'];
export type LocationPlain = components['schemas']['location_plain'];
export type EverifyClientPlain = components['schemas']['client_plain'];

export type CreateEVerifyClientPlain = getKey<
  getKey<NonNullable<components['requestBodies']['create_client']>, 'content'>,
  'application/json'
>;

type EverifyCaseType = ArrayElement<
  getKey<NonNullable<operations['hrRetrieveCases']['responses'][200]['content']['application/json']>, 'data'>
>;

export enum EverifyCaseStatusType {
  NOT_STARTED = 'NOT_STARTED',
  DRAFT = 'DRAFT',
  SUBMISSION_FAILED = 'SUBMISSION_FAILED',
  UNCONFIRMED_DATA = 'UNCONFIRMED_DATA',
  PHOTO_MATCH = 'PHOTO_MATCH',
  SCAN_AND_UPLOAD = 'SCAN_AND_UPLOAD',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  PENDING_REFERRAL = 'PENDING_REFERRAL',
  REFERRED = 'REFERRED',
  QUEUED = 'QUEUED',
  FINAL_NONCONFIRMATION = 'FINAL_NONCONFIRMATION',
  CLOSE_CASE_AND_RESUBMIT = 'CLOSE_CASE_AND_RESUBMIT',
  EMPLOYMENT_AUTHORIZED = 'EMPLOYMENT_AUTHORIZED',
  CLOSED = 'CLOSED',
}

export type EverifyCase = WithRequired<
  Omit<EverifyCaseType, 'status'> & {
    status: EverifyCaseStatusType;
    error_message?: string;
  },
  'uuid'
>;

export type EverifyCasePlain = components['schemas']['case_plain'];

export type CompanyCheckrNodeSchema = components['schemas']['company_checker_node'];
export type CompanyCheckrPackageSchema = components['schemas']['company_checker_package'];

export type CompanyCheckrNode = WithRequired<CompanyCheckrNodeSchema, 'id'>;

// Direct use CompanyCheckrPackageSchema CompanyCheckrPackage as  after Ryan fix api doc
export interface Screening {
  type: string;
  subtype: null | string;
}
export type CompanyCheckrPackage = Omit<WithRequired<CompanyCheckrPackageSchema, 'id'>, 'screenings'> & {
  screenings: Screening[];
};

export type EhxAccountSyncTask = components['schemas']['sync_task'];
export type EhxLocation = components['schemas']['location-2'];
export type EhxAccount = WithRequired<
  getKey<NonNullable<operations['retrieveEhxAccount']['responses'][200]['content']>, 'application/json'>,
  'id' | 'masked_guid'
>;

export type EhxLocationBinding = WithRequired<components['schemas']['location_binding'], 'id'>;
