function addLevelInfoToItem(item, level, ancestors) {
  const result = { ...item, level, ...(ancestors && { ancestors }) };
  if (!item.children) return result;
  if (item.children.length === 0) return result;
  const nextLevel = level + 1;
  // eslint-disable-next-line
  ancestors = [...ancestors, item.key];
  result.children = result.children.map((record) => addLevelInfoToItem(record, nextLevel, ancestors));
  return result;
}

function flattenAvailablePermissionsFn(acc, item) {
  if (!item.children) return [...acc, item];
  if (item.children.length === 0) return [...acc, item];
  const newAcc = [item, ...item.children.reduce(flattenAvailablePermissionsFn, [...acc])];
  return newAcc;
}

function parseAvailablePermissions(rawAvailablePermissions: unknown[]) {
  const availablePermissions = rawAvailablePermissions.map((item) => {
    return addLevelInfoToItem(item, 0, []);
  });

  const flattenAvailablePermissions = availablePermissions.reduce(flattenAvailablePermissionsFn, []);

  return { availablePermissions, flattenAvailablePermissions };
}

export { flattenAvailablePermissionsFn, parseAvailablePermissions };
