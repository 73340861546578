import { ModuleType, IntegrationStatus } from './interface';

export const ALL_STEPS = ['NameProcessStep', 'SelectTasksStep', 'ConfigureTasksStep', 'ReviewAndSaveStep'];

export const REQURIED_TASK_TYPES: ModuleType[] = [ModuleType.BASIC_INFORMATION];
export const GOVERMENT_TASK_TYPES: ModuleType[] = [ModuleType.FORMI9_COMPLIANCE_EVERIFY, ModuleType.I9, ModuleType.W4];
export const FINANCIAL_TASK_TYPES: ModuleType[] = [
  ModuleType.ADP,
  ModuleType.US_STATE_TAX,
  ModuleType.WOTC,
  ModuleType.EHX_WOTC,
  ModuleType.DIRECT_DEPOSIT,
  ModuleType.PAYCHEX,
];
export const CUSTOM_TASK_TYPES: ModuleType[] = [ModuleType.COLLECT_INFO];
export const NOT_VALID_TASK_TYPES: ModuleType[] = [ModuleType.SIGNING];

export const SUPPORT_MULTIPLE_TASK_TYPES: ModuleType[] = [ModuleType.COLLECT_INFO];

export const NO_CONFIG_TASK_TYPES: ModuleType[] = [ModuleType.W4, ModuleType.US_STATE_TAX, ModuleType.EHX_WOTC];

export const DISABLED_INTEGRATION_STATUS: IntegrationStatus[] = [
  IntegrationStatus.INTEGRATION_NOT_FOUND,
  IntegrationStatus.STATUS_NOT_AVAILABLE,
  IntegrationStatus.NOT_READY,
  IntegrationStatus.INVALID_COMPANY_ACCESSED,
];

export const MessageService = {
  configurationCanceled: 'ws.onboardingProcess.config.configurationCanceled',
  configurationPartiallySaved: 'ws.onboardingProcess.config.configurationPartiallySaved',
  configurationCompleted: 'ws.onboardingProcess.config.configurationCompleted',
  configurationSkiped: 'ws.onboardingProcess.config.configurationSkiped',
  processCanceled: 'ws.onboardingProcess.config.processCanceled',
  taskInProgressing: 'ws.onboardingProcess.task.taskInProgressing',
  taskCompleted: 'ws.onboardingProcess.task.taskCompleted',
  taskFillingCanceled: 'ws.onboardingProcess.task.taskFillingCanceled',
};
