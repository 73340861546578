import request from '../utils/request';
import { APIHOST } from '../config';

export async function fetchPermittedLocationGroups() {
  const path = '/v1/company_staffs/location_groups';
  return request('GET', APIHOST + path);
}

export async function fetchPermittedLocations(params = {}) {
  const { filter_group: filterGroup = '' } = params;
  const path = '/v1/company_staffs/locations';
  const data = { filter_group: filterGroup };
  return request('GET', APIHOST + path, data);
}

export async function fetchPermittedPositionsOfLocation(params = {}) {
  const { id, search = '' } = params;
  const path = `/v1/locations/${id}/positions${search}`;
  return request('GET', APIHOST + path);
}

export async function fetchEmployerTaxInfos() {
  const path = '/v1/employer_tax_infos';
  return request('GET', APIHOST + path);
}

export async function fetchPermittedDepartments() {
  const path = '/v1/company_staffs/departments';
  return request('GET', APIHOST + path);
}

export async function createLocation(payload) {
  const {
    brand_id,
    identify_code: identifyCode,
    name,
    country,
    state,
    address_line_1,
    address_line_2,
    full_address,
    city,
    postal_code,
    latitude,
    longitude,
    is_remote,
    employer_tax_info_attributes,
    employer_tax_info_id,
    custom_field_values,
    google_place_id,
  } = payload;

  const path = '/v1/locations';
  return request('POST', APIHOST + path, {
    location: {
      brand_id,
      identify_code: identifyCode,
      name,
      address_line_1,
      address_line_2,
      full_address,
      country,
      state,
      city,
      postal_code,
      latitude,
      longitude,
      is_remote,
      employer_tax_info_attributes,
      employer_tax_info_id,
      custom_field_values,
      google_place_id,
    },
  });
}

export async function createLocations(payload) {
  const path = '/v1/locations/create_multiple';
  return request('POST', APIHOST + path, payload);
}

export async function createOrUpdateLocations(payload) {
  const path = '/v1/locations/create_or_update_multiple';
  return request('POST', APIHOST + path, payload);
}

export async function updateLocation(payload) {
  const {
    id,
    brand_id,
    identify_code: identifyCode,
    name,
    country,
    state,
    full_address,
    address_line_1,
    address_line_2,
    city,
    postal_code,
    latitude,
    longitude,
    is_remote,
    employer_tax_info_attributes,
    employer_tax_info_id,
    custom_field_values,
    google_place_id,
  } = payload;

  const path = '/v1/locations/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, {
    location: {
      brand_id,
      identify_code: identifyCode,
      name,
      country,
      state,
      full_address,
      address_line_1,
      address_line_2,
      city,
      postal_code,
      latitude,
      longitude,
      is_remote,
      employer_tax_info_attributes,
      employer_tax_info_id,
      custom_field_values,
      google_place_id,
    },
  });
}

export async function deleteLocation(payload) {
  const { id } = payload;
  const path = '/v1/locations/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function createDepartment(payload) {
  const { name, description, custom_field_values } = payload;

  const path = '/v1/departments';
  return request('POST', APIHOST + path, {
    department: {
      name,
      description,
      custom_field_values,
    },
  });
}

export async function updateDepartment(payload) {
  const { id, name, description, custom_field_values } = payload;

  const path = '/v1/departments/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, {
    department: {
      name,
      description,
      custom_field_values,
    },
  });
}

export async function deleteDepartment(payload) {
  const { id } = payload;
  const path = '/v1/departments/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function fetchStaffsInLocation(payload) {
  const { id } = payload;
  const path = `/v1/locations/${id}/company_staffs`;
  return request('GET', APIHOST + path);
}

export async function fetchPaginatedStaffsInLocation(payload) {
  const { id } = payload;
  const path = `/v1/locations/${id}/staffs`;
  return request('GET', APIHOST + path, payload);
}

export async function updateCompanyStaffsInLocation(payload) {
  const { id } = payload;
  const path = `/v1/locations/${id}/update_company_staffs`;
  return request('POST', APIHOST + path, payload);
}

export async function getLatLonByIp() {
  return request('GET', `${APIHOST}/v1/locations/ip_latlon`);
}
