import request from '../../utils/request';
import { APIHOST } from '../../config';
import { toFormData } from '../../utils';

export async function fetchEverifyIntegrations() {
  return request('GET', `${APIHOST}/v1/onboarding/formi9_compliance/hr/companies`);
}

export async function fetchEverifyIntegration(payload) {
  const { id } = payload;
  return request('GET', `${APIHOST}/v1/onboarding/formi9_compliance/hr/companies/${id}`);
}

export async function createEverifyIntegration(payload) {
  const { data } = payload;
  const formData = toFormData({ formi9_compliance_company: data });
  const path = '/v1/onboarding/formi9_compliance/hr/companies';
  return request('POST', APIHOST + path, formData);
}

export async function updateEverifyIntegration(payload) {
  const { id, data } = payload;
  const path = `/v1/onboarding/formi9_compliance/hr/companies/${id}`;
  const formData = toFormData({ formi9_compliance_company: data });

  return request('PUT', APIHOST + path, formData);
}

export async function submitEverifyIntegration(payload) {
  const { id } = payload;
  const path = `/v1/onboarding/formi9_compliance/hr/companies/${id}/submit`;

  return request('PUT', APIHOST + path);
}

export async function deleteEverifyIntegration(payload) {
  const { id } = payload;
  const path = `/v1/onboarding/formi9_compliance/hr/companies/${id}`;

  return request('DELETE', APIHOST + path);
}
