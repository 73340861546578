import _ from 'lodash';

import constants from '@/utils/constants';
import * as positionsHelpers from '@/helpers/positions';

import request from '../utils/request';
import { APIHOST } from '../config';
import { toFormData } from '../utils';

export async function fetchPositionJobDetails(data) {
  const { digestkey } = data;
  const path = '/v1/positions/:digest_key'.replace(':digest_key', digestkey);
  return request('GET', APIHOST + path);
}

export async function fetchPreviewPosition(data) {
  const path = '/v1/positions/create_cache';
  return request('POST', APIHOST + path, data);
}

export async function fetchPositionStages(data) {
  const { id } = data;
  const path = '/v1/positions/position_template';
  return request('GET', APIHOST + path, { id, editing: true });
}

export async function fetchJobBoards(payload) {
  const { digestkey } = payload;
  const path = '/v1/positions/:digest_key/job_boards'.replace(':digest_key', digestkey);
  return request('GET', APIHOST + path);
}

export async function searchJobBoards(data) {
  const { query, country_code: countryCode, digest_key: digestKey } = data;
  const path = '/v1/company_job_boards/search';
  return request('GET', APIHOST + path, { query, country_code: countryCode, digest_key: digestKey });
}

export async function updatePosition(data) {
  const {
    position: { digest_key: digestkey },
  } = data;

  const path = '/v1/positions/:digest_key'.replace(':digest_key', digestkey);
  return request('PUT', APIHOST + path, data);
}

export async function publishPosition(data) {
  const { digestkey } = data;

  const path = '/v1/positions/:digest_key/publish'.replace(':digest_key', digestkey);
  return request('POST', APIHOST + path);
}

export async function unpublishPosition(data) {
  const { digestkey } = data;

  const path = '/v1/positions/:digest_key/pend'.replace(':digest_key', digestkey);
  return request('POST', APIHOST + path);
}

export async function updatePositionAccess(data) {
  const { digestkey, access } = data;

  const path =
    access === 'link_only'
      ? '/v1/positions/:digest_key/unlist'.replace(':digest_key', digestkey)
      : '/v1/positions/:digest_key/list'.replace(':digest_key', digestkey);

  return request('POST', APIHOST + path);
}

export async function getDefaultRejectionTemplate() {
  return request('GET', `${APIHOST}/v1/position_auto_actions/new`);
}

export async function fetchRejectionTemplates(payload) {
  const { digestkey } = payload;
  const path = '/v1/positions/:position_digest_key/position_auto_actions'.replace(':position_digest_key', digestkey);
  return request('GET', APIHOST + path);
}

export async function createRejectionTemplate(payload) {
  const { digestkey, ...data } = payload;
  const path = '/v1/positions/:position_digest_key/position_auto_actions'.replace(':position_digest_key', digestkey);
  return request('POST', APIHOST + path, data);
}

export async function updateRejectionTemplate(payload) {
  const { id, ...data } = payload;
  const path = '/v1/position_auto_actions/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, data);
}

export async function removeRejectionTemplateFromPosition(payload) {
  const { id } = payload;
  const path = '/v1/position_auto_actions/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function deleteRejectionTemplate(payload) {
  const { id } = payload;
  const path = '/v1/rejection_message_templates/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function fetchRejectionAutomations(payload) {
  const { digestkey } = payload;
  const path = '/v1/positions/:position_digest_key/position_trigger_actions'.replace(':position_digest_key', digestkey);
  return request('GET', APIHOST + path);
}

export async function createRejectionAutomation(payload) {
  const { digestkey, ...data } = payload;
  const path = '/v1/positions/:position_digest_key/position_trigger_actions'.replace(':position_digest_key', digestkey);
  return request('POST', APIHOST + path, data);
}

export async function updateRejectionAutomation(payload) {
  const { id, ...data } = payload;
  const path = '/v1/position_trigger_actions/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, data);
}

export async function deleteRejectionAutomation(payload) {
  const { id } = payload;
  const path = '/v1/position_trigger_actions/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function updatePositionStagesOrder(payload) {
  const { digestkey, ...data } = payload;
  const path = '/v1/positions/:digest_key/reorder_stages'.replace(':digest_key', digestkey);
  return request('POST', APIHOST + path, data);
}

export async function createPositionStage(payload) {
  const { digestkey, ...data } = payload;

  if (
    data.position_stage.stage_type === constants.STAGE_TYPE.SCHEDULING &&
    !_.isEmpty(data.position_stage.scheduling_stage_attributes.interviewers)
  ) {
    data.position_stage.scheduling_stage_attributes.interviewers = positionsHelpers.convertRolesAndStaffsToInterviewers(
      data.position_stage.scheduling_stage_attributes.interviewers,
    );
  }

  const path = '/v1/positions/:digest_key/create_stage'.replace(':digest_key', digestkey);
  return request('POST', APIHOST + path, data);
}

export async function updatePositionStage(payload) {
  const { id, ...data } = payload;
  const path = '/v1/position_stages/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, data);
}

export async function deletePositionStage(payload: { id: number; target_stage_id?: number }) {
  const { id, target_stage_id } = payload;
  const path = `/v1/position_stages/${id}`;
  const data = target_stage_id ? { target_stage_id } : null;
  return request('DELETE', APIHOST + path, data);
}

export async function toggleStageAutoAction(payload) {
  const { actionType, id } = payload;
  const path = `/v1/stage_auto_actions/:id/${actionType}`.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function updateStageAutoAction(payload) {
  const { id, ...data } = payload;
  const path = '/v1/stage_auto_actions/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, data);
}

export async function createStageTriggerAction(payload) {
  const path = '/v1/stage_trigger_actions';
  return request('POST', APIHOST + path, payload);
}

export async function updateStageTriggerAction(payload) {
  const { id, ...data } = payload;
  const path = '/v1/stage_trigger_actions/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, data);
}

export async function deleteStageTriggerAction(payload) {
  const { id } = payload;
  const path = '/v1/stage_trigger_actions/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function createStageOnboardingAction(payload) {
  const path = '/v1/stage_onboarding_actions';
  return request('POST', APIHOST + path, payload);
}

export async function updateStageOnboardingAction(payload) {
  const { id, ...data } = payload;
  const path = '/v1/stage_onboarding_actions/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, data);
}

export async function deleteStageOnboardingAction(payload) {
  const { id } = payload;
  const path = '/v1/stage_onboarding_actions/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function createCandidateInfo(payload) {
  const { positionDigestKey, ...data } = payload;
  const path = '/v1/positions/:position_digest_key/candidate_infos'.replace(':position_digest_key', positionDigestKey);
  if (data.candidate_info.question_type === 'image') {
    return request('POST', APIHOST + path, toFormData(data));
  }
  return request('POST', APIHOST + path, data);
}

export async function updateCandidateInfo(payload) {
  const { id, ...data } = payload;
  const path = '/v1/candidate_infos/:id'.replace(':id', id);
  if (data.candidate_info.question_type === 'image') {
    if (typeof data.candidate_info.attachment === 'object' && data.candidate_info.attachment instanceof window.File) {
      return request('PUT', APIHOST + path, toFormData(data));
    }
  }
  return request('PUT', APIHOST + path, data);
}

export async function deleteCandidateInfo(payload) {
  const { id } = payload;
  const path = '/v1/candidate_infos/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function reorderQuestions(payload) {
  const { positionDigestKey, ...data } = payload;
  const path = '/v1/positions/:position_digest_key/online_forms/reorder_questions'.replace(
    ':position_digest_key',
    positionDigestKey,
  );
  return request('POST', APIHOST + path, data);
}

export async function createSmartScreening(payload) {
  const { positionDigestKey, ...data } = payload;
  const path = '/v1/positions/:position_digest_key/smart_screenings'.replace(':position_digest_key', positionDigestKey);
  return request('POST', APIHOST + path, data);
}

export async function updateSmartScreening(payload) {
  const { id, ...data } = payload;
  const path = '/v1/smart_screenings/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, data);
}

export async function deleteSmartScreening(payload) {
  const { id } = payload;
  const path = '/v1/smart_screenings/:id'.replace(':id', id);
  return request('DELETE', APIHOST + path);
}

export async function switchApplicationAutoMove(payload) {
  const { id } = payload;
  const path = '/v1/online_forms/:id/switch_auto_move'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function switchFormAutoMove(payload) {
  const { id } = payload;
  const path = '/v1/forms/:id/switch_auto_move'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function updateQuiz(payload) {
  const { id, ...data } = payload;
  const path = '/v1/online_quizzes/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, data);
}

export async function updateSchedulingStage(payload) {
  const { id, data } = payload;

  if (!_.isEmpty(data.scheduling_stage.interviewers)) {
    data.scheduling_stage.interviewers = positionsHelpers.convertRolesAndStaffsToInterviewers(
      data.scheduling_stage.interviewers,
    );
  }

  const path = '/v1/scheduling_stages/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, data);
}

export async function reorderOffers(payload) {
  const { signingId, ids } = payload;
  const path = '/v1/signings/:signing_id/offers/reorder'.replace(':signing_id', signingId);
  return request('POST', APIHOST + path, { sequences: ids });
}

export async function validateStageBeforeDelete(payload: { id: number }) {
  const { id } = payload;
  const path = `/v1/position_stages/${id}/delete_validation`;
  return request('POST', APIHOST + path);
}
