import * as services from '../../services/employee-onboarding/connectorJwtToken';

export default {
  namespace: 'connectorJwtToken',

  state: {
    jwtToken: null,
  },

  effects: {
    // eslint-disable-next-line no-unused-vars
    *getJwtToken({ payload }, { call, put }) {
      const response = yield call(services.getJWTToken);
      if (response.error) throw response.error;

      yield put({
        type: 'getJWTTokenSuccess',
        payload: {
          token: response.data.jwt,
        },
      });

      return response;
    },
  },

  reducers: {
    getJWTTokenSuccess(state, { payload }) {
      return {
        ...state,
        jwtToken: payload.token,
      };
    },
  },
};
