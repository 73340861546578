import styled from 'styled-components';

export const FormWrap = styled.div`
  font-family: 'Plus Jakarta Sans', 'sans-serif';

  .ant-input-number-input,
  .ant-input-number-input-lg,
  .ant-input-number-lg,
  .ant-select-lg,
  .ant-input,
  .ant-input-lg {
    font-size: 16px;
    border-radius: 8px;
  }

  .ant-select-selection {
    border-radius: 8px;
  }

  .ant-select-lg .ant-input {
    height: 42px;
  }

  .ant-select-lg .ant-select-selection--single,
  .ant-input-lg {
    height: 42px;
  }

  .ant-select-lg .ant-select-selection__rendered {
    line-height: 40px;
  }

  .ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 34px;
    line-height: 34px;
  }
  .ant-radio-wrapper {
    font-size: 14px;
  }

  .ant-input-number.ant-input-number-lg {
    height: 42px;
  }
  .ant-input-number-input {
    height: 40px;
    line-height: 40px;
  }

  .ant-select-selection--multiple .ant-select-selection__choice {
    color: #0d8ce6;
    background-color: #e7f4fe;
    border: 1px solid #c9e9ff;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove {
    color: #0d8ce6;
  }
`;
