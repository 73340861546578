import React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Modal as Antd2Modal } from 'antd';
import { connect } from 'dva';
import { Flex, Box } from '@flexbox';

import lockIcon from '../assets/locationsPaywall/lock.svg';
import envelopeIcon from '../assets/locationsPaywall/envelope.svg';
import chatIcon from '../assets/locationsPaywall/chat.svg';

import { TextLabel } from './Label';
import AppStoreProvider from './AppStoreProvider';
import { Image } from './Buttons';

const Modal = styled(Antd2Modal)`
  & .ant-modal-header {
    padding: 40px 36px 34px;
    border-bottom: none;
  }

  & .ant-modal-body {
    padding: 0px 36px 38px;
  }
`;

let div;

class Paywall extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'app/getUpgradeDetails',
      payload: {
        pageURL: window.location.href,
        paywallInfo: {
          plan_type: 'fixed_locations',
          paywall_title: 'Location cap reached',
          paywall_body: `${this.getPaywallTitle()}
${this.getPaywallBody()}
${this.getEmail()}
${this.getPhone()}
`,
          paywall_limit_text: `${this.props.count} locations`,
        },
      },
    });
  }

  destoryFn() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  getPaywallTitle() {
    return "You've reached your plan limit!";
  }

  getPaywallBody() {
    return `Your current plan is capped at ${this.props.count} locations.
To add more locations to your plan, please reach out to the sales team.
They'll have you hiring for your new location in no time!`;
  }

  getPhone() {
    return 'Call or text us at 415-767-1006';
  }

  getEmail() {
    return 'Email us at sales@workstream.is';
  }

  render() {
    return (
      <Modal visible onCancel={this.destoryFn} footer={null}>
        <Flex column pt={70}>
          <Flex align="center" column>
            <Image src={lockIcon} />
            <Box mt={28} />
            <TextLabel fz25px bold>
              {this.getPaywallTitle()}
            </TextLabel>
            <Box mt={16} />
            <TextLabel wrap fz14px center>
              {this.getPaywallBody()}
            </TextLabel>
            <Flex align="center" mt={58}>
              <Image src={chatIcon} />
              <Box ml={12} />
              <TextLabel fz16px semibold>
                {this.getPhone()}
              </TextLabel>
            </Flex>
            <Flex align="center" mt={24}>
              <Image src={envelopeIcon} />
              <Box ml={12} />
              <TextLabel fz16px semibold>
                {this.getEmail()}
              </TextLabel>
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    );
  }
}

const ConnectedPaywall = connect(({ loading, app }) => ({
  loading,
  app,
}))(Paywall);

export const showFixedLocationsPaywall = (locationsCount) => {
  div = document.createElement('div');
  document.body.appendChild(div);

  ReactDOM.render(
    <AppStoreProvider>
      <ConnectedPaywall count={locationsCount} />
    </AppStoreProvider>,
    div,
  );
};
