import _ from 'lodash';

import { APIHOST } from '@/config';
import request from '@/utils/request';

export async function getFieldsToConfirm(payload) {
  const { caseId } = payload;
  const path = `/v1/onboarding/e_verify/case/${caseId}/confirm_details_fields`;
  return request('GET', APIHOST + path);
}

export async function confirmDetails(payload) {
  const { caseId, values } = payload;
  const path = `/v1/onboarding/e_verify/case/${caseId}/confirm_details`;
  return request('POST', APIHOST + path, { values });
}

export async function downloadFurtherActionNotice(payload) {
  const { caseId, language } = payload;
  const path = `/v1/onboarding/e_verify/case/${caseId}/download_further_action_notice?language=${language}`;
  return request('GET', APIHOST + path, undefined, undefined, undefined, 'blob');
}

export async function downloadReferralDateConfirmation(payload) {
  const { caseId, language } = payload;
  const path = `/v1/onboarding/e_verify/case/${caseId}/download_referral_date_confirmation?language=${language}`;
  return request('GET', APIHOST + path, undefined, undefined, undefined, 'blob');
}

export async function retrievePhoto(payload) {
  const { caseId } = payload;
  const path = `/v1/onboarding/e_verify/case/${caseId}/retrieve_photo`;
  return request('GET', APIHOST + path);
}

export async function getClosureReasons(payload) {
  const { caseId } = payload;
  const path = `/v1/onboarding/e_verify/case/${caseId}/closure_reasons`;
  return request('GET', APIHOST + path);
}

export async function closeCase(payload) {
  const { caseId } = payload;
  const path = `/v1/onboarding/e_verify/case/${caseId}/close`;
  return request('POST', APIHOST + path, _.omit(payload, 'caseId'));
}

export async function referCase(payload) {
  const { caseId } = payload;
  const path = `/v1/onboarding/e_verify/case/${caseId}/refer`;
  return request('POST', APIHOST + path);
}

export async function noAction(payload) {
  const { caseId } = payload;
  const path = `/v1/onboarding/e_verify/case/${caseId}/no_action`;
  return request('POST', APIHOST + path);
}

export async function scanAndUpload(caseId, payload) {
  const path = `/v1/onboarding/e_verify/case/${caseId}/scan_and_upload`;
  return request('POST', APIHOST + path, payload);
}

export async function photoMatch(caseId, payload) {
  const path = `/v1/onboarding/e_verify/case/${caseId}/photo_match`;
  return request('POST', APIHOST + path, payload);
}

export async function refresh(payload) {
  const { caseId } = payload;
  const path = `/v1/onboarding/e_verify/case/${caseId}/refresh`;
  return request('GET', APIHOST + path);
}

export async function fetch(payload) {
  const path = `/v1/onboarding/e_verify/employee/cases/employee_latest_case`;
  return request('GET', APIHOST + path, payload);
}
