import { useUsersMeCached } from '@helloworld1812/hr-api';

import { VIEW_SENSITIVE_PII } from '@/constants/permission';
import { getViewSensitivePiiPermission } from '@/utils/permission';

function usePiiPermissions() {
  const { user } = useUsersMeCached();
  const viewSensitivePiiPermission = getViewSensitivePiiPermission({ user }, 'view_sensitive_pii');

  return {
    hasNoAccess: !viewSensitivePiiPermission,
    hasFullAccess: viewSensitivePiiPermission === VIEW_SENSITIVE_PII.FULL_ACCESS,
    hasPartialAccess: viewSensitivePiiPermission === VIEW_SENSITIVE_PII.PARTIAL_ACCESS,
  };
}

function useHasNoAccess() {
  return usePiiPermissions().hasNoAccess;
}

function useHasFullAccess() {
  return usePiiPermissions().hasFullAccess;
}

function useHasPartialAccess() {
  return usePiiPermissions().hasPartialAccess;
}

export { useHasNoAccess, useHasFullAccess, useHasPartialAccess, usePiiPermissions };
