import {
  jobTitleKey,
  organizationKey,
  supervisorKey,
  emergencyContactRelationshipTypeKey,
} from '@/routes/employee-onboarding/view-onboarding/modules/paychex/utils';

import request from '../utils/request';
import { APIHOST } from '../config';

export async function addCompany(payload) {
  // eslint-disable-next-line camelcase
  const { display_id } = payload;
  const path = '/v1/onboarding/paychex/hr/companies';
  return request('POST', APIHOST + path, { display_id });
}

export async function getCompanies() {
  const path = '/v1/onboarding/paychex/hr/companies';
  return request('GET', APIHOST + path);
}

export async function getSubdivisions(payload) {
  const { countryValue } = payload;
  const path = `/v1/countries/${countryValue}`;
  return request('GET', APIHOST + path);
}

export async function getCompanySpecificData(payload) {
  const { companyValue } = payload;
  const path = `/v1/onboarding/paychex/hr/companies/${companyValue}/dynamic_values`;
  return request('GET', APIHOST + path, { dynamic_keys: [jobTitleKey, organizationKey, supervisorKey] });
}

export async function getCompanySpecificDataforEmployee(payload) {
  const { companyValue, digestKey } = payload;
  const path = `/v1/onboarding/paychex/employee/actions/${digestKey}/dynamic_values`;
  return request('GET', APIHOST + path, {
    paychex_company_id: companyValue,
    dynamic_keys: [emergencyContactRelationshipTypeKey],
  });
}

export async function verifyWorker(payload) {
  const { companyValue, workerId } = payload;
  const path = `/v1/onboarding/paychex/hr/companies/${companyValue}/verify_worker`;
  return request('POST', APIHOST + path, {
    worker_id: workerId,
  });
}

export async function sendToPaychex(payload) {
  const { actionId, formPayload } = payload;
  const data = { onboarding_form_submissions: formPayload };

  const path = `/v1/employee_onboarding_actions/${actionId}/send_to_paychex`;
  return request('POST', APIHOST + path, data);
}
