import request from '../utils/request';
import { APIHOST } from '../config';

export async function getCompany() {
  const path = '/v1/wotc/company';
  return request('GET', APIHOST + path);
}

export async function createCompany(payload) {
  const path = '/v1/wotc/company';
  return request('POST', APIHOST + path, payload);
}

export async function compareTaxInfos(payload) {
  const path = '/v1/employer_tax_infos/wotc_comparison';
  return request('POST', APIHOST + path, payload);
}

export async function updateTaxEntities(payload) {
  const path = '/v1/wotc/company/update_tax_entities';
  return request('POST', APIHOST + path, payload);
}

export async function unlinkCompany() {
  const path = '/v1/wotc/company';
  return request('DELETE', APIHOST + path);
}
