import { AxiosInstance } from 'axios';
import { initHrAxios, hrAxiosInstance } from '@helloworld1812/hr-api';
import { createOpenapiAxios, createOpenapiUseMutation, createOpenapiUseQuery } from '@helloworld1812/ws-openapi-axios';

import { reportErrorToIntercom } from '../../utils/request';
import { APIHOST } from '../../config';

import { WsBackendApiPaths } from './ws-backend-api';
import { WsInternalApiPaths } from './ws-internal-api';
import { WsPortalApiPaths } from './ws-portal-api';

type HrApiPaths = WsBackendApiPaths & WsInternalApiPaths & WsPortalApiPaths;
type HrApiUrlKey = keyof HrApiPaths;

initHrAxios({
  baseURL: APIHOST,
});

hrAxiosInstance.interceptors.response.use(undefined, reportErrorToIntercom);

export { hrAxiosInstance };

export const hrAxios = createOpenapiAxios<HrApiUrlKey, HrApiPaths>(hrAxiosInstance);

export const useHrQuery = createOpenapiUseQuery<HrApiUrlKey, HrApiPaths>(hrAxios as unknown as AxiosInstance);

export const useHrMutation = createOpenapiUseMutation<HrApiUrlKey, HrApiPaths>(hrAxios as unknown as AxiosInstance);
