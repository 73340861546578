export default {
  QUESTION_TYPES: {
    SHORT_ANSWERS: 'short_text',
    PARAGRAPH_ANSWERS: 'long_text',
    FILE_UPLOADS: 'attachment',
    CHECKBOX_ANSWERS: 'multiple_selection',
    MULTIPLE_CHOICE_ANSWERS: 'multiple_choice',
    DROPDOWN_ANSWERS: 'dropdown',
    TELEPHONE_ANSWERS: 'telephone',
    EMAIL_ANSWERS: 'email',
    ID_NUMBER_ANSWERS: 'identity',
    DATE_ANSWERS: 'date',
  },
};
