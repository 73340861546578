import React from 'react';
import { Provider } from 'react-redux';
import { WsThemeProvider } from '@helloworld1812/app-global-components';

function AppStoreProvider({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={window.dvaApp._store}>
      <WsThemeProvider>{children}</WsThemeProvider>
    </Provider>
  );
}

export default AppStoreProvider;
