import type {
  GetCustomReportTemplatesResponseData,
  GetCustomReportTemplateResponseData,
  CustomReportPreviewApiParams,
  CreateCustomReportTemplateApiParams,
  UpdateCustomReportTemplateApiParams,
  CustomReportSchedule,
  CustomReportPreviewDataRawApiResponseData,
  CustomReportDataApiResponseData,
} from '@/types/app/analytics/reports/dto';
import { SortOrder } from '@/types/app/analytics/reports/report-data';
import request from '@/utils/request';
import { APIHOST } from '@/config';

async function requestCustomReportTemplates() {
  return request<GetCustomReportTemplatesResponseData>('GET', `${APIHOST}/v1/custom_report_templates`);
}

async function requestCustomReportTemplate(reportId: number) {
  return request<GetCustomReportTemplateResponseData>('GET', `${APIHOST}/v1/custom_report_templates/${reportId}`);
}

async function createCustomReportTemplate(params: CreateCustomReportTemplateApiParams) {
  return request('POST', `${APIHOST}/v1/custom_report_templates`, params);
}

async function updateCustomReportTemplate(params: UpdateCustomReportTemplateApiParams) {
  return request('PUT', `${APIHOST}/v1/custom_report_templates/${params.custom_report_template.id}`, params);
}

async function deleteCustomReportTemplate(reportId: number) {
  return request('DELETE', `${APIHOST}/v1/custom_report_templates/${reportId}`);
}

async function exportCustomReport(reportId: number, format: string) {
  return request('POST', `${APIHOST}/v1/custom_data_exports`, {
    custom_data_export: {
      export_type: format,
      template_id: reportId,
    },
  });
}

async function downloadCustomReport(reportId: number) {
  return request('GET', `${APIHOST}/v1/custom_data_exports/${reportId}/download_report`);
}

async function requestCustomReportPreview(params: CustomReportPreviewApiParams) {
  return request<CustomReportPreviewDataRawApiResponseData>(
    'POST',
    `${APIHOST}/v1/custom_report_templates/preview`,
    params,
  );
}

async function requestRecentGeneratedReportForCustomReportTemplate(
  page: number,
  per_page: number,
  reportId: number,
  sort_by?: string,
  order?: SortOrder,
) {
  return request<CustomReportDataApiResponseData>(
    'GET',
    `${APIHOST}/v1/custom_report_templates/${reportId}/recent_report`,
    {
      page,
      per_page,
      ...(order != null && { sort_by, order }),
    },
  );
}

function updateCustomReportSchedule(reportId: number, schedule: CustomReportSchedule) {
  return request('PUT', `${APIHOST}/v1/custom_report_templates/${reportId}/schedule`, schedule);
}

function deleteCustomReportSchedule(reportId: number) {
  return request('DELETE', `${APIHOST}/v1/custom_report_templates/${reportId}/schedule`);
}

export {
  requestCustomReportTemplates,
  requestCustomReportTemplate,
  createCustomReportTemplate,
  updateCustomReportTemplate,
  deleteCustomReportTemplate,
  exportCustomReport,
  downloadCustomReport,
  requestCustomReportPreview,
  requestRecentGeneratedReportForCustomReportTemplate,
  updateCustomReportSchedule,
  deleteCustomReportSchedule,
};
