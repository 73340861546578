import _ from 'lodash';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import * as services from '@/services/hiring-process-template';
import { getCandidateInfos } from '@/utils';
import constants from '@/utils/constants';

const { STAGE_TYPE } = constants;
export const initialState = {
  isBasic: false,
  defaultSigning: null,
  stageAutoActionList: {},
  positionTemplates: [],
  initialJobDetails: {},
  initialHiringProcess: {},
  initialJobBoards: [],
  selectedStageId: null,
  jobDetails: {
    position_dynamic_fields: [],
    pay_amount_type: 'range',
    pay_frequency: 'hour',
    display_brand_place: 'after_job_desc', // 'before_job_desc'
    remote_type: 'on_site',
  },
  copiedTemplateTitle: '',
  hiringProcess: {},
  hiringProcessName: undefined,
  jobBoards: [],
  brandDetail: {},
  locationsWithDepartments: [],
};

const subscriptions = {
  // setup({ dispatch, history }) {
  //   history.listen((location) => {
  //     const { pathname } = location;
  //     const re = pathToRegexp('/create-hiring-process-template/stages(.*)');
  //     if (re.test(pathname)) {
  //       dispatch({ type: 'resetAddNewPositionState' });
  //       dispatch({ type: 'fetchStageAutoActionList' });
  //       dispatch({ type: 'fetchDefaultSigning' });
  //     }
  //   });
  // },
};

export const reducers = {
  fetchUserLocationsWithDepartmentsSuccess(state, { payload }) {
    const locationsWithDepartments = payload;
    return {
      ...state,
      locationsWithDepartments,
    };
  },

  initWithBasicTemplate(state, { payload }) {
    const { id, digest_key } = payload;
    return {
      ...state,
      isBasic: true,
      jobDetails: {
        ...state.jobDetails,
        id,
        digest_key,
      },
    };
  },
  updateBrandDetail(state, { payload }) {
    const { brandDetail } = payload;
    return {
      ...state,
      brandDetail,
    };
  },

  resetAddNewPositionState(state) {
    const { stageAutoActionList, defaultSigning } = state;

    return {
      ...initialState,
      stageAutoActionList,
      defaultSigning,
    };
  },

  updateStageAutoActionList(state, { payload }) {
    const { stageAutoActionList } = payload;

    // Clean up data for the 'consolidate automations' feature.
    // 1. In 'form'/'online_quiz' and other stages, the welcome message need to add the `trigger_action_type: 'auto'`
    const stageList = [
      STAGE_TYPE.ONLINE_FORM,
      STAGE_TYPE.ONLINE_QUIZ,
      STAGE_TYPE.BACKGROUND_CHECK,
      STAGE_TYPE.PHONE_INTERVIEW,
      STAGE_TYPE.ONSITE_INTERVIEW,
      STAGE_TYPE.SCHEDULING,
      STAGE_TYPE.REVIEW,
      STAGE_TYPE.LONG_TERM_HOLD,
      STAGE_TYPE.HIRING_COMPLETE,
      STAGE_TYPE.AI_SCREENING,
    ];

    stageList.forEach((stageType) => {
      let stageAutoActions = stageAutoActionList[stageType];
      stageAutoActions = stageAutoActions.map((item) => {
        if (!item.trigger_action_type) {
          return { ...item, trigger_action_type: 'auto' };
        }
        return item;
      });
      stageAutoActionList[stageType] = stageAutoActions;
    });

    return {
      ...state,
      stageAutoActionList,
    };
  },

  updateDefaultSigning(state, { payload }) {
    const { defaultSigning } = payload;

    return {
      ...state,
      defaultSigning,
    };
  },

  updatePositionTemplates(state, { payload }) {
    const { positionTemplates } = payload;
    return {
      ...state,
      positionTemplates,
    };
  },

  updateIsBasic(state, { payload }) {
    const { isBasic } = payload;
    return {
      ...state,
      isBasic,
    };
  },

  updateCopiedTemplateTitle(state, { payload }) {
    const { title } = payload;
    return {
      ...state,
      copiedTemplateTitle: title,
    };
  },

  updateJobDetails(state, { payload }) {
    const { jobDetails } = payload;
    return {
      ...state,
      jobDetails,
    };
  },

  updatePositionTitle(state, { payload }) {
    const { title } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        title,
      },
    };
  },

  updatePositionLocation(state, { payload }) {
    const { location } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        location,
      },
    };
  },

  updatePositionDepartment(state, { payload }) {
    const { department } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        department,
      },
    };
  },

  updatePositionLanguage(state, { payload }) {
    const { language } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        language,
      },
    };
  },

  updatePositionPayAmount(state, { payload }) {
    const { pay_amount } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        pay_amount,
      },
    };
  },

  updatePositionPayFrequency(state, { payload }) {
    const { pay_frequency } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        pay_frequency,
      },
    };
  },

  updatePositionRemoteType(state, { payload }) {
    const { remote_type: remoteType } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        remote_type: remoteType,
      },
    };
  },

  updatePositionJobType(state, { payload }) {
    const { job_type } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        job_type,
      },
    };
  },

  updatePositionOverview(state, { payload }) {
    const { overview } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        overview,
      },
    };
  },

  updatePositionJobDetails(state, { payload }) {
    const { changes } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        ...changes,
      },
    };
  },

  updatePositionDynamicFields(state, { payload }) {
    const { dynamicFields: newField } = payload;
    const dynamicFields = [...(state.jobDetails.position_dynamic_fields || [])];

    const foundIndex = dynamicFields.findIndex((field) => field.id === newField.id);
    if (foundIndex !== -1) {
      dynamicFields[foundIndex] = newField;
    } else {
      dynamicFields.push(newField);
    }

    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        position_dynamic_fields: dynamicFields,
      },
    };
  },

  updateAllPositionDynamicFields(state, { payload }) {
    const { dynamicFields } = payload;

    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        position_dynamic_fields: dynamicFields,
      },
    };
  },

  deletePositionDynamicFields(state, { payload }) {
    const { id } = payload;
    const { jobDetails } = state;
    const dynamicFields = jobDetails.position_dynamic_fields.filter((field) => field.id !== id);
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        position_dynamic_fields: dynamicFields,
      },
    };
  },

  sortPositionDynamicFields(state, { payload }) {
    const { oldIndex, newIndex } = payload;
    const dynamicFields = arrayMove(state.jobDetails.position_dynamic_fields, oldIndex, newIndex);

    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        position_dynamic_fields: dynamicFields,
      },
    };
  },

  updateHiringProcess(state, { payload }) {
    const { hiringProcess } = payload;
    return {
      ...state,
      hiringProcess,
    };
  },

  updateSelectedStageId(state, { payload }) {
    const { selectedStageId } = payload;
    return {
      ...state,
      selectedStageId,
    };
  },

  updateJobBoards(state, { payload }) {
    const { jobBoards } = payload;
    return {
      ...state,
      jobBoards,
    };
  },

  updateInitialJobDetails(state, { payload }) {
    const { initialJobDetails } = payload;
    return {
      ...state,
      initialJobDetails,
    };
  },

  updateInitialHiringProcess(state, { payload }) {
    const { initialHiringProcess } = payload;
    return {
      ...state,
      initialHiringProcess,
    };
  },

  updateInitialJobBoards(state, { payload }) {
    const { initialJobBoards } = payload;
    return {
      ...state,
      initialJobBoards,
    };
  },

  updateHiringProcessName(state, { payload }) {
    const { name } = payload;
    return {
      ...state,
      hiringProcessName: name,
    };
  },
};

export const effects = {
  *fetchStageAutoActionList({ payload }, { put, select, call }) {
    const response = yield call(services.fetchStageAutoActionList);
    if (response.error) throw response.error;

    const stageAutoActionList = response.data.stage_auto_actions;

    yield put({
      type: 'updateStageAutoActionList',
      payload: {
        stageAutoActionList,
      },
    });
  },

  *fetchDefaultSigning({ payload }, { put, select, call }) {
    const response = yield call(services.fetchDefaultSigning);
    if (response.error) throw response.error;

    const defaultSigning = response.data.signing;

    yield put({
      type: 'updateDefaultSigning',
      payload: {
        defaultSigning,
      },
    });
  },

  *fetchPositionTemplates({ payload }, { put, call }) {
    const response = yield call(services.fetchAllTemplates);
    if (response.error) throw response.error;

    const positionTemplates = response.data;

    yield put({
      type: 'updatePositionTemplates',
      payload: {
        positionTemplates,
      },
    });
    return response.data;
  },

  *fetchPositionStages({ payload }, { put, call }) {
    const { id } = payload;
    const response = yield call(services.fetchPositionStages, { id });
    if (response.error) throw response.error;

    const hiringProcess = response.data.position;
    const firstStage = hiringProcess.position_stages[0];
    const { online_form: onlineForm } = firstStage;
    const { candidate_infos: candidateInfos } = onlineForm;

    // remove is_new. used only for edit position to create instead of update new resume field
    onlineForm.candidate_infos = getCandidateInfos(candidateInfos, hiringProcess.language).map((info) => {
      // eslint-disable-next-line no-unused-vars
      const { is_new, ...candidateInfo } = info;
      return candidateInfo;
    });

    const initialHiringProcess = _.cloneDeep(hiringProcess);

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess,
      },
    });

    yield put({
      type: 'updateInitialHiringProcess',
      payload: {
        initialHiringProcess,
      },
    });

    yield put({
      type: 'updateSelectedStageId',
      payload: {
        selectedStageId: firstStage.id,
      },
    });

    return hiringProcess;
  },

  *fetchPreviewPosition({ payload }, { call }) {
    const response = yield call(services.fetchPreviewPosition, payload);
    if (response.error) {
      throw response.error;
    }

    return response.data;
  },

  *fetchJobBoards({ payload }, { call, put }) {
    const response = yield call(services.fetchJobBoards, payload);
    if (response.error) {
      throw response.error;
    }

    let jobBoards = response.data.job_boards;
    if (!payload.isEditPosition) {
      // Default free job boards to chosen
      jobBoards = _.filter(jobBoards, ['is_sponsored', false]).map((board) => {
        if (!board.is_sponsored && board.status === 'live') return { ...board, chosen: true };
        return { ...board };
      });
    }
    const initialJobBoards = _.cloneDeep(jobBoards);

    yield put({
      type: 'updateJobBoards',
      payload: {
        jobBoards,
      },
    });

    yield put({
      type: 'updateInitialJobBoards',
      payload: {
        initialJobBoards,
      },
    });
  },

  *searchJobBoards({ payload }, { call }) {
    const response = yield call(services.searchJobBoards, payload);
    if (response.error) throw response.error;

    const { job_boards: jobBoards } = response.data;
    return { jobBoards };
  },

  *createPosition({ payload }, { call, put }) {
    const response = yield call(services.createPosition, payload);
    if (response.error) {
      throw response.error;
    }

    return response.data.position;
  },

  *fetchUserLocationsWithDepartments({ payload }, { put, call }) {
    const response = yield call(services.fetchUserLocationsWithDepartments);
    if (response.error) throw response.error;
    yield put({ type: 'fetchUserLocationsWithDepartmentsSuccess', payload: response.data.locations_with_departments });
  },
};

export default {
  namespace: 'createHiringProcessTemplate',
  state: initialState,
  subscriptions,
  reducers,
  effects,
};
