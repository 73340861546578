import { createConsumer } from '@rails/actioncable';

import { AppDispatch } from '@/types/app/models';
import { ApplicationHistory } from '@/api';

import { ACTION_CABLE_URL } from '../config';

const dealWithReceivedMessages = () => {
  const { webSocket, receivedMessages = [] } = window;
  const { dispatch } = webSocket;
  if (receivedMessages.length > 0) {
    const newReceivedMessages = [...receivedMessages];
    receivedMessages.length = 0;

    const messages = newReceivedMessages.map((item) => item.message);
  }
};

function run() {
  window.receivedMessages = [];
  window.receivedChecklistModules = [];

  let cableInterval;

  if (cableInterval) {
    clearInterval(cableInterval);
  }

  cableInterval = setInterval(() => {
    dealWithReceivedMessages();
  }, 1500);
}

function create(dispatch: AppDispatch) {
  // 设置webSocket
  window.webSocket = {
    dispatch,
    cable: createConsumer(ACTION_CABLE_URL),
  };
}

function subscriptions() {
  const { webSocket } = window;
  const { cable } = webSocket;

  webSocket.messages = cable.subscriptions.create('MessagesChannel', {
    connected() {
      // do nothing
    },
    disconnected() {
      // do nothing
    },
    received(data: { message: string; history: ApplicationHistory }) {
      window.receivedMessages?.push(data);
    },
    speak(message: string) {
      return this.perform('speak', message);
    },
  });
  window.webSocket = webSocket;
}

function unSubscriptions() {
  const {
    webSocket: { cable, messages, appearance },
  } = window;
  cable.subscriptions.remove(messages);
  cable.subscriptions.remove(appearance);
}

function appear() {
  const {
    webSocket: { appearance },
  } = window;
  if (appearance) appearance.appear();
}

function away() {
  const {
    webSocket: { appearance },
  } = window;
  if (appearance) appearance.away();
}

export { run, create, subscriptions, unSubscriptions, appear, away };
