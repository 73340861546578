import i18n from 'i18next';
import { locizePlugin } from 'locize';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import locizeBackend from 'i18next-locize-backend';
import locizeLastUsed, { LocizeLastusedOptions } from 'locize-lastused';
import 'dayjs/locale/fr';
import 'dayjs/locale/zh';

const isProduction = process.env.DEPLOY_ENV === 'production';

const locizeOptions = {
  projectId: 'e59cf20c-1fab-44d3-bb0c-05df49f3023e',
  apiKey: isProduction ? '' : '50c6c853-4033-4061-b1f2-7d238e630fd0',
  referenceLng: 'en',
};

if (!isProduction) {
  i18n.use(locizeLastUsed);
}

export const initI18n = () =>
  i18n
    .use(locizeBackend)
    .use(locizePlugin)
    .use(languageDetector)
    .use(initReactI18next)
    .init<LocizeLastusedOptions>({
      debug: !isProduction,
      fallbackLng: 'en',
      saveMissing: !isProduction,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: locizeOptions,
      locizeLastUsed: locizeOptions,
      react: {
        bindI18n: 'languageChanged editorSaved',
      },
    });

export type Language = 'en' | 'es' | 'fr' | 'zh';
