import { showNewMessage, shutdown } from '@intercom/messenger-js-sdk';
import type {
  Method,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosError,
  CancelToken,
  ResponseType,
} from 'axios';
import axios from 'axios';
import jstz from 'jstimezonedetect';
import axiosRetry from 'axios-retry';
import { wsLocalStorage, wsSessionStorage } from '@helloworld1812/ws-storage';
import { refreshAndRetry } from '@helloworld1812/ws-auth';
import { WsResponseError } from '@helloworld1812/hr-api';

import { ENABLE_MFE } from '@/config';
import { composeRawRouteUrl } from '@/utils/nav';

interface ResponseError {
  error: { message: string; errorCode: number };
}

type MixedResponse<ResponseData> = AxiosResponse<ResponseData> | ResponseError;

// Setup auto retry
axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
});

/**
 * Get auth headers from localStorage
 * @param overrides
 * @returns
 */
const getAuthHeaders = (overrides?: AxiosRequestHeaders): AxiosRequestHeaders => {
  const token = wsLocalStorage.getWsToken();
  const companyId = wsLocalStorage.getItem('WS_SELECTED_COMPANY');

  return {
    Authorization: token || '',
    TimezoneOffset: String(new Date().getTimezoneOffset()),
    TimezoneName: jstz.determine().name(),
    ...(companyId && companyId !== 'undefined' && { 'ws-company-id': companyId }),
    // TODO: remove hash routing after MFE phase 2 release
    'ws-event-path': ENABLE_MFE
      ? `${window.location.pathname}${window.location.search}`
      : window.location.hash.substring(1),
    ...overrides,
  };
};

/**
 * Check response status, throw error if it's not 2xx
 * @param response
 * @returns
 */
function checkResponseStatus<ResponseData = unknown>(response: MixedResponse<ResponseData>) {
  if ('error' in response) {
    return response;
  }

  // Redirect user to select company when required by backend
  const isInSelectCompany = window.location.href.indexOf('/select-company') !== -1;

  if (response.headers['require-select-company'] === '1' && !isInSelectCompany) {
    wsLocalStorage.setItem('WS_NEED_TO_SELECT_COMPANY', 'TRUE');
    // TODO: remove hash routing after MFE phase 2 release
    window.location.replace(composeRawRouteUrl('/select-company'));
  }

  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  error.response = response;
  throw error;
}

/**
 * Reset ws token and page url when server error to prevent it's caused by token expiration
 *
 * @deprecated
 * @param err
 * @returns
 */
const resetAfterServerError = async (err: AxiosError) => {
  let message = 'Server error';
  let errorCode = -1;
  if (err.response && err.response.data && (err.response.data.error_code || err.response.data.message)) {
    errorCode = err.response.data.error_code;
    if (errorCode === 10003) {
      const result = await refreshAndRetry(err, () => {
        wsLocalStorage.resetWsToken();
        wsSessionStorage.setRedirectUrl(window.location.href);
        // TODO: remove hash routing after MFE phase 2 release
        window.location.replace(composeRawRouteUrl('/login'));

        shutdown();
      });

      // Refresh token and retry handled error, skip error
      return result;
    }
    message = err.response.data.message;
  }
  return { error: { message, errorCode } };
};

export default function request<ResponseData = unknown>(
  method: Method,
  url: string,
  // Use `any` here for `data` param otherwise might get "missing index signature of type string"
  // error when giving an object literal.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: unknown,
  locale?: string,
  cancelToken?: CancelToken,
  responseType?: ResponseType,
  withCredentials?: boolean,
): Promise<MixedResponse<ResponseData>> {
  const dataKey = ['POST', 'PUT', 'DELETE'].includes(method) ? 'data' : 'params';

  const config: AxiosRequestConfig = {
    method,
    url,
    [dataKey]: data,
    withCredentials:
      withCredentials ?? (process.env.DEPLOY_ENV !== 'test' && process.env.PROD_SANITY_CHECK_IN_PR !== 'true'),
    ...(cancelToken && { cancelToken }),
    ...(responseType && { responseType }),
  };

  const token = wsLocalStorage.getWsToken();

  // TODO: confirm this comparison is correct, normally it's `typeof token !== 'undefined'`
  if (token && token !== 'undefined') {
    config.headers = getAuthHeaders({ locale: locale ?? 'en' });
  }

  return axios.request<ResponseData>(config);
}

export function reportErrorToIntercom(
  err: AxiosError<
    WsResponseError & {
      error_reference?: string;
    }
  >,
) {
  const wsError = err.response?.data;
  const searchParams = new URLSearchParams(window.location.hash.split('?')[1]);
  const isReportErrToCsOn =
    searchParams.get('report_err_to_cs') === '1' || localStorage.getItem('report_err_to_cs') === '1';

  if (wsError?.error_reference && isReportErrToCsOn) {
    showNewMessage(
      JSON.stringify(
        {
          ...wsError,
          current_page: window.location.href,
          request_url: (err.config?.baseURL ?? '') + (err.config?.url ?? ''),
        },
        null,
        2,
      ),
    );
  }

  throw err;
}

axios.interceptors.response.use(undefined, reportErrorToIntercom);
axios.interceptors.response.use(checkResponseStatus, resetAfterServerError);
