export function ignoreAntd2ConsoleWarningsAndErrors() {
  const originalConsoleError = console.error;
  const originalConsoleWarn = console.warn;

  console.error = (...args) => {
    const errorArgs0RegExps = [
      /^Warning: %s uses the legacy (childContextTypes|contextTypes) API which is no longer supported/,

      /^Warning: Component "%s" contains the string ref "%s". Support for string refs will be removed in a future major release./,
      /^Warning: findDOMNode is deprecated and will be removed in the next major release./,
      /^Warning: unmountComponentAtNode is deprecated and will be removed in the next major release./,
      /^Warning: ReactDOM.render is no longer supported in React 18. Use createRoot instead./,
      /^Warning: ReactDOM.unstable_renderSubtreeIntoContainer\(\) is no longer supported in React 18./,
      /^Warning: \[antd-compatible: Icon\] The legacy component has been deprecated, and ant design 4.0 now released!/,
      // eslint-disable-next-line max-len
      /^Warning: \[antd: DatePicker\] `dropdownClassName` is deprecated which will be removed in next major version. Please use `popupClassName` instead./,
      // eslint-disable-next-line max-len
      /^Warning: %s: Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead.%s/,
      /^Warning: \[antd: Steps\] Step is deprecated. Please use `items` directly./,
    ];

    const errorArgs1RegExps = [
      // eslint-disable-next-line max-len
      /^(Component|Form|FormItem|Upload|div|span|Input|Modal|Select|Checkbox|SelectTrigger|Trigger|Table|Fragment|CheckboxGroup|_a|Menu|MenuItem|Tooltip|RadioGroup|Radio|CheckboxGroup|Checkbox|Popover|Dropdown|StickyContainer)$/,
      /^\n *at /,
    ];

    if (
      errorArgs0RegExps.some((reg) => reg.test(args[0])) &&
      (!args[1] || errorArgs1RegExps.some((reg) => reg.test(args[1])))
    ) {
      return;
    }

    // console.log('console.error', typeof args[0], args[1], args[0]);

    originalConsoleError.call(console, ...args);
  };

  console.warn = (...args) => {
    const warnArgs0RegExps = [/^Warning: componentWillReceiveProps has been renamed, and is not recommended for use./];

    if (warnArgs0RegExps.some((reg) => reg.test(args[0]))) {
      return;
    }

    // eslint-disable-next-line no-console
    // console.log('console.warn', typeof args[0], args[1], args[0]);

    originalConsoleWarn.call(console, ...args);
  };
}
