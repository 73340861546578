import { EmployerTaxInfo } from './app';

export interface WotcModal {
  wotcCompany?: WotcCompany;
  paneOpen: boolean;
  showExitConfirm: boolean;
  progress: {
    currentPage: Page;
    allSteps: Step[];
    currentStep: Step;
    completedSteps: Step[];
    editingStep?: Step;
  };
  formFields: {
    accountInfoFields?: { [key: string]: FormField };
    taxInfoFields?: { [key: string]: FormField };
  };
  data: {
    accountInfoData?: AccountInfoData;
    taxInfoData?: TaxInfoData;
  };
  accessToken?: string;
  comparedTaxInfos?: ComparedTaxInfos;
  errorMessage?: string;
  updateTaxEntityModalOpen: boolean;
  taxInfosToUpdate: EmployerTaxInfo[];
  einLocationMap: {
    [key: string]: number[];
  };
  missingAddressModalOpen: boolean;
}

export interface WotcCompany {
  id: number;
  company_id: number;
  access_token: string;
  name: string;
  city: string;
  state: string;
  ein: string;
  status: string;
  error_message: string | null;
  website: string;
  phone: string;
  email: string;
}

export interface AccountInfoData {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  website: string;
  for_profit: boolean;
}

export interface TaxInfoData {
  company_name: string;
  ein: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}
export interface FormField {
  name: string;
  errors?: FieldError;
  value: string;
  touched: boolean;
  dirty: boolean;
  validating: boolean;
}

export interface FieldError {
  field: string;
  message: string;
}

export interface ComparedTaxInfos {
  missing_tax_infos_in_wotc: EmployerTaxInfo[];
  missing_tax_infos_in_ws: EmployerTaxInfo[];
  shared_tax_infos: EmployerTaxInfo[];
}

export enum WotcConnectPage {
  ACCOUNT_CONNECT_OPTIONS = 'Account Connect Options',
  NEW_ACCOUNT_CREATE = 'New Account Create',
  ACCESS_TOKEN_CONNECT = 'Access Token Connect',
  CONFIRMATION = 'Confirmation',
}

export enum CreateNewAccountStep {
  ACCOUNT_INFO = 'Account Information',
  TAX_INFO = 'Tax Entity Information',
  REVIEW = 'Review',
}

export type Page = WotcConnectPage;
export type Step = CreateNewAccountStep;
