import {
  jobTitleKey,
  organizationKey,
  supervisorKey,
  emergencyContactRelationshipTypeKey,
} from '@/routes/employee-onboarding/view-onboarding/modules/paychex/utils';

import * as services from '../../services/paychex';
import * as onboardingServices from '../../services/employeeOnboarding';

export default {
  namespace: 'paychex',
  state: {
    companies: [],
    companyData: {},
    employeeData: {},
    subdivisions: {},
  },
  reducers: {
    getCompaniesSuccess(state, { payload }) {
      return {
        companies: payload,
      };
    },
    addCompanySuccess(state, { payload }) {
      return {
        ...state,
        companies: [...state.companies, payload],
      };
    },

    getSubdivisionsSuccess(state, { payload }) {
      const { subdivisions, countryValue } = payload;
      const subdivisionsOptions = Object.keys(subdivisions).map((key) => ({
        codeValue: key,
        shortName: subdivisions[key].name || subdivisions[key].translations.en,
      }));
      return {
        ...state,
        subdivisions: {
          ...state.subdivisions,
          [countryValue]: subdivisionsOptions,
        },
      };
    },
    getCompanySpecificDataSuccess(state, { payload }) {
      const { dynamicList, companyId } = payload;
      const jobTitleOptions = dynamicList.find((e) => e.key === jobTitleKey).values;
      const organizationsOptions = dynamicList.find((e) => e.key === organizationKey).values;
      const supervisorOptions = dynamicList.find((e) => e.key === supervisorKey).values;
      return {
        ...state,
        companyData: {
          [companyId]: {
            jobTitleOptions,
            organizationsOptions,
            supervisorOptions,
          },
        },
      };
    },
    getCompanySpecificDataSuccessForEmployee(state, { payload }) {
      const { dynamicList, companyId } = payload;
      const emergencyRelationshipType = dynamicList.find((e) => e.key === emergencyContactRelationshipTypeKey);
      const emergencyRelationshipTypeOptions = emergencyRelationshipType.values;
      return {
        ...state,
        employeeData: {
          [companyId]: {
            emergencyRelationshipTypeOptions,
          },
        },
      };
    },
  },
  effects: {
    *addCompany({ payload }, { call, put }) {
      const response = yield call(services.addCompany, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'addCompanySuccess',
        payload: response.data,
      });
      return response.data;
    },

    *getCompanies({ payload }, { call, put }) {
      const response = yield call(services.getCompanies, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'getCompaniesSuccess',
        payload: response.data,
      });
      return response.data;
    },

    *getSubdivisions({ payload }, { call, put }) {
      const { countryValue } = payload;
      const response = yield call(services.getSubdivisions, { countryValue });
      if (response.error) throw response.error;
      yield put({
        type: 'getSubdivisionsSuccess',
        payload: {
          subdivisions: response.data.subdivisions,
          countryValue,
        },
      });
      return response.data;
    },

    *getCompanySpecificData({ payload }, { call, put }) {
      const response = yield call(services.getCompanySpecificData, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'getCompanySpecificDataSuccess',
        payload: {
          dynamicList: response.data,
          companyId: payload.companyValue,
          allQuestions: payload.allQuestions,
        },
      });
      return response.data;
    },

    *getCompanySpecificDataforEmployee({ payload }, { call, put }) {
      const response = yield call(services.getCompanySpecificDataforEmployee, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'getCompanySpecificDataSuccessForEmployee',
        payload: {
          digestKey: payload.digestKey,
          dynamicList: response.data,
          companyId: payload.companyValue,
          allQuestions: payload.allQuestions,
        },
      });
      return response.data;
    },

    *verifyWorker({ payload }, { call }) {
      const response = yield call(services.verifyWorker, payload);
      return response;
    },

    *submitForm({ payload }, { call, put }) {
      const response = yield call(onboardingServices.createFormSubmission, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'employeeOnboarding/hrSubmittedAnswers',
        payload: { moduleType: 'paychex', [payload.action_id]: response.data },
      });
      return response.data;
    },
    *sendToPaychex({ payload }, { call, put }) {
      const response = yield call(services.sendToPaychex, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'employeeOnboarding/hrSubmittedAnswers',
        payload: { moduleType: 'paychex', [payload.action_id]: response.data },
      });
      return response.data;
    },
  },
};
