import type * as integrationIndeedModelTypes from '@/types/app/models/integration-indeed';

import request from '../utils/request';
import { APIHOST } from '../config';

export async function fetchIntegrations() {
  const path = '/v1/integrations';
  return request('GET', APIHOST + path);
}

export async function requestIndeedPredictOrganic(positionDigestKey: string, indeedAccountId: number, locale: string) {
  const path = `/v1/positions/${positionDigestKey}/predict_indeed_organic`;

  return request('GET', APIHOST + path, { indeed_account_id: indeedAccountId, locale });
}

export async function requestIndeedCampaignPredict(
  payload: integrationIndeedModelTypes.RequestIndeedCampaignPredictApiParam,
) {
  const { positionDigestKey, type, budget, start_date, end_date, indeedAccountId, locale } = payload;
  const path = `/v1/positions/${positionDigestKey}/predict_indeed_campaign`;
  return request('GET', APIHOST + path, {
    type,
    budget,
    start_date,
    end_date,
    indeed_account_id: indeedAccountId,
    locale,
  });
}

export async function getCheckerNodePackages(payload: { checkerId: number; nodeId: number }) {
  const { checkerId, nodeId } = payload;
  const path = `/v1/company_checkers/${checkerId}/company_checker_nodes/${nodeId}/available_packages`;
  return request('GET', APIHOST + path);
}

export async function updateCheckerNodeStatus(payload: { checkerId: number; nodeId: number; status: 'on' | 'off' }) {
  const { checkerId, nodeId, status } = payload;
  const path = `/v1/company_checkers/${checkerId}/company_checker_nodes/${nodeId}`;
  return request('PUT', APIHOST + path, {
    company_checker_node: {
      status,
    },
  });
}
