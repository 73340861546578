import React from 'react';
import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/es/modal';
import { colors, Text } from '@helloworld1812/ws-components';
import styled, { createGlobalStyle } from 'styled-components';
import { Checkmark, Cross, Info, Warning } from '@helloworld1812/ws-icons';
import { WsThemeProvider } from '@helloworld1812/app-global-components';

/*
how to use:
import { statusModal } from "...."

statusModal({
      type: 'success',
      title: 'Succesfully added to the queue!',
      content: '1/1 reminders will be sent out shortly',
      onOk: func(),
});

*/

type modalTypes = 'info' | 'success' | 'error' | 'warn' | 'warning' | 'confirm';
export interface StatusModalProps extends ModalFuncProps {
  buttonText?: string;
}

/**
 * @deprecated prefer Modal from antd4
 */
const statusModal = (props: StatusModalProps) => {
  const {
    onOk = () => {
      // do nothing
    },
    type = 'info',
    title,
    content = '',
    buttonText = 'Close',
  } = props;

  Modal[type]({
    ...props,
    bodyStyle: {
      height: '440px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '12px',
    },
    width: '620px',
    icon: null,
    style: {
      height: '440px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.12)',
      borderRadius: '12px',
    },
    closable: true,
    closeIcon: <Cross />,
    afterClose: () => {
      onOk();
    },
    className: 'status-modal-antd',
    title: null,
    content: renderContent(type, buttonText, title, content),
  });
};

const renderContent = (type: modalTypes, buttonText: string, title?: React.ReactNode, content?: React.ReactNode) => {
  return (
    <WsThemeProvider>
      <StyledContent type={type}>
        <div className="circle-icon-wrapper">
          <GlobalStyle buttonText={buttonText} />
          {getIcon(type)}
        </div>
        <div className="title-wrapper">
          <Text fontWeight={700} fontSize="20px">
            {title}
          </Text>
        </div>
        <div className="content-wrapper">
          <Text>{content}</Text>
        </div>
      </StyledContent>
    </WsThemeProvider>
  );
};

const getIconColor = (type: modalTypes) => {
  switch (type) {
    case 'info':
      return colors.blue600;
    case 'warning':
      return colors.gold600;
    case 'warn':
      return colors.gold600;
    case 'success':
      return colors.green600;
    case 'error':
      return colors.red500;
    default:
      return colors.blue600;
  }
};

const getBackgroundColor = (type: modalTypes) => {
  switch (type) {
    case 'info':
      return colors.blue200;
    case 'warning':
      return colors.gold100;
    case 'warn':
      return colors.gold100;
    case 'success':
      return colors.green200;
    case 'error':
      return colors.red200;
    default:
      return colors.blue200;
  }
};

const getIcon = (type: modalTypes) => {
  switch (type) {
    case 'info':
      return <Info />;
    case 'warning':
      return <Warning />;
    case 'warn':
      return <Warning />;
    case 'success':
      return <Checkmark />;
    case 'error':
      return <Cross />;
    default:
      return <Info />;
  }
};

const GlobalStyle = createGlobalStyle<{ buttonText: string }>`
  .ant4-modal-close-x {
    display: flex;
    align-items: end;
    svg {
      color: black;
    }
  }


  .status-modal-antd {
    .ant4-modal-content {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.12);
      border-radius: 12px;

      .ant4-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .ant4-modal-confirm-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      float: none;
      margin-top: 40px;

      button {
        margin: 0;
        padding: 12px 20px 12px 20px;
        min-width: 82px;
        height: 2.5rem;
        color: white;
        font-size: 16px;
        font-weight: 600;
        font-family: 'Plus Jakarta Sans';
        background-color: #3FABF8;
        border-radius: 8px;
        transition: none;
        text-shadow: none;
        box-shadow: none;
        border: none;
        &:hover {
          background-color: ${colors.blue600}
        }

        :active {
          background-color: ${colors.blue600}
        }

        span {
          display: none;
        }

        &:after {
          content: '${(props) => props.buttonText}';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;

const StyledContent = styled.div<{ type: modalTypes }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .close-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: end;
    svg {
      cursor: pointer;
    }
  }

  .circle-icon-wrapper {
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: ${(props) => getBackgroundColor(props.type)};
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    color: ${(props) => getIconColor(props.type)};
  }

  .title-wrapper {
    margin-bottom: 12px;
  }
`;

export { statusModal };
