const config = {
  identity_regexes: [
    {
      country_name: 'United States',
      country_code: 'US',
      iso_code: '1',
      identity_types: [
        {
          value: 'dl',
          label: 'Driver’s License',
          states: [
            {
              state_code: 'AL',
              state_name: 'Alabama',
              regex: '^[0-9]{1,8}$',
              description: ['1-8 Numeric'],
            },
            {
              state_code: 'AK',
              state_name: 'Alaska',
              regex: '^[0-9]{1,7}$',
              description: ['1-7 Numeric'],
            },
            {
              state_code: 'AZ',
              state_name: 'Arizona',
              regex: '(^[A-Z]{1}[0-9]{1,8}$)|(^[A-Z]{2}[0-9]{2,5}$)|(^[0-9]{9}$)',
              description: ['1 Alpha + 1-8 Numeric', '2 Alpha + 2-5 Numeric', '9 Numeric'],
            },
            {
              state_code: 'AR',
              state_name: 'Arkansas',
              regex: '^[0-9]{4,9}$',
              description: ['4-9 Numeric'],
            },
            {
              state_code: 'CA',
              state_name: 'California',
              regex: '^[A-Z]{1}[0-9]{7}$',
              description: ['1 Alpha + 7 Numeric'],
            },
            {
              state_code: 'CO',
              state_name: 'Colorado',
              regex: '(^[0-9]{9}$)|(^[A-Z]{1}[0-9]{3,6}$)|(^[A-Z]{2}[0-9]{2,5}$)',
              description: ['9 Numeric', '1 Alpha + 3-6 Numeric', '2 Alpha + 2-5 Numeric'],
            },
            {
              state_code: 'CT',
              state_name: 'Connecticut',
              regex: '^[0-9]{9}$',
              description: ['9 Numeric'],
            },
            {
              state_code: 'DE',
              state_name: 'Delaware',
              regex: '^[0-9]{1,7}$',
              description: ['1-7 Numeric'],
            },
            {
              state_code: 'DC',
              state_name: 'District of Columbia',
              regex: '(^[0-9]{7}$)|(^[0-9]{9}$)',
              description: ['7 Numeric', '9 Numeric'],
            },
            {
              state_code: 'FL',
              state_name: 'Florida',
              regex: '^[A-Z]{1}[0-9]{12}$',
              description: ['1 Alpha + 12 Numeric'],
            },
            {
              state_code: 'GA',
              state_name: 'Georgia',
              regex: '^[0-9]{7,9}$',
              description: ['7-9 Numeric'],
            },
            {
              state_code: 'HI',
              state_name: 'Hawaii',
              regex: '(^[A-Z]{1}[0-9]{8}$)|(^[0-9]{9}$)',
              description: ['1 Alpha + 8 Numeric', '9 Numeric'],
            },
            {
              state_code: 'ID',
              state_name: 'Idaho',
              regex: '(^[A-Z]{2}[0-9]{6}[A-Z]{1}$)|(^[0-9]{9}$)',
              description: ['2 Alpha + 6 Numeric + 1 Alpha', '9 Numeric'],
            },
            {
              state_code: 'IL',
              state_name: 'Illinois',
              regex: '^[A-Z]{1}[0-9]{11,12}$',
              description: ['1 Alpha + 11-12 Numeric'],
            },
            {
              state_code: 'IN',
              state_name: 'Indiana',
              regex: '(^[A-Z]{1}[0-9]{9}$)|(^[0-9]{9,10}$)',
              description: ['1 Alpha + 9 Numeric', '9-10 Numeric'],
            },
            {
              state_code: 'IA',
              state_name: 'Iowa',
              regex: '^([0-9]{9}|([0-9]{3}[A-Z]{2}[0-9]{4}))$',
              description: ['9 Numeric', '3 Numeric + 2 Alpha + 4 Numeric'],
            },
            {
              state_code: 'KS',
              state_name: 'Kansas',
              regex: '(^([A-Z]{1}[0-9]{1}){2}[A-Z]{1}$)|(^[A-Z]{1}[0-9]{8}$)|(^[0-9]{9}$)',
              description: ['1 Alpha + 1 Numeric + 1 Alpha + 1 Numeric + 1 Alpha', '1 Alpha + 8 Numeric', '9 Numeric'],
            },
            {
              state_code: 'KY',
              state_name: 'Kentucky',
              regex: '(^[A-Z]{1}[0-9]{8,9}$)|(^[0-9]{9}$)',
              description: ['1 Alpha + 8-9 Numeric', '9 Numeric'],
            },
            {
              state_code: 'LA',
              state_name: 'Louisiana',
              regex: '^[0-9]{1,9}$',
              description: ['1-9 Numeric'],
            },
            {
              state_code: 'ME',
              state_name: 'Maine',
              regex: '(^[0-9]{7,8}$)|(^[0-9]{7}[A-Z]{1}$)',
              description: ['7-8 Numeric', '7 Numeric + 1 Alpha'],
            },
            {
              state_code: 'MD',
              state_name: 'Maryland',
              regex: '(^[A-Z]{1}[0-9]{12}$)|(^MD-[0-9]{11}$)',
              description: ['1Alpha+12Numeric'],
            },
            {
              state_code: 'MA',
              state_name: 'Massachusetts',
              regex: '(^[A-Z]{1}[0-9]{8}$)|(^[A-Z]{2}[0-9]{7}$)|(^[0-9]{9}$)',
              description: ['1 Alpha + 8 Numeric', '9 Numeric'],
            },
            {
              state_code: 'MI',
              state_name: 'Michigan',
              regex: '(^[A-Z]{1}[0-9]{10}$)|(^[A-Z]{1}[0-9]{12}$)',
              description: ['1 Alpha + 10 Numeric', '1 Alpha + 12 Numeric'],
            },
            {
              state_code: 'MN',
              state_name: 'Minnesota',
              regex: '^[A-Z]{1}[0-9]{12}$',
              description: ['1 Alpha + 12 Numeric'],
            },
            {
              state_code: 'MS',
              state_name: 'Mississippi',
              regex: '^[0-9]{9}$',
              description: ['9 Numeric'],
            },
            {
              state_code: 'MO',
              state_name: 'Missouri',
              regex:
                '(^[A-Z]{1}[0-9]{5,9}$)|(^[A-Z]{1}[0-9]{6}[R]{1}$)|(^[0-9]{8}[A-Z]{2}$)|(^[0-9]{9}[A-Z]{1}$)|(^[0-9]{9}$)',
              description: [
                '1 Alpha + 5-9 Numeric',
                "1 Alpha + 6 Numeric + 'R'",
                '8 Numeric + 2 Alpha',
                '9 Numeric + 1 Alpha',
                '9 Numeric',
              ],
            },
            {
              state_code: 'MT',
              state_name: 'Montana',
              regex: '(^[A-Z]{1}[0-9]{8}$)|(^[0-9]{13}$)|(^[0-9]{9}$)|(^[0-9]{14}$)|(^[A-Z]{3}[0-9]{10}$)',
              description: ['1 Alpha + 8 Numeric', '13 Numeric', '9 Numeric', '14 Numeric', '3 Alpha + 10 Numeric'],
            },
            {
              state_code: 'NE',
              state_name: 'Nebraska',
              regex: '^[A-Z]{1}[0-9]{6,8}$',
              description: ['1 Alpha + 6-8 Numeric'],
            },
            {
              state_code: 'NV',
              state_name: 'Nevada',
              regex: '(^[0-9]{9,10}$)|(^[0-9]{12}$)|(^[X]{1}[0-9]{8}$)',
              description: ['9 Numeric', '10 Numeric', '12 Numeric', "'X' + 8 Numeric"],
            },
            {
              state_code: 'NH',
              state_name: 'New Hampshire',
              regex: '(^[0-9]{2}[A-Z]{3}[0-9]{5}$)|(^[A-Z]{3}[0-9]{8}$)',
              description: ['2 Numeric + 3 Alpha + 5 Numeric'],
            },
            {
              state_code: 'NJ',
              state_name: 'New Jersey',
              regex: '^[A-Z]{1}[0-9]{14}$',
              description: ['1 Alpha + 14 Numeric'],
            },
            {
              state_code: 'NM',
              state_name: 'New Mexico',
              regex: '^[0-9]{8,9}$',
              description: ['8 Numeric', '9 Numeric'],
            },
            {
              state_code: 'NY',
              state_name: 'New York',
              regex: '(^[A-Z]{1}[0-9]{7}$)|(^[A-Z]{1}[0-9]{18}$)|(^[0-9]{8}$)|(^[0-9]{9}$)|(^[0-9]{16}$)|(^[A-Z]{8}$)',
              description: [
                '1 Alpha + 7 Numeric',
                '1 Alpha + 18 Numeric',
                '8 Numeric',
                '9 Numeric',
                '16 Numeric',
                '8 Alpha',
              ],
            },
            {
              state_code: 'NC',
              state_name: 'North Carolina',
              regex: '^[0-9]{1,12}$',
              description: ['1-12 Numeric'],
            },
            {
              state_code: 'ND',
              state_name: 'North Dakota',
              regex: '(^[A-Z]{3}[0-9]{6}$)|(^[0-9]{9}$)',
              description: ['3 Alpha + 6 Numeric', '9 Numeric'],
            },
            {
              state_code: 'OH',
              state_name: 'Ohio',
              regex: '(^[A-Z]{1}[0-9]{4,8}$)|(^[A-Z]{2}[0-9]{3,7}$)|(^[0-9]{8}$)',
              description: ['1 Alpha + 4-8 Numeric', '2 Alpha + 3-7 Numeric', '8 Numeric'],
            },
            {
              state_code: 'OK',
              state_name: 'Oklahoma',
              regex: '(^[A-Z]{1}[0-9]{9}$)|(^[0-9]{9}$)',
              description: ['1 Alpha + 9 Numeric', '9 Numeric'],
            },
            {
              state_code: 'OR',
              state_name: 'Oregon',
              regex: '^[0-9]{1,9}$',
              description: ['1-9 Numeric'],
            },
            {
              state_code: 'PA',
              state_name: 'Pennsylvania',
              regex: '^[0-9]{8}$',
              description: ['8 Numeric'],
            },
            {
              state_code: 'RI',
              state_name: 'Rhode Island',
              regex: '^([0-9]{7}$)|(^[A-Z]{1}[0-9]{6}$)',
              description: ['7 Numeric', '1 Alpha + 6 Numeric'],
            },
            {
              state_code: 'SC',
              state_name: 'South Carolina',
              regex: '^[0-9]{5,11}$',
              description: ['5-11 Numeric'],
            },
            {
              state_code: 'SD',
              state_name: 'South Dakota',
              regex: '(^[0-9]{6,10}$)|(^[0-9]{12}$)',
              description: ['6-10 Numeric', '12 Numeric'],
            },
            {
              state_code: 'TN',
              state_name: 'Tennessee',
              regex: '^[0-9]{7,9}$',
              description: ['7-9 Numeric'],
            },
            {
              state_code: 'TX',
              state_name: 'Texas',
              regex: '^[0-9]{7,8}$',
              description: ['7-8 Numeric'],
            },
            {
              state_code: 'UT',
              state_name: 'Utah',
              regex: '^[0-9]{4,10}$',
              description: ['4-10 Numeric'],
            },
            {
              state_code: 'VT',
              state_name: 'Vermont',
              regex: '(^[0-9]{8}$)|(^[0-9]{7}[A]$)',
              description: ['8 Numeric', "7 Numeric + 'A'"],
            },
            {
              state_code: 'VA',
              state_name: 'Virginia',
              regex: '(^[A-Z]{1}[0-9]{8,11}$)|(^[0-9]{9}$)',
              description: [
                '1 Alpha + 8 Numeric',
                '1 Alpha + 9 Numeric',
                '1 Alpha + 10 Numeric',
                '1 Alpha + 11 Numeric',
                '9 Numeric',
              ],
            },
            {
              state_code: 'WA',
              state_name: 'Washington',
              regex: '^(?=.{12}$)[A-Z]{1,7}[A-Z0-9\\*]{4,11}$',
              description: ['1-7 Alpha + any combination of Alpha, Numeric, and * for a total of 12 characters'],
            },
            {
              state_code: 'WV',
              state_name: 'West Virginia',
              regex: '(^[0-9]{7}$)|(^[A-Z]{1,2}[0-9]{5,6}$)',
              description: ['7 Numeric', '1-2 Alpha + 5-6 Numeric'],
            },
            {
              state_code: 'WI',
              state_name: 'Wisconsin',
              regex: '^[A-Z]{1}[0-9]{13}$',
              description: ['1 Alpha + 13 Numeric'],
            },
            {
              state_code: 'WY',
              state_name: 'Wyoming',
              regex: '^[0-9]{9,10}$',
              description: ['9-10 Numeric'],
            },
          ],
        },
        {
          value: 'ssn',
          label: 'Social Security Number',
          regex: '^(?!(000|666|9\\d{2}))\\d{3}-?(?!00)\\d{2}-?(?!0000)\\d{4}$',
        },
      ],
    },
    {
      country_name: 'Singapore',
      country_code: 'SG',
      iso_code: '65',
      identity_types: [
        {
          value: 'nric',
          label: 'NRIC',
          regex: '^[STFG]\\d{7}[A-Z]$',
        },
      ],
    },
  ],
};

export default config;
