// wiki:
// https://github.com/helloworld1811/workstream-backend/wiki/API-v1-PositionApplications#an-applications-profile

import _ from 'lodash';

import request from '../utils/request';
import { toFormData } from '../utils';
import { APIHOST } from '../config';

const moveToNextStagePath = '/v1/position_applications/:id/move_to_next_stage';

const moveToStagePath = '/v1/position_applications/:id/move_to_stage';

const takeNotePath = '/v1/position_applications/:id/application_notes';

const sendEmailPath = '/v1/position_applications/:id/send_email';

const sendSMSPath = '/v1/position_applications/:id/send_sms';

const rejectPath = '/v1/position_applications/:id/reject';

const addApplicantPath = '/v1/positions/:digest_key/add_applicant';

const exportApplicantsToEmailsPath = '/v1/positions/send_applicants_to_emails';

const downloadApplicantsPdfsPath = '/v1/positions/download_applicants_pdfs';

const moveToPositionPath = '/v1/position_applications/:id/move_to_position';
const availableMovedPositionsPath = '/v1/positions/available';

const updateApplicantPath = '/v1/position_applications/:id';

const sendReminderPath = '/v1/position_applications/:id/send_reminder';

const allApplicationBucketsPath = '/v1/application_buckets';

const applicationBucketsPath = '/v1/application_buckets/:bucketName';

const inactiveApplicantsPath = '/v1/position_applications';

export async function fetchApplicantsOfCompany(payload) {
  const { nickname } = payload;
  const path = APIHOST + '/v1/companies/:nickname/applicants'.replace(':nickname', nickname);

  const {
    page,
    perPage: per_page,
    query,
    sort_key: sortKey,
    order,
    stages,
    positions,
    status,
    rating_gte: ratingGte,
    progress_status: progressStatus,
    tag_ids: tags = [],
    unread_message: unreadMessage,
  } = payload;
  const data = {
    page,
    per_page,
    ...(tags.length > 0 ? { tag_ids: tags } : {}),
  };
  if (query) {
    data.query = query;
  }
  if (sortKey && order) {
    const key = sortKey;
    let orderValue = order;
    // if (key === 'published' || key === 'url') key = 'status';
    orderValue = orderValue === 'ascend' ? 'asc' : 'desc';
    data.order = `${key},${orderValue}`;
  }

  if (stages && !_.isEqual(stages, ['all'])) {
    data.stage = stages;
  }

  if (positions && !_.isEqual(positions, ['all'])) {
    data.position_id = positions;
  }

  if (status) {
    data.status = status;
  }

  if (progressStatus) {
    data.progress_status = progressStatus;
  }

  if (ratingGte || ratingGte === 0) {
    data.rating_gte = ratingGte;
  }

  if (unreadMessage) data.unread_message = unreadMessage;

  return request('GET', path, data);
}

export async function fetchSelectedPosition(payload) {
  const { digestkey } = payload;
  const path = '/v1/positions/:digest_key'.replace(':digest_key', digestkey);
  return request('GET', APIHOST + path, { list: true });
}

export async function fetchDetail(payload) {
  const { id } = payload;
  const path = '/v1/position_applications/:id'.replace(':id', id);
  return request('GET', APIHOST + path);
}

export async function fetchQuiz(payload) {
  const { id } = payload;
  const path = '/v1/application_online_quizzes/:digest_key/result'.replace(':digest_key', id);
  return request('GET', APIHOST + path);
}

export async function fetchForm(payload) {
  const { id } = payload;
  const path = '/v1/form_submissions/:digest_key'.replace(':digest_key', id);
  return request('GET', APIHOST + path);
}

export async function fetchOffer(payload) {
  const { id } = payload;
  const path = '/v1/application_offers/:id'.replace(':id', id);
  return request('GET', APIHOST + path);
}

export async function fetchApplicantBackgroundCheck(payload) {
  const { id } = payload;
  const path = '/v1/application_background_checks/:digest_key'.replace(':digest_key', id);
  return request('GET', APIHOST + path);
}

export async function fetchApplicationBuckets(payload) {
  return request('GET', APIHOST + allApplicationBucketsPath, payload);
}

export async function fetchApplicationInBucket(payload: { [prop: string]: string }) {
  const {
    bucket,
    page,
    perPage: per_page,
    sort_key: sortKey,
    order,
    stage,
    // positions,
    // status,
    rejected,
    hired,
    tag_ids,
    unread_message,
    only_with_scope,
    // rating_gte,
    last_message_type,
    stuck_in_stage,
    query,
    brandId,
    locationId,
    positionId,
    stage_status,
    rating_types,
    rejection_tag_ids,
    filters,
  } = payload;

  const path = APIHOST + applicationBucketsPath.replace(':bucketName', bucket);
  const data: { [prop: string]: unknown } = {
    page,
    per_page,
  };

  if (sortKey && order) {
    const key = sortKey;
    let orderValue = order;
    // if (key === 'published' || key === 'url') key = 'status';
    orderValue = orderValue === 'ascend' || orderValue === 'asc' ? 'asc' : 'desc';
    data.order = `${key},${orderValue}`;
  }

  if (stage && !_.isEqual(stage, ['all'])) {
    data.stage = stage;
  }
  if (rejected) {
    data.status = ['rejected'];
  }
  if (hired && bucket === 'hiring') {
    data.status = ['hired'];
  }
  if (tag_ids?.length && Array.isArray(tag_ids)) {
    data.tag_ids = tag_ids?.map((ele) => Number(ele));
  }
  if (rejection_tag_ids?.length && Array.isArray(rejection_tag_ids)) {
    if (data.tag_ids && Array.isArray(data.tag_ids)) {
      rejection_tag_ids.forEach((ele) => {
        data.tag_ids.push(Number(ele));
      });
    } else {
      data.tag_ids = rejection_tag_ids.map((ele) => Number(ele));
    }
  }

  if (stage_status?.length && Array.isArray(stage_status) && bucket === 'interviewing') {
    data.stage_status = stage_status;
  }

  if (rating_types?.length && Array.isArray(rating_types)) {
    data.rating_types = rating_types;
  }

  data.unread_message = unread_message ? true : undefined;

  data.last_message_type = last_message_type ? 'sent' : undefined;

  data.stuck_in_stage = stuck_in_stage ? true : undefined;

  if (only_with_scope) {
    data.rating_gte = 0;
  }
  if (query && _.trim(query)) {
    data.query = _.trim(query);
  }

  if (filters) {
    data.filters = filters;
  }

  data.brand_id = !brandId || brandId === 'all' ? undefined : brandId;
  data.location_id = !locationId || locationId === 'all' ? undefined : locationId;
  data.position_id = !positionId || positionId === 'all' ? undefined : positionId;

  return request('GET', path, data);
}

export const mapPayloadToRequest = (payload) => {
  const {
    page,
    perPage: per_page,
    positionId,
    query,
    sort_key,
    order,
    status,
    reason,
    time,
    brandId,
    locationId,
  } = payload;

  const data: { [prop: string]: unknown } = {
    page,
    per_page,
    status: 'inactive',
  };

  const mappedOrder = order === 'ascend' || order === 'asc' ? 'asc' : 'desc';

  const calculateSortKey = () => {
    if (!sort_key) {
      data.order = 'created_at,desc';
      return;
    }

    if (sort_key === 'created_at') {
      data.order = `${sort_key},${mappedOrder}`;
      return;
    }

    data.order = `${sort_key},${mappedOrder},created_at,desc`;
  };

  calculateSortKey();

  if (positionId && !_.isEqual(positionId, 'all')) {
    data.position_id = positionId;
  }

  if (query && _.trim(query)) {
    data.query = _.trim(query);
  }

  if (time && time !== 'all') {
    data.inactive_days = time;
  }

  // turns object into key-value pair, loops through and assigns them to data
  if (reason) {
    Object.entries(reason).forEach((key_value) => {
      if (key_value[0] !== 'inactive_by' || (key_value[1]?.length > 0 && key_value[1][0] !== 'all'))
        data[key_value[0]] = key_value[1];
    });
  }

  if (status) {
    Object.entries(status).forEach((key_value) => {
      data[key_value[0]] = key_value[1];
    });
  }

  data.brand_id = !brandId || brandId === 'all' || brandId === 'undefined' ? undefined : brandId;
  data.location_id = !locationId || locationId === 'all' || locationId === 'undefined' ? undefined : locationId;
  data.position_id = !positionId || positionId === 'all' || positionId === 'undefined' ? undefined : positionId;
  return data;
};

export async function fetchInactiveApplicants(payload: { [prop: string]: string }) {
  const data = mapPayloadToRequest(payload);
  const path = APIHOST + inactiveApplicantsPath;
  return request('GET', path, data);
}

export async function reject(payload) {
  const { applicantId, position_auto_action: pAA, meetingOptions } = payload.item;
  const path = rejectPath.replace(':id', applicantId);
  const params = {};
  if (pAA) {
    params.position_auto_action = { ...pAA };
  }
  if (meetingOptions) {
    params.meeting_options = { ...meetingOptions };
  }

  return request('POST', APIHOST + path, { ...params });
}

export async function markNotHired(payload) {
  const { id } = payload;
  const path = '/v1/position_applications/:id/unhire'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function unreject(payload) {
  const { id } = payload;
  const path = '/v1/position_applications/:id/unreject'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function archive(payload) {
  const { id } = payload;
  const path = '/v1/position_applications/:id/archive'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function unarchive(payload) {
  const { id } = payload;
  const path = '/v1/position_applications/:id/unarchive'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function sendSMS(payload) {
  const { id, content } = payload;
  const path = sendSMSPath.replace(':id', id);
  return request('POST', APIHOST + path, {
    content,
  });
}

export async function sendEmailToApplicant(payload) {
  const { id, subject, content } = payload;
  const path = sendEmailPath.replace(':id', id);
  return request('POST', APIHOST + path, {
    subject,
    content,
  });
}

export async function moveToNextStage(payload) {
  const { id } = payload;
  const path = moveToNextStagePath.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function moveToAnyStage(payload) {
  const { id, stageId: position_stage_id, extraData, schedule_complete: scheduleComplete } = payload;
  const path = moveToStagePath.replace(':id', id);
  const data = { position_stage_id };
  switch (extraData?.stageType) {
    case 'offer':
      data.send_signature_request = extraData.sendOffer;
      const offerTemplate = extraData.applicantCustomOfferTemplate[id];
      if (offerTemplate) {
        data.application_offer = { ...offerTemplate };
      }
      break;
    case 'background_check':
      data.send_background_check_request = extraData.sendBackgroundCheck;
      break;
    case 'scheduling_stage':
      if (extraData.time_slot) {
        data.time_slot = extraData.time_slot;
      }
      break;
    case 'hiring_complete': {
      const { position_application } = extraData;
      data.position_application = position_application;
      break;
    }
    default:
      break;
  }
  if (scheduleComplete !== undefined) data.schedule_complete = scheduleComplete;

  return request('POST', APIHOST + path, data);
}

export async function orderBackgroundCheck(payload) {
  const { id } = payload;
  const path = '/v1/position_applications/:id/send_background_check_request'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function sendOffer(payload) {
  const { id, customOfferTemplate } = payload;
  const path = '/v1/position_applications/:id/send_signature_request'.replace(':id', id);
  const data = {};
  if (customOfferTemplate && customOfferTemplate[id]) {
    data.application_offer = { ...customOfferTemplate[id] };
  }
  return request('POST', APIHOST + path, data);
}

export async function cancelOffer(payload) {
  const { id } = payload;
  const path = '/v1/application_offers/:id/cancel'.replace(':id', id);
  const data = {};
  return request('POST', APIHOST + path, data);
}

export async function addApplicant(payload) {
  const { digestkey, data } = payload;
  const path = addApplicantPath.replace(':digest_key', digestkey);
  return request('POST', APIHOST + path, data);
}

export async function updateApplicant(payload) {
  // { position_application: {
  //   first_name, last_name, region_number, phone, email,
  //   candidate_info: {1: value, 2: value},
  //   smart_screening: {1: 'yes', 2: 'no'}
  //   application_attachments: {resume: choose_file}
  // } }
  const { id, position_application } = payload;
  const path = updateApplicantPath.replace(':id', id);
  if (position_application.application_attachments) {
    const data = toFormData({ position_application });
    return request('PUT', APIHOST + path, data);
  } else {
    return request('PUT', APIHOST + path, { position_application });
  }
}

export async function updateApplicantQuizSubmission(payload) {
  const { digestkey, quizKeyValue } = payload;
  const path = '/v1/application_online_quizzes/:digest_key/modify'.replace(':digest_key', digestkey);
  const id = Object.keys(quizKeyValue)[0];
  const value = quizKeyValue[id];
  const data = {
    application_online_quiz: {
      answer: {
        quiz_question_id: id,
        chosen: value,
      },
    },
  };
  return request('POST', APIHOST + path, toFormData(data));
}

export async function updateApplicantFormSubmission(payload) {
  const { digestkey, formValues } = payload;
  const path = '/v1/form_submissions/:digest_key/modify'.replace(':digest_key', digestkey);

  const data = {
    form_submission: {
      answers: {
        ...formValues,
      },
    },
  };

  return request('POST', APIHOST + path, toFormData(data));
}

export async function exportApplicantsToEmails(payload) {
  const { ids, subject, content, emails } = payload;
  return request('POST', APIHOST + exportApplicantsToEmailsPath, {
    position_application_ids: ids,
    subject,
    content,
    emails: emails.split(','),
  });
}

export async function downloadApplicantsPdfs(payload) {
  const { ids, sendToEmail = null } = payload;
  return request('POST', APIHOST + downloadApplicantsPdfsPath, {
    position_application_ids: ids,
    send_to_email: sendToEmail,
  });
}

export async function moveToPosition(payload) {
  const { id, newPositionId, newPositionStageId, skipNotifications, skipAutoActions } = payload;
  const path = moveToPositionPath.replace(':id', id);
  return request('POST', APIHOST + path, {
    new_position_id: newPositionId,
    new_position_stage_id: newPositionStageId,
    skip_notifications: skipNotifications,
    skip_auto_actions: skipAutoActions,
  });
}

export async function queryAvailableMovedPositions() {
  const path = availableMovedPositionsPath;
  return request('GET', APIHOST + path, {});
}

export async function fetchAvailableMovedPositionsByPositionName(positionName) {
  return request('GET', APIHOST + availableMovedPositionsPath, { title: positionName });
}

export async function sendReminder(payload) {
  const { id } = payload;
  const path = sendReminderPath.replace(':id', id);
  return request('POST', APIHOST + path, {});
}

export async function getBackgroundCheckStatus(payload) {
  const { ids } = payload;
  const path = '/v1/position_applications/background_checks';
  return request('GET', APIHOST + path, { ids });
}

export async function createRating(payload) {
  const {
    id,
    application_rating: {
      rating_plan_id: ratingPlanId,
      rating_total: ratingTotal,
      rating_type: ratingType,
      mentions,
      application_rating_items_attributes: applicationRatingItemsAttributes,
    },
  } = payload;
  const path = '/v1/position_applications/:id/application_ratings'.replace(':id', id);
  const formData = {
    rating_plan_id: ratingPlanId,
    rating_total: ratingTotal,
    rating_type: ratingType,
    application_rating_items_attributes: applicationRatingItemsAttributes,
  };
  if (mentions && mentions.length > 0) {
    formData.company_staff_ids = mentions;
  }
  const data = toFormData({
    application_rating: {
      ...formData,
    },
  });
  return request('POST', APIHOST + path, data);
}

export async function fetchAllStages(payload) {
  const { nickname, access, status } = payload;
  const path = '/v1/companies/:nickname/all_stages'.replace(':nickname', nickname);
  return request('GET', APIHOST + path, { access, status });
}

export async function fetchStagesForApplicantPhase(payload) {
  const { bucket, positionId, locationId } = payload;
  const path = `/v1/application_buckets/${bucket}/position_stages`;
  return request('GET', APIHOST + path, { position_id: positionId, location_id: locationId });
}

export async function startOnboarding(payload) {
  const { id } = payload;
  const path = '/v1/position_applications/:id/onboard'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function fetchApplicantsStageGroups(payload) {
  const { ids } = payload;
  const path = '/v1/position_applications/group_by_stage';
  return request('POST', APIHOST + path, { position_application_ids: ids });
}

export async function fetchPendingApplicants(params) {
  const path = '/v1/company_staffs/pending_applicants';
  return request('GET', APIHOST + path, params);
}
