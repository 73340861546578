import type { DvaInstance } from 'dva';

import { WS_DISABLE_WEBSOCKET } from '@/config';
import { registerModelIfNotExist } from '@/utils/dva';
import profile from '@/models/profile';
import appModel from '@/models/app';
import companyStaff from '@/models/companyStaff';
import webSocketModel from '@/models/webSocket';
import applicant from '@/models/applicant';
import employeeOnboarding from '@/models/employee-onboarding';
import companyDocuments from '@/models/companyDocuments';
import processSettings from '@/models/employee-onboarding/onboarding-process-settings';
import employees from '@/models/employee-onboarding/employees';
import employeeProfile from '@/models/employee-onboarding/employee-profile';
import wotc from '@/models/employee-onboarding/wotc';
import eVerify from '@/models/employee-onboarding/e-verify';
import i9 from '@/models/employee-onboarding/i9';
import eVerifyQuiz from '@/models/e-verify-quiz';
import eVerifyCase from '@/models/e-verify-case';
import paychex from '@/models/employee-onboarding/paychex';
import connectorJwtToken from '@/models/employee-onboarding/connectorJwtToken';

import configureOnboardingProcess from './employee-onboarding/configure-onboarding-process';
import customForm from './employee-onboarding/custom-form';
import createHiringProcessTemplate from './decoupled-job-posting/hiring-process-template/create-hiring-process-template';
import editHiringProcessTemplate from './decoupled-job-posting/hiring-process-template/edit-hiring-process-template';

export function registerAllModels(app: DvaInstance) {
  registerModelIfNotExist(app, profile);
  registerModelIfNotExist(app, appModel);
  registerModelIfNotExist(app, companyStaff);

  if (!WS_DISABLE_WEBSOCKET) {
    registerModelIfNotExist(app, webSocketModel);
  }

  registerModelIfNotExist(app, applicant);

  registerModelIfNotExist(app, employeeOnboarding);
  registerModelIfNotExist(app, companyDocuments);
  registerModelIfNotExist(app, processSettings);
  registerModelIfNotExist(app, employeeProfile);
  registerModelIfNotExist(app, wotc);
  registerModelIfNotExist(app, employees);
  registerModelIfNotExist(app, paychex);
  registerModelIfNotExist(app, customForm);
  registerModelIfNotExist(app, i9);

  registerModelIfNotExist(app, eVerify);
  registerModelIfNotExist(app, eVerifyQuiz);
  registerModelIfNotExist(app, eVerifyCase);
  registerModelIfNotExist(app, connectorJwtToken);

  registerModelIfNotExist(app, configureOnboardingProcess);

  registerModelIfNotExist(app, createHiringProcessTemplate);
  registerModelIfNotExist(app, editHiringProcessTemplate);
}

export type { RootState } from '@/types/app/models';

export type { DvaLoadingState } from 'dva-loading';
