import { Tooltip } from '@helloworld1812/ws-components';
import React from 'react';
import { useHasFunctionModule } from '@helloworld1812/ws-biz-hooks';

import { useHasFullAccess } from '@/hooks/permission/user-permission-pii';
import { useHasFullTeamRecordAccess } from '@/hooks/permission/manage_team_record';

interface PIIFieldProps {
  value: string;
}

function PIIField({ value }: PIIFieldProps) {
  const hasPIIFullAccess = useHasFullAccess();
  const hasFullTeamRecordAccess = useHasFullTeamRecordAccess();
  const hasTmModule = useHasFunctionModule('team_records');

  const isFullAccess = hasTmModule ? hasFullTeamRecordAccess : hasPIIFullAccess;

  if (isFullAccess) return value;

  return (
    <Tooltip placement="topLeft" title="Please contact your company's Super Admin for view access">
      {value}
    </Tooltip>
  );
}

export default PIIField;
