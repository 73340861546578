import pathToRegexp from 'path-to-regexp';

import * as services from '../services/applicant';
import { getJsonFromUrl } from '../utils';
import constants from '../utils/constants';

export const initialState = {
  quickReviewApplicantId: undefined,
  quickReviewApplicantDetail: undefined,
  selectedApplicant: undefined,
  hasAccess: true,
  quizResult: {},
  formResult: {},
  offerResult: {},
  backgroundCheckResult: {},

  currQuizAttempt: 1,

  profileId: '',

  notesTablePagination: {
    total: 10,
    current: 1,
    pageSize: 10,
  },

  notesQuery: {
    id: '',
    page: 1,
    per_page: 10,
  },

  formQuery: {
    digestkey: '',
  },

  quizQuery: {
    digestkey: '',
  },

  offerQuery: {
    id: '',
  },

  schedulingQuery: {
    id: '',
  },
};

export const reducers = {
  selectScheduleItem(state, { payload }) {
    const { id } = payload;
    return {
      ...state,
      schedulingQuery: {
        id,
      },
    };
  },

  updateCurrentQuizAttempt(state, { payload }) {
    const { currQuizAttempt } = payload;
    return {
      ...state,
      currQuizAttempt,
    };
  },

  setupProfileId(state, { payload }) {
    const { profileId, sidebarKey } = payload;

    return {
      ...state,
      profileId,
      sidebarKey,
    };
  },

  setupOfferQuery(state, { payload }) {
    return {
      ...state,
      offerQuery: payload,
    };
  },

  setupQuizQuery(state, { payload }) {
    return {
      ...state,
      quizQuery: payload,
    };
  },

  setupFormQuery(state, { payload }) {
    return {
      ...state,
      formQuery: payload,
    };
  },

  detailUpdate(state, { payload }) {
    const { position_application: selectedApplicant } = payload;
    const { id } = selectedApplicant;
    selectedApplicant.detail = { ...selectedApplicant };

    // only update selectedApplicant when it's id is the same as profileId
    if (!state.profileId || state.profileId.toString() === id.toString()) {
      return {
        ...state,
        selectedApplicant,
        quickReviewApplicantDetail: selectedApplicant,
      };
    } else {
      return state;
    }
  },

  detailUpdateArchive(state, { payload }) {
    const { selectedApplicant } = state;
    const { position_application: applicant = {} } = payload;
    const { conversation_archived: conversationArchived } = applicant;
    const newSelectedApplicant = {
      ...selectedApplicant,
      conversation_archived: conversationArchived,
    };
    return {
      ...state,
      selectedApplicant: newSelectedApplicant,
      quickReviewApplicantDetail: newSelectedApplicant,
    };
  },

  detailUpdateOffer(state, { payload }) {
    const { selectedApplicant } = state;
    const { detail } = selectedApplicant;
    const { application_offers: applicationOffers } = detail;

    const updatedApplicationOffers = applicationOffers.map((applicationOffer) => {
      return applicationOffer.id === payload.id ? payload : applicationOffer;
    });

    const updatedSelectedApplicant = {
      ...selectedApplicant,
      detail: { ...detail, application_offers: updatedApplicationOffers },
    };
    return {
      ...state,
      selectedApplicant: updatedSelectedApplicant,
      quickReviewApplicantDetail: updatedSelectedApplicant,
    };
  },
  setupQuiz(state, { payload }) {
    return {
      ...state,
      quizResult: payload,
    };
  },
  setupForm(state, { payload }) {
    return {
      ...state,
      formResult: payload,
    };
  },
  setupOffer(state, { payload }) {
    return {
      ...state,
      offerResult: payload,
    };
  },

  setupApplicantBackgroundCheck(state, { payload }) {
    return {
      ...state,
      backgroundCheckResult: payload,
    };
  },

  updateAccess(state, { payload }) {
    const { hasAccess } = payload;
    return {
      ...state,
      hasAccess,
    };
  },

  setupQuickReviewApplicantId(state, { payload }) {
    const { id, trigger, defaultTab, focus, detail } = payload;
    const curDetail = state.quickReviewApplicantDetail;

    return {
      ...state,
      quickReviewApplicantId: id,
      quickReviewApplicantDetail: detail ?? (curDetail?.id === id ? curDetail : {}),
    };
  },
};

export default {
  namespace: 'profile',

  state: initialState,

  reducers,

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen((location) => {
        const { pathname } = location;
        if (pathToRegexp('/applicant/:id').test(pathname)) {
          const re = pathToRegexp('/applicant/:id');
          const profileId = re.exec(pathname)?.[1];

          const query = getJsonFromUrl(true);
          const { section } = query;
          let sidebarKey = section;
          if (['form', 'quiz'].indexOf(section) !== -1) {
            const { digestkey } = query;
            sidebarKey = `${section}@${digestkey}`;
          }

          dispatch({
            type: 'setupProfileId',
            payload: { profileId, sidebarKey },
          });
        }
      });
    },
  },

  effects: {
    *fetchApplicantDetail({ payload }, { put, call }) {
      const response = yield call(services.fetchDetail, payload);
      if (
        response.error &&
        (response.error.errorCode === constants.ERROR_CODE.NO_ACCESS_ERROR_CODE ||
          response.error.errorCode === constants.ERROR_CODE.PAGE_NOT_FOUND_ERROR_CODE)
      ) {
        yield put({
          type: 'updateAccess',
          payload: {
            hasAccess: false,
          },
        });
        return;
      }

      if (response.error) throw response.error;
      yield put({
        type: 'detailUpdate',
        payload: response.data,
      });

      yield put({
        type: 'updateAccess',
        payload: {
          hasAccess: true,
        },
      });
      if (payload.onSuccess) payload.onSuccess();
    },
    *fetchQuiz({ payload }, { put, call }) {
      const { id: digestkey } = payload;
      yield put({
        type: 'setupQuizQuery',
        payload: { digestkey },
      });

      // Reset the quiz attempt back to 1 when switching between quizzes of different stages
      yield put({
        type: 'updateCurrentQuizAttempt',
        payload: { currQuizAttempt: 1 },
      });

      const response = yield call(services.fetchQuiz, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'setupQuiz',
        payload: response.data.application_online_quizzes,
      });
    },
    *fetchForm({ payload }, { put, call }) {
      const { id: digestkey } = payload;
      yield put({
        type: 'setupFormQuery',
        payload: { digestkey },
      });
      const response = yield call(services.fetchForm, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'setupForm',
        payload: response.data.form_submission,
      });
    },
    *fetchOffer({ payload }, { put, call }) {
      const { id } = payload;
      yield put({
        type: 'setupOfferQuery',
        payload: { id },
      });
      const response = yield call(services.fetchOffer, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'setupOffer',
        payload: response.data.application_offer,
      });
    },

    *cancelOffer({ payload }, { call, put }) {
      const { id } = payload;

      const response = yield call(services.cancelOffer, { id });
      if (response.error) throw response.error;
      yield put({
        type: 'setupOffer',
        payload: response.data.application_offer,
      });
      yield put({
        type: 'detailUpdateOffer',
        payload: response.data.application_offer,
      });
    },

    *fetchApplicantBackgroundCheck({ payload }, { put, call }) {
      const response = yield call(services.fetchApplicantBackgroundCheck, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'setupApplicantBackgroundCheck',
        payload: response.data.application_background_check,
      });
    },

    *updateApplicant({ payload }, { put, call }) {
      const response = yield call(services.updateApplicant, payload);
      // re fetch the applicant detail, because the API response for services.updateApplicant
      // doesn't match the the data in the profile/fetchApplicantDetail, and that lack of
      // data will crash our app since we gonna read data from `undefined` sometimes
      if (response.error) throw response.error;
      yield put({
        type: 'profile/fetchApplicantDetail',
        payload: { id: payload.id },
      });

      return response.data;
    },

    *updateApplicantQuizSubmission({ payload }, { put, call }) {
      const response = yield call(services.updateApplicantQuizSubmission, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'setupQuiz',
        payload: response.data.application_online_quiz,
      });
    },

    *updateApplicantFormSubmission({ payload }, { put, call }) {
      const response = yield call(services.updateApplicantFormSubmission, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'setupForm',
        payload: response.data.form_submission,
      });
      // yield put({
      //   type: ''
      // })
    },
  },
};
