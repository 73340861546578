import React from 'react';
import OriginalIntlTelInput from 'react-intl-tel-input';
import styled from 'styled-components';
import classNames from 'classnames';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

function IntlTelInput({
  className,
  containerClassName,
  inputClassName,
  onPhoneNumberChange,
  onPhoneNumberBlur,
  ...otherProps
}) {
  function phoneNumberChangeHandler(_status, value, countryData, fullNumber) {
    const overriddenValidateStatus = isPossiblePhoneNumber(value, countryData.iso2?.toUpperCase());
    return onPhoneNumberChange(overriddenValidateStatus, value, countryData, fullNumber);
  }

  function phoneNumberBlurHandler(_status, value, countryData, fullNumber) {
    const overriddenValidateStatus = isPossiblePhoneNumber(value, countryData.iso2?.toUpperCase());
    return onPhoneNumberBlur(overriddenValidateStatus, value, countryData, fullNumber);
  }

  return (
    <OriginalIntlTelInput
      {...otherProps}
      containerClassName={classNames(['intl-tel-input', className, containerClassName])}
      inputClassName={classNames(['ant-input', 'ant-input-lg', inputClassName])}
      {...(onPhoneNumberChange && { onPhoneNumberChange: phoneNumberChangeHandler })}
      {...(onPhoneNumberBlur && { onPhoneNumberBlur: phoneNumberBlurHandler })}
    />
  );
}

// Deal with css pollution by global styles for `div.arrow` in `src/index.css`.
const StyledIntlTelInput = styled(IntlTelInput)`
  & div.arrow {
    width: unset;
    height: unset;
    background: unset;
    position: unset;
    margin-top: unset;

    &:before {
      content: none;
    }

    &:after {
      position: unset;
      border-style: unset;
      border-width: unset;
      border-color: unset;
      display: unset;
      width: unset;
      z-index: unset;
      top: unset;
      left: unset;
    }
  }
`;

export default StyledIntlTelInput;
