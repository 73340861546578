import { useHasFunctionModule, FunctionModuleKey } from '@helloworld1812/ws-biz-hooks';
import { useUsersMeCached } from '@helloworld1812/hr-api';

import { AppState } from '@/types/app/models/app';

import { UseHiringPaywallReturn } from './hiring-paywall.types';

export const isLiteTier = (appState: AppState) => {
  const { company } = appState;
  return company?.tiers?.length === 1 && company?.tiers?.[0] === 'lite';
};

export function useIsLiteTier(): boolean {
  const { user } = useUsersMeCached();
  const tiers = user?.company?.tiers || [];

  return tiers.length === 1 && tiers[0] === 'lite';
}

export function useHiringPaywall(hasNoFunctionModule: FunctionModuleKey): UseHiringPaywallReturn {
  const hasFunctionModule = useHasFunctionModule(hasNoFunctionModule);
  const isLite = useIsLiteTier();
  const isVisible = !hasFunctionModule && isLite;

  return {
    isVisible,
  };
}

export type { UseHiringPaywallReturn };
