import request from '../../utils/request';
import { APIHOST } from '../../config';

export async function downloadEmployeesData(payload) {
  const { export_type, hired_at_gte, hired_at_lte } = payload;
  return request('GET', `${APIHOST}/v1/onboarding/hr/employee_informations/export`, {
    export_type,
    hired_at_gte,
    hired_at_lte,
  });
}
