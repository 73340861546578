import * as services from '@/services/e-verify-case';

export const initialState = {
  id: null,
  status: null,
  fieldsToConfirm: [],
  closureReasons: [],
};

export const reducers = {
  updateCaseStatus(state, { payload }) {
    const { case_status } = payload;
    return {
      ...state,
      status: case_status,
    };
  },

  updateFieldsToConfirm(state, { payload }) {
    const fieldsToConfirm = payload;
    return {
      ...state,
      fieldsToConfirm,
    };
  },

  updateClosureReasons(state, { payload }) {
    const { case_closure_reasons: closureReasons } = payload;
    return {
      ...state,
      closureReasons,
    };
  },

  updateCase(state, { payload }) {
    if (payload === null) {
      return initialState;
    } else {
      return {
        ...state,
        ...payload,
      };
    }
  },
};

export const effects = {
  *getFieldsToConfirm({ payload = {} }, { call, put }) {
    const response = yield call(services.getFieldsToConfirm, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateFieldsToConfirm',
      payload: response.data,
    });
  },

  *confirmDetails({ payload = {} }, { call, put }) {
    const response = yield call(services.confirmDetails, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateCaseStatus',
      payload: response.data,
    });
  },

  *fetch({ payload = {} }, { call, put }) {
    const response = yield call(services.fetch, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateCase',
      payload: response.data,
    });
  },

  *downloadFurtherActionNotice({ payload = {} }, { call, put, select }) {
    const eVerifyCase = yield select((state) => state.eVerifyCase);
    const { language } = payload;
    const response = yield call(services.downloadFurtherActionNotice, {
      language,
      caseId: eVerifyCase.id,
    });

    if (response.error) {
      throw response.error;
    }
    return response;
  },

  *downloadReferralDateConfirmation({ payload = {} }, { call, put, select }) {
    const eVerifyCase = yield select((state) => state.eVerifyCase);
    const { language } = payload;
    const response = yield call(services.downloadReferralDateConfirmation, {
      language,
      caseId: eVerifyCase.id,
    });

    if (response.error) {
      throw response.error;
    }
    return response;
  },

  *retrievePhoto({ payload = {} }, { call, put, select }) {
    const eVerifyCase = yield select((state) => state.eVerifyCase);
    const response = yield call(services.retrievePhoto, { caseId: eVerifyCase.id });
    if (response.error) {
      throw response.error;
    }
    return response;
  },

  *getClosureReasons({ payload = {} }, { call, put }) {
    const response = yield call(services.getClosureReasons, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateClosureReasons',
      payload: response.data,
    });
  },

  *closeCase({ payload = {} }, { call, put, select }) {
    const eVerifyCase = yield select((state) => state.eVerifyCase);
    const requestData = { ...payload, caseId: eVerifyCase.id };
    const response = yield call(services.closeCase, requestData);
    if (response.error) {
      throw response.error;
    }
    yield put({
      type: 'updateCaseStatus',
      payload: response.data,
    });
  },

  *referCase({ payload = {} }, { call, put, select }) {
    const eVerifyCase = yield select((state) => state.eVerifyCase);
    const response = yield call(services.referCase, {
      caseId: eVerifyCase.id,
    });
    if (response.error) {
      throw response.error;
    }
    yield put({
      type: 'updateCaseStatus',
      payload: response.data,
    });
  },

  *noAction({ payload = {} }, { call, put, select }) {
    const eVerifyCase = yield select((state) => state.eVerifyCase);
    const response = yield call(services.noAction, {
      caseId: eVerifyCase.id,
    });
    if (response.error) {
      throw response.error;
    }
    yield put({
      type: 'updateCaseStatus',
      payload: response.data,
    });
  },

  *scanAndUpload({ payload = {} }, { call, put, select }) {
    const eVerifyCase = yield select((state) => state.eVerifyCase);
    const response = yield call(services.scanAndUpload, eVerifyCase.id, payload);
    if (response.error) {
      throw response.error;
    }
    yield put({
      type: 'updateCaseStatus',
      payload: response.data,
    });
  },

  *photoMatch({ payload = {} }, { call, put, select }) {
    const eVerifyCase = yield select((state) => state.eVerifyCase);
    const response = yield call(services.photoMatch, eVerifyCase.id, payload);
    if (response.error) {
      throw response.error;
    }
    yield put({
      type: 'updateCaseStatus',
      payload: response.data,
    });
  },

  *refresh({ payload = {} }, { call, put, select }) {
    const eVerifyCase = yield select((state) => state.eVerifyCase);
    const response = yield call(services.refresh, {
      caseId: eVerifyCase.id,
    });
    if (response.error) {
      throw response.error;
    }
    yield put({
      type: 'updateCaseStatus',
      payload: response.data,
    });
  },
};

export default {
  namespace: 'eVerifyCase',
  state: initialState,
  reducers,
  effects,
};
