import * as services from '../../services/employeeOnboarding';

export const initialState = {};

export const reducers = {};

const effects = {
  *approveEmployeeOnboardingProcess({ payload }, { call }) {
    const response = yield call(services.approveEmployeeOnboardingProcess, payload);
    if (response.error) throw response.error;

    return response.data;
  },
};

export default {
  namespace: 'customForm',
  state: initialState,
  reducers,
  effects,
};
