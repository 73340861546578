import { Modal as AntdModal } from 'antd';
import _ from 'lodash';

import { statusModal } from '@/components/design-system-style-wrapper/status-modal';
import { ApplicantBucket, PositionApplication } from '@/api';

import { fetchStageList, fetchApplicantsOfStage } from '../services/login';
import * as services from '../services/applicant';
import { fetchUserIP } from '../utils/checkip';
import constants from '../utils/constants';

const PAGE_SIZE = 10;
const PAGE_INDEX = 1;
const INACTIVE_APPLICANT_PAGE_SIZE = 100;

export type { PositionStage } from '@/api';

export type ApplicantInterface = PositionApplication;

export interface ApplicantsBucketInterface {
  name: ApplicantBucket;
  applicants_number: number;
}

interface PaginationState {
  total: number;
  current: number;
  pageSize: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  pageSizeOptions: string[];
}

export interface ApplicantState {
  applicationBuckets: ApplicantsBucketInterface[];
  locations: unknown[];
  hasAccess: boolean;
  latestPathname: string | undefined;
  stageList: unknown[];
  funnelApplicantCount: Record<string, unknown>;
  applicantList: ApplicantInterface[];
  allApplicantsList: ApplicantInterface[];
  inactiveApplicantsList: unknown[];
  inactiveApplicantMaxCount: number;
  inactiveApplicantFilterCount: number;
  hasMoreApplicants: boolean;
  hasMoreInactiveApplicants: boolean;
  applicantListPaginationInfo: PaginationState;
  selectedPosition: unknown;
  selectedStage: unknown;

  applicantsToBeMoveToNextStage: unknown[];
  applicantsToBeMoveToAnyStage: unknown[];
  applicantsToBeSendEmail: unknown[];
  applicantsToBeSendSMS: unknown[];
  applicantsToBeReject: unknown[];

  availableMovedPositions: unknown[];
  availableMovedPositionsByPositionName: unknown[];

  // The `noRefreshApplicantDataFlag` is for component to decide whether or not to do an update
  // Eg: In applicants table, hr check the
  // profile of applicant and coming back to appliants table. There is
  // no need to fetch the applicants table data again.
  noRefreshApplicantDataFlag: boolean;

  allApplicantsListPagination: PaginationState;
  inactiveApplicantsListPagination: PaginationState;

  // data for render 'position/location', 'stage' filters in all applicants page
  // every loction has a 'positions' key contains all the positions in that location
  locationsForFilter: unknown[];
  positionsForFilter: unknown[];
  stagesForFilter: unknown[];
  stagesForApplicantPhase: unknown[];
  hiredCount: number;
  inProgressCount: number;
  rejectedCount: number;
  inactiveCount: number;
  waitingForReviewCount: number;
  heldForLaterCount: number;
  othersCount: number;
  applicationsWithUnreadMessage: unknown[];
  applicationsWithUnreadMessageTotalCount: number;
  searchCount: Record<string, number>;
}

export const allApplicantsDefaultFilters = {
  page: PAGE_INDEX,
  perPage: PAGE_SIZE,
  query: '',
  sort_key: 'created_at',
  order: 'descend',
  positions: ['all'],
  stages: ['all'],
  progress_status: [],
  status: ['hired', 'in_progress'],
  tag_ids: [],
  rating_gte: '',
  bucket: '',
};

export const positionApplicantsDefaultSearch = {
  digestkey: '',
  stageId: undefined,
  page: PAGE_INDEX,
  perPage: PAGE_SIZE,
  status: ['in_progress', 'hired'],
  updateTimestampe: new Date().getTime(),
  sort_key: 'days_in_current_stage',
  order: 'ascend',
  tag_ids: [],
  rating_gte: '',
  filters: {},
};

export const getCompoundStages = (positions) => {
  const stagesForFilter = [];
  positions.forEach((p) => {
    const { position_stages: stages } = p;
    stages.forEach((s) => {
      const { name } = s;
      if (stagesForFilter.indexOf(name) === -1) {
        stagesForFilter.push(name);
      }
    });
  });
  return stagesForFilter;
};

export default {
  namespace: 'applicant',

  state: {
    locations: [],
    hasAccess: true,
    latestPathname: undefined,
    stageList: [],
    funnelApplicantCount: {},
    applicantList: [],
    allApplicantsList: [],
    inactiveApplicantsList: [],
    inactiveApplicantMaxCount: 0,
    inactiveApplicantFilterCount: 0,
    hasMoreApplicants: false,
    hasMoreInactiveApplicants: false,
    applicantListPaginationInfo: {
      total: 1,
      current: 1,
      pageSize: PAGE_SIZE,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['10', '25', '50', '100'],
    },
    selectedPosition: undefined,
    selectedStage: undefined,

    applicantsToBeMoveToNextStage: [],
    applicantsToBeMoveToAnyStage: [],
    applicantsToBeSendEmail: [],
    applicantsToBeSendSMS: [],
    applicantsToBeReject: [],

    availableMovedPositions: [],
    availableMovedPositionsByPositionName: [],

    // The `noRefreshApplicantDataFlag` is for component to decide whether or not to do an update
    // Eg: In applicants table, hr check the
    // profile of applicant and coming back to appliants table. There is
    // no need to fetch the applicants table data again.
    noRefreshApplicantDataFlag: false,

    allApplicantsListPagination: {
      total: 1,
      current: 1,
      pageSize: PAGE_SIZE,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['10', '25', '50', '100'],
    },
    inactiveApplicantsListPagination: {
      total: 1,
      current: 1,
      pageSize: PAGE_SIZE,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['10', '25', '50', '100'],
    },

    // data for render 'position/location', 'stage' filters in all applicants page
    // every loction has a 'positions' key contains all the positions in that location
    locationsForFilter: [],
    positionsForFilter: [],
    stagesForFilter: [],
    stagesForApplicantPhase: [],
    hiredCount: 0,
    inProgressCount: 0,
    inactiveCount: 0,
    rejectedCount: 0,
    waitingForReviewCount: 0,
    heldForLaterCount: 0,
    othersCount: 0,
    searchCount: {},
    applicationsWithUnreadMessage: [],
    applicationsWithUnreadMessageTotalCount: 0,
  },

  reducers: {
    fetchAllStagesSuccess(state, { payload }) {
      const { positions } = payload;
      const locations = [];
      let stagesForFilter = [];

      positions.forEach((p) => {
        const location = p.location || {};
        stagesForFilter.push(p.position_stages);
        const foundLocation = _.find(locations, { id: location && location.id });
        if (!foundLocation) {
          location.positions = [p];
          locations.push(location);
        } else {
          foundLocation.positions.push(p);
        }
      });

      stagesForFilter = _.flatten(stagesForFilter);

      return {
        ...state,
        locationsForFilter: locations,
        positionsForFilter: positions,
        stagesForFilter,
      };
    },
    fetchAllStagesForApplicantPhaseSuccess(state, { payload }) {
      return {
        ...state,
        stagesForApplicantPhase: payload.stages,
      };
    },
    clearApplicants(state) {
      return {
        ...state,
        applicantList: [],
      };
    },

    noRefreshApplicantDataFlagReverse(state) {
      return {
        ...state,
        noRefreshApplicantDataFlag: !state.noRefreshApplicantDataFlag,
      };
    },

    rejectApplicantsFinish(state, { payload }) {
      const { applicants } = payload;
      const { applicantList } = state;
      const cacheList = [...applicantList];
      const realRejectApplicant = applicants.filter((applicant) => {
        const found = _.find(cacheList, {
          id: applicant.id,
          status: 'rejected',
        });
        return !found;
      });

      // if (!realRejectApplicant || realRejectApplicant.length === 0) return state;

      const stageList = state.stageList.map((stage) => {
        const foundedApplicants = _.filter(realRejectApplicant, (o) => {
          return o.position_stage.id === stage.id;
        });
        if (foundedApplicants.length === 0) return stage;
        return {
          ...stage,
          in_progress_count: stage.in_progress_count - foundedApplicants.length,
          rejected_count: stage.rejected_count + foundedApplicants.length,
        };
      });

      const inProgressCount = state.funnelApplicantCount.in_progress_count - realRejectApplicant.length;

      return {
        ...state,
        stageList,
        funnelApplicantCount: {
          ...state.funnelApplicantCount,
          rejected_count: state.funnelApplicantCount.rejected_count + realRejectApplicant.length,
          in_progress_count: inProgressCount,
        },
      };
    },

    setupApplicantsToBeReject(state, { payload }) {
      return {
        ...state,
        applicantsToBeReject: payload.ids,
      };
    },

    removeRejectApplicant(state, { payload }) {
      const applicantsToBeReject = state.applicantsToBeReject.filter((id) => id !== payload);
      return {
        ...state,
        applicantsToBeReject,
      };
    },

    setupApplicantsToBeSendSMS(state, { payload }) {
      return {
        ...state,
        applicantsToBeSendSMS: payload.ids,
      };
    },

    removeSendSMSApplicant(state, { payload }) {
      const applicantsToBeSendSMS = state.applicantsToBeSendSMS.filter((id) => id !== payload);
      return {
        ...state,
        applicantsToBeSendSMS,
      };
    },

    setupApplicantsToBeSendEmail(state, { payload }) {
      return {
        ...state,
        applicantsToBeSendEmail: payload.ids,
      };
    },

    removeSendEmailApplicant(state, { payload }) {
      const applicantsToBeSendEmail = state.applicantsToBeSendEmail.filter((id) => id !== payload);
      return {
        ...state,
        applicantsToBeSendEmail,
      };
    },

    setupApplicantsToBeMoveToAnyStage(state, { payload }) {
      return {
        ...state,
        applicantsToBeMoveToAnyStage: payload.ids,
      };
    },

    removeToBeMoveToAnyStageApplicant(state, { payload }) {
      const applicantsToBeMoveToAnyStage = state.applicantsToBeMoveToAnyStage.filter((id) => id !== payload);
      return {
        ...state,
        applicantsToBeMoveToAnyStage,
      };
    },

    setupApplicantsToBeMoveToNextStage(state, { payload }) {
      return {
        ...state,
        applicantsToBeMoveToNextStage: payload.ids,
      };
    },

    removeToBeMoveToNextStageApplicant(state, { payload }) {
      const applicantsToBeMoveToNextStage = state.applicantsToBeMoveToNextStage.filter((id) => id !== payload);
      return {
        ...state,
        applicantsToBeMoveToNextStage,
      };
    },

    setupApplicantList(state, { payload }) {
      const { applicantList, pagination } = payload;
      return {
        ...state,
        applicantList,
        applicantListPaginationInfo: {
          ...state.applicantListPaginationInfo,
          ...pagination,
        },
      };
    },

    setupAllApplicantsList(state, { payload }) {
      const {
        applicantsList: allApplicantsList,
        pagination,
        rejectedCount,
        hiredCount,
        inProgressCount,
        heldForLaterCount,
        othersCount,
        waitingForReviewCount,
        applicationsWithUnreadMessage,
        applicationsWithUnreadMessageTotalCount,
        hasMoreApplicants,
        searchCount,
      } = payload;
      return {
        ...state,
        allApplicantsList,
        allApplicantsListPagination: {
          ...state.allApplicantsListPagination,
          ...pagination,
        },
        rejectedCount,
        hiredCount,
        inProgressCount,
        heldForLaterCount,
        othersCount,
        waitingForReviewCount,
        applicationsWithUnreadMessage,
        applicationsWithUnreadMessageTotalCount,
        hasMoreApplicants,
        searchCount,
      };
    },

    setupInactiveApplicantsList(state, { payload }) {
      const { inactiveApplicantsList, pagination, hasMoreInactiveApplicants, search_count, total_inactive_count } =
        payload;

      return {
        ...state,
        inactiveApplicantsList,
        inactiveApplicantsListPagination: {
          ...state.inactiveApplicantsListPagination,
          ...pagination,
        },
        hasMoreInactiveApplicants,
        inactiveApplicantMaxCount: total_inactive_count,
        inactiveApplicantFilterCount: search_count,
      };
    },

    addSingleApplicantToList(state, { payload }) {
      const { applicant, bucket } = payload;
      const newApplicantList = [applicant, ...state.allApplicantsList];

      if (applicant.status === 'rejected') return state;

      const tempBuckets = state.applicationBuckets;
      let bucketIndex = 0;

      if (applicant.bucket === 'interviewing') bucketIndex = 1;

      if (applicant.bucket === 'hiring') bucketIndex = 2;

      tempBuckets[bucketIndex] = {
        ...tempBuckets[bucketIndex],
        applicants_number: tempBuckets[bucketIndex].applicants_number + 1,
      };

      return {
        ...state,
        applicationBuckets: tempBuckets,
        allApplicantsList: applicant.bucket === bucket ? newApplicantList : state.allApplicantsList,
      };
    },

    addMoreApplicantsList(state, { payload }) {
      const { applicantsList, pagination, hasMoreApplicants } = payload;

      return {
        ...state,
        allApplicantsList: [...state.allApplicantsList, ...applicantsList],
        allApplicantsListPagination: {
          ...state.allApplicantsListPagination,
          ...pagination,
        },
        hasMoreApplicants,
      };
    },

    addMoreInactiveApplicantsList(state, { payload }) {
      const { inactiveApplicantsList, pagination, hasMoreInactiveApplicants } = payload;

      return {
        ...state,
        // inactiveApplicantsList: [...state.inactiveApplicantsList, ...inactiveApplicantsList],
        inactiveApplicantsList: [...inactiveApplicantsList],
        inactiveApplicantsListPagination: {
          ...state.inactiveApplicantsList,
          ...pagination,
        },
        hasMoreInactiveApplicants,
      };
    },

    refreshApplicantsPage(state, { payload }) {
      const { applicantsList, page, searchCount } = payload;
      const list = [...state.allApplicantsList];
      list.splice((page - 1) * PAGE_SIZE, PAGE_SIZE, ...applicantsList);
      // remove duplicated element if exists.
      if (list?.[page * PAGE_SIZE]?.id && list[page * PAGE_SIZE].id === list[page * PAGE_SIZE - 1].id) {
        list.splice(page * PAGE_SIZE, 1);
      }

      return {
        ...state,
        searchCount,
        allApplicantsList: list,
      };
    },

    setupLocations(state, { payload }) {
      return {
        ...state,
        locations: payload,
      };
    },

    setupLatestPathname(state, { payload }) {
      return {
        ...state,
        latestPathname: payload,
      };
    },

    setupSelectedPosition(state, { payload }) {
      const { position } = payload;
      return {
        ...state,
        selectedPosition: position,
      };
    },

    setupStageList(state, { payload }) {
      return {
        ...state,
        stageList: payload,
      };
    },

    setupFunnelCount(state, { payload }) {
      return {
        ...state,
        funnelApplicantCount: payload,
      };
    },

    updateAccess(state, { payload }) {
      const { hasAccess } = payload;
      return {
        ...state,
        hasAccess,
      };
    },

    availableMovedPositionsUpdate(state, { payload }) {
      const { positions } = payload;
      return {
        ...state,
        availableMovedPositions: positions,
      };
    },

    receiveAvailableMovedPositionsByPositionName(state, { payload }) {
      const { positions } = payload;
      return {
        ...state,
        availableMovedPositionsByPositionName: positions,
      };
    },

    allApplicationBuckets(state, { payload: { buckets } }) {
      return {
        ...state,
        applicationBuckets: buckets,
      };
    },

    newApplicantsList(state, { payload: { buckets, newApplicantsList } }) {
      return {
        ...state,
        applicationBuckets: buckets,
        allApplicantsList: newApplicantsList,
      };
    },
  },

  effects: {
    *fetchSelectedPosition({ payload }, { put, call }) {
      const res = yield call(services.fetchSelectedPosition, payload);
      if (res.error) throw res.error;

      const { position } = res.data;
      yield put({
        type: 'setupSelectedPosition',
        payload: { position },
      });

      return res;
    },
    *fetchAllStages({ payload }, { put, call }) {
      const res = yield call(services.fetchAllStages, payload);
      if (res.error) throw res.error;

      const { positions } = res.data;
      yield put({
        type: 'fetchAllStagesSuccess',
        payload: { positions },
      });
    },

    fetchStagesForApplicantPhase: [
      function* fetch({ payload }, { put, call }) {
        const res = yield call(services.fetchStagesForApplicantPhase, payload);
        if (res.error) throw res.error;

        const { position_stages } = res.data;
        yield put({
          type: 'fetchAllStagesForApplicantPhaseSuccess',
          payload: { stages: position_stages },
        });
      },
      { type: 'takeLatest' },
    ],

    *getUserIP({ payload }, { call }) {
      const { callback } = payload;
      const response = yield call(fetchUserIP);
      if (response && callback) {
        callback(response.data);
      }
    },

    *getBackgroundCheckStatus({ payload }, { call }) {
      const { onSuccess } = payload;
      const response = yield call(services.getBackgroundCheckStatus, payload);
      if (response.error) throw response.error;
      if (onSuccess) {
        onSuccess(response.data);
      }
    },

    *addApplicant({ payload }, { call, put }) {
      const { onAnySuccess, bucket } = payload;
      const response = yield call(services.addApplicant, payload);
      if (response.error) {
        AntdModal.warning({
          content: response.error.message,
          okText: 'OK',
        });
      } else {
        AntdModal.success({
          title: 'Successfully added new applicant',
          okText: 'OK',
        });

        yield put({
          type: 'addSingleApplicantToList',
          payload: { applicant: response.data.position_application, bucket },
        });
        if (onAnySuccess) onAnySuccess();
      }
    },

    *reject({ payload }, { call }) {
      const { items, onSuccess } = payload;
      const applicantsIDs = items.map((item) => item.applicantId);
      const failedIDs = [];
      let failedMessages = [];
      const successApplicants = [];
      for (const item of items) {
        const response = yield call(services.reject, { item });
        if (response.error) {
          failedIDs.push(item.applicantId);
          failedMessages.push(response.error.message);
        } else {
          successApplicants.push(response.data.position_application);
        }
      }
      if (successApplicants.length > 0) {
        if (onSuccess) onSuccess();
        if (payload.onAnySuccess) {
          payload.onAnySuccess(successApplicants, applicantsIDs);
        }
      }

      if (failedIDs.length > 0) {
        const title = `${failedIDs.length}/${applicantsIDs.length} fail, please try again.`;
        let content = '';
        failedMessages = _.flatten(failedMessages);
        if (applicantsIDs.length === 1 && failedMessages.length > 0) content = failedMessages.join('\n');
        if (payload.onAnyError) {
          payload.onAnyError(title, content, failedMessages);
        } else {
          AntdModal.warning({ title, content, okText: 'Got it' });
        }
      }
    },

    *unreject({ payload }, { call }) {
      const { ids } = payload;
      const failedIDs = [];
      let failedMessages = [];
      const successApplicants = [];
      for (const id of ids) {
        const response = yield call(services.unreject, { id });
        if (response.error) {
          failedIDs.push(id);
          failedMessages.push(response.error.message);
        } else {
          successApplicants.push(response.data.position_application);
        }
      }

      if (successApplicants.length > 0) {
        if (payload.onAnySuccess) payload.onAnySuccess();
      }

      if (failedIDs.length > 0) {
        const title = `${failedIDs.length}/${ids.length} fail, please try again.`;
        let content = '';
        failedMessages = _.flatten(failedMessages);
        if (ids.length === 1 && failedMessages.length > 0) content = failedMessages.join('\n');
        if (payload.onAnyError) {
          payload.onAnyError(title, content, failedMessages);
        } else {
          AntdModal.warning({ title, content, okText: 'Got it' });
        }
      }
    },

    *sendReminder({ payload }, { call }) {
      const { ids } = payload;
      const failedIDs = [];
      let failedMessages = [];
      const successApplicants = [];
      for (const id of ids) {
        const response = yield call(services.sendReminder, { id });
        if (response.error) {
          failedIDs.push(id);
          failedMessages.push(response.error.message);
        } else {
          successApplicants.push({ id });
        }
      }

      if (successApplicants.length > 0) {
        if (payload.onAnySuccess) payload.onAnySuccess();
      }

      if (failedIDs.length > 0) {
        const title = `${failedIDs.length}/${ids.length} fail, please try again.`;
        let content = '';
        failedMessages = _.flatten(failedMessages);
        if (ids.length === 1 && failedMessages.length > 0) content = failedMessages.join('\n');

        if (payload.onAnyError) {
          payload.onAnyError(title, content, failedMessages);
        } else {
          AntdModal.warning({ title, content, okText: 'Got it' });
        }
      }
    },

    *nfwSendReminder({ payload }, { call }) {
      const { ids } = payload;
      const failedIDs = [];
      const failedMessages = [];
      const successApplicants = [];
      for (const id of ids) {
        const response = yield call(services.sendReminder, { id });
        if (response.error) {
          failedIDs.push(id);
          failedMessages.push(response.error.message);
        } else {
          successApplicants.push({ id });
        }
      }

      if (successApplicants.length > 0) {
        const sucessMessage = 'Succesfully sent!';
        const sucessDescription = `${successApplicants.length}/${ids.length} reminders were sent out.`;
        if (payload.onResponse) payload.onResponse('success', sucessMessage, sucessDescription);
      }

      if (failedIDs.length > 0) {
        const failDescription = `${failedIDs.length}/${ids.length} reminders failed to send, please try again.`;
        const failMessage = `Something went wrong.`;
        if (payload.onResponse) payload.onResponse('error', failMessage, failDescription);
      }

      if (payload.onComplete) payload.onComplete();
    },

    *exportApplicantsToEmails({ payload }, { call }) {
      const { ids, content, subject, emails } = payload;
      const response = yield call(services.exportApplicantsToEmails, {
        ids,
        emails,
        subject,
        content,
      });

      if (response.error) {
        throw response.error;
      }
      return response;
    },

    *downloadApplicantsPdfs({ payload }, { call }) {
      const { ids, sendToEmail = null } = payload;
      const response = yield call(services.downloadApplicantsPdfs, {
        ids,
        sendToEmail,
      });

      if (response.error) {
        throw response.error;
      }
      return response;
    },

    // eslint-disable-next-line no-empty-pattern
    *queryAvailableMovedPositions({}, { call, put }) {
      const response = yield call(services.queryAvailableMovedPositions, {});

      if (response.error) {
        throw response.error;
      }

      yield put({
        type: 'availableMovedPositionsUpdate',
        payload: {
          positions: response.data.positions,
        },
      });

      return response;
    },

    *fetchAvailableMovedPositionsByPositionName({ payload }, { call, put }) {
      const response = yield call(services.fetchAvailableMovedPositionsByPositionName, payload);

      if (response.error) {
        throw response.error;
      }

      yield put({
        type: 'receiveAvailableMovedPositionsByPositionName',
        payload: {
          positions: response.data.positions,
        },
      });

      return response;
    },

    *moveToPosition({ payload }, { call }) {
      // we removed digestKey here passed from AllApplicantsTable, because
      // it's not used at all when calling the API or other places in this flow
      const { ids, newPositionId, newPositionStageId, skipNotifications, skipAutoActions } = payload;
      const failedIDs = [];
      let failedMessages = [];
      const successApplicants = [];
      for (const id of ids) {
        const response = yield call(services.moveToPosition, {
          id,
          newPositionId,
          newPositionStageId,
          skipNotifications,
          skipAutoActions,
        });
        if (response.error) {
          failedIDs.push(id);
          failedMessages.push(response.error.message);
        } else {
          successApplicants.push(response.data.position_application);
        }
      }

      if (successApplicants.length > 0) {
        if (payload.onAnySuccess) payload.onAnySuccess();
      }

      if (failedIDs.length > 0) {
        const title = `${failedIDs.length}/${ids.length} fail, please try again.`;
        let content = '';
        failedMessages = _.flatten(failedMessages);
        if (ids.length === 1 && failedMessages.length > 0) content = failedMessages.join('\n');
        if (payload.onAnyError) {
          payload.onAnyError(title, content, failedMessages);
        } else {
          AntdModal.warning({ title, content, okText: 'Got it' });
        }
      }
    },

    *sendSMS({ payload }, { put, call }) {
      const { ids, content, silent } = payload;
      const failedIDs = [];
      let failedMessages = [];
      const successIDs = [];
      let response = {};
      for (const id of ids) {
        response = yield call(services.sendSMS, { id, content });
        if (response.error) {
          failedIDs.push(id);
          failedMessages.push(response.error.message);
        } else {
          successIDs.push(id);
        }
        yield put({
          type: 'removeSendSMSApplicant',
          payload: id,
        });
      }
      if (successIDs.length > 0) {
        if (payload.onAnySuccess) payload.onAnySuccess();
        if (!silent) {
          statusModal({
            type: 'success',
            title: 'Successfully added to the queue!',
            content: `${successIDs.length}/${ids.length} SMS will be sent out shortly.`,
            buttonText: 'Done',
          });
        }
      }

      if (failedIDs.length > 0) {
        if (silent && !payload.onAnyError) return;

        const title = `${failedIDs.length}/${ids.length} fail, please try again.`;
        let modalContent = '';
        failedMessages = _.flatten(failedMessages);
        if (ids.length === 1 && failedMessages.length > 0) modalContent = failedMessages.join('\n');

        if (payload.onAnyError) {
          payload.onAnyError(title, modalContent, failedMessages);
        } else {
          AntdModal.warning({ title, content: modalContent, okText: 'Got it' });
        }
      }

      // all success
      if (failedIDs.length === 0) {
        const { onSuccess } = payload;
        if (onSuccess) onSuccess();
      }
    },

    *sendOffer({ payload }, { call }) {
      const { ids, customOfferTemplate } = payload;

      const failedIDs = [];
      let failedMessages = [];
      const successApplicants = [];
      for (const id of ids) {
        const response = yield call(services.sendOffer, {
          id,
          customOfferTemplate,
        });
        if (response.error) {
          failedIDs.push(id);
          failedMessages.push(response.error.message);
        } else {
          successApplicants.push(response.data.position_application);
        }
      }

      if (successApplicants.length > 0) {
        const title = `${successApplicants.length}/${ids.length} signing request sent successfully`;

        if (payload.onAnySuccess) payload.onAnySuccess(title);
      }

      if (failedIDs.length > 0) {
        const title = `${failedIDs.length}/${ids.length} fail, please try again.`;
        let content = '';
        failedMessages = _.flatten(failedMessages);
        if (ids.length === 1 && failedMessages.length > 0) content = failedMessages.join('\n');

        if (payload.onAnyError) {
          payload.onAnyError(failedMessages);
        } else {
          AntdModal.warning({ title, content, okText: 'Got it' });
        }
      }
    },

    *cancelOffer({ payload }, { call }) {
      const { id } = payload;

      const response = yield call(services.cancelOffer, { id });
      if (response.error) {
        throw response.error;
      }
      return response;
    },

    *moveToAnyStage({ payload }, { put, call }) {
      const { ids, stageId, extraData, schedule_complete: scheduleComplete } = payload;

      const failedIDs = [];
      let failedMessages = [];
      const successApplicants = [];
      for (const id of ids) {
        const response = yield call(services.moveToAnyStage, {
          id,
          stageId,
          extraData,
          schedule_complete: scheduleComplete,
        });
        if (response.error) {
          failedIDs.push(id);
          failedMessages.push(response.error.message);
        } else {
          successApplicants.push(response.data.position_application);
        }
        yield put({
          type: 'removeToBeMoveToAnyStageApplicant',
          payload: id,
        });
      }

      if (successApplicants.length > 0) {
        if (payload.onAnySuccess) payload.onAnySuccess(stageId);
      }

      if (failedIDs.length > 0) {
        const title = `${failedIDs.length}/${ids.length} fail, please try again.`;
        let content = '';
        failedMessages = _.flatten(failedMessages);
        if (ids.length === 1 && failedMessages.length > 0) content = failedMessages.join('\n');
        if (payload.onAnyError) {
          payload.onAnyError(title, content, failedMessages);
        } else {
          AntdModal.warning({ title, content, okText: 'Got it' });
        }
      }
    },

    *orderBackgroundCheck({ payload }, { call }) {
      const { ids } = payload;

      const failedIDs = [];
      let failedMessages = [];
      const successApplicants = [];
      for (const id of ids) {
        const response = yield call(services.orderBackgroundCheck, { id });
        if (response.error) {
          failedIDs.push(id);
          failedMessages.push(response.error.message);
        } else {
          successApplicants.push(response.data.position_application);
        }
      }

      if (successApplicants.length > 0) {
        if (payload.onAnySuccess) payload.onAnySuccess();
      }

      if (failedIDs.length > 0) {
        const title = `${failedIDs.length}/${ids.length} fail, please try again.`;
        let content = '';
        failedMessages = _.flatten(failedMessages);
        if (ids.length === 1 && failedMessages.length > 0) content = failedMessages.join('\n');

        if (payload.onAnyError) {
          payload.onAnyError(title, content, failedMessages);
        } else {
          AntdModal.warning({ title, content, okText: 'Got it' });
        }
      }
    },

    *moveToNextStage({ payload }, { put, call }) {
      const { ids } = payload;
      const failedIDs = [];
      let failedMessages = [];
      const successApplicants = [];
      for (const id of ids) {
        const response = yield call(services.moveToNextStage, { id });
        if (response.error) {
          failedIDs.push(id);
          failedMessages.push(response.error.message);
        } else {
          successApplicants.push(response.data.position_application);
        }

        yield put({
          type: 'removeToBeMoveToNextStageApplicant',
          payload: id,
        });
      }

      if (successApplicants.length > 0) {
        AntdModal.success({
          title: `${successApplicants.length}/${ids.length} move to next stage successfully`,
          okText: 'OK',
        });
        // If any success request, need execute the onsuccess callback
        if (payload.onSuccess) payload.onSuccess();
        if (payload.onAnySuccess) payload.onAnySuccess();
      }

      if (failedIDs.length > 0) {
        const title = `${failedIDs.length}/${ids.length} fail, please try again.`;
        let content = '';
        failedMessages = _.flatten(failedMessages);
        if (ids.length === 1 && failedMessages.length > 0) content = failedMessages.join('\n');

        if (payload.onAnyError) {
          payload.onAnyError(title, content, failedMessages);
        } else {
          AntdModal.warning({ title, content, okText: 'Got it' });
        }
      }
    },

    *sendEmail({ payload }, { put, call }) {
      const { ids, subject, emailContent: content, silent } = payload;
      const failedIDs = [];
      let failedMessages = [];
      const successIDs = [];
      let response = {};
      for (const id of ids) {
        response = yield call(services.sendEmailToApplicant, {
          id,
          subject,
          content,
        });
        if (response.error) {
          failedIDs.push(id);
          failedMessages.push(response.error.message);
        } else {
          successIDs.push(id);
        }
        yield put({
          type: 'removeSendEmailApplicant',
          payload: id,
        });
      }

      if (successIDs.length > 0) {
        if (payload.onAnySuccess) payload.onAnySuccess(successIDs);
        if (silent) return;
        statusModal({
          type: 'success',
          title: 'Successfully added to the queue!',
          content: `${successIDs.length}/${ids.length} emails will be sent out shortly`,
          buttonText: 'Done',
        });
      }
      if (failedIDs.length > 0) {
        if (silent && !payload.onAnyError) return;

        const title = `${failedIDs.length}/${ids.length} fail, please try again.`;
        let modalContent = '';
        failedMessages = _.flatten(failedMessages);
        if (ids.length === 1 && failedMessages.length > 0) modalContent = failedMessages.join('\n');

        if (payload.onAnyError) {
          payload.onAnyError(title, modalContent, failedMessages);
        } else {
          AntdModal.warning({ title, content: modalContent, okText: 'Got it' });
        }
      }
    },

    *markNotHired({ payload }, { call }) {
      const { id } = payload;
      const response = yield call(services.markNotHired, { id });
      if (response.error) throw response.error;
    },

    *sendEmailToApplicant({ payload }, { call }) {
      const { silent } = payload;
      const response = yield call(services.sendEmailToApplicant, payload);
      if (response.error) {
        throw response.error;
      }

      const { onSuccess } = payload;
      if (onSuccess) onSuccess();

      if (silent) return;

      AntdModal.success({
        title: 'Email send successfully',
        okText: 'OK',
      });
    },

    *startOnboarding({ payload, onAnySuccess }, { call }) {
      const { ids } = payload;
      const failedIDs = [];
      const successIDs = [];
      let response = {};
      for (const id of ids) {
        response = yield call(services.startOnboarding, { id });
        if (response.error) {
          failedIDs.push(id);
        } else {
          successIDs.push(id);
        }
      }
      if (failedIDs.length === ids.length) throw response.error;
      if (successIDs.length > 0) {
        if (onAnySuccess) onAnySuccess();
        AntdModal.success({
          title: `${successIDs.length}/${ids.length} start onboarding successfully.`,
          okText: 'OK',
        });
      }
      if (failedIDs.length > 0) {
        AntdModal.warning({
          title: `${failedIDs.length}/${ids.length} fail, please try again.`,
          okText: 'Got it',
        });
      }
    },

    fetchStageList: [
      function* fetch({ payload }, { put, call }) {
        const { digestkey } = payload;

        const response = yield call(fetchStageList, digestkey);
        if (
          response.error &&
          (response.error.errorCode === constants.ERROR_CODE.NO_ACCESS_ERROR_CODE ||
            response.error.errorCode === constants.ERROR_CODE.PAGE_NOT_FOUND_ERROR_CODE)
        ) {
          yield put({
            type: 'updateAccess',
            payload: {
              hasAccess: false,
            },
          });
          return;
        }
        if (response.error) {
          throw response.error;
        }

        yield put({
          type: 'setupStageList',
          payload: response.data.position_stages,
        });

        yield put({
          type: 'updateAccess',
          payload: {
            hasAccess: true,
          },
        });

        if (payload.onSuccess) payload.onSuccess(response.data.position_stages);
      },
      { type: 'takeLatest' },
    ],

    fetchApplicants: [
      function* fetch({ payload }, { put, call }) {
        const { page, perPage } = payload;

        const applicantsResponse = yield call(fetchApplicantsOfStage, payload);
        if (applicantsResponse.error) {
          throw applicantsResponse.error;
        }
        const {
          position_applications: positionApplications,
          total_pages: totalPage,
          position,
        } = applicantsResponse.data;

        if (position) {
          yield put({
            type: 'app/updatePositionItem',
            payload: { updates: position },
          });
        }

        // const { position_applications, current_page, total_pages } = payload;
        const pagination = {
          total: totalPage * perPage,
          current: Number(page),
          pageSize: Number(perPage),
        };

        yield put({
          type: 'setupApplicantList',
          payload: {
            applicantList: positionApplications,
            pagination,
          },
        });

        return true;
      },
      { type: 'takeLatest' },
    ],

    fetchAllApplicants: [
      function* fetch({ payload }, { put, call }) {
        const { page, perPage } = payload;

        const applicantsResponse = yield call(services.fetchApplicantsOfCompany, payload);
        if (applicantsResponse.error) {
          throw applicantsResponse.error;
        }

        const {
          position_applications: positionApplications,
          total_pages: totalPage,
          hired_count: hiredCount,
          in_progress_count: inProgressCount,
          rejected_count: rejectedCount,
          held_for_later_count: heldForLaterCount,
          others_count: othersCount,
          waiting_for_review_count: waitingForReviewCount,
          applications_with_unread_message: applicationsWithUnreadMessage,
          applications_with_unread_message_total_count: applicationsWithUnreadMessageTotalCount,
        } = applicantsResponse.data;

        // const { position_applications, current_page, total_pages } = payload;
        const pagination = {
          total: totalPage * perPage,
          current: Number(page),
          pageSize: Number(perPage),
        };

        yield put({
          type: 'setupAllApplicantsList',
          payload: {
            applicantsList: positionApplications,
            hiredCount,
            inProgressCount,
            rejectedCount,
            pagination,
            heldForLaterCount,
            othersCount,
            waitingForReviewCount,
            applicationsWithUnreadMessage,
            applicationsWithUnreadMessageTotalCount,
          },
        });

        return true;
      },
      { type: 'takeLatest' },
    ],

    *fetchPendingApplicants({ params }, { call }) {
      const res = yield call(services.fetchPendingApplicants, params);
      if (res.error) throw res.error;

      return res.data;
    },

    *fetchBulkStageGroups({ payload }, { call }) {
      const response = yield call(services.fetchApplicantsStageGroups, payload);
      if (response.error) {
        throw response.error;
      }

      return response;
    },

    *fetchAllApplicationBuckets(action, { put, call }) {
      const response = yield call(services.fetchApplicationBuckets, action.payload);
      if (response.error) {
        throw response.error;
      }

      yield put({
        type: 'allApplicationBuckets',
        payload: response.data,
      });

      return response.data;
    },

    *updateApplicantsList(action, { put }) {
      yield put({
        type: 'newApplicantsList',
        payload: action.payload,
      });

      return;
    },

    fetchAllApplicationInBucket: [
      function* fetch({ payload }, { put, call }) {
        const { page = PAGE_INDEX, perPage = PAGE_SIZE } = payload;

        const applicantsResponse = yield call(services.fetchApplicationInBucket, payload);
        if (applicantsResponse.error) {
          throw applicantsResponse.error;
        }

        const {
          position_applications: positionApplications,
          total_pages: totalPage,
          next_page: nextPage,
          search_count: searchCount,
        } = applicantsResponse.data;

        const pagination = {
          total: totalPage * perPage,
          current: Number(page),
          pageSize: Number(perPage),
        };

        yield put({
          type: 'setupAllApplicantsList',
          payload: {
            applicantsList: positionApplications,
            pagination,
            searchCount,
            hasMoreApplicants: !!nextPage,
          },
        });

        return applicantsResponse;
      },
      { type: 'takeLatest' },
    ],

    loadMoreApplicationInBucket: [
      function* fetch({ payload }, { put, call }) {
        const { page = PAGE_INDEX, perPage = PAGE_SIZE } = payload;

        const applicantsResponse = yield call(services.fetchApplicationInBucket, payload);
        if (applicantsResponse.error) {
          throw applicantsResponse.error;
        }

        const {
          position_applications: positionApplications,
          total_pages: totalPage,
          next_page: nextPage,
        } = applicantsResponse.data;

        const pagination = {
          total: totalPage * perPage,
          current: Number(page),
          pageSize: Number(perPage),
        };

        yield put({
          type: 'addMoreApplicantsList',
          payload: {
            applicantsList: positionApplications,
            pagination,
            hasMoreApplicants: !!nextPage,
          },
        });

        return applicantsResponse;
      },
      { type: 'takeLatest' },
    ],

    fetchInactiveApplicants: [
      function* fetch({ payload }, { put, call }) {
        const { page = PAGE_INDEX, perPage = INACTIVE_APPLICANT_PAGE_SIZE } = payload;

        const inactiveApplicantsResponse = yield call(services.fetchInactiveApplicants, { ...payload, page, perPage });
        if (inactiveApplicantsResponse?.error) {
          throw inactiveApplicantsResponse.error;
        }

        const { position_applications, next_page, search_count, total_inactive_count } =
          inactiveApplicantsResponse.data;

        const pagination = {
          total: search_count,
          current: Number(page),
          pageSize: Number(perPage),
        };

        yield put({
          type: 'setupInactiveApplicantsList',
          payload: {
            inactiveApplicantsList: position_applications,
            pagination,
            hasMoreInactiveApplicants: !!next_page,
            search_count,
            total_inactive_count,
          },
        });

        return inactiveApplicantsResponse;
      },
      { type: 'takeLatest' },
    ],

    refreshCurrentPage: [
      function* fetch({ payload }, { put, call }) {
        const { page = PAGE_INDEX } = payload;

        const applicantsResponse = yield call(services.fetchApplicationInBucket, payload);
        if (applicantsResponse.error) {
          throw applicantsResponse.error;
        }

        const { position_applications: positionApplications, search_count: searchCount } = applicantsResponse.data;

        yield put({
          type: 'refreshApplicantsPage',
          payload: {
            applicantsList: positionApplications,
            page,
            searchCount,
          },
        });

        return applicantsResponse;
      },
      { type: 'takeLatest' },
    ],

    *archive({ payload }, { call }) {
      const { id } = payload;
      const response = yield call(services.archive, { id });
      if (response.error) throw response.error;
      return response;
    },

    *unarchive({ payload }, { call }) {
      const { id } = payload;
      const response = yield call(services.unarchive, { id });
      if (response.error) throw response.error;
      return response;
    },
  },
};
