import _ from 'lodash';
import { UserInfo } from '@helloworld1812/ws-biz-hooks';

import constants from '@/utils/constants';

/**
 * This function is compatible with both string and object permission item.
 */
function hasUserPermission(appState: { user: UserInfo }, permissionName: string) {
  if (appState?.user?.company_admin == null) {
    return false;
  }

  // Flatten `appState.user.company_admin.permissions`
  const processedUserPermissions = appState.user.company_admin.permissions
    .map((permissionItem) => (typeof permissionItem === 'string' ? permissionItem : Object.keys(permissionItem)))
    .reduce<string[]>((acc, curr) => (_.isArray(curr) ? [...acc, ...curr] : [...acc, curr]), []);

  if (processedUserPermissions.includes(permissionName)) {
    return true;
  }

  return false;
}

function hasViewSensitivePiiPermission(
  appState: { user: UserInfo },
  targetPermissionKey: string = constants.USER_PERMISSIONS.VIEW_SENSITIVE_PII,
) {
  return hasUserPermission(appState, targetPermissionKey);
}

function getViewSensitivePiiPermission(
  appState: { user: UserInfo },
  targetPermissionKey: string = constants.USER_PERMISSIONS.VIEW_SENSITIVE_PII,
) {
  if (!hasViewSensitivePiiPermission(appState, targetPermissionKey)) {
    return undefined;
  }

  const targetPermissionItem = appState.user.company_admin.permissions.find(
    (permissionItem) => typeof permissionItem !== 'string' && Object.keys(permissionItem).includes(targetPermissionKey),
  ) as Record<string, string>;

  return targetPermissionItem[targetPermissionKey];
}

function getManageTeamRecordPermission(appState: { user: UserInfo }) {
  return getViewSensitivePiiPermission(appState, constants.USER_PERMISSIONS.MANAGE_TEAM_RECORD);
}

export { hasViewSensitivePiiPermission, getViewSensitivePiiPermission, getManageTeamRecordPermission };
