import React from 'react';

import type { Reflexbox3FlexBoxProps } from './flexbox.types';
import { Box } from './reflexbox3box';

/**
 * The replacement of `Flex` of `reflexbox3`,
 * which is actually a wrapper of `FlexBox` of `@helloworld1812/ws-components`,
 * surrounded by `AppThemeProvider` when `theme` is not accessible.
 * It has the exact same API as `Flex` of `reflexbox3`.
 *
 * @deprecated Use `FlexBox` from `@helloworld1812/ws-components` instead.
 *
 * @todo Replace all antd v2 popover like components (Modal, Popover, Dropdown, etc.) with those
 * in antd v4, so that we don't need to wrap `AppThemeProvider` around `FlexBox`.
 */
export function Flex(props: Reflexbox3FlexBoxProps) {
  return <Box {...props} flex />;
}
