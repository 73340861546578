import * as services from '../../services/employee-onboarding/e-verify';

export const ALL_STEPS = ['company_info', 'employer_category', 'hiring_sites', 'contacts', 'preview'];

export const initialState = {
  paneOpen: false,
  eVerifyIntegrationsList: [],
  activeEverifyIntegrationList: [],
  draftEverifyIntegrationList: [],
  pendingReviewEverifyIntegrationList: [],
  eVerifyClientsList: [],
  eVerifyIntegration: {
    dataState: {
      haveNaicsCode: true,
      selectedEmployerCategories: {},
    },
    physical_address_as_mailing_address: {
      name: 'physical_address_as_mailing_address',
      value: true,
    },
    main_contact_as_signatory: {
      name: 'main_contact_as_signatory',
      value: true,
    },
    employer_category: {},
    business_best_describe: {},
    verifying_employee_type: {},
  },
  currentStep: ALL_STEPS[0],
  missingFields: [],
  stepErrors: {},
};

const buildFormData = (payload) => {
  const formData = {};
  Object.keys(payload).forEach((key) => {
    if (key === 'employer_id_number') {
      const einValue = payload[key].replace(/-/g, '');
      const formattedValue = `${einValue.slice(0, 2)}-${einValue.slice(2)}`;
      formData[key] = {
        name: key,
        value: formattedValue,
      };
    } else {
      formData[key] = {
        name: key,
        value: payload[key],
      };
    }
  });
  return formData;
};

const getFormData = (payload) => {
  const formData = {};
  Object.keys(payload).forEach((key) => {
    if (key === 'employer_id_number') {
      formData[key] = payload[key].value.replace(/-/g, '');
    } else if (key !== 'dataState') {
      formData[key] = payload[key].value;
    }
  });
  return formData;
};

const filterEverifyIntegrationList = (payload) => {
  const draftEverifyIntegrationList = payload.filter((i) => i.status === 'draft');
  const pendingReviewEverifyIntegrationList = payload.filter((i) => i.status === 'pending_review');
  const activeEverifyIntegrationList = payload.filter((i) => i.status === 'active');
  return {
    draftEverifyIntegrationList,
    pendingReviewEverifyIntegrationList,
    activeEverifyIntegrationList,
  };
};

export const reducers = {
  fetchEverifyIntegrationsSuccess(state, { payload }) {
    const { draftEverifyIntegrationList, pendingReviewEverifyIntegrationList, activeEverifyIntegrationList } =
      filterEverifyIntegrationList(payload);
    return {
      ...state,
      eVerifyIntegrationsList: payload,
      draftEverifyIntegrationList,
      pendingReviewEverifyIntegrationList,
      activeEverifyIntegrationList,
    };
  },

  setupEverifyIntegration(state, { payload }) {
    const data = buildFormData(payload);
    return {
      ...state,
      eVerifyIntegration: {
        ...state.eVerifyIntegration,
        ...data,
      },
    };
  },

  saveDataState(state, { payload }) {
    return {
      ...state,
      eVerifyIntegration: {
        ...state.eVerifyIntegration,
        dataState: {
          ...state.eVerifyIntegration.dataState,
          ...payload,
        },
      },
    };
  },

  saveFields(state, { payload }) {
    return {
      ...state,
      eVerifyIntegration: {
        ...state.eVerifyIntegration,
        ...payload,
      },
    };
  },

  selectCategory(state, { payload }) {
    const selectedCategoryKeys = Object.keys(payload).map((k) => parseInt(k, 10));
    const currentSelectedCategory = payload[selectedCategoryKeys[0]];
    const currentSelectedCategoryKey = currentSelectedCategory.selectedKey.split('/')[1];

    const updatedCategory = selectedCategoryKeys.sort();
    const lastLevel = updatedCategory[updatedCategory.length - 1];
    const wholeLevel = Array.from(Array(lastLevel + 1).keys());
    const newSelectedEmployerCategories = {};
    wholeLevel.forEach((level) => {
      if (updatedCategory.includes(level)) {
        newSelectedEmployerCategories[level] = {
          ...state.eVerifyIntegration.dataState.selectedEmployerCategories[level],
          ...payload[level],
        };
      } else {
        newSelectedEmployerCategories[level] = state.eVerifyIntegration.dataState.selectedEmployerCategories[level];
      }
    });
    return {
      ...state,
      eVerifyIntegration: {
        ...state.eVerifyIntegration,
        dataState: {
          ...state.eVerifyIntegration.dataState,
          selectedEmployerCategories: newSelectedEmployerCategories,
          [currentSelectedCategoryKey]: currentSelectedCategory.value,
        },
      },
    };
  },

  changeStepErrors(state, { payload }) {
    return {
      ...state,
      stepErrors: {
        ...state.stepErrors,
        ...payload,
      },
    };
  },

  moveToStep(state, { payload }) {
    return {
      ...state,
      currentStep: payload.currentStep,
    };
  },

  resetEverifyIntegration(state) {
    return {
      ...state,
      eVerifyIntegration: initialState.eVerifyIntegration,
      currentStep: initialState.currentStep,
      stepErrors: initialState.stepErrors,
    };
  },

  deleteEverifyIntegrationSuccess(state, { payload }) {
    const eVerifyIntegrationsList = state.eVerifyIntegrationsList.filter((it) => {
      return it.digest_key !== payload.id;
    });
    const { draftEverifyIntegrationList, pendingReviewEverifyIntegrationList, activeEverifyIntegrationList } =
      filterEverifyIntegrationList(eVerifyIntegrationsList);
    return {
      ...state,
      eVerifyIntegrationsList,
      draftEverifyIntegrationList,
      pendingReviewEverifyIntegrationList,
      activeEverifyIntegrationList,
    };
  },

  setMissingFields(state, { payload }) {
    return {
      ...state,
      missingFields: payload.missingFields,
    };
  },

  startCompanySettingSetup(state) {
    return { ...state, paneOpen: true };
  },

  exitCompanySettingSetup() {
    return initialState;
  },
};

export const effects = {
  *fetchEverifyIntegrations({ payload = {} }, { call, put }) {
    const response = yield call(services.fetchEverifyIntegrations);
    if (response.error) throw response.error;
    yield put({
      type: 'fetchEverifyIntegrationsSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *fetchEverifyIntegration({ payload = {} }, { call, put }) {
    const response = yield call(services.fetchEverifyIntegration, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'setupEverifyIntegration',
      payload: response.data,
    });
    return response.data;
  },

  *saveDraftEverifyIntegration({ payload = {} }, { call, put }) {
    const data = getFormData(payload.data);
    let response;
    if (data.digest_key) {
      response = yield call(services.updateEverifyIntegration, {
        id: data.digest_key,
        data,
      });
    } else {
      response = yield call(services.createEverifyIntegration, { data });
    }
    if (response.error) throw response.error;
    yield put({
      type: 'resetEverifyIntegration',
    });
    return response.data;
  },

  *submitEverifyIntegration({ payload = {} }, { call, put }) {
    const response = yield call(services.submitEverifyIntegration, payload);
    if (response.error) throw response.error;

    yield put({
      type: 'resetEverifyIntegration',
    });
    return response.data;
  },

  *deleteEverifyIntegration({ payload = {} }, { call, put }) {
    const response = yield call(services.deleteEverifyIntegration, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'deleteEverifyIntegrationSuccess',
      payload,
    });
    return response.data;
  },
};

export default {
  namespace: 'eVerify',
  state: initialState,
  reducers,
  effects,
};
