import request from '../utils/request';
import { APIHOST } from '../config';

export async function fetchCompanyStaffs(payload, withPermittedLocations = false) {
  const path = withPermittedLocations ? '/v1/company_staffs/with_staff_permission_locations' : '/v1/company_staffs';
  const { page, perPage: per_page, query, status, sort_key: sortKey, order, employee_record_link_required } = payload;
  const data = {
    page,
    per_page,
  };
  if (query) {
    data.query = query;
  }
  if (status) {
    data.status = status;
  }
  if (sortKey && order) {
    const key = sortKey;
    let orderValue = order;
    // if (key === 'published' || key === 'url') key = 'status';
    orderValue = orderValue === 'ascend' ? 'asc' : 'desc';
    data.order = `${key},${orderValue}`;
  }

  if (employee_record_link_required) {
    data.needs_employee_record_mapping = employee_record_link_required;
  }

  return request('GET', APIHOST + path, data);
}

export async function fetchMarkedInactiveApplicantsCompanyStaffList(payload) {
  const { query, sort } = payload;
  const path = '/v1/company_staffs';
  const data = {
    type: 'marked_inactive_applicants',
  };

  if (query) data.query = query;
  if (sort) data.order = sort;

  return request('GET', APIHOST + path, data);
}

export async function addCompanyStaff(payload) {
  const { companyId, data } = payload;
  const path = '/v1/companies/:company_id/company_staffs'.replace(':company_id', companyId);
  return request('POST', APIHOST + path, data);
}

export async function updateCompanyStaff(payload) {
  const { id, data } = payload;
  const path = '/v1/company_staffs/:id'.replace(':id', id);
  return request('PUT', APIHOST + path, data);
}

export async function disableCompanyStaff(payload) {
  const { id } = payload;
  const path = '/v1/company_staffs/:id/disable'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function bulkDisableCompanyStaff(payload) {
  const { selectedIds } = payload;
  const path = '/v1/company_staffs/bulk_disable';
  return request('POST', APIHOST + path, {
    company_staff_ids: selectedIds,
  });
}

export async function restoreCompanyStaff(payload) {
  const { id } = payload;
  const path = '/v1/company_staffs/:id/restore'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function resetPasswordCompanyStaff(payload) {
  const { id } = payload;
  const path = '/v1/company_staffs/:id/reset_password'.replace(':id', id);
  return request('POST', APIHOST + path);
}

export async function fetchUnsignedDocuments() {
  const path = '/v1/company_staffs/unsigned_documents';
  return request('GET', APIHOST + path);
}

export async function skipInterview(payload) {
  const path = '/v1/company_staffs/skip_interview';
  return request('POST', APIHOST + path, payload);
}

export async function fetchStaffPermissions(params) {
  const path = '/v1/staff_permissions';
  return request('GET', APIHOST + path, params);
}

export async function fetchActiveEmployeeActions(payload) {
  const { staffId } = payload;
  const path = `/v1/company_staffs/${staffId}/active_employee_actions`;
  return request('GET', APIHOST + path);
}
