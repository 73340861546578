import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';

import {
  fetchStaffsInLocation,
  fetchPaginatedStaffsInLocation,
  updateCompanyStaffsInLocation,
} from '@/services/locations';

import * as services from '../services/companyStaff';
import { customMessage as message } from '../components/CustomMessage';

export default {
  namespace: 'companyStaff',
  state: {
    companyStaffList: [],
    allCompanyStaffsList: [],
    allCompanyStaffsListQuery: {
      nickname: '',
      status: 'active',
      page: 1,
      perPage: 10,
      sort_key: 'created_at',
      order: 'descend',
    },
    companyStaffsOverviewQuery: {
      digestkey: '',
    },
    allCompanyStaffsListPagination: {
      total: 1,
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['10', '25', '50', '100'],
    },
    unlinkedCompanyStaffsCount: 0,
    unsignedDocuments: [],
    locationStaffsList: [],
    total: 0,
    current: 0,
    pageSize: 0,
    inactive_applicant_marked_by_staffs: [],
    companyStaffMaxCount: 0,
  },
  reducers: {
    setupAllCompanyStaffsList(state, { payload }) {
      const { companyStaffsList: allCompanyStaffsList, pagination, total_count } = payload;
      return {
        ...state,
        allCompanyStaffsList,
        allCompanyStaffsListPagination: { ...state.allCompanyStaffsListPagination, ...pagination },
        companyStaffTotalCount: total_count,
      };
    },
    setupUnlinkedCompanyStaffsCount(state, { payload }) {
      const { total_count: unlinkedCompanyStaffsCount } = payload;
      return {
        ...state,
        unlinkedCompanyStaffsCount,
      };
    },
    allCompanyStaffsListQueryUpdate(state, { payload }) {
      const allCompanyStaffsListQuery = {
        ...state.allCompanyStaffsListQuery,
        ...payload,
      };
      return {
        ...state,
        allCompanyStaffsListQuery,
      };
    },
    companyStaffsOverviewQueryUpdate(state, { payload }) {
      const companyStaffsOverviewQuery = {
        ...state.companyStaffsOverviewQuery,
        ...payload,
      };
      return {
        ...state,
        companyStaffsOverviewQuery,
      };
    },
    fetchUnsignedDocumentsSuccess(state, { payload }) {
      const { documents: unsignedDocuments } = payload;
      return {
        ...state,
        unsignedDocuments,
      };
    },
    setupLocationCompanyStaffsList(state, { payload }) {
      const { companyStaffs } = payload;
      return {
        ...state,
        locationStaffsList: companyStaffs,
      };
    },
    setupInactiveApplicantsStaff(state, { payload }) {
      const { inactive_applicant_marked_by_staffs } = payload;
      return {
        ...state,
        inactive_applicant_marked_by_staffs,
      };
    },
  },
  subscriptions: {},
  effects: {
    *addCompanyStaff({ payload }, { call }) {
      const { onAnySuccess, onErr } = payload;
      const response = yield call(services.addCompanyStaff, payload);
      if (response.error) {
        if (onErr) {
          onErr(response.error?.message || 'something went wrong');
        } else {
          throw response.error;
        }
      } else if (onAnySuccess) {
        onAnySuccess();
      } else {
        notification.open({
          message: 'Successfully added new user',
          placement: 'bottomRight',
          icon: <CheckCircleOutlined style={{ color: '#3bd68e' }} />,
        });
      }
    },
    *updateCompanyStaff({ payload }, { call }) {
      const { onAnySuccess, onErr } = payload;
      const response = yield call(services.updateCompanyStaff, payload);
      if (response.error) {
        if (onErr) {
          onErr(response.error);
          return;
        }

        throw response.error;
      } else {
        if (onAnySuccess) {
          onAnySuccess();
          return;
        }

        notification.open({
          message: 'Successfully updated user',
          placement: 'bottomRight',
          icon: <CheckCircleOutlined style={{ color: '#3bd68e' }} />,
        });
      }
    },
    *disableCompanyStaff({ payload }, { call }) {
      const { onAnySuccess } = payload;
      const response = yield call(services.disableCompanyStaff, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
      } else {
        notification.open({
          message: 'Successfully disabled user',
          placement: 'bottomRight',
          icon: <CheckCircleOutlined style={{ color: '#3bd68e' }} />,
        });
        if (onAnySuccess) onAnySuccess();
      }
    },
    *bulkDisableCompanyStaff({ payload }, { call }) {
      const { onAnySuccess } = payload;
      const response = yield call(services.bulkDisableCompanyStaff, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
      } else {
        notification.open({
          message: 'Successfully disabled users',
          placement: 'bottomRight',
          icon: <CheckCircleOutlined style={{ color: '#3bd68e' }} />,
        });
        if (onAnySuccess) onAnySuccess();
      }
    },
    *restoreCompanyStaff({ payload }, { call }) {
      const { onAnySuccess } = payload;
      const response = yield call(services.restoreCompanyStaff, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
      } else {
        notification.open({
          message: 'Successfully restored users',
          placement: 'bottomRight',
          icon: <CheckCircleOutlined style={{ color: '#3bd68e' }} />,
        });
        if (onAnySuccess) onAnySuccess();
      }
    },
    *resetPasswordCompanyStaff({ payload }, { call }) {
      const { onAnySuccess } = payload;
      const response = yield call(services.resetPasswordCompanyStaff, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
      } else {
        notification.open({
          message: 'Successfully reset password',
          placement: 'bottomRight',
          icon: <CheckCircleOutlined style={{ color: '#3bd68e' }} />,
        });
        if (onAnySuccess) onAnySuccess();
      }
    },
    *fetchUnsignedDocuments({ payload }, { put, call }) {
      const response = yield call(services.fetchUnsignedDocuments, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
      } else {
        yield put({ type: 'fetchUnsignedDocumentsSuccess', payload: response.data });
      }
    },
    *fetchLocationCompanyStaffs({ payload }, { put, call }) {
      const response = yield call(fetchStaffsInLocation, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
        return;
      }
      yield put({ type: 'setupLocationCompanyStaffsList', payload: { companyStaffs: response.data.company_staffs } });
    },
    *fetchPaginatedStaffsInLocation({ payload }, { put, call }) {
      const response = yield call(fetchPaginatedStaffsInLocation, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
        return;
      }
      yield put({
        type: 'setupLocationCompanyStaffsList',
        payload: {
          companyStaffs: response.data.company_staffs,
          total: response.data.total_count,
          current: response.data.current_page,
          pageSize: payload?.per_page,
        },
      });
    },
    *queryCompanyStaff({ payload }, { put, call }) {
      const res = yield call(services.fetchCompanyStaffs, payload);
      if (res.error) {
        throw res.error;
      }
      return res.data;
    },
    *updateCompanyStaffsInLocation({ payload }, { call }) {
      const response = yield call(updateCompanyStaffsInLocation, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
        return;
      }
      return response;
    },
    fetchAllCompanyStaffs: [
      function* fetch({ payload, withPermittedLocations }, { put, call }) {
        const { page, perPage } = payload;
        const companyStaffsResponse = yield call(services.fetchCompanyStaffs, payload, withPermittedLocations);
        if (companyStaffsResponse.error) {
          throw companyStaffsResponse.error;
        }
        const { company_staffs: companyStaffs } = companyStaffsResponse.data;
        const { total_pages: totalPage } = companyStaffsResponse.data;
        const { total_count } = companyStaffsResponse.data;

        const pagination = {
          total: totalPage * perPage,
          current: page,
          pageSize: perPage,
        };

        yield put({
          type: 'setupAllCompanyStaffsList',
          payload: {
            companyStaffsList: companyStaffs,
            pagination,
            total_count,
          },
        });
        return companyStaffsResponse.data;
      },
      { type: 'takeLatest' },
    ],
    fetchUnlinkedCompanyStaffsCount: [
      function* fetch({ payload }, { put, call }) {
        const companyStaffsResponse = yield call(services.fetchCompanyStaffs, {
          nickname: '',
          status: 'active',
          page: 1,
          perPage: 10,
          sort_key: 'created_at',
          order: 'descend',
          employee_record_link_required: true,
        });
        if (companyStaffsResponse.error) {
          throw companyStaffsResponse.error;
        }
        const { company_staffs } = companyStaffsResponse.data;
        yield put({
          type: 'setupUnlinkedCompanyStaffsCount',
          payload: {
            total_count: company_staffs.length,
          },
        });
        return companyStaffsResponse.data;
      },
      { type: 'takeLatest' },
    ],
    *skipInterview({ payload }, { call }) {
      const response = yield call(services.skipInterview, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
      }
    },
    *fetchStaffPermissions({ payload }, { call }) {
      const response = yield call(services.fetchStaffPermissions, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
      }

      return response.data;
    },
    *fetchActiveEmployeeActions({ payload }, { call }) {
      const response = yield call(services.fetchActiveEmployeeActions, payload);
      if (response.error) {
        message.error(`${response.error.message}`);
      }

      return response.data;
    },
    *fetchInactiveApplicantsStaff({ payload }, { put, call }) {
      const { query } = payload;
      const response = yield call(services.fetchMarkedInactiveApplicantsCompanyStaffList, {
        sort: 'updated_at,desc',
        query,
      });

      if (response.error) message.error(`${response.error.message}`);

      yield put({
        type: 'setupInactiveApplicantsStaff',
        payload: { inactive_applicant_marked_by_staffs: response.data.inactive_applicant_marked_by_staffs },
      });
      return;
    },
  },
};
