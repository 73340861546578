import request from '@/utils/request';
import { APIHOST } from '@/config';
import { toFormData } from '@/utils';

export async function fetchDocumentsList(data, cancelToken) {
  const { page, perPage, query = '', sortKey, sortOrder, fields } = data;

  const params = {
    ...(page && { page }),
    ...(perPage && { per_page: perPage }),
    ...(query && { query }),
    ...(sortKey && sortOrder && { order: `${sortKey},${sortOrder === 'descend' ? 'desc' : 'asc'}` }),
    ...(fields && { fields }),
  };

  const path = '/v1/company_document_templates';
  return request('GET', APIHOST + path, params, null, cancelToken);
}

export async function fetchAllDocuments() {
  const path = '/v1/company_document_templates/all';
  return request('GET', APIHOST + path);
}

export async function prepareCompanyDocumentTemplate(payload) {
  const { signerGroups, templateFile, name, id } = payload;
  const data = {
    company_document_template: {
      signer_groups: signerGroups,
      ...(templateFile.url && { template_file_url: templateFile.url }),
      ...(!templateFile.url && { template_file: templateFile }),
      name,
      id,
    },
  };

  const path = '/v1/company_document_templates/prepare_hello_sign_template';
  return request('POST', APIHOST + path, toFormData(data));
}

export async function getCompanyDocumentTemplate(payload) {
  const { id } = payload;

  const path = '/v1/company_document_templates/:id/hello_sign_template'.replace(':id', id);
  return request('GET', APIHOST + path);
}

export async function createCompanyDocumentTemplate(payload) {
  const { name, offer, signerGroups } = payload;

  const path = '/v1/company_document_templates';

  const data = {
    company_document_template: {
      name,
      signer_groups: signerGroups,
    },
  };

  // const newAssignees = assignees.map((a) => {
  //   return {
  //     sequence: a.sequence,
  //     assignee_id: a.company_role_id || a.company_staff_id,
  //     name: a.name,
  //     assignee_type: a.assignee_type,
  //   };
  // });

  // data.company_document_template.signers_attributes = newAssignees;

  const keys = ['template_type', 'title', 'template_file', 'template_id'];

  for (const k of keys) {
    data.company_document_template[k] = offer[k];
  }

  return request('POST', APIHOST + path, toFormData(data));
}

export async function updateCompanyDocumentTemplate(payload) {
  const { signerGroups, offer, name, documentId } = payload;

  const path = '/v1/company_document_templates/:id'.replace(':id', documentId);

  const data = {
    company_document_template: {
      name,
      signer_groups: signerGroups,
    },
  };

  // const newAssignees = assignees.map((a) => {
  //   return {
  //     id: a.id,
  //     sequence: a.sequence,
  //     assignee_id: a.company_role_id || a.company_staff_id,
  //     name: a.name,
  //     assignee_type: a.assignee_type,
  //   };
  // });

  // data.company_document_template.signers_attributes = newAssignees;

  const keys = ['template_type', 'title', 'template_file', 'template_id'];

  for (const k of keys) {
    data.company_document_template[k] = offer[k];
  }

  return request('PUT', APIHOST + path, toFormData(data));
}

export async function deleteCompanyDocumentTemplate(payload) {
  const { documentId } = payload;
  const path = '/v1/company_document_templates/:id'.replace(':id', documentId);
  return request('DELETE', APIHOST + path);
}
