import { isArray } from 'lodash';

import { isNumeric } from '@/utils';
import {
  QuestionWithAnswerType,
  QuestionFieldType,
  QuestionOptionType,
  QuestionAnswerType,
} from '@/routes/employee-onboarding/process-setup/interface';

import { getComponentType, TYPES } from '../../common/form/question';

export const streetLineOneKey = 'address/streetLineOne';
const cityKey = 'address/city';
export const countrySubdivisionCodeKey = 'address/countrySubdivisionCode';
const postalCodeKey = 'address/postalCode';
export const countryCodeKey = 'address/countryCode';
export const amountKey = 'compensation/payrate/amount';
const rateTypeKey = 'compensation/payrate/rateType';
export const supervisorWorkerIdKey = 'supervisor/workerId';
export const companyKey = 'companyId';
export const jobTitleKey = 'job/jobTitleId';
export const organizationKey = 'organization/organizationId';
export const supervisorKey = 'supervisor/workerId';
export const emergencyContactKey = 'emergencyContact';
export const emergencyContactRelationshipTypeKey = `${emergencyContactKey}/relationship/relationshipType/relationshipTypeId`;
export const selectCompanyMessage = 'Please select Paychex company first.';
export const addressNote = 'If city, state, zip code or country is specified then this field is mandatory.';
export const payrateNote = 'If pay frequency is specified then this field is mandatory.';

const emergencyContactGivenName = `${emergencyContactKey}/relationship/person/name/givenName`;
const emergencyContactFamilyName = `${emergencyContactKey}/relationship/person/name/familyName`;
const emergencyContactPhoneNumber = `${emergencyContactKey}/relationship/person/communication/telecom/dialNumber`;
const emergencyContactEmail = `${emergencyContactKey}/relationship/person/communication/email/uri`;
const emergencyContactNote = 'Please fill out all emergency contact fields or leave them all blank.';

const isEmergencyContactValid = (formFields: QuestionAnswerType) => {
  let emptyFieldCount = 0;
  const mandatoryEmergencyContactKeys = [
    emergencyContactRelationshipTypeKey,
    emergencyContactGivenName,
    emergencyContactFamilyName,
    emergencyContactPhoneNumber,
    emergencyContactEmail,
  ];
  const mandatoryEmergencyContactKeysLength = mandatoryEmergencyContactKeys.length;
  for (const key of mandatoryEmergencyContactKeys) {
    if (!formFields[key]) {
      emptyFieldCount += 1;
    }
  }
  return emptyFieldCount === 0 || emptyFieldCount === mandatoryEmergencyContactKeysLength;
};

export const buildFormPayload = ({ questions }: { questions: QuestionWithAnswerType[] }) => {
  return questions.reduce((acc: unknown, question: QuestionWithAnswerType) => {
    const { onboarding_form_question_id: questionId } = question;

    acc[questionId] = question.answer.answer;

    return acc;
  }, {});
};

export const getNestedFields = (question: QuestionFieldType) => {
  const { fields = [] } = question;
  const nestFields = fields.filter((field) => {
    return field.fields;
  });
  return nestFields;
};

type ruleType = {
  category: 'number' | 'length' | 'regex' | 'date';
  action: 'gte' | 'lte' | 'min' | 'max' | 'match' | 'unmatch' | 'lt' | 'gt' | 'between' | 'unbetween';
  value: unknown;
  customErrorMessage: string;
  gte: number;
  lte: number;
  min: number | string;
  max: number | string;
};

const getErrorMessage = (value: unknown, rules: ruleType[]) => {
  // Replace all the start white spaces to prevent by-passing the
  // required fields only with white space value like '  '
  // We only operate on string value for now
  let newValue = value;
  if (typeof value === 'string') {
    newValue = value.replace(/^\s*/g, '');
  }
  if (newValue === '' || newValue === null || newValue === undefined) return 'This is a mandatory field.';
  // multiple_selection has empty array as default empty value,
  // need to check array length
  if (newValue.length === 0) return 'This is a mandatory field.';
  if (!rules || rules.length === 0 || rules[0] === null) return undefined;
  let message;
  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];
    const { category: categoryValue, action: actionValue, value: ruleValue, customErrorMessage } = rule;
    if (categoryValue === 'number') {
      if (!isNumeric(newValue)) {
        message = 'Please enter a valid number';
        break;
      } else if (actionValue === 'gte' && Number(newValue) < ruleValue) {
        message = `Please enter a number greater than or equal to ${ruleValue}`;
        break;
      } else if (actionValue === 'lte' && Number(newValue) > ruleValue) {
        message = `Please enter a number less than or equal to ${ruleValue}`;
      } else if (rule.gte || rule.lte) {
        if (Number(newValue) < rule.gte) {
          message = `Please enter a number greater than or equal to ${rule.gte}`;
          break;
        } else if (Number(newValue) > rule.lte) {
          message = `Please enter a number less than or equal to ${rule.lte}`;
          break;
        }
      }
    } else if (categoryValue === 'length') {
      if (actionValue === 'min' && newValue.length < ruleValue) {
        message = `Your response is too short. The minimum character limit is ${ruleValue}`;
        break;
      }
      if (actionValue === 'max' && newValue.length > ruleValue) {
        message = `Your response is too long. The maximum character limit is ${ruleValue}`;
        break;
      }
    }
    if (categoryValue === 'regex') {
      if (actionValue === 'match' && !new RegExp(ruleValue).test(newValue)) {
        message = customErrorMessage || 'Please enter a valid response';
        break;
      }
      if (actionValue === 'unmatch' && new RegExp(ruleValue).test(newValue)) {
        message = customErrorMessage || 'Please enter a valid response';
        break;
      }
    } else if (categoryValue === 'date') {
      const userInputDate = new Date(newValue);
      const ruleDateValue = new Date(ruleValue);
      const ruleDateMin = new Date(rule.min);
      const ruleDateMax = new Date(rule.max);
      if (actionValue === 'lt' && userInputDate >= ruleDateValue) {
        message = `Please enter a date before ${ruleValue}`;
        break;
      } else if (actionValue === 'lte' && userInputDate > ruleDateValue) {
        message = `Please enter a date on or before ${ruleValue}`;
        break;
      } else if (actionValue === 'gt' && userInputDate <= ruleDateValue) {
        message = `Please enter a date after ${ruleValue}`;
        break;
      } else if (actionValue === 'gte' && userInputDate < ruleDateValue) {
        message = `Please enter a date on or after ${ruleValue}`;
        break;
      } else if (actionValue === 'between' && (userInputDate < ruleDateMin || userInputDate > ruleDateMax)) {
        message = `Please enter a date on or between ${rule.min} and ${rule.max}`;
        break;
      } else if (actionValue === 'unbetween' && (userInputDate <= ruleDateMin || userInputDate >= ruleDateMax)) {
        message = `Please enter a date between ${rule.min} and ${rule.max}`;
        break;
      }
    }
  }
  return message;
};

export const getOptions = ({
  field,
  companyData,
  employeeData,
  subdivisions,
  countryAnswer,
}: {
  field?: QuestionFieldType;
  companyData?: {
    jobTitleOptions: QuestionOptionType[];
    organizationsOptions: QuestionOptionType[];
    supervisorOptions: QuestionOptionType[];
  };
  employeeData?: {
    emergencyRelationshipTypeOptions: QuestionOptionType[];
  };
  subdivisions?: {
    [key: string]: QuestionOptionType[];
  };
  countryAnswer?: string;
}) => {
  if (!field) return [];

  const options = (field.options as QuestionOptionType[]) || (field.code_lists && field.code_lists[field.key]) || [];
  if (field.key === emergencyContactRelationshipTypeKey) {
    return employeeData?.emergencyRelationshipTypeOptions || [];
  }
  if (field.key === jobTitleKey) {
    return companyData?.jobTitleOptions || [];
  }
  if (field.key === organizationKey) {
    return companyData?.organizationsOptions || [];
  }
  if (field.key === supervisorKey) {
    return companyData?.supervisorOptions || [];
  }
  if (field.key === countrySubdivisionCodeKey) {
    return subdivisions && countryAnswer ? subdivisions[countryAnswer] : [];
  }
  return options;
};

export const getRequiredCategoryField = ({
  question,
  allQuestions,
}: {
  question: QuestionFieldType;
  allQuestions: QuestionFieldType[];
}) => {
  let requiredQuestion: QuestionFieldType = {} as QuestionFieldType;
  let requiredCategoryFieldTitle = '';

  if (question.category_type) {
    allQuestions.forEach((q) => {
      if (q.key === question.category_type) {
        requiredCategoryFieldTitle = q.title;
        requiredQuestion = q;
      } else if (q.fields) {
        q.fields.forEach((f) => {
          if (f.key === question.category_type) {
            requiredCategoryFieldTitle = f.title;
            requiredQuestion = q;
          }
        });
      }
    });
  }
  return { requiredQuestion, requiredCategoryFieldTitle };
};

export const getRequiredCategoryFieldAnswer = ({
  question,
  allQuestions,
  rowIdx,
}: {
  question: QuestionFieldType;
  allQuestions: QuestionFieldType[];
  rowIdx?: number;
}) => {
  const { requiredQuestion } = getRequiredCategoryField({ question, allQuestions });

  let answer = requiredQuestion && requiredQuestion.answer ? requiredQuestion.answer.answer : null;
  if (requiredQuestion && requiredQuestion.fields && rowIdx !== undefined) {
    if (requiredQuestion.max_items) {
      answer = requiredQuestion.answer.answer[rowIdx];
    }
  }
  return answer || answer === false ? answer[question.category_type] : '';
};

export const getQuestionInputName = ({
  question,
  fieldKey,
  rowIndex,
  nestedFieldKey,
}: {
  question: QuestionFieldType;
  fieldKey?: string;
  rowIndex?: number;
  nestedFieldKey?: string;
}) => {
  const componentType = getComponentType(question);
  let name = '';
  const isNestedQuestionGroup = nestedFieldKey && rowIndex !== undefined && getNestedFields(question).length !== 0;
  const { onboarding_form_question_id: questionId } = question;

  switch (componentType) {
    case TYPES.single_question:
      name = `${questionId}[${question.key}]`;
      break;
    case TYPES.group_question:
      if (isNestedQuestionGroup) {
        name = `${questionId}[${fieldKey}][${rowIndex}][${nestedFieldKey}]`;
      } else {
        name = `${questionId}[${fieldKey}]`;
      }
      break;
    default:
  }

  return name;
};

export const getValidateErrorMessage = ({ questions }: { questions: QuestionWithAnswerType[] }) => {
  const errorMessageMap = {} as Record<string, string | undefined>;
  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    const componentType = getComponentType(question);
    switch (componentType) {
      case TYPES.single_question:
        const rules = question.rules;
        const singleQuestionAnswer = question.answer.answer as QuestionAnswerType;
        const value = singleQuestionAnswer[question.key];
        if (
          (!question.is_required || question.temporaryDisableRequired) &&
          (value === '' || (isArray(value) && value.length === 0))
        )
          continue;
        if (getErrorMessage(value, rules)) {
          errorMessageMap[getQuestionInputName({ question })] = getErrorMessage(value, rules);
        }
        break;
      case TYPES.group_question:
        if (question.fields) {
          for (let j = 0; j < question.fields.length; j++) {
            const fieldItem = question.fields[j];
            const nestedFields = getNestedFields(question);
            if (fieldItem && nestedFields.length !== 0) {
              const fields = nestedFields[0].fields || [];
              for (let n = 0; n < fields.length; n++) {
                const nestedFieldItem = fields[n];
                const nestedFieldAnswer = question.answer.answer as QuestionAnswerType;
                const nestedFieldAnswerResult = nestedFieldAnswer[fieldItem.key] as QuestionAnswerType[];
                for (let rowIdx = 0; rowIdx < nestedFieldAnswerResult.length; rowIdx++) {
                  const nestedFieldItemRules = nestedFieldItem.rules;
                  const nestedFieldAnswerValue = nestedFieldAnswer[nestedFields[0].key] as QuestionAnswerType[];
                  const nestedFieldValue = nestedFieldAnswerValue[rowIdx][nestedFieldItem.key];
                  if (
                    (!nestedFieldItem.is_required || nestedFieldItem.temporaryDisableRequired) &&
                    (nestedFieldValue === '' || (isArray(nestedFieldValue) && nestedFieldValue.length === 0))
                  )
                    continue;
                  if (getErrorMessage(nestedFieldValue, nestedFieldItemRules)) {
                    errorMessageMap[
                      getQuestionInputName({
                        question,
                        fieldKey: fieldItem.key,
                        rowIndex: rowIdx,
                        nestedFieldKey: nestedFieldItem.key,
                      })
                    ] = getErrorMessage(nestedFieldValue, nestedFieldItemRules);
                  }
                }
              }
            }

            // Handle special checks for Address and Pay rate
            const fieldItemRules = fieldItem.rules;
            const checkedQuestionAnswer = question.answer.answer as QuestionAnswerType;
            const checkedValue = checkedQuestionAnswer[fieldItem.key];
            if (fieldItem.key === streetLineOneKey && !checkedValue) {
              if (
                checkedQuestionAnswer[cityKey] ||
                checkedQuestionAnswer[countrySubdivisionCodeKey] ||
                checkedQuestionAnswer[postalCodeKey] ||
                checkedQuestionAnswer[countryCodeKey]
              ) {
                errorMessageMap[getQuestionInputName({ question, fieldKey: fieldItem.key })] = addressNote;
              }
            } else if (
              question.group === emergencyContactKey &&
              !checkedValue &&
              !isEmergencyContactValid(checkedQuestionAnswer)
            ) {
              errorMessageMap[fieldItem.key] = emergencyContactNote;
            } else if (fieldItem.key === amountKey && !checkedValue) {
              if (checkedQuestionAnswer[rateTypeKey]) {
                errorMessageMap[getQuestionInputName({ question, fieldKey: fieldItem.key })] = payrateNote;
              }
            }

            if (
              (!fieldItem.is_required || fieldItem.temporaryDisableRequired) &&
              (checkedValue === '' || (isArray(checkedValue) && checkedValue.length === 0))
            )
              continue;

            if (getErrorMessage(checkedValue, fieldItemRules)) {
              errorMessageMap[getQuestionInputName({ question, fieldKey: fieldItem.key })] = getErrorMessage(
                checkedValue,
                fieldItemRules,
              );
            }
          }
        }
        break;
      default:
    }
  }
  return errorMessageMap;
};
