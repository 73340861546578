import { message } from '@helloworld1812/ws-components';
import _ from 'lodash';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import * as services from '@/services/edit-position';
import * as hiringProcessTemplateService from '@/services/hiring-process-template';
import { getCandidateInfos } from '@/utils';

export const initialState = {
  initialJobDetails: {},
  jobDetails: {},
  hiringProcess: {},
  defaultRejectionTemplate: {},
  rejectionTemplates: undefined,
  rejectionAutomations: undefined,
  jobBoards: [],
  brandDetail: {},
};

export const reducers = {
  updateBrandDetail(state, { payload }) {
    const { brandDetail } = payload;
    return {
      ...state,
      brandDetail,
    };
  },
  resetEditPositionState() {
    return {
      ...initialState,
    };
  },

  updateInitialJobDetails(state, { payload }) {
    const { initialJobDetails } = payload;
    return {
      ...state,
      initialJobDetails: {
        ...state.initialJobDetails,
        ...initialJobDetails,
      },
    };
  },

  updateJobDetails(state, { payload }) {
    const { jobDetails } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        ...jobDetails,
      },
    };
  },

  updatePositionJobDetails(state, { payload }) {
    const { changes } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        ...changes,
      },
    };
  },

  updatePositionTitle(state, { payload }) {
    const { title } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        title,
      },
    };
  },

  updatePositionLocation(state, { payload }) {
    const { location } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        location,
      },
    };
  },

  updatePositionDepartment(state, { payload }) {
    const { department } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        department,
      },
    };
  },

  updatePositionPayAmount(state, { payload }) {
    const { pay_amount } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        pay_amount,
      },
    };
  },

  updatePositionPayFrequency(state, { payload }) {
    const { pay_frequency: payFrequency = null } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        pay_frequency: payFrequency,
      },
    };
  },

  updatePositionJobType(state, { payload }) {
    const { job_types: jobType = null } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        job_types: jobType,
      },
    };
  },

  updatePositionRemoteType(state, { payload }) {
    const { remote_type: remoteType } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        remote_type: remoteType,
      },
    };
  },

  updatePositionLanguage(state, { payload }) {
    const { language } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        language,
      },
    };
  },

  updatePositionOverview(state, { payload }) {
    const { overview } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        overview,
      },
    };
  },

  updatePositionStatus(state, { payload }) {
    const { status } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        status,
      },
      initialJobDetails: {
        ...state.initialJobDetails,
        status,
      },
    };
  },

  updatePositionAccessState(state, { payload }) {
    const { access } = payload;
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        access,
      },
      initialJobDetails: {
        ...state.initialJobDetails,
        access,
      },
    };
  },

  updatePositionDynamicFields(state, { payload }) {
    const { dynamicFields: newField } = payload;
    const dynamicFields = state.jobDetails.position_dynamic_fields || [];

    const foundIndex = dynamicFields.findIndex((field) => field.id === newField.id);
    if (foundIndex !== -1) {
      dynamicFields[foundIndex] = newField;
    } else {
      dynamicFields.push(newField);
    }

    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        position_dynamic_fields: dynamicFields,
      },
    };
  },

  updateAllPositionDynamicFields(state, { payload }) {
    const { dynamicFields } = payload;

    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        position_dynamic_fields: dynamicFields,
      },
    };
  },

  deletePositionDynamicFields(state, { payload }) {
    const { id } = payload;
    const { jobDetails } = state;
    const dynamicFields = jobDetails.position_dynamic_fields.filter((field) => field.id !== id);
    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        position_dynamic_fields: dynamicFields,
      },
    };
  },

  sortPositionDynamicFields(state, { payload }) {
    const { oldIndex, newIndex } = payload;
    const dynamicFields = arrayMove(state.jobDetails.position_dynamic_fields, oldIndex, newIndex);

    return {
      ...state,
      jobDetails: {
        ...state.jobDetails,
        position_dynamic_fields: dynamicFields,
      },
    };
  },

  updateHiringProcess(state, { payload }) {
    const { hiringProcess } = payload;
    return {
      ...state,
      hiringProcess,
    };
  },

  updateDefaultRejectionTemplate(state, { payload }) {
    const { defaultRejectionTemplate } = payload;
    return {
      ...state,
      defaultRejectionTemplate,
    };
  },

  updateRejectionTemplates(state, { payload }) {
    const { rejectionTemplates } = payload;
    return {
      ...state,
      rejectionTemplates,
    };
  },

  appendSingleRejectionTemplate(state, { payload }) {
    const existingRejectionTemplates = state.rejectionTemplates ?? [];
    const rejectionTemplate = payload;

    if (
      existingRejectionTemplates.find(
        (template) => template.rejection_message_template_id === rejectionTemplate.rejection_message_template_id,
      )
    ) {
      const errorMessage = 'Rejection message template has already been taken';
      message.error(errorMessage);
      throw new Error(errorMessage);
    }

    if (rejectionTemplate.is_default) {
      return {
        ...state,
        rejectionTemplates: [
          ...existingRejectionTemplates.map((template) => ({ ...template, is_default: false })),
          rejectionTemplate,
        ],
      };
    } else {
      return {
        ...state,
        rejectionTemplates: [...existingRejectionTemplates, rejectionTemplate],
      };
    }
  },

  updateSingleRejectionTemplate(state, { payload }) {
    const existingRejectionTemplates = state.rejectionTemplates ?? [];
    const rejectionTemplate = payload;

    const indexToChange = existingRejectionTemplates.findIndex(
      (template) => template.rejection_message_template_id === rejectionTemplate.rejection_message_template_id,
    );

    if (rejectionTemplate.is_default) {
      return {
        ...state,
        // Element sequence matters here!
        rejectionTemplates: [
          ...existingRejectionTemplates.slice(0, indexToChange).map((template) => ({ ...template, is_default: false })),
          rejectionTemplate,
          ...existingRejectionTemplates
            .slice(indexToChange + 1)
            .map((template) => ({ ...template, is_default: false })),
        ],
      };
    } else {
      return {
        ...state,
        // Element sequence matters here!
        rejectionTemplates: [
          ...existingRejectionTemplates.slice(0, indexToChange),
          rejectionTemplate,
          ...existingRejectionTemplates.slice(indexToChange + 1),
        ],
      };
    }
  },

  removeSingleRejectionTemplate(state, { payload }) {
    const existingRejectionTemplates = state.rejectionTemplates ?? [];
    const rejectionTemplate = payload;

    return {
      ...state,
      rejectionTemplates: existingRejectionTemplates.filter(
        (template) => template.rejection_message_template_id !== rejectionTemplate.rejection_message_template_id,
      ),
    };
  },

  updateRejectionAutomations(state, { payload }) {
    const { rejectionAutomations } = payload;
    return {
      ...state,
      rejectionAutomations,
    };
  },

  resetRejectionAutomations(state) {
    return {
      ...state,
      rejectionAutomations: undefined,
    };
  },

  resetRejectionTemplatesAndRejectionAutomations(state) {
    return {
      ...state,
      rejectionTemplates: undefined,
      rejectionAutomations: undefined,
    };
  },

  updateJobBoards(state, { payload }) {
    const { jobBoards } = payload;
    return {
      ...state,
      jobBoards,
    };
  },

  updateJobBoard(state, { payload }) {
    const { jobBoard } = payload;

    const targetJobBoardIndex = state.jobBoards.findIndex((board: unknown) => board.id === jobBoard.id);

    // Sequence matters!
    return {
      ...state,
      jobBoards: [
        ...state.jobBoards.slice(0, targetJobBoardIndex),
        jobBoard,
        ...state.jobBoards.slice(targetJobBoardIndex + 1),
      ],
    };
  },

  // Consolidate automation
  updateStage(state, { payload }) {
    const { updatedStage } = payload;
    const positionStages = state.hiringProcess.position_stages.map((item) => {
      if (item.id !== updatedStage.id) return item;
      return updatedStage;
    });
    return {
      ...state,
      hiringProcess: {
        ...state.hiringProcess,
        position_stages: positionStages,
      },
    };
  },

  updateBackgroundCheckStage(state, { payload }) {
    const { id, stageId, backgroundCheck } = payload;
    const targetStage = state.hiringProcess.position_stages?.find((stage) => stage.id === stageId) || {};
    const background_checks = targetStage.background_checks || [];
    const positionStages = state.hiringProcess.position_stages?.map((stage) => {
      if (stage.id === stageId) {
        return {
          ...stage,
          background_checks: id
            ? background_checks?.map((check) => {
                if (check.id === id) {
                  return backgroundCheck;
                } else {
                  return check;
                }
              })
            : [...(background_checks || []), backgroundCheck],
        };
      } else {
        return stage;
      }
    });

    return {
      ...state,
      hiringProcess: {
        ...state.hiringProcess,
        position_stages: positionStages,
      },
    };
  },

  removeBackgroundCheckStagePackage(state, { payload }) {
    const { id, stageId } = payload;
    const targetStage = state.hiringProcess.position_stages?.find((stage) => stage.id === stageId) || {};
    const background_checks = targetStage.background_checks || [];
    const positionStages = state.hiringProcess.position_stages?.map((stage) => {
      if (stage.id === stageId) {
        return {
          ...stage,
          background_checks: background_checks?.filter((check) => check.id !== id),
        };
      } else {
        return stage;
      }
    });

    return {
      ...state,
      hiringProcess: {
        ...state.hiringProcess,
        position_stages: positionStages,
      },
    };
  },
};

export const effects = {
  *fetchPositionJobDetails({ payload }, { put, call }) {
    const { digestkey } = payload;
    const response = yield call(services.fetchPositionJobDetails, { digestkey });
    if (response.error) throw response.error;

    const jobDetails = {
      ...response.data.position,
      pay_frequency: response.data.position.pay_frequency || 'hour',
      job_types: _.isEmpty(response.data.position.job_types) ? ['full_time'] : response.data.position.job_types,
    };
    yield put({
      type: 'updateJobDetails',
      payload: {
        jobDetails,
      },
    });

    const initialJobDetails = _.cloneDeep(jobDetails);
    yield put({
      type: 'updateInitialJobDetails',
      payload: {
        initialJobDetails,
      },
    });

    return jobDetails;
  },

  *fetchHiringProcessTemplateDetail({ payload }, { put, call }) {
    const id = payload;
    const response = yield call(hiringProcessTemplateService.fetchHiringProcessTemplateDetail, id);

    if (response.error) {
      throw response.error;
    }

    const hiringProcess = response.data;
    const firstStage = hiringProcess.position_stages[0];
    const onlineForm = firstStage?.online_form || {};
    const candidateInfos = onlineForm?.candidate_infos;

    onlineForm.candidate_infos = getCandidateInfos(candidateInfos, hiringProcess.language);

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess,
      },
    });

    return hiringProcess;
  },

  *fetchPositionStages({ payload }, { put, call }) {
    const { id } = payload;
    const response = yield call(services.fetchPositionStages, { id });
    if (response.error) throw response.error;

    const hiringProcess = response.data.position;
    const firstStage = hiringProcess.position_stages[0];
    const onlineForm = firstStage?.online_form || {};
    const candidateInfos = onlineForm?.candidate_infos;

    onlineForm.candidate_infos = getCandidateInfos(candidateInfos, hiringProcess.language);

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess,
      },
    });

    return hiringProcess;
  },

  *fetchPreviewPosition({ payload }, { call }) {
    const response = yield call(services.fetchPreviewPosition, payload);
    if (response.error) {
      throw response.error;
    }

    return response.data;
  },

  *fetchDefaultRejectionTemplate({ payload }, { call, put }) {
    const response = yield call(services.getDefaultRejectionTemplate);
    if (response.error) throw response.error;

    const defaultRejectionTemplate = response.data.position_auto_action;

    yield put({
      type: 'updateDefaultRejectionTemplate',
      payload: { defaultRejectionTemplate },
    });
    if (payload.onSuccess) payload.onSuccess(response.data);
  },

  *fetchRejectionTemplates({ payload }, { call, put }) {
    const response = yield call(hiringProcessTemplateService.fetchRejectionTemplates, payload);
    if (response.error) {
      throw response.error;
    }

    const rejectionTemplates = response.data.position_auto_actions;

    yield put({
      type: 'updateRejectionTemplates',
      payload: {
        rejectionTemplates,
      },
    });
  },

  *createRejectionTemplate({ payload }, { call, put, select }) {
    const response = yield call(hiringProcessTemplateService.createRejectionTemplate, payload);
    if (response.error) {
      throw response.error;
    }

    const rejectionTemplate = response.data.position_auto_action;
    let rejectionTemplates = yield select((state) => state.editHiringProcessTemplate.rejectionTemplates);

    if (rejectionTemplate.is_default) {
      rejectionTemplates = rejectionTemplates.map((template) => {
        return { ...template, is_default: false };
      });
    }

    rejectionTemplates = [...rejectionTemplates, rejectionTemplate];

    yield put({
      type: 'updateRejectionTemplates',
      payload: {
        rejectionTemplates,
      },
    });
  },

  *updateRejectionTemplate({ payload }, { call, put, select }) {
    const response = yield call(services.updateRejectionTemplate, payload);
    if (response.error) {
      throw response.error;
    }

    const rejectionTemplate = response.data.position_auto_action;
    let rejectionTemplates = yield select((state) => state.editHiringProcessTemplate.rejectionTemplates);
    rejectionTemplates = rejectionTemplates.map((template) => {
      if (template.id === rejectionTemplate.id) {
        return rejectionTemplate;
      } else if (rejectionTemplate.is_default) {
        return { ...template, is_default: false };
      } else {
        return template;
      }
    });

    yield put({
      type: 'updateRejectionTemplates',
      payload: {
        rejectionTemplates,
      },
    });
  },

  *removeRejectionTemplateFromPosition({ payload }, { call, put, select }) {
    const response = yield call(services.removeRejectionTemplateFromPosition, payload);
    if (response.error) {
      throw response.error;
    }

    let rejectionTemplates = yield select((state) => state.editHiringProcessTemplate.rejectionTemplates);
    rejectionTemplates = rejectionTemplates.filter((template) => template.id !== payload.id);

    yield put({
      type: 'updateRejectionTemplates',
      payload: {
        rejectionTemplates,
      },
    });
  },

  *deleteRejectionTemplate({ payload }, { call, put, select }) {
    const response = yield call(services.deleteRejectionTemplate, payload);
    if (response.error) {
      throw response.error;
    }

    let rejectionTemplates = yield select((state) => state.editHiringProcessTemplate.rejectionTemplates);
    rejectionTemplates = rejectionTemplates.filter((template) => template.id !== payload.id);

    yield put({
      type: 'updateRejectionTemplates',
      payload: {
        rejectionTemplates,
      },
    });
  },

  *fetchRejectionAutomations({ payload }, { call, put }) {
    const response = yield call(hiringProcessTemplateService.fetchRejectionAutomations, payload);
    if (response.error) {
      throw response.error;
    }

    const rejectionAutomations = response.data.position_trigger_actions;

    yield put({
      type: 'updateRejectionAutomations',
      payload: {
        rejectionAutomations,
      },
    });
  },

  *createRejectionAutomation({ payload }, { call, put, select }) {
    const response = yield call(hiringProcessTemplateService.createRejectionAutomation, payload);
    if (response.error) {
      throw response.error;
    }

    const rejectionAutomation = response.data.position_trigger_action;
    let rejectionAutomations = yield select((state) => state.editHiringProcessTemplate.rejectionAutomations);
    rejectionAutomations = [...rejectionAutomations, rejectionAutomation];

    yield put({
      type: 'updateRejectionAutomations',
      payload: {
        rejectionAutomations,
      },
    });
  },

  *updateRejectionAutomation({ payload }, { call, put, select }) {
    const response = yield call(services.updateRejectionAutomation, payload);
    if (response.error) {
      throw response.error;
    }

    const rejectionAutomation = response.data.position_trigger_action;
    let rejectionAutomations = yield select((state) => state.editHiringProcessTemplate.rejectionAutomations);
    rejectionAutomations = rejectionAutomations.map((template) =>
      template.id === rejectionAutomation.id ? rejectionAutomation : template,
    );

    yield put({
      type: 'updateRejectionAutomations',
      payload: {
        rejectionAutomations,
      },
    });
  },

  *deleteRejectionAutomation({ payload }, { call, put, select }) {
    const response = yield call(services.deleteRejectionAutomation, payload);
    if (response.error) {
      throw response.error;
    }

    let rejectionAutomations = yield select((state) => state.editHiringProcessTemplate.rejectionAutomations);
    rejectionAutomations = rejectionAutomations.filter((template) => template.id !== payload.id);

    yield put({
      type: 'updateRejectionAutomations',
      payload: {
        rejectionAutomations,
      },
    });
  },

  *updatePositionStagesOrder({ payload }, { call }) {
    const { onError, ...data } = payload;
    const response = yield call(hiringProcessTemplateService.reorderStages, data);
    if (response.error) {
      if (onError) onError();
      throw response.error;
    }
    // State already pre-updated
  },

  *createPositionStage({ payload }, { put, call, select }) {
    const response = yield call(hiringProcessTemplateService.createHiringProcessTemplateStage, payload);
    if (response.error) throw response.error;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const stages = response.data.hiring_processes;

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });

    return response.data.new_hiring_process.id;
  },

  *updatePositionStage({ payload }, { put, call, select }) {
    const response = yield call(services.updatePositionStage, payload);
    if (response.error) throw response.error;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const updatedStage = response.data.position_stage;
    const stages = hiringProcess.position_stages.map((stage) => (stage.id === updatedStage.id ? updatedStage : stage));

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *deletePositionStage({ payload }, { put, call, select }) {
    const response = yield call(services.deletePositionStage, payload);
    if (response.error) throw response.error;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const stages = hiringProcess.position_stages.filter((stage) => stage.id !== payload.id);

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *updateSchedulingStage({ payload, callback }, { call, put, select }) {
    const { positionStageId, id, scheduling_stage } = payload;
    let response;
    if (!id) {
      response = yield call(hiringProcessTemplateService.createSchedulingStage, {
        position_stage_id: positionStageId,
        scheduling_stage,
      });
    } else {
      response = yield call(hiringProcessTemplateService.updateSchedulingStage, {
        id,
        scheduling_stage,
      });
    }
    if (response.error) throw response.error;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const schedulingStage = response.data.scheduling_stage;
    const stages = hiringProcess.position_stages.map((stage) => {
      const schedulingStages = stage?.scheduling_stages || [];
      if (stage.id === positionStageId) {
        const scStages = id
          ? schedulingStages.map((ele) => (ele.id === id ? schedulingStage : ele))
          : [...schedulingStages, schedulingStage];
        return {
          ...stage,
          scheduling_stage: schedulingStage,
          scheduling_stages: scStages,
        };
      } else {
        return stage;
      }
    });

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *deleteSchedulingStage({ payload }, { call, put, select }) {
    const { id, positiongStageId } = payload;
    const response = yield call(hiringProcessTemplateService.deleteSchedulingStage, {
      id,
      position_stage_id: positiongStageId,
    });
    if (response.error) throw response.error;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const stages = hiringProcess.position_stages.map((stage) => {
      if (stage.id === positiongStageId) {
        const schedulingStages = stage?.scheduling_stages || [];
        return {
          ...stage,
          scheduling_stages: schedulingStages.filter((ele) => ele.id !== id),
        };
      } else {
        return stage;
      }
    });

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *updateStageAutoAction({ payload }, { call, put, select }) {
    const { onError, selectedStageId, ...data } = payload;

    const response = yield call(services.updateStageAutoAction, data);
    if (response.error) {
      if (onError) onError();
      throw response.error;
    }

    const stageAutoAction = response.data.stage_auto_action;
    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const stages = hiringProcess.position_stages.map((stage) => {
      if (stage.id !== selectedStageId) {
        return stage;
      }

      const stageAutoActions = stage.stage_auto_actions.map((action) => {
        return action.id !== payload.id ? action : stageAutoAction;
      });

      return {
        ...stage,
        stage_auto_actions: stageAutoActions,
      };
    });

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *toggleStageAutoAction({ payload }, { call }) {
    const { onError, ...data } = payload;

    const response = yield call(services.toggleStageAutoAction, data);
    if (response.error) {
      if (onError) onError();
      throw response.error;
    }

    // State already pre-updated
  },

  *createStageTriggerAction({ payload }, { put, call, select }) {
    const response = yield call(services.createStageTriggerAction, payload);
    if (response.error) throw response.error;

    const stageTriggerAction = response.data.stage_trigger_action;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const stages = hiringProcess.position_stages.map((stage) => {
      if (stage.id !== payload.stage_trigger_action.position_stage_id) {
        return stage;
      }

      const stageTriggerActions = [...stage.stage_trigger_actions, stageTriggerAction];

      return {
        ...stage,
        stage_trigger_actions: stageTriggerActions,
      };
    });

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *updateStageTriggerAction({ payload }, { put, call, select }) {
    const { position_stage_id: positionStageId, ...data } = payload;
    const response = yield call(services.updateStageTriggerAction, data);
    if (response.error) throw response.error;

    const stageTriggerAction = response.data.stage_trigger_action;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const stages = hiringProcess.position_stages.map((stage) => {
      if (stage.id !== positionStageId) {
        return stage;
      }

      const stageTriggerActions = stage.stage_trigger_actions.map((action) => {
        return action.id !== payload.id ? action : stageTriggerAction;
      });

      return {
        ...stage,
        stage_trigger_actions: stageTriggerActions,
      };
    });

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *deleteStageTriggerAction({ payload }, { put, call, select }) {
    const { position_stage_id: positionStageId, ...data } = payload;
    const response = yield call(services.deleteStageTriggerAction, data);
    if (response.error) throw response.error;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const stages = hiringProcess.position_stages.map((stage) => {
      if (stage.id !== positionStageId) {
        return stage;
      }

      const stageTriggerActions = stage.stage_trigger_actions.filter((action) => {
        return action.id !== payload.id;
      });

      return {
        ...stage,
        stage_trigger_actions: stageTriggerActions,
      };
    });

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *createStageOnboardingAction({ payload }, { put, call, select }) {
    const response = yield call(services.createStageOnboardingAction, payload);
    if (response.error) throw response.error;

    const stageOnboardingAction = response.data.stage_onboarding_action;
    const newPositionStage = response.data.position_stage;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const stages = hiringProcess.position_stages.map((stage) => {
      if (stage.id !== payload.position_stage_id) {
        return { ...stage };
      }

      return {
        ...stage,
        auto_hire: newPositionStage.auto_hire,
        stage_onboarding_action: stageOnboardingAction,
      };
    });

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *updateStageOnboardingAction({ payload }, { put, call, select }) {
    const { position_stage_id: positionStageId, ...data } = payload;
    const response = yield call(services.updateStageOnboardingAction, data);
    if (response.error) throw response.error;

    const stageOnboardingAction = response.data.stage_onboarding_action;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const stages = hiringProcess.position_stages.map((stage) => {
      if (stage.id !== positionStageId) {
        return stage;
      }

      return {
        ...stage,
        stage_onboarding_action: stageOnboardingAction,
      };
    });

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *deleteStageOnboardingAction({ payload }, { put, call, select }) {
    const { position_stage_id: positionStageId, ...data } = payload;
    const response = yield call(services.deleteStageOnboardingAction, data);
    if (response.error) throw response.error;

    const hiringProcess = yield select((state) => state.editHiringProcessTemplate.hiringProcess);
    const stages = hiringProcess.position_stages.map((stage) => {
      if (stage.id !== positionStageId) {
        return stage;
      }

      return {
        ...stage,
        stage_onboarding_action: undefined,
      };
    });

    yield put({
      type: 'updateHiringProcess',
      payload: {
        hiringProcess: {
          ...hiringProcess,
          position_stages: stages,
        },
      },
    });
  },

  *createCandidateInfo({ payload }, { call }) {
    const response = yield call(hiringProcessTemplateService.createCandidateInfo, payload);
    if (response.error) throw response.error;

    return response.data.candidate_info;
  },

  *updateCandidateInfo({ payload }, { call }) {
    const response = yield call(hiringProcessTemplateService.updateCandidateInfo, payload);
    if (response.error) throw response.error;

    return response.data.candidate_info;
  },

  *deleteCandidateInfo({ payload }, { call }) {
    const response = yield call(hiringProcessTemplateService.deleteCandidateInfo, payload);
    if (response.error) throw response.error;
  },

  *reorderQuestions({ payload }, { call }) {
    const { onError, ...data } = payload;
    const response = yield call(hiringProcessTemplateService.reorderQuestions, data);

    if (response.error) {
      if (onError) onError();
      throw response.error;
    }

    return response;
  },

  *createSmartScreening({ payload }, { call }) {
    const response = yield call(hiringProcessTemplateService.createSmartScreening, payload);
    if (response.error) throw response.error;

    return response.data.smart_screening;
  },

  *updateSmartScreening({ payload }, { call }) {
    const response = yield call(hiringProcessTemplateService.updateSmartScreening, payload);
    if (response.error) throw response.error;

    return response.data.smart_screening;
  },
  *deleteSmartScreening({ payload }, { call }) {
    const response = yield call(hiringProcessTemplateService.deleteSmartScreening, payload);
    if (response.error) throw response.error;
  },

  *switchApplicationAutoMove({ payload }, { call }) {
    const response = yield call(services.switchApplicationAutoMove, payload);
    if (response.error) throw response.error;

    return response.data.online_form;
  },

  *switchFormAutoMove({ payload }, { call }) {
    const response = yield call(services.switchFormAutoMove, payload);
    if (response.error) throw response.error;

    return response.data.form;
  },

  *updateQuiz({ payload }, { call }) {
    const response = yield call(services.updateQuiz, payload);
    if (response.error) throw response.error;

    return response.data.online_quiz;
  },

  *fetchJobBoards({ payload }, { call, put }) {
    const response = yield call(services.fetchJobBoards, payload);
    if (response.error) throw response.error;

    const jobBoards = response.data.job_boards;

    yield put({
      type: 'updateJobBoards',
      payload: {
        jobBoards,
      },
    });
  },

  *searchJobBoards({ payload }, { call }) {
    const response = yield call(services.searchJobBoards, payload);
    if (response.error) throw response.error;

    const { job_boards: jobBoards } = response.data;
    return { jobBoards };
  },

  *publishPosition({ payload }, { call, put }) {
    const response = yield call(services.publishPosition, payload);
    if (response.error) throw response.error;

    yield put({
      type: 'updatePositionStatus',
      payload: {
        status: response.data.position.status,
      },
    });
    yield put({
      type: 'app/increaseAvailablePositionCount',
      payload: { increasement: -1 },
    });
  },

  *unpublishPosition({ payload }, { call, put }) {
    const response = yield call(services.unpublishPosition, payload);
    if (response.error) {
      throw response.error;
    }

    yield put({
      type: 'updatePositionStatus',
      payload: {
        status: response.data.position.status,
      },
    });
    yield put({
      type: 'app/increaseAvailablePositionCount',
      payload: { increasement: 1 },
    });
  },

  *updatePositionAccess({ payload }, { call, put }) {
    const response = yield call(services.updatePositionAccess, payload);
    if (response.error) {
      throw response.error;
    }

    yield put({
      type: 'updatePositionAccessState',
      payload: {
        access: response.data.position.access,
      },
    });
  },

  *reorderOffers({ payload }, { call }) {
    const response = yield call(services.reorderOffers, payload);
    if (response.error) {
      if (payload.onError) payload.onError();
      throw response.error;
    }
    if (payload.onSuccess) payload.onSuccess();
  },
};

export default {
  namespace: 'editHiringProcessTemplate',
  state: initialState,
  reducers,
  effects,
};
