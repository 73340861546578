import request from '../utils/request';
import { APIHOST } from '../config';

export async function queryNextUserOnboardingStep(payload) {
  const { user_onboarding_desktop_step } = payload;
  const path = `${APIHOST}/v1/users/next_onboarding_step`;
  return request('POST', path, { user_onboarding_desktop_step });
}

export async function sendSMSDownloadLink(payload) {
  const { phoneNumber: phone, regionNumber } = payload;
  const path = '/v1/users/send_app_link';
  return request('POST', APIHOST + path, { phone, region_number: regionNumber });
}
