import lodash from 'lodash';

import type {
  HiringProcessStage,
  HiringProcessTemplateCandidateInfo,
  HiringProcessTemplateSmartScreening,
} from '@/api';
import constants from '@/utils/constants';
import { hrAxios, hrAxiosInstance } from '@/api';
import * as positionsHelpers from '@/helpers/positions';
import request from '@/utils/request';
import { APIHOST } from '@/config';
import { toFormData } from '@/utils';

export function fetchHiringProcessTemplateDetail(hiringProcessTemplateId: number) {
  return hrAxios({
    method: 'get',
    url: '/v1/hiring_process_templates/{id}',
    urlParams: { id: String(hiringProcessTemplateId) },
  });
}

export function createHiringProcessTemplateStage({
  hiringProcessTemplateId,
  data,
}: {
  hiringProcessTemplateId: number;
  data: { hiring_process: HiringProcessStage; sequences: Array<string | number> };
}) {
  let params = data;

  if (
    params.hiring_process.stage_type === constants.STAGE_TYPE.SCHEDULING &&
    !lodash.isEmpty(params.hiring_process.scheduling_stage?.interviewers)
  ) {
    // data.hiring_process.scheduling_stage.interviewers = positionsHelpers.convertRolesAndStaffsToInterviewers(
    //   data.hiring_process.scheduling_stage.interviewers,
    // );

    params = Object.assign({}, params, {
      ...params,
      hiring_process: {
        ...params.hiring_process,
        scheduling_stage: {
          ...params.hiring_process.scheduling_stage,
          interviewers: positionsHelpers.convertRolesAndStaffsToInterviewers(
            params.hiring_process.scheduling_stage?.interviewers ?? [],
          ),
        },
      },
    });
  }

  return hrAxios({
    method: 'post',
    url: '/v1/hiring_process_templates/{id}/create_stage',
    urlParams: { id: String(hiringProcessTemplateId) },
    data: params,
  });
}

export function reorderStages({
  hiringProcessTemplateId,
  sequences,
}: {
  hiringProcessTemplateId: number;
  sequences: number[];
}) {
  return hrAxios({
    method: 'post',
    url: '/v1/hiring_process_templates/{id}/reorder_stages',
    urlParams: { id: String(hiringProcessTemplateId) },
    data: { sequences },
  });
}

export function createCandidateInfo({
  hiringProcessTemplateId,
  candidate_info,
}: {
  hiringProcessTemplateId: number;
  candidate_info: HiringProcessTemplateCandidateInfo;
}) {
  return hrAxios({
    method: 'post',
    url: '/v1/hiring_process_templates/{id}/candidate_infos',
    urlParams: { id: String(hiringProcessTemplateId) },
    data: { candidate_info },
  });
}

export function updateCandidateInfo({
  hiringProcessTemplateId,
  questionId,
  candidate_info,
}: {
  hiringProcessTemplateId: number;
  questionId: number;
  candidate_info: HiringProcessTemplateCandidateInfo;
}) {
  return hrAxios({
    method: 'put',
    url: '/v1/hiring_process_templates/{hiring_process_template_id}/candidate_infos/{id}',
    urlParams: { hiring_process_template_id: String(hiringProcessTemplateId), id: String(questionId) },
    data: { candidate_info },
  });
}

export function deleteCandidateInfo({
  hiringProcessTemplateId,
  questionId,
}: {
  hiringProcessTemplateId: number;
  questionId: number;
}) {
  return hrAxios({
    method: 'delete',
    url: '/v1/hiring_process_templates/{hiring_process_template_id}/candidate_infos/{id}',
    urlParams: { hiring_process_template_id: String(hiringProcessTemplateId), id: String(questionId) },
  });
}

export function createSmartScreening({
  hiringProcessTemplateId,
  smart_screening,
}: {
  hiringProcessTemplateId: number;
  smart_screening: HiringProcessTemplateSmartScreening;
}) {
  return hrAxios({
    method: 'post',
    url: '/v1/hiring_process_templates/{id}/smart_screenings',
    urlParams: { id: String(hiringProcessTemplateId) },
    data: { smart_screening },
  });
}

export function updateSmartScreening({
  hiringProcessTemplateId,
  questionId,
  smart_screening,
}: {
  hiringProcessTemplateId: number;
  questionId: number;
  smart_screening: HiringProcessTemplateSmartScreening;
}) {
  return hrAxios({
    method: 'put',
    url: '/v1/hiring_process_templates/{hiring_process_template_id}/smart_screenings/{id}',
    urlParams: { hiring_process_template_id: String(hiringProcessTemplateId), id: String(questionId) },
    data: { smart_screening },
  });
}

export function deleteSmartScreening({
  hiringProcessTemplateId,
  questionId,
}: {
  hiringProcessTemplateId: number;
  questionId: number;
}) {
  return hrAxios({
    method: 'delete',
    url: '/v1/hiring_process_templates/{hiring_process_template_id}/smart_screenings/{id}',
    urlParams: { hiring_process_template_id: String(hiringProcessTemplateId), id: String(questionId) },
  });
}

export function reorderQuestions({
  hiringProcessTemplateId,
  candidate_info_sequences,
  smart_screening_sequences,
}: {
  hiringProcessTemplateId: number;
  candidate_info_sequences: Record<number, number>;
  smart_screening_sequences: Record<number, number>;
}) {
  return hrAxiosInstance.request({
    method: 'post',
    url: `/v1/hiring_process_templates/${hiringProcessTemplateId}/online_forms/reorder_questions`,
    data: { candidate_info_sequences, smart_screening_sequences },
  });
}

export function fetchRejectionTemplates({ hiringProcessTemplateId }: { hiringProcessTemplateId: number }) {
  return hrAxios({
    method: 'get',
    url: '/v1/hiring_process_templates/{id}/position_auto_actions',
    urlParams: { id: String(hiringProcessTemplateId) },
  });
}

export function createRejectionTemplate({
  hiringProcessTemplateId,
  position_auto_action,
}: {
  hiringProcessTemplateId: number;
  position_auto_action: {
    is_default: boolean;
    is_email_active: boolean;
    is_sms_active: boolean;
    rejection_message_template_id: number;
  };
}) {
  return hrAxios({
    method: 'post',
    url: '/v1/hiring_process_templates/{id}/position_auto_actions',
    urlParams: { id: String(hiringProcessTemplateId) },
    data: { position_auto_action },
  });
}

export function fetchRejectionAutomations({ hiringProcessTemplateId }: { hiringProcessTemplateId: number }) {
  return hrAxios({
    method: 'get',
    url: '/v1/hiring_process_templates/{id}/position_trigger_actions',
    urlParams: { id: String(hiringProcessTemplateId) },
  });
}

export function createRejectionAutomation({
  hiringProcessTemplateId,
  position_trigger_action,
}: {
  hiringProcessTemplateId: number;
  position_trigger_action: {
    action: string;
    is_active: boolean;
    position_auto_action_id: number;
    unit: string;
    value: number;
  };
}) {
  return hrAxios({
    method: 'post',
    url: '/v1/hiring_process_templates/{id}/position_trigger_actions',
    urlParams: { id: String(hiringProcessTemplateId) },
    data: { position_trigger_action },
  });
}

export function createSchedulingStage({ position_stage_id, scheduling_stage }) {
  return hrAxios({
    method: 'post',
    url: '/v1/position_stages/{position_stage_id}/scheduling_stages',
    urlParams: { position_stage_id },
    data: { scheduling_stage },
  });
}

export function updateSchedulingStage({ id, scheduling_stage }) {
  return hrAxios({
    method: 'put',
    url: '/v1/scheduling_stages/{id}',
    urlParams: { id },
    data: { scheduling_stage },
  });
}

export function deleteSchedulingStage({ id, position_stage_id }) {
  return hrAxios({
    method: 'delete',
    url: '/v1/position_stages/{position_stage_id}/scheduling_stages/{id}',
    urlParams: { id, position_stage_id },
  });
}

export async function fetchStageAutoActionList() {
  const path = '/v1/stage_auto_actions/list';
  return request('GET', APIHOST + path);
}

export async function fetchDefaultSigning() {
  const path = '/v1/signings/default';
  return request('GET', APIHOST + path);
}

export async function fetchAllTemplates() {
  const path = '/v1/positions/position_all_templates';
  return request('GET', APIHOST + path);
}

export async function fetchPositionJobDetails(data) {
  const { id } = data;
  const path = '/v1/positions/description_template';
  return request('GET', APIHOST + path, { id });
}

export async function fetchPreviewPosition(data) {
  const path = '/v1/positions/create_cache';
  return request('POST', APIHOST + path, data);
}

export async function fetchPositionStages(data) {
  const { id } = data;
  const path = '/v1/positions/position_template';
  return request('GET', APIHOST + path, { id });
}

export async function fetchJobBoards(data) {
  const { countryCode } = data;
  const path = '/v1/company_job_boards';
  return request('GET', APIHOST + path, { country_code: countryCode });
}

export async function searchJobBoards(data) {
  const { query } = data;
  const path = '/v1/company_job_boards/search';
  return request('GET', APIHOST + path, { query });
}

export async function createPosition(data) {
  if (lodash.isArray(data?.position_stages)) {
    for (const stage of data.position_stages) {
      if (
        stage.stage_type === constants.STAGE_TYPE.SCHEDULING &&
        !lodash.isEmpty(stage.scheduling_stage.interviewers)
      ) {
        stage.scheduling_stage.interviewers = positionsHelpers.convertRolesAndStaffsToInterviewers(
          stage.scheduling_stage.interviewers,
        );
      }
    }
  }

  const path = '/v1/positions/create_full';
  return request('POST', APIHOST + path, toFormData(data));
}

export async function fetchUserLocationsWithDepartments() {
  const path = '/v1/company_staffs/locations_with_departments';
  return request('GET', APIHOST + path);
}

export async function createStage(params) {
  const { id, data } = params;
  const path = `/v1/hiring_process_templates/${id}/create_stage`;
  return request('POST', APIHOST + path, data);
}

export async function updateStage(params) {
  const { id, data } = params;
  const path = `/v1/position_stages/${id}`;
  return request('PUT', APIHOST + path, data);
}

export async function deleteStage(params) {
  const { id } = params;
  const path = `/v1/position_stages/${id}`;
  return request('DELETE', APIHOST + path);
}

export async function addFormQuestions(params) {
  const { id, data } = params;
  const path = `/v1/forms/${id}/form_questions`;
  return request('POST', APIHOST + path, data);
}

export async function updateFormQuestions(params) {
  const { id, formId, data } = params;
  const path = `/v1/form_questions/${formId}/form_questions/${id}`;
  return request('PUT', APIHOST + path, data);
}

export async function deleteFormQuestions(params) {
  const { id, formId } = params;
  const path = `/v1/form_questions/${formId}/form_questions/${id}`;
  return request('DELETE', APIHOST + path);
}

export async function addQuizQuestion(params) {
  const { id, data } = params;
  const path = `/v1/online_quizzes/${id}/quiz_question`;
  return request('POST', APIHOST + path, data);
}

export async function updateQuizQuestion(params) {
  const { id, quizId, data } = params;
  const path = `/v1/online_quizzes/${quizId}/quiz_question/${id}`;
  return request('PUT', APIHOST + path, data);
}

export async function deleteQuizQuestion(params) {
  const { id, quizId } = params;
  const path = `/v1/online_quizzes/${quizId}/quiz_question/${id}`;
  return request('DELETE', APIHOST + path);
}

export async function addCandidateInfos(params) {
  const { id, data } = params;
  const path = `/v1/hiring_process_templates/${id}/candidate_infos`;
  return request('POST', APIHOST + path, data);
}

export async function updateCandidateInfos(params) {
  const { id, hiringProcessTemplateId, data } = params;
  const path = `/v1/hiring_process_templates/${hiringProcessTemplateId}/candidate_infos/${id}`;
  return request('PUT', APIHOST + path, data);
}

export async function deleteCandidateInfos(params) {
  const { id, hiringProcessTemplateId } = params;
  const path = `/v1/hiring_process_templates/${hiringProcessTemplateId}/candidate_infos/${id}`;
  return request('DELETE', APIHOST + path);
}

export async function addSmartScreenings(params) {
  const { id, data } = params;
  const path = `/v1/hiring_process_templates/${id}/smart_screenings`;
  return request('POST', APIHOST + path, data);
}

export async function updateSmartScreenings(params) {
  const { id, hiringProcessTemplateId, data } = params;
  const path = `/v1/hiring_process_templates/${hiringProcessTemplateId}/smart_screenings/${id}`;
  return request('PUT', APIHOST + path, data);
}

export async function deleteSmartScreenings(params) {
  const { id, hiringProcessTemplateId } = params;
  const path = `/v1/hiring_process_templates/${hiringProcessTemplateId}/smart_screenings/${id}`;
  return request('DELETE', APIHOST + path);
}

export async function getSchedulingStages(params) {
  const { id } = params;
  const path = `/v1/position_stages/${id}/scheduling_stages`;
  return request('GET', APIHOST + path);
}

export async function addSchedulingStages(params) {
  const { id, data } = params;
  const path = `/v1/position_stages/${id}/scheduling_stages`;
  return request('POST', APIHOST + path, data);
}

export async function updateSchedulingStages(params) {
  const { id, data } = params;
  const path = `/v1/scheduling_stages/${id}`;
  return request('PUT', APIHOST + path, data);
}

export async function deleteSchedulingStages(params) {
  const { id, positionStageId } = params;
  const path = `/v1/position_stages/${positionStageId}/scheduling_stages/${id}`;
  return request('DELETE', APIHOST + path);
}

export async function addBackgroundChecks(params) {
  const { id, data } = params;
  const path = `/v1/position_stages/${id}/background_checks`;
  return request('POST', APIHOST + path, data);
}

export async function updateBackgroundChecks(params) {
  const { id, positionStageId, data } = params;
  const path = `/v1/position_stages/${positionStageId}/background_checks/${id}`;
  return request('PUT', APIHOST + path, data);
}

export async function deleteBackgroundChecks(params) {
  const { id, positionStageId } = params;
  const path = `/v1/position_stages/${positionStageId}/background_checks/${id}`;
  return request('DELETE', APIHOST + path);
}
