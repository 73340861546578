import _ from 'lodash';

import { EmployerTaxInfo } from '@/types/app/models/app';
import { ComparedTaxInfos, CreateNewAccountStep, WotcConnectPage, WotcModal } from '@/types/app/models/wotc';

import * as services from '../../services/wotc';

const initialState: WotcModal = {
  paneOpen: false,
  showExitConfirm: false,
  progress: {
    currentPage: WotcConnectPage.ACCOUNT_CONNECT_OPTIONS,
    allSteps: [],
    completedSteps: [],
    currentStep: CreateNewAccountStep.ACCOUNT_INFO,
  },
  formFields: {},
  data: {},
  updateTaxEntityModalOpen: false,
  taxInfosToUpdate: [],
  einLocationMap: {},
  missingAddressModalOpen: false,
};

const reducers = {
  fetchCompanySuccess(state: WotcModal, { payload }: unknown): WotcModal {
    return { ...state, wotcCompany: payload };
  },
  startSetup(state: WotcModal): WotcModal {
    return { ...state, paneOpen: true };
  },
  exitSetup(state: WotcModal): WotcModal {
    let needConfirm;
    switch (state.progress.currentPage) {
      case WotcConnectPage.NEW_ACCOUNT_CREATE:
        needConfirm = !_.isEmpty(state.formFields);
        break;
      case WotcConnectPage.ACCESS_TOKEN_CONNECT:
        needConfirm = state.accessToken;
        break;
      default:
        needConfirm = false;
    }
    if (needConfirm && !state.showExitConfirm) {
      return { ...state, showExitConfirm: true };
    }
    return initialState;
  },
  cancelExit(state: WotcModal): WotcModal {
    return { ...state, showExitConfirm: false };
  },
  updateProgress(state: WotcModal, { payload }: unknown): WotcModal {
    return { ...state, progress: { ...state.progress, ...payload } };
  },
  proceedToNextStep(state: WotcModal) {
    const {
      progress: { allSteps, currentStep, completedSteps },
    } = state;
    const currentStepIndex = allSteps.findIndex((step) => step === currentStep);
    const nextStep =
      currentStepIndex === -1 || currentStepIndex + 1 >= allSteps.length ? currentStep : allSteps[currentStepIndex + 1];

    return {
      ...state,
      progress: {
        ...state.progress,
        currentStep: nextStep,
        completedSteps: _.uniq([...completedSteps, currentStep]),
      },
    };
  },
  formFieldsChanged(state: WotcModal, { payload }: unknown): WotcModal {
    return {
      ...state,
      formFields: { ...state.formFields, ...payload },
      progress: {
        ...state.progress,
        completedSteps: state.progress.completedSteps.filter((step) => step !== state.progress.currentStep),
      },
    };
  },
  fieldsValidateError(state: WotcModal): WotcModal {
    return {
      ...state,
      progress: {
        ...state.progress,
        completedSteps: state.progress.completedSteps.filter((step) => step !== state.progress.currentStep),
      },
    };
  },
  fieldsValidateSuccess(state: WotcModal, { payload }: unknown): WotcModal {
    return {
      ...state,
      data: { ...state.data, ...payload },
      progress: {
        ...state.progress,
        completedSteps: _.uniq([...state.progress.completedSteps, state.progress.currentStep]),
        editingStep: undefined,
      },
    };
  },
  taxInfoSelected(state: WotcModal, { payload }: unknown): WotcModal {
    return {
      ...state,
      data: { ...state.data, taxInfoData: payload },
      progress: {
        ...state.progress,
        completedSteps: _.uniq([...state.progress.completedSteps, state.progress.currentStep]),
      },
    };
  },
  editStep(state: WotcModal, { payload }: unknown): WotcModal {
    return {
      ...state,
      progress: {
        ...state.progress,
        currentStep: payload,
        completedSteps: state.progress.completedSteps.filter((step) => step !== payload),
        editingStep: payload,
      },
    };
  },
  accessTokenChanged(state: WotcModal, { payload }: unknown): WotcModal {
    return { ...state, accessToken: payload };
  },
  compareTaxInfosSuccess(state: WotcModal, { payload }: unknown): WotcModal {
    return { ...state, comparedTaxInfos: payload, errorMessage: undefined };
  },
  compareTaxInfosError(state: WotcModal, { payload }: unknown): WotcModal {
    return { ...state, comparedTaxInfos: undefined, errorMessage: payload };
  },
  preSyncAccessTokenSuccess(state: WotcModal, { payload }: unknown): WotcModal {
    return { ...state, taxInfosToUpdate: payload };
  },
  unlinkCompanySuccess(): WotcModal {
    return initialState;
  },
  selectTaxInfo(state: WotcModal, { payload }: unknown): WotcModal {
    return { ...state, taxInfosToUpdate: [...state.taxInfosToUpdate, payload] };
  },
  unselectTaxInfo(state: WotcModal, { payload }: unknown): WotcModal {
    if (state.comparedTaxInfos?.missing_tax_infos_in_ws.some((ti) => ti.ein === payload.ein)) {
      const newEinLocationMap = { ...state.einLocationMap };
      delete newEinLocationMap[payload.ein];

      return {
        ...state,
        taxInfosToUpdate: state.taxInfosToUpdate.filter((ti) => ti.ein !== payload.ein),
        einLocationMap: newEinLocationMap,
      };
    }
    return { ...state, taxInfosToUpdate: state.taxInfosToUpdate.filter((ti) => ti.ein !== payload.ein) };
  },
  selectLocationForEin(state: WotcModal, { payload }: unknown): WotcModal {
    const { ein, locationId } = payload;

    const oldLocationsArray = state.einLocationMap[ein];
    const newLocationsArray = oldLocationsArray ? [...oldLocationsArray, locationId] : [locationId];

    return { ...state, einLocationMap: { ...state.einLocationMap, [ein]: newLocationsArray } };
  },
  unselectLocationForEin(state: WotcModal, { payload }: unknown): WotcModal {
    const { ein, locationId } = payload;

    const oldLocationsArray = state.einLocationMap[ein];
    const newLocationsArray = oldLocationsArray.filter((id) => id !== locationId);

    if (newLocationsArray.length === 0) {
      const newEinLocationMap = { ...state.einLocationMap };
      delete newEinLocationMap[ein];
      return { ...state, einLocationMap: newEinLocationMap };
    }
    return { ...state, einLocationMap: { ...state.einLocationMap, [ein]: newLocationsArray } };
  },
  openUpdateTaxEntityModal(state: WotcModal): WotcModal {
    return { ...state, updateTaxEntityModalOpen: true };
  },
  closeUpdateTaxEntityModal(state: WotcModal): WotcModal {
    return { ...state, updateTaxEntityModalOpen: false, taxInfosToUpdate: [], einLocationMap: {} };
  },
  openMissingAddressModal(state: WotcModal): WotcModal {
    return { ...state, missingAddressModalOpen: true };
  },
  closeMissingAddressModal(state: WotcModal): WotcModal {
    return { ...state, missingAddressModalOpen: false };
  },
};

function* compareTaxInfos({ payload }, { call, put }) {
  const response = yield call(services.compareTaxInfos, payload);
  if (response.error) {
    // WOTC.com access token is invalid.
    if (response.error.errorCode === 60013) {
      yield put({
        type: 'compareTaxInfosError',
        payload: "We couldn't confirm the access token. Please double check and try again.",
      });
      return;
    } else {
      throw response.error;
    }
  }

  yield put({ type: 'compareTaxInfosSuccess', payload: response.data });
  return response.data;
}

function* confirmMissingAddress({ payload }, { put, take, race }) {
  const { toPush }: { toPush: EmployerTaxInfo[] } = payload;
  const isPushingTaxEntityWithMissingAddress = toPush.some(
    (ti) => ti.id && !ti.wotc_sub_entity_id && (!ti.city || !ti.state || !ti.zip_code),
  );
  if (!isPushingTaxEntityWithMissingAddress) return true;

  yield put({ type: 'openMissingAddressModal' });

  const { confirmed } = yield race({
    confirmed: take('wotc/actionConfirmed'),
    canceled: take('wotc/actionCanceled'),
  });

  yield put({ type: 'closeMissingAddressModal' });

  return !!confirmed;
}

const effects = {
  *fetchCompany(__, { call, put }) {
    const response = yield call(services.getCompany);
    if (response.error) throw response.error;

    yield put({ type: 'fetchCompanySuccess', payload: response.data.wotc_company });
  },
  *createCompany({ payload }, { call, put, select, take, race }) {
    let requestPayload = payload;
    const { access_token } = payload;

    if (access_token) {
      const wotcState: WotcModal = yield select((state) => state.wotc);
      const { taxInfosToUpdate, comparedTaxInfos } = wotcState;

      let classifiedTaxEntities = {};
      if (comparedTaxInfos) {
        classifiedTaxEntities = classifyTaxEntities(taxInfosToUpdate, comparedTaxInfos);
      }

      if (classifiedTaxEntities.to_push?.length > 0) {
        const confirmed: boolean = yield call(
          confirmMissingAddress,
          { payload: { toPush: taxInfosToUpdate.filter((ti) => classifiedTaxEntities.to_push.includes(ti.id)) } },
          { put, take, race },
        );
        if (!confirmed) return;
      }

      requestPayload = { ...payload, ...classifiedTaxEntities };
    }

    const response = yield call(services.createCompany, requestPayload);
    if (response.error) throw response.error;

    yield put({ type: 'updateProgress', payload: { currentPage: WotcConnectPage.CONFIRMATION } });
  },
  compareTaxInfos,
  *preSyncAccessToken({ payload }, { call, put }) {
    const comparedTaxInfos: ComparedTaxInfos = yield call(compareTaxInfos, { payload }, { call, put });

    if (comparedTaxInfos) {
      yield put({
        type: 'preSyncAccessTokenSuccess',
        payload: [
          ...comparedTaxInfos.missing_tax_infos_in_wotc,
          ...comparedTaxInfos.missing_tax_infos_in_ws,
          ...comparedTaxInfos.shared_tax_infos,
        ],
      });
    }
  },
  *updateTaxEntities(__, { call, put, select, take, race }) {
    const wotcState: WotcModal = yield select((state) => state.wotc);
    const { taxInfosToUpdate, einLocationMap, comparedTaxInfos } = wotcState;

    if (taxInfosToUpdate.length === 0) {
      yield put({ type: 'closeUpdateTaxEntityModal' });
      return;
    }

    let classifiedTaxEntities = {};
    if (comparedTaxInfos) {
      classifiedTaxEntities = classifyTaxEntities(taxInfosToUpdate, comparedTaxInfos);
    }

    if (classifiedTaxEntities.to_push?.length > 0) {
      const confirmed: boolean = yield call(
        confirmMissingAddress,
        { payload: { toPush: taxInfosToUpdate.filter((ti) => classifiedTaxEntities.to_push.includes(ti.id)) } },
        { put, take, race },
      );
      if (!confirmed) return;
    }

    const payload = { ...classifiedTaxEntities, ein_locations_map: einLocationMap };
    const response = yield call(services.updateTaxEntities, payload);
    if (response.error) throw response.error;

    yield put({ type: 'closeUpdateTaxEntityModal' });
  },
  *unlinkCompany(__, { call, put }) {
    const response = yield call(services.unlinkCompany);
    if (response.error) throw response.error;

    yield put({ type: 'unlinkCompanySuccess' });
  },
};

const classifyTaxEntities = (taxInfosToUpdate: EmployerTaxInfo[], comparedTaxInfos: ComparedTaxInfos) => {
  const to_push: number[] = [];
  const to_pull: string[] = [];
  const to_relink: number[] = [];
  const to_unlink: number[] = [];

  taxInfosToUpdate.forEach((info) => {
    if (info.id === null) {
      to_pull.push(info.ein);
    } else if (info.wotc_sub_entity_id === null) {
      const alreadyExistInWotc = comparedTaxInfos?.shared_tax_infos.some((ti) => ti.id === info.id);

      if (alreadyExistInWotc) {
        to_relink.push(info.id);
      } else {
        to_push.push(info.id);
      }
    } else {
      to_unlink.push(info.id);
    }
  });

  return {
    to_push,
    to_pull,
    to_relink,
    to_unlink,
  };
};

export default {
  namespace: 'wotc',
  state: initialState,
  reducers,
  effects,
};
