import { message } from 'antd';
import _ from 'lodash';

export const customMessage = _.cloneDeep(message);

const calculateDuration = (msg) => {
  let completeErrorMsg = msg;
  if (typeof msg === 'object') {
    if (msg instanceof Array) {
      completeErrorMsg = msg.join(' ');
    } else {
      const errorMessageArray = Object.values(msg);
      completeErrorMsg = errorMessageArray.join(' ');
    }
  }
  const numWords = completeErrorMsg.split(' ').length;
  const duration = numWords / 3.33 + 1;
  if (duration < 3) {
    return 3;
  } else {
    return duration;
  }
};

customMessage.error = (content, duration, onClose) => {
  if (!duration) {
    const newDuration = calculateDuration(content);
    return message.error(content, newDuration, onClose);
  }
  return message.error(content, duration, onClose);
};
