import { DatePicker } from 'antd2';
import styled from 'styled-components';

import COLOR from './color';

export default styled(DatePicker).attrs((props) => ({ size: 'large' }))`
  width: 100%;

  &:hover {
    .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: ${COLOR.GRAY};
    }
  }

  .ant-calendar-picker-icon {
    margin-top: -10px;
    right: 16px;
    width: 20px;
    height: 20px;

    &:after {
      font-size: 20px;
      color: ${COLOR.GRAY};
    }
  }

  .ant-calendar-picker-clear {
    margin-top: -10px;
    right: 16px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
  }
`;
