import React from 'react';
import { LocaleProvider } from 'antd2';
import enUS from 'antd2/es/locale-provider/en_US';
import { ConfigProvider } from 'antd';
import antd4_EN_US from 'antd/es/locale/en_US';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WsThemeProvider } from '@helloworld1812/app-global-components';

import { getSubAppContainer } from '@/utils/mfe';

const queryClient = new QueryClient();

export function NonBusinessApp({ children }: { children: React.ReactNode }) {
  return (
    <WsThemeProvider>
      <ConfigProvider
        locale={antd4_EN_US}
        iconPrefixCls="ant4icon"
        {...(window.__POWERED_BY_QIANKUN__ && { getPopupContainer: () => getSubAppContainer() })}
      >
        <LocaleProvider locale={enUS}>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </LocaleProvider>
      </ConfigProvider>
    </WsThemeProvider>
  );
}
