import { arrayMoveImmutable as arrayMove } from 'array-move';
import { CancelToken } from 'axios';

import * as services from '../services/employeeOnboarding';
import constants from '../utils/constants';

// namespace: 'employeeOnboarding',
const { ONBOARDING_MODULE_TYPES: MODULE_TYPES } = constants;

export const getDefaultSettingOfModule = (moduleConfiguration) => {
  const { type: moduleType, name: moduleName } = moduleConfiguration;
  let settings;
  switch (moduleType) {
    case MODULE_TYPES.W4: {
      settings = {
        name: 'Form W-4',
        module_type: moduleType,
        config: {
          employer_name: undefined,
          employer_address: undefined,
          employer_ein: undefined,
        },
        actions: [
          {
            action_type: 'w4_form',
            assignees: [],
            name: 'Fill Form W-4',
            sequence: 0,
          },
        ],
        description: 'Send employee to ADP',
      };
      break;
    }
    case MODULE_TYPES.I9: {
      settings = {
        name: 'Form I-9',
        module_type: moduleType,
        config: {
          employer_name: undefined,
          employer_address: undefined,
          employer_ein: undefined,
          skip_prepopulate: false,
        },
        actions: [
          {
            action_type: 'employee_fill_i9_form',
            assignees: [],
            name: 'Employee completes section 1',
            sequence: 0,
          },
          {
            action_type: 'hr_fill_i9_form',
            assignees: [],
            name: 'Employer completes section 2',
            sequence: 1,
          },
        ],
        description: 'Send employee to ADP',
        has_active_e_verify_client: moduleConfiguration.has_active_e_verify_client,
      };
      break;
    }
    case MODULE_TYPES.SIGN_DOCUMENT: {
      settings = {
        name: moduleName,
        module_type: moduleType,
        actions: [],
        signing_sequences: false,
      };
      break;
    }
    case MODULE_TYPES.ADP:
      settings = {
        name: moduleName,
        module_type: moduleType,
        actions: [
          {
            action_type: 'employee_fill_adp_form',
            name: 'Fill ADP form',
            sequence: 0,
            form: {
              questions: [],
            },
          },
          {
            action_type: 'hr_fill_adp_form',
            name: 'Fill ADP form',
            sequence: 1,
            form: {
              questions: [],
            },
            assignees: [],
          },
          {
            action_type: 'review_and_send_to_adp',
            sequence: 2,
            assignees: [],
          },
        ],
      };
      break;
    case MODULE_TYPES.COLLECT_INFO:
      settings = {
        name: moduleName,
        module_type: moduleType,
        actions: [
          {
            assignees: [],
            description: 'Manager will review the collected information',
            name: 'Review information',
            sequence: 1,
            type: 'review_form',
          },
          {
            description: 'Employee complete form',
            sequence: 0,
            name: 'Enter information',
            type: 'employee_fill_form',
            form: {
              questions: [],
            },
            assignees: [],
          },
        ],
      };
      break;
    case MODULE_TYPES.WOTC:
      settings = {
        name: moduleName,
        module_type: moduleType,
        actions: [
          {
            description: 'Employee complete form',
            name: 'Fill WOTC form',
            sequence: 0,
            type: 'employee_fill_wotc_form',
            form: {
              questions: [],
            },
            assignees: [],
          },
        ],
        wotc_company: moduleConfiguration.wotc_company,
      };
      break;
    case MODULE_TYPES.BASIC_INFORMATION:
      settings = {
        name: moduleName,
        module_type: moduleType,
        sequence: 0,
        actions: [
          {
            description: 'HR complete form',
            name: 'Enter employee information',
            assignees: [],
            sequence: 0,
            action_type: constants.ONBOARDING_ACTION_TYPES.HR_FILL_BASIC_FORM,
          },
          {
            description: 'Employee complete form',
            name: 'Enter basic information',
            sequence: 1,
            action_type: constants.ONBOARDING_ACTION_TYPES.EMPLOYEE_FILL_BASIC_FORM,
            form: {
              questions: [],
            },
            assignees: [],
          },
        ],
      };
      break;
    case MODULE_TYPES.DEPOSIT:
      settings = {
        name: moduleName,
        module_type: moduleType,
        actions: [
          {
            description: 'Employee will fill the form',
            name: 'Direct deposit',
            sequence: 0,
            action_type: 'direct_deposit_form',
            form: {
              questions: [],
            },
            assignees: [],
          },
        ],
      };
      break;
    case 'us_state_tax':
      settings = {
        description:
          'Automatically selects the correct US state tax form for new employee to electronically complete so that you can withhold the correct state income tax from their pay.',
        name: moduleName,
        module_type: moduleType,
        actions: [
          {
            description: 'Employee fills in US state tax form and signs the document.',
            name: 'Fill state tax form',
            sequence: 0,
            action_type: 'employee_fill_us_state_tax_form',
          },
        ],
      };
      break;
    case MODULE_TYPES.PAYCHEX:
      settings = {
        name: moduleName,
        module_type: moduleType,
        config: {
          default_company_id: undefined,
        },
        actions: [
          {
            assignees: [],
            description: 'HR will review the collected information',
            name: 'Complete Paychex form',
            sequence: 1,
            action_type: 'hr_fill_paychex_form',
            form: {
              questions: [],
            },
          },
          {
            description: 'Employee complete form',
            sequence: 0,
            name: 'Paychex form for employee',
            action_type: 'employee_fill_paychex_form',
            form: {
              questions: [],
            },
          },
          {
            description: 'Reviewer will fill verify and send the details to Paychex',
            sequence: 2,
            name: 'Review Paychex form',
            action_type: 'review_and_send_to_paychex',
            assignees: [],
          },
        ],
      };
      break;
    case MODULE_TYPES.COMPANY_DOCUMENTS:
      settings = {
        name: moduleName,
        module_type: moduleType,
        company_document_templates: [],
        actions: [
          {
            description: 'HR fills and signs form',
            name: 'Complete document',
            assignees: [],
            sequence: 0,
            action_type: constants.ONBOARDING_ACTION_TYPES.HR_SIGN_COMPANY_DOCUMENTS,
          },
          {
            description: 'Employee fills and signs form',
            name: 'Complete document',
            sequence: 1,
            action_type: constants.ONBOARDING_ACTION_TYPES.EMPLOYEE_SIGN_COMPANY_DOCUMENTS,
            assignees: [],
          },
        ],
      };
      break;
    default:
      break;
  }
  return settings;
};

export const defaultNewProcessData = {
  id: undefined,
  name: '',
  modules: [getDefaultSettingOfModule({ type: 'basic_information', name: 'Basic Information' })],
};

export const PROCESS_STEP = {
  configure_name: 'configure process name',
  select_module: 'select module',
  module_intro: 'module introduction',
  configure_module: 'configure module',
};

export const initialProcessState = {
  currentStep: PROCESS_STEP.configure_name,
  selectedModuleSettings: undefined,
  selectedConfiguration: undefined,
  selectedVersionModules: [],
};

export const initialState = {
  // New processes related data
  newProcessData: {
    ...defaultNewProcessData,
  },
  // All available modules
  moduleList: [],
  moduleConfigurationInfo: {
    // [moduleType]: {...moduleDetails}
  },
  processState: initialProcessState,

  // dashboard page
  dashboardInfo: {
    overview: {
      not_started: 0,
      need_attention: 0,
      in_progress: 0,
      completed: 0,
    },
    employees: [],
  },
  dashboardPagination: {
    total: 1,
    current: 1,
    pageSize: 10,
  },

  // processes page
  allProcessList: [], // This key store all the processes and contain minimal data
  onboardingProcessList: [], // This key is for processes page table
  processListSearch: {
    page: 1,
    perPage: 10,
    sortKey: 'name',
    sortOrder: 'ascend',
  },
  onboardingProcessListPagination: {
    total: 1,
    current: 1,
    pageSize: 10,
  },

  // Employees page
  employeeList: [],
  employeeListDefaultSearch: {
    page: 1,
    perPage: 10,
    positions: [],
    processes: [],
    status: ['not_started', 'in_progress', 'completed', 'suspended'],
    sortKey: 'hired_date',
    sortOrder: 'descend',
  },
  employeeListPagination: {
    total: 1,
    current: 1,
    pageSize: 10,
  },
  adpTemplates: [],
  inValidTemplates: [],
  adpTemplateConfiguration: {},
  externalConnectors: [],

  startOnboarding: {
    isOpen: false,
    employeeId: '',
    changeProcess: false,
    isProcessReady: false,
  },
  viewOnboarding: {
    isOpen: false,
    employeeId: '',
    processId: '',
  },
  modules: {
    adp: {
      answeredQuestions: {},
      hrReadyForReview: {},
      hrSubmittedAnswers: {},
      reviewerUnsavedAnswers: 0,
    },
    paychex: {
      answeredQuestions: {},
      hrReadyForReview: {},
      hrSubmittedAnswers: {},
      reviewerUnsavedAnswers: 0,
    },
  },
  employeeReview: {
    isOpen: false,
    pathName: '',
    previousPathName: '',
  },
  sendOnboardingLink: {
    isSending: false,
    isOpen: false,
  },
  employeeOnboardingProcess: null,
};

export interface startOnboardingModel {
  isOpen: boolean;
  employeeId: string;
  changeProcess: boolean;
  isProcessReady: boolean;
}

export interface ViewOnboardingModel {
  isOpen: boolean;
  employeeId: string;
  processId: string;
  answeredQuestions: unknown;
  pathName: string;
  previousPathName: string;
}

export const reducers = {
  resetProcessState(state) {
    return {
      ...state,
      processState: initialProcessState,
    };
  },

  startOnboardingUpdated(state, { payload }) {
    return {
      ...state,
      startOnboarding: {
        ...state.startOnboarding,
        ...payload,
      },
    };
  },

  viewOnboardingUpdated(state, { payload }) {
    return {
      ...state,
      viewOnboarding: {
        ...state.viewOnboarding,
        ...payload,
      },
    };
  },

  answeredQuestionsUpdated(state, { payload }) {
    return {
      ...state,
      modules: {
        ...state.modules,
        [payload.moduleType]: {
          ...state.modules[payload.moduleType],
          answeredQuestions: {
            ...state.modules[payload.moduleType].answeredQuestions,
            ...payload.answeredQuestions,
          },
        },
      },
    };
  },

  hrReadyForReviewUpdated(state, { payload }) {
    return {
      ...state,
      modules: {
        ...state.modules,
        [payload.moduleType]: {
          ...state.modules[payload.moduleType],
          hrReadyForReview: {
            ...state.modules[payload.moduleType].hrReadyForReview,
            ...payload.hrReadyForReview,
          },
        },
      },
    };
  },

  hrSubmittedAnswers(state, { payload }) {
    return {
      ...state,
      modules: {
        ...state.modules,
        [payload.moduleType]: {
          ...state.modules[payload.moduleType],
          hrSubmittedAnswers: {
            ...state.modules[payload.moduleType].hrSubmittedAnswers,
            ...payload,
          },
        },
      },
    };
  },

  reviewerUnsavedAnswers(state, { payload }) {
    return {
      ...state,
      modules: {
        ...state.modules,
        [payload.moduleType]: {
          ...state.modules[payload.moduleType],
          reviewerUnsavedAnswers: payload.answersNumber,
        },
      },
    };
  },

  employeeReviewUpdated(state, { payload }) {
    return {
      ...state,
      employeeReview: {
        ...state.employeeReview,
        ...payload,
      },
    };
  },

  updateProcessState(state, { payload }) {
    const { currentStep, selectedModuleSettings, selectedConfiguration, selectedVersionModules } = payload;
    const {
      currentStep: originalCurrentStep,
      selectedModuleSettings: originalSelectedModuleSettings,
      selectedConfiguration: originalSelectedConfiguration,
      selectedVersionModules: originalSelectedVersionModules,
    } = state.processState;
    return {
      ...state,
      processState: {
        currentStep: currentStep || originalCurrentStep,
        selectedModuleSettings: selectedModuleSettings || originalSelectedModuleSettings,
        selectedConfiguration: selectedConfiguration || originalSelectedConfiguration,
        selectedVersionModules: selectedVersionModules || originalSelectedVersionModules,
      },
    };
  },

  getOnboardingModuleDetailsByTypeSuccess(state, { payload }) {
    const { onboarding_module: moduleDetails } = payload;
    const moduleConfigurationInfo = {
      ...state.moduleConfigurationInfo,
      [moduleDetails.type]: moduleDetails,
    };
    return {
      ...state,
      moduleConfigurationInfo,
    };
  },

  fetchOnboardingProcessesSuccess(state, { payload }) {
    const { pagination, processes, search } = payload;
    return {
      ...state,
      processListSearch: search,
      onboardingProcessList: processes,
      onboardingProcessListPagination: {
        ...state.onboardingProcessListPagination,
        ...pagination,
      },
    };
  },

  updateNewProcess(state, { payload }) {
    return {
      ...state,
      newProcessData: {
        ...state.newProcessData,
        ...payload,
      },
    };
  },

  updateNewProcessAddModule(state, { payload }) {
    const { moduleSettings } = payload;
    const modules = [
      ...state.newProcessData.modules,
      {
        ...moduleSettings,
        sequence: state.newProcessData.modules.length,
      },
    ];
    return {
      ...state,
      newProcessData: {
        ...state.newProcessData,
        modules,
      },
    };
  },

  updateNewProcessUpdateModule(state, { payload }) {
    const { moduleSettings } = payload;
    const modules = state.newProcessData.modules.map((m) => {
      if (m.sequence !== moduleSettings.sequence) return m;
      return { ...moduleSettings };
    });
    return {
      ...state,
      newProcessData: {
        ...state.newProcessData,
        modules,
      },
    };
  },

  updateNewProcessReorderModules(state, { payload }) {
    const { oldIndex, newIndex } = payload;
    let modules = arrayMove(state.newProcessData.modules, oldIndex, newIndex);
    modules = modules.map((m, idx) => ({ ...m, sequence: idx }));
    return {
      ...state,
      newProcessData: {
        ...state.newProcessData,
        modules,
      },
    };
  },

  updateNewProcessDeleteModule(state, { payload }) {
    const { deleteIdx } = payload;
    let modules = state.newProcessData.modules.filter((item, idx) => {
      return idx !== deleteIdx;
    });
    // recalculate the sequence after deleting
    modules = modules.map((m, idx) => ({ ...m, sequence: idx }));
    return {
      ...state,
      newProcessData: {
        ...state.newProcessData,
        modules,
      },
    };
  },

  fetchOnboardingSummarySuccess(state, { payload }) {
    const { overview, company_onboarding_processes: companyOnboardingProcesses } = payload;
    return {
      ...state,
      dashboardInfo: {
        ...state.dashboardInfo,
        overview,
      },
      allProcessList: companyOnboardingProcesses,
    };
  },

  fetchDashboardEmployeeListSuccess(state, { payload }) {
    const {
      search,
      responseData,
      responseData: { employees, overview },
    } = payload;

    // fetch employee list for dashboard
    const dashboardInfo = {
      ...state.dashboardInfo,
      overview,
      // // update different status employee as needed
      employees,
    };

    const dashboardPagination = {
      total: responseData.total_pages * search.perPage,
      current: responseData.current_page,
      pageSize: search.perPage != null ? Number(search.perPage) : 10,
    };

    return {
      ...state,
      dashboardInfo,
      dashboardPagination,
    };
  },

  fetchEmployeeListSuccess(state, { payload }) {
    const { responseData, search } = payload;
    const { employees } = responseData;
    const pagination = {
      total: responseData.total_pages * search.perPage,
      current: responseData.current_page,
      pageSize: search.perPage != null ? Number(search.perPage) : 10,
    };

    return {
      ...state,
      employeeList: employees,
      employeeListPagination: pagination,
    };
  },

  employeeOnboardingProcessUpdated(state, { payload }) {
    const { employee_onboarding_process } = payload;
    return {
      ...state,
      employeeOnboardingProcess: employee_onboarding_process,
    };
  },

  updateSendOnboardingLink(state, { payload }) {
    return {
      ...state,
      sendOnboardingLink: {
        ...state.sendOnboardingLink,
        ...payload,
      },
    };
  },
};

export const effects = {
  *fetchOnboardingProcesses({ payload = {} }, { call, put }) {
    const response = yield call(services.fetchOnboardingProcesses, payload);
    if (response.error) throw response.error;
    const { perPage, page, sortKey, sortOrder } = payload;
    const {
      current_page: currentPage,
      total_pages: totalPages,
      company_onboarding_processes: processes,
    } = response.data;

    const pagination = {
      total: totalPages * perPage,
      current: currentPage,
      pageSize: perPage != null ? Number(perPage) : 10,
    };

    const search = {
      page,
      perPage,
      sortKey,
      sortOrder,
    };

    yield put({
      type: 'fetchOnboardingProcessesSuccess',
      payload: {
        pagination,
        processes,
        search,
      },
    });
  },

  *createAdpFormSubmission({ payload }, { put, call }) {
    const response = yield call(services.createFormSubmission, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'hrSubmittedAnswers',
      payload: { moduleType: 'adp', [payload.action_id]: response.data },
    });
    return response.data;
  },

  *sendAdpFormToADP({ payload }, { call }) {
    const response = yield call(services.sendAdpFormtoADP, payload);
    if (response.error) throw response.error;
    return response;
  },

  *fetchOnboardingSummary({ payload }, { put, call }) {
    const response = yield call(services.fetchOnboardingSummary, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'fetchOnboardingSummarySuccess',
      payload: response.data,
    });
    return response.data;
  },

  fetchEmployeeList: [
    function* fn({ payload }, { call, cancelled }) {
      const source = CancelToken.source();
      try {
        const response = yield call(services.fetchEmployeeList, payload, source.token);
        if (response.error) throw response.error;
        return response.data;
      } finally {
        if (yield cancelled()) {
          source.cancel('Operation canceled by the consecutive request.');
        }
      }
    },
    { type: 'takeLatest' },
  ],

  *getOnboardingModuleDetailsByType({ payload }, { call, put }) {
    const response = yield call(services.getOnboardingModuleDetails, payload);

    if (response.error) throw response.error;
    yield put({
      type: 'getOnboardingModuleDetailsByTypeSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *startOnboardingProcess({ payload }, { call }) {
    const response = yield call(services.startOnboardingProcess, payload);
    if (response.error) throw response.error;
  },

  *createEmployeeOnboardingProcess({ payload }, { call }) {
    const response = yield call(services.createEmployeeOnboardingProcess, payload);
    if (response.error) throw response.error;
    return response.data;
  },

  *fetchEmployeeOnboardingProcess({ payload }, { call, put }) {
    const response = yield call(services.fetchEmployeeOnboardingProcess, payload);
    if (response.error) throw response.error;
    const { data } = response;
    yield put({
      type: 'employeeOnboardingProcessUpdated',
      payload: data,
    });
    return data;
  },

  *fetchEmployeeAndEmployeeOnboardingProcess({ payload }, { call, put }) {
    const { data: processData, error: processError } = yield call(services.fetchEmployeeOnboardingProcess, payload);
    if (processError) throw processError;
    yield put({
      type: 'employeeOnboardingProcessUpdated',
      payload: processData,
    });

    const { data: employeeData, error: employeeError } = yield call(services.browseEmployee, {
      employeeId: processData.employee_onboarding_process.employee_id,
    });
    if (employeeError) throw employeeError;
    yield put({
      type: 'employeeProfile/browseEmployeeSuccess',
      payload: employeeData,
    });
  },

  *fetchEmployeeOnboardingProcessDetails({ payload }, { call, put }) {
    const { data, error } = yield call(services.fetchEmployeeOnboardingProcess, payload);
    if (error) throw error;

    yield put({
      type: 'employeeOnboardingProcessUpdated',
      payload: data,
    });

    return data;
  },

  *updateEmployeeOnboardingModule({ payload }, { call }) {
    const response = yield call(services.updateEmployeeOnboardingModule, payload);
    if (response.error) throw response.error;

    return response.data;
  },

  *signCompanyDocument({ payload }, { call }) {
    const response = yield call(services.signCompanyDocument, payload);
    if (response.error) throw response.error;
    return response;
  },

  *changeOnboardingProcess({ payload }, { call }) {
    const response = yield call(services.changeOnboardingProcess, payload);
    if (response.error) throw response.error;
    return response.data;
  },

  *stopOnboardingProcess({ payload }, { call }) {
    const response = yield call(services.stopOnboardingProcess, payload);
    if (response.error) throw response.error;
  },

  *getUsStateTaxFormSubmission({ payload }, { call }) {
    const response = yield call(services.getUsStateTaxFormSubmission, payload);
    if (response.error) throw response.error;
    return response.data;
  },

  *wotcReadyCheck({ payload }, { call, put }) {
    const response = yield call(services.wotcReadyCheck, payload);
    if (response.error) throw response.error;

    if (response.data.wotc_ready) {
      yield put({ type: 'startOnboardingUpdated', payload: { isProcessReady: true, error: undefined } });
    } else {
      const associatedLocation = response.data.associated_location;

      yield put({
        type: 'startOnboardingUpdated',
        payload: {
          isProcessReady: false,
          error: {
            type: 'wotc',
            associatedLocation,
          },
        },
      });
    }
  },

  *uscisIntegrationReadyCheck({ payload }, { call, put }) {
    const response = yield call(services.uscisReadyCheck, payload);
    if (response.error) throw response.error;
    if (response.data.uscis_ready) {
      yield put({ type: 'startOnboardingUpdated', payload: { isProcessReady: true, error: undefined } });
    } else {
      const {
        employer_id_number: employerIdNumber,
        associated_location: associatedLocation,
        error_code: errorCode,
      } = response.data;

      yield put({
        type: 'startOnboardingUpdated',
        payload: {
          isProcessReady: false,
          error: {
            type: 'uscis',
            associatedLocation,
            errorCode,
            employerIdNumber,
          },
        },
      });
    }
  },

  *sendOnboardingLink({ payload }, { call, put }) {
    yield put({ type: 'updateSendOnboardingLink', payload: { isSending: true } });
    const response = yield call(services.sendOnboardingLink, payload);
    yield put({ type: 'updateSendOnboardingLink', payload: { isSending: false, isOpen: !!response.error } });
    if (response.error) throw response.error;
  },
};

export default {
  namespace: 'employeeOnboarding',
  state: initialState,
  reducers,
  effects,
};
