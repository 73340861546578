import { Form, Input, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';

import { QuestionProps } from '../../common/form/question';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

function AdpFileNumber(props: QuestionProps) {
  const { value, onChange, ...restProps } = props;
  const [radioValue, setRadioValue] = useState<number>(value ? 1 : 0);

  useEffect(() => {
    if (radioValue === 0) {
      onChange({ name: props.name, value: '' });
    }
    if (props.onSetTemporaryDisableRequired) {
      props.onSetTemporaryDisableRequired({ name: props.name, checked: radioValue === 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioValue]);

  return (
    <FormItem>
      <Space direction="vertical">
        <RadioGroup
          onChange={(e) => {
            setRadioValue(e.target.value);
            if (e.target.value === 0) {
              props.onChange({ name: props.name, value: '' });
            }
            props.onSetTemporaryDisableRequired({ name: props.name, checked: e.target.value === 0 });
          }}
          value={radioValue}
        >
          <Space direction="vertical">
            <Radio value={0}>Automatically assign the file number</Radio>
            <Radio value={1}>Manually assign file number</Radio>
          </Space>
        </RadioGroup>
        {radioValue === 1 && (
          <Input
            placeholder="Enter file number"
            value={value}
            onChange={(e) => {
              props.onChange({ name: props.name, value: e.target.value });
            }}
            {...restProps}
          />
        )}
      </Space>
    </FormItem>
  );
}

export default AdpFileNumber;
