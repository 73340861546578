import React from 'react';
import _ from 'lodash';
import { FlexBox, FlexBoxProps } from '@helloworld1812/ws-components';
import { WsThemeProvider } from '@helloworld1812/app-global-components';

import type { Reflexbox3FlexBoxProps, FlexBoxResponsiveProps } from './flexbox.types';

/**
 * According to existing usages, reflexbox3-style repsonsive values (in array format) only exist in
 * these props.
 */
const RESPONSIVE_FLEX_BOX_PROP_KEYS = [
  'w',
  'm',
  'mx',
  'my',
  'mt',
  'mb',
  'ml',
  'mr',
  'p',
  'px',
  'py',
  'pt',
  'pb',
  'pl',
  'pr',
] as const;

const OTHER_FLEX_BOX_PROP_KEYS = ['wrap', 'column', 'auto', 'order', 'align', 'justify'] as const;

const UNSUPPORTED_FLEX_BOX_PROP_KEYS = [
  'margin',
  'marginTop',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginX',
  'marginY',
  'marginExceptTop',
  'marginExceptBottom',
  'marginExceptLeft',
  'marginExceptRight',
  'mxt',
  'mxb',
  'mxl',
  'mxr',
  'padding',
  'paddingTop',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingX',
  'paddingY',
  'paddingExceptTop',
  'paddingExceptBottom',
  'paddingExceptLeft',
  'paddingExceptRight',
  'pxt',
  'pxb',
  'pxl',
  'pxr',
  'flex',
  'display',
  'position',
  'top',
  'bottom',
  'left',
  'right',
  'width',
  'height',
  'minWidth',
  'minHeight',
  'maxWidth',
  'maxHeight',
  'h',
  'color',
  'backgroundColor',
  'bgColor',
  'borderColor',
  'zIndex',
  'boxShadow',
  'elevation',
  'borderRadius',
  'cornerRadius',
  'borderWidth',
  'borderTop',
  'borderBottom',
  'borderLeft',
  'borderRight',
  'borderStyle',
  'border',
  'flexDirection',
  'flexWrap',
  'alignItems',
  'justifyContent',
  'shrink',
  'grow',
  'basis',
  'alignSelf',
  'gap',
  'direction',
] as const;

/**
 * The replacement of `Box` of `reflexbox3`,
 * which is actually a wrapper of `FlexBox` of `@helloworld1812/ws-components`,
 * surrounded by `AppThemeProvider` when `theme` is not accessible.
 * It has the exact same API as `Box` of `reflexbox3`.
 *
 * @deprecated Use `Box` from `@helloworld1812/ws-components` instead.
 *
 * @todo Replace all antd v2 popover like components (Modal, Popover, Dropdown, etc.) with those
 * in antd v4, so that we don't need to wrap `AppThemeProvider` around `FlexBox`.
 */
export function Box(props: Reflexbox3FlexBoxProps) {
  const responsiveFlexBoxProps = _.pick(props, RESPONSIVE_FLEX_BOX_PROP_KEYS);

  const processedResponsiveFlexBoxProps: FlexBoxResponsiveProps = {};

  for (const key of Object.keys(responsiveFlexBoxProps) as Array<keyof typeof responsiveFlexBoxProps>) {
    const value = responsiveFlexBoxProps[key];

    if (Array.isArray(value)) {
      let processedValues = value;

      // As for prop `w`, `FlexBox` of `ws-components` treats value `1` differently from `reflexbox3`,
      // the former is `8px` while the latter is `100%`.
      if (key === 'w') {
        processedValues = value.map((v) => (v === 1 ? '100%' : v));
      }

      Object.assign(
        processedResponsiveFlexBoxProps,
        // According to existing usages, only need to support 2 breakpoints at most
        {
          [key]: {
            ...(processedValues[0] != null && { phoneSmall: processedValues[0] }),
            ...(processedValues[1] != null && { tablet: processedValues[1] }),
          },
        },
      );
    } else {
      let processedValue = value;

      if (key === 'w') {
        processedValue = value === 1 ? '100%' : value;
      }

      Object.assign(processedResponsiveFlexBoxProps, { [key]: processedValue });
    }
  }

  const otherFlexBoxProps = _.pick(props, OTHER_FLEX_BOX_PROP_KEYS);

  // `display: block` is default on MSEdge and Chrome
  const displayProp: Pick<FlexBoxProps, 'display'> = props.flex ? { display: 'flex' } : { display: 'block' };

  // Filter out all `FlexBox` props to get all other props we should pass down, e.g. `className`, `children`.
  const otherSupportedPropKeys = _.difference(
    Object.keys(props),
    // List all property keys here since we need a value not a type (`keyof`)
    [...RESPONSIVE_FLEX_BOX_PROP_KEYS, ...OTHER_FLEX_BOX_PROP_KEYS, ...UNSUPPORTED_FLEX_BOX_PROP_KEYS],
  ) as unknown as keyof Omit<
    Reflexbox3FlexBoxProps,
    | (typeof RESPONSIVE_FLEX_BOX_PROP_KEYS)[number]
    | (typeof OTHER_FLEX_BOX_PROP_KEYS)[number]
    | (typeof UNSUPPORTED_FLEX_BOX_PROP_KEYS)[number]
  >;

  const otherSupportedProps = _.pick(props, otherSupportedPropKeys);

  const flexbox = (
    <FlexBox {...processedResponsiveFlexBoxProps} {...otherFlexBoxProps} {...otherSupportedProps} {...displayProp} />
  );

  return <WsThemeProvider>{flexbox}</WsThemeProvider>;
}
