import * as services from '../../services/employee-onboarding/employees';

export const initialState = {};

export const reducers = {};

export const effects = {
  *downloadEmployeesData({ payload }, { call }) {
    const response = yield call(services.downloadEmployeesData, payload);
    if (response.error) throw response.error;
    if (payload.onSuccess) payload.onSuccess(response.data);
  },
};

export default {
  namespace: 'employees',
  state: initialState,
  reducers,
  effects,
};
