import React from 'react';
import { IconHappyFace } from '@helloworld1812/ws-icons';
import { notification } from 'antd';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import * as services from '../../services/employeeOnboarding';

//  namespace: 'onboardingProcessSettings',
const initialState = {
  processSettings: {},
  // The moduleSettings here is the settings of the currently selected module in the Sidebar
  moduleSettings: undefined,
};

const reducers = {
  fetchProcessSettingsSuccess(state, { payload }) {
    const { company_onboarding_process: processSettings } = payload;
    return {
      ...state,
      processSettings,
    };
  },

  updateModuleSettings(state, { payload }) {
    const { company_onboarding_module: moduleSettings } = payload;
    return {
      ...state,
      moduleSettings,
    };
  },

  createModuleSuccess(state, { payload }) {
    const {
      company_onboarding_module: module,
      company_onboarding_process: { status: processStatus },
    } = payload;
    // add new module to processSettings.company_onboading_modules arr
    const modules = [...state.processSettings.company_onboarding_modules, module];
    return {
      ...state,
      processSettings: {
        ...state.processSettings,
        company_onboarding_modules: modules,
        status: processStatus,
      },
    };
  },

  updateModuleSuccess(state, { payload }) {
    const {
      company_onboarding_module: updatedModule,
      company_onboarding_process: { status: processStatus },
    } = payload;
    const modules = state.processSettings.company_onboarding_modules.map((m) => {
      if (m.id !== updatedModule.id) return m;
      return updatedModule;
    });

    return {
      ...state,
      processSettings: {
        ...state.processSettings,
        status: processStatus,
        company_onboarding_modules: modules,
      },
    };
  },

  deleteModuleSuccess(state, { payload }) {
    const { company_onboarding_module_id: deletedId } = payload;
    // add new module to processSettings.company_onboading_modules arr
    const modules = state.processSettings.company_onboarding_modules.filter((m) => m.id !== deletedId);
    return {
      ...state,
      processSettings: {
        ...state.processSettings,
        company_onboarding_modules: modules,
      },
    };
  },

  reorderModulesSuccess(state, { payload }) {
    const { company_onboarding_process: processSettings } = payload;
    return {
      ...state,
      processSettings,
    };
  },

  updateProcessSuccess(state, { payload }) {
    const { company_onboarding_process: processSettings } = payload;
    return {
      ...state,
      processSettings,
    };
  },

  updateActionSuccess(state, { payload }) {
    const { company_onboarding_action: updatedAction, company_onboarding_process: processStatusInfo } = payload;
    const actions = state.moduleSettings.company_onboarding_actions.map((a) => {
      if (a.id !== updatedAction.id) return a;
      return updatedAction;
    });
    const {
      status: processStatus,
      company_onboarding_module: { status: moduleStatus },
    } = processStatusInfo;
    const moduleSettings = {
      ...state.moduleSettings,
      company_onboarding_actions: actions,
      status: moduleStatus,
    };
    const companyOnboardingModules = state.processSettings.company_onboarding_modules.map((item) => {
      return {
        ...item,
        ...(moduleSettings.id === item.id && { status: moduleSettings.status }),
      };
    });
    return {
      ...state,
      processSettings: {
        ...state.processSettings,
        company_onboarding_modules: companyOnboardingModules,
        status: processStatus,
      },
      moduleSettings,
    };
  },

  reorderModulesLocal(state, { payload }) {
    const { oldIndex, newIndex } = payload;
    const modules = arrayMove(state.processSettings.company_onboarding_modules, oldIndex, newIndex);
    return {
      ...state,
      processSettings: {
        ...state.processSettings,
        company_onboarding_modules: modules,
      },
    };
  },

  revertReorderModules(state, { payload }) {
    const { oldIndex, newIndex } = payload;
    const modules = arrayMove(state.processSettings.company_onboarding_modules, newIndex, oldIndex);
    return {
      ...state,
      processSettings: {
        ...state.processSettings,
        company_onboarding_modules: modules,
      },
    };
  },

  updateProcessAndModuleStatus(state, { payload }) {
    const {
      company_onboarding_process: {
        company_onboarding_module: { status: moduleStatus },
        status: processStatus,
      },
    } = payload;

    let processSettings = {
      ...state.processSettings,
      status: processStatus,
    };

    const moduleSettings = {
      ...state.moduleSettings,
      ...(moduleStatus && { status: moduleStatus }),
    };

    if (moduleStatus) {
      const modules = processSettings.company_onboarding_modules.map((m) => {
        if (m.id !== moduleSettings.id) return m;
        return {
          ...m,
          status: moduleStatus,
        };
      });
      processSettings = {
        ...processSettings,
        company_onboarding_modules: modules,
      };
    }

    return {
      ...state,
      processSettings,
      moduleSettings,
    };
  },
};

const effects = {
  *fetchProcessSettings({ payload }, { call, put }) {
    const response = yield call(services.fetchOnboardingProcessSettings, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'fetchProcessSettingsSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *fetchModuleSettings({ payload }, { call, put }) {
    const response = yield call(services.fetchOnboardingModuleSettings, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateModuleSettings',
      payload: response.data,
    });
    return response.data;
  },

  *createModule({ payload }, { call, put }) {
    const response = yield call(services.createOnboardingModule, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'createModuleSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *createModules({ payload }, { call, put }) {
    const response = yield call(services.createOnboardingModules, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'fetchProcessSettingsSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *deleteModule({ payload }, { call, put }) {
    const response = yield call(services.deleteOnboardingModule, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'deleteModuleSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *updateModule({ payload }, { call, put }) {
    const response = yield call(services.updateOnboardingModule, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateModuleSuccess',
      payload: response.data,
    });
    yield put({
      type: 'updateModuleSettings',
      payload: response.data,
    });
    return response.data;
  },

  *reorderModules({ payload }, { call, put }) {
    yield put({
      type: 'reorderModulesLocal',
      payload,
    });
    const response = yield call(services.reorderOnboardingModules, payload);
    if (response.error) {
      yield put({
        type: 'revertReorderModules',
        payload,
      });
      throw response.error;
    }

    notification.open({
      message: 'Reorder module successfully',
      icon: <IconHappyFace color="blue300" size="large" />,
    });

    yield put({
      type: 'reorderModulesSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *resetModuleActions({ payload }, { call }) {
    const response = yield call(services.resetOnboardingModuleActions, payload);
    if (response.error) throw response.error;
  },

  *updateProcess({ payload }, { call, put }) {
    const response = yield call(services.updateOnboardingProcess, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateProcessSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *updateAction({ payload }, { call, put }) {
    const response = yield call(services.updateOnboardingAction, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateActionSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *reorderSigners({ payload }, { call, put }) {
    const response = yield call(services.reorderSigners, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateModuleSettings',
      payload: response.data,
    });
  },

  *deleteAction({ payload }, { call }) {
    const response = yield call(services.deleteAction, payload);
    if (response.error) throw response.error;
  },

  *reorderActions({ payload }, { call }) {
    const response = yield call(services.reorderActions, payload);
    if (response.error) throw response.error;
  },

  *prepareHSTemplate({ payload }, { call }) {
    const response = yield call(services.prepareHSTemplate, payload);
    if (response.error) throw response.error;
    return response.data;
  },

  *getHSTemplate({ payload }, { call }) {
    const response = yield call(services.getHSTemplate, payload);
    if (response.error) throw response.error;
    return response.data;
  },

  *createOnboardingAction({ payload }, { call, put }) {
    const response = yield call(services.createOnboardingAction, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'onboardingProcessSettings/updateProcessAndModuleStatus',
      payload: response.data,
    });
    return response.data;
  },

  *deleteProcess({ payload }, { call }) {
    const response = yield call(services.deleteProcess, payload);
    if (response.error) throw response.error;
  },

  *duplicateProcess({ payload }, { call }) {
    const response = yield call(services.duplicateProcess, payload);
    if (response.error) throw response.error;
  },

  *downloadDocumentBundleable({ payload }, { call }) {
    const response = yield call(services.downloadDocumentBundleable, payload);

    const { data, headers } = response;

    if (response.error) {
      throw response.error;
    }
    return { data, headers };
  },

  *checkDocumentBundleableStatus({ payload }, { call }) {
    const response = yield call(services.checkDocumentBundleableStatus, payload);

    const { data, headers } = response;

    if (response.error) {
      throw response.error;
    }
    return { data, headers };
  },

  *resendOnboardingModule({ payload }, { call }) {
    const response = yield call(services.resendOnboardingModules, payload);
    if (response.error) throw response.error;
  },

  *resendActions({ payload }, { call }) {
    const response = yield call(services.resendOnboardingActions, payload);
    if (response.error) throw response.error;
  },

  *updateExternalConnectorRemoteConfig({ payload }, { call, put }) {
    const connectorRemoteConfig = yield call(services.updateExternalConnectorRemoteConfig, payload);

    if (connectorRemoteConfig.error) throw connectorRemoteConfig.error;

    const processData = yield call(services.fetchOnboardingProcessSettings, {
      processId: connectorRemoteConfig.data.company_onboarding_process_id,
    });

    yield put({
      type: 'fetchProcessSettingsSuccess',
      payload: processData.data,
    });

    return connectorRemoteConfig.data;
  },
};

export default {
  namespace: 'onboardingProcessSettings',
  state: initialState,
  reducers,
  effects,
};
