export const AnalyticsGroup = (company) => {
  analytics.group(`${company.id}`, {
    company_name: company.name,
    plan: company.tier,
    status: company.status,
    brands: company.brands.map(({ id, industry, location_counts, name }) => ({
      id,
      industry,
      location_counts,
      name,
    })),
    total_locations: company.brands.map((b) => b.location_counts).reduce((next, prev) => next + prev),
  });
};
