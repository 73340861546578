import request from '../utils/request';
import { APIHOST } from '../config';

export async function fetchBrandsWithLocationGroups() {
  const path = '/v1/company_staffs/brands_with_location_groups';
  return request('GET', APIHOST + path);
}

export async function fetchLocationsOfBrand(params = {}) {
  const { nickname, filter_group: filterGroup = '' } = params;
  const path = `/v1/brands/${nickname}/locations`;
  const data = { filter_group: filterGroup };
  return request('GET', APIHOST + path, data);
}
