import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Text, FlexBox } from '@helloworld1812/ws-components';
import { Select, Space, Form } from 'antd';

import { FormWrap } from '@/components/design-system-style-wrapper/antd-form-wrapper';
import { QuestionOptionType } from '@/routes/employee-onboarding/process-setup/interface';

import { QuestionProps } from '../../common/form/question';

const FormItem = Form.Item;

const Option = Select.Option;

export const DeleteButton = styled.button`
  appearance: none;
  cursor: pointer;
  border: none;
  color: #f05220;
`;

const QuestionErrorMessage = styled.div`
  color: red;
`;

interface StateProps {
  nameCodeValue: string;
}

type ADPHomeOrgUnitProps = Omit<QuestionProps, 'type'>;
class ADP_HomeOrgUnit extends Component<ADPHomeOrgUnitProps, StateProps> {
  constructor(props: QuestionProps) {
    super(props);
    const nameCodeField = props.question.fields[1];

    this.state = {
      nameCodeValue: props.value[nameCodeField.key],
    };
  }

  dependsOnCompanyCode(value: string) {
    return value === 'Department' || value === 'HomeDepartment' || value === 'HomeCostNumber';
  }

  filteredCodeList(value: string, codeList: QuestionOptionType[]) {
    if (!(value === 'HomeDepartment' || value === 'HomeCostNumber')) {
      return codeList;
    }

    const { companyCode } = this.props;
    return codeList.filter((code) => code.foreignKey === companyCode);
  }

  render() {
    const { question, value, title, errorMessage, deletable, onDeleteRow, disabled, companyCode } = this.props;

    const typeCodeField = question.fields[0];
    const nameCodeField = question.fields[1];

    const typeCodeValue = value[typeCodeField.key];
    let typeCodeKey = typeCodeValue;
    if (!nameCodeField.code_lists[typeCodeValue]) {
      typeCodeKey = nameCodeField.key.replace('/nameCode/code', `/nameCode/${typeCodeValue}`);
    }

    const { nameCodeValue } = this.state;

    return (
      <FormWrap>
        <FlexBox alignItems="center" style={{ marginBottom: '5px' }}>
          <FlexBox justifyContent="space-between" alignItems="center" width={1}>
            <Text>{title}</Text>
          </FlexBox>
        </FlexBox>
        <FormItem>
          <Space>
            <Select
              disabled={disabled}
              value={typeCodeValue}
              onChange={(changedValue) => {
                this.props.onChange({
                  name: this.props.name,
                  value: changedValue,
                  fieldKey: typeCodeField.key,
                });
                this.setState({ nameCodeValue: '' });
              }}
            >
              <Option disabled value="" style={{ display: 'none' }} key={0}>
                Select from dropdown
              </Option>
              {typeCodeField.options &&
                typeCodeField.options.map((option: QuestionOptionType, idx: number) => {
                  return (
                    <Option value={option.code || option.codeValue} key={idx}>
                      {option.name || option.shortName || option.longName || option.valueDescription}
                    </Option>
                  );
                })}
            </Select>

            {typeCodeValue && (
              <Select
                disabled={disabled}
                value={nameCodeValue}
                onChange={(changedValue) => {
                  this.setState({ nameCodeValue: changedValue });
                  this.props.onChange({
                    name: this.props.name,
                    value: changedValue,
                    fieldKey: nameCodeField.key,
                  });
                }}
                style={{
                  display: this.dependsOnCompanyCode(typeCodeValue) && !companyCode ? 'none' : 'block',
                }}
              >
                {typeCodeValue === 'Department' && companyCode && (
                  <Fragment>
                    <Option disabled value="" style={{ display: 'none' }} key={0}>
                      Select from dropdown
                    </Option>
                    {nameCodeField.code_lists[typeCodeValue] &&
                      nameCodeField.code_lists[typeCodeValue][companyCode] &&
                      nameCodeField.code_lists[typeCodeValue][companyCode].map(
                        (option: QuestionOptionType, idx: number) => {
                          return (
                            <Option value={option.codeValue} key={idx}>
                              {option.shortName || option.longName || option.valueDescription}
                            </Option>
                          );
                        },
                      )}
                  </Fragment>
                )}
                {typeCodeValue !== 'Department' && (
                  <Fragment>
                    <Option disabled value="" style={{ display: 'none' }} key={0}>
                      select from dropdown
                    </Option>

                    {nameCodeField.code_lists[typeCodeKey] &&
                      this.filteredCodeList(typeCodeValue, nameCodeField.code_lists[typeCodeKey]).map((option, idx) => {
                        return (
                          <Option value={option.codeValue} key={idx}>
                            {option.shortName || option.longName || option.valueDescription}
                          </Option>
                        );
                      })}
                  </Fragment>
                )}
              </Select>
            )}
            {deletable && (
              <DeleteButton
                onClick={(e) => {
                  e.preventDefault();
                  if (onDeleteRow) {
                    onDeleteRow();
                  }
                }}
              >
                Remove
              </DeleteButton>
            )}
            {this.dependsOnCompanyCode(typeCodeValue) && !companyCode && (
              <QuestionErrorMessage>Please select Company Code first.</QuestionErrorMessage>
            )}
            {errorMessage && <QuestionErrorMessage>{errorMessage}</QuestionErrorMessage>}
          </Space>
        </FormItem>
      </FormWrap>
    );
  }
}

export default ADP_HomeOrgUnit;
