import { uniq } from 'lodash';

import * as services from '../../services/employeeOnboarding';
import { getAction } from '../../routes/employee-onboarding/process-setup/utils';
import { ModuleConfigurationActionType } from '../../routes/employee-onboarding/process-setup/interface';

export const ALL_STEPS = ['NameProcessStep', 'SelectTasksStep', 'ConfigureTaskStep', 'ReviewAndSaveStep'];

export const initialState = {
  name: '',
  currentStep: ALL_STEPS[0],
  isExtraAdded: false,
  tasks: [],
  selectedTasks: [],
  extraAddedTasks: [],
  currentSelectedTask: {},
  moduleSettings: {},
  moduleConfigurations: {},
  newProcessData: {},
  roles: [],
  microServices: [],
  currentExistedTasks: [],
  sortedTaskInReview: [],
  isEditInCreateProcess: false,
  isEditSavedModule: false,
  notReadyModulesIdInCreateProcess: [],
  pendingUpdatedModules: {},
};

const reducers = {
  setProcessName(state, { payload }) {
    return {
      ...state,
      name: payload.name,
    };
  },

  resetConfiguration() {
    return initialState;
  },

  extraAdd(state, { payload }) {
    return {
      ...state,
      isExtraAdded: payload.isExtraAdded || false,
    };
  },

  moveToStep(state, { payload }) {
    return {
      ...state,
      currentStep: payload.currentStep,
    };
  },

  moveToConnector(state, { payload }) {
    return {
      ...state,
      currentSelectedTask: payload.currentSelectedTask,
    };
  },

  editInCreateProcess(state, { payload }) {
    return {
      ...state,
      isEditInCreateProcess: payload.isEditInCreateProcess,
    };
  },

  editSavedModule(state, { payload }) {
    return {
      ...state,
      isEditSavedModule: payload.isEditSavedModule,
    };
  },

  recordPendingUpdatedModules(state, { payload }) {
    const { id, config, updatedActions, addedActions } = payload;
    const pendingUpdatedModules = { ...state.pendingUpdatedModules };
    pendingUpdatedModules[id] = {
      id,
      config,
      updatedActions,
      addedActions,
    };
    return {
      ...state,
      pendingUpdatedModules,
    };
  },

  updateNotReadyModulesIdInCreateProcess(state, { payload }) {
    const { isStepReady, taskId } = payload;
    let newIds;
    if (isStepReady) {
      newIds = state.notReadyModulesIdInCreateProcess.filter((id) => id !== taskId);
    } else {
      newIds = uniq(state.notReadyModulesIdInCreateProcess.concat(taskId));
    }
    return {
      ...state,
      notReadyModulesIdInCreateProcess: newIds,
    };
  },

  selectTask(state, { payload }) {
    const { selectedTask, isSelected, isExtraAdded } = payload;
    let newSelectedTasks;
    let newAddedTasks = [];
    if (isSelected) {
      newSelectedTasks = [...state.selectedTasks, selectedTask];
      if (isExtraAdded) {
        newAddedTasks = [...state.extraAddedTasks, selectedTask];
      }
    } else {
      newSelectedTasks = state.selectedTasks.filter((t) => {
        return t.id !== selectedTask.id;
      });
      if (isExtraAdded) {
        newAddedTasks = state.extraAddedTasks.filter((t) => {
          return t.id !== selectedTask.id;
        });
      }
    }
    return {
      ...state,
      selectedTasks: newSelectedTasks,
      extraAddedTasks: newAddedTasks,
    };
  },

  addTask(state, { payload }) {
    const { addedTask, sequence, isExtraAdded } = payload;
    let selectedTasks = state.selectedTasks;
    let newAddedTasks = [];
    if (sequence >= 0) {
      selectedTasks.splice(sequence, 0, ...addedTask);
    } else if (isExtraAdded) {
      selectedTasks = [...state.selectedTasks, addedTask];
      newAddedTasks = [...state.extraAddedTasks, addedTask];
    } else {
      selectedTasks = [...state.selectedTasks, addedTask];
    }
    return {
      ...state,
      selectedTasks,
      extraAddedTasks: newAddedTasks,
    };
  },

  removeTask(state, { payload }) {
    const { removedTask } = payload;
    const newSelectedTask = state.selectedTasks.filter((t) => {
      return t.id !== removedTask.id;
    });
    const newExtraAddedTasks = state.extraAddedTasks.filter((t) => {
      return t.id !== removedTask.id;
    });

    return {
      ...state,
      selectedTasks: newSelectedTask,
      extraAddedTasks: newExtraAddedTasks,
    };
  },

  updateSelectedTasks(state, { payload }) {
    const { selectedTasks } = payload;
    return {
      ...state,
      selectedTasks,
    };
  },

  updateSortedTasksInReview(state, { payload }) {
    const { sortedTaskInReview } = payload;
    return {
      ...state,
      sortedTaskInReview,
    };
  },

  updateCurrentExistedTasks(state, { payload }) {
    const { currentExistedTasks } = payload;
    return {
      ...state,
      currentExistedTasks,
    };
  },

  fetchOnboardingModulesSuccess(state, { payload }) {
    const onboardingModules = payload.onboarding_modules.map((ob, idx) => {
      return {
        ...ob,
        id: ob.id || `${ob.type}-${ob.id}`,
      };
    });
    if (payload.external_modules) {
      const externalConnectors = payload.external_modules.map((item) => {
        return {
          id: item.id,
          name: item.app_title,
          description: item.description,
          type: 'microservice',
          baseUrl: item.base_url,
          moduleType: item.app_name,
        };
      });
      return {
        ...state,
        tasks: onboardingModules.concat(externalConnectors),
      };
    } else {
      return {
        ...state,
        tasks: onboardingModules,
      };
    }
  },

  fetchExternalConnectorsSuccess(state, { payload }) {
    return {
      ...state,
      ...payload,
    };
  },

  fetchExternalConnectorsTaskUrlSuccess(state, { payload }) {
    return {
      ...state,
      ...payload,
    };
  },

  updateModuleSettings(state, { payload }) {
    const { moduleSettings } = payload;

    return {
      ...state,
      moduleSettings: {
        ...state.moduleSettings,
        ...moduleSettings,
      },
    };
  },

  getOnboardingModuleDetailsByTypeSuccess(state, { payload }) {
    const { onboarding_module: moduleDetails } = payload;
    const moduleConfigurations = {
      ...state.moduleConfigurations,
      [moduleDetails.type]: moduleDetails,
    };
    return {
      ...state,
      moduleConfigurations,
    };
  },

  fetchADPTemplatesSuccess(state, { payload }) {
    const validTemplates = payload.templates.filter((t) => t.status === 'valid');

    const moduleConfigurations = {
      ...state.moduleConfigurations,
      adp: {
        ...state.moduleConfigurations.adp,
        adpTemplates: validTemplates,
      },
    };
    return {
      ...state,
      moduleConfigurations,
    };
  },

  fetchADPTemplateFieldsSuccess(state, { payload }) {
    const templateActions = payload.template.actions;
    const newAdpConfigurationActions = state.moduleConfigurations.adp?.actions.map((action) => {
      const templateAction = getAction({
        moduleActions: templateActions,
        actionType: action.type,
      }) as ModuleConfigurationActionType;
      if (templateAction) {
        return {
          ...action,
          questions: templateAction.questions,
        };
      } else {
        return action;
      }
    });

    const moduleConfigurations = {
      ...state.moduleConfigurations,
      adp: {
        ...state.moduleConfigurations.adp,
        actions: newAdpConfigurationActions,
      },
    };
    return {
      ...state,
      moduleConfigurations,
    };
  },
};

const effects = {
  *checkProcessNameUniqueness({ payload }, { call }) {
    const response = yield call(services.checkProcessNameUniqueness, payload);
    if (response.error) throw response.error;
    return response.data;
  },

  *fetchOnboardingModules({ payload }, { call, put }) {
    const response = yield call(services.fetchOnboardingModules);
    if (response.error) throw response.error;

    const externalConnectorsResponse = yield call(services.fetchExternalConnectors);
    if (externalConnectorsResponse.error) throw externalConnectorsResponse.error;

    yield put({
      type: 'fetchOnboardingModulesSuccess',
      payload: {
        onboarding_modules: response.data.onboarding_modules,
        external_modules: externalConnectorsResponse.data,
      },
    });
  },

  *fetchExternalConnectors({ payload }, { call, put }) {
    const externalConnectorsResponse = yield call(services.fetchExternalConnectors);
    if (externalConnectorsResponse.error) throw externalConnectorsResponse.error;
    yield put({
      type: 'fetchExternalConnectorsSuccess',
      payload: {
        microServices: externalConnectorsResponse.data,
      },
    });
  },

  *fetchExternalConnectorsTaskUrl({ payload }, { call, put }) {
    const response = yield call(services.fetchExternalConnectorsTaskUrl, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'fetchExternalConnectorsTaskUrlSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *fetchModuleSettings({ payload }, { call, put }) {
    const response = yield call(services.fetchOnboardingModuleSettings, payload);
    if (response.error) throw response.error;
    const currentModule = response.data.company_onboarding_module;
    const newPayload = {
      moduleSettings: {
        [currentModule.module_type]: {
          ...currentModule,
          actions: currentModule.company_onboarding_actions,
        },
      },
    };
    yield put({
      type: 'updateModuleSettings',
      payload: newPayload,
    });
  },

  *createOnboardingAction({ payload }, { call }) {
    const response = yield call(services.createOnboardingAction, payload);
    if (response.error) throw response.error;
    return response.data;
  },

  *updateModule({ payload }, { call, put }) {
    const response = yield call(services.updateOnboardingModule, payload);
    if (response.error) throw response.error;

    yield put({
      type: 'updateModuleSettings',
      payload: response.data,
    });
    return response.data;
  },

  *updateAction({ payload }, { call }) {
    const response = yield call(services.updateOnboardingAction, payload);
    if (response.error) throw response.error;

    return response.data;
  },

  *getOnboardingModuleDetailsByType({ payload }, { call, put }) {
    const response = yield call(services.getOnboardingModuleDetails, payload);

    if (response.error) throw response.error;
    yield put({
      type: 'getOnboardingModuleDetailsByTypeSuccess',
      payload: response.data,
    });
    return response.data;
  },

  *createOnboardingProcess({ payload }, { call, put }) {
    const response = yield call(services.createOnboardingProcess, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'resetConfiguration',
    });
    return response.data;
  },

  *reorderModules({ payload }, { call }) {
    const response = yield call(services.reorderOnboardingModules, payload);
    if (response.error) {
      throw response.error;
    }

    return response.data;
  },

  *fetchADPTemplates({ payload = {} }, { call, put }) {
    const response = yield call(services.fetchADPTemplates);
    if (response.error) throw response.error;
    yield put({
      type: 'fetchADPTemplatesSuccess',
      payload: response.data,
    });
  },

  *fetchADPTemplateFields({ payload }, { call, put }) {
    const response = yield call(services.fetchADPTemplateFields, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'fetchADPTemplateFieldsSuccess',
      payload: response.data,
    });
  },
};

export default {
  namespace: 'configureOnboardingProcess',
  state: initialState,
  reducers,
  effects,
};
