const VIEW_SENSITIVE_PII = {
  FULL_ACCESS: 'full_access',
  PARTIAL_ACCESS: 'partial_access',
};

const MANAGE_TEAM_RECORD = {
  FULL_ACCESS: 'tr_full_access',
  PARTIAL_ACCESS: 'tr_partial_access',
};

export { VIEW_SENSITIVE_PII, MANAGE_TEAM_RECORD };
