/**
 * DON'T add buttons here unless it's reused in two different components with no relationship
 * Please do as below if you don't know how to distinguish
 * 1. If it's only used once,
 *    1.1. Please add the button component you want in your component file
 *    1.2. or even embed the JSX structure in your component (don't even need to write a button component)
 *
 * 2. If it's reused in multiple components from the same feature,
 *    2.1. Please just do 1.1. in one component and reuse in other components
 *    2.2. or create a separate button component file in the same folder
 *    2.3. or convert the whole feature into a folder-component and do 2.2
 *
 * 3. If it's reused in multiple components from different features,
 *    you can create a button component here and reuse it, but remember to
 *    3.1. Remove all business related logic
 *    3.2. Reuse ButtonProps from ws-components and override some props if necessary
 *
 */

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@flexbox';
import { Button as AntdButton } from 'antd';
import { ButtonType } from 'antd/es/button/button';
import { Tooltip } from '@helloworld1812/ws-components';

import plusIcon from '@/assets/10+locations-wizard/add.svg';

import addIcon from '../assets/answer-add.svg';
import fileAddIcon from '../assets/file-add.svg';
import deleteImage from '../assets/delete.svg';
import ShowTokenIcon from '../assets/token-arrow-down-icon.svg';
import HideTokenIcon from '../assets/token-arrow-up-icon.svg';
import downloadDocumentsIcon from '../assets/employee-onboarding/download-documents.svg';
import resendIcon from '../assets/employee-onboarding/resend.svg';

import { ButtonLabel, TextLabel } from './Label';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  hoverSrc?: string;
  opacity?: number;
}

/**
 * @deprecated Please finish TODO and add new features
 *
 * TODO: uncomment the new implementation below and test it
 */
export function Image({ src = '', hoverSrc, opacity = 1, ...props }: ImageProps) {
  return (
    <img
      src={src}
      style={{ display: 'block', opacity }}
      alt=""
      role="presentation"
      {...props}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={(e) => {
        if (hoverSrc) {
          // eslint-disable-next-line
          e.currentTarget.src = hoverSrc;
        }
      }}
      // eslint-disable-next-line
      onMouseOut={(e) => (e.currentTarget.src = src)}
    />
  );
}

// export const Image = ({ src, hoverSrc, opacity = 1, ...props }: ImageProps) => {
//   const [hovered, setHovered] = useState(false);

//   return (
//     <img
//       src={hovered && hoverSrc ? hoverSrc : src}
//       style={{ display: 'block', opacity }}
//       alt=""
//       role="presentation"
//       {...props}
//       onMouseOver={() => setHovered(true)}
//       onMouseOut={() => setHovered(false)}
//       onFocus={() => setHovered(true)}
//       onBlur={() => setHovered(false)}
//     />
//   );
// };

/**
 * @deprecated use Image directly
 *
 * TODO: uncomment the new implementation below and test it
 */
export function HoverImage({ src = '', hoverSrc, ...props }: ImageProps) {
  return (
    <img
      src={src}
      alt=""
      role="presentation"
      {...props}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={(e) => {
        if (hoverSrc) {
          // eslint-disable-next-line
          e.currentTarget.src = hoverSrc;
        }
      }}
      // eslint-disable-next-line
      onMouseOut={(e) => (e.currentTarget.src = src)}
    />
  );
}

// export const HoverImage = (props: Omit<ImageProps, 'opacity'>) => {
//   return (
//     // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
//     <Image {...props} style={undefined} />
//   );
// };

type ButtonProps = React.ButtonHTMLAttributes<HTMLElement>;

/**
 * @deprecated move into component file or folder instead
 */
export function AddAnswerButton({ children, onClick }: ButtonProps) {
  return (
    <ButtonLabel>
      <Flex align="center" onClick={onClick}>
        <Box mr={1}>
          <Image src={addIcon} />
        </Box>{' '}
        {children}
      </Flex>
    </ButtonLabel>
  );
}

export function ShowTokenButton({ onClick }: ButtonProps) {
  return (
    <Flex
      align="center"
      onClick={onClick}
      style={{
        backgroundColor: 'white',
        borderRadius: '9999px',
        padding: '8px 12px',
        // boxShadow: '0 0 4px 0 rgba(0,0,0,0.16)',
        cursor: 'pointer',
      }}
    >
      <Box mr={1}>
        <Image src={ShowTokenIcon} />
      </Box>
      <TextLabel fz12px semibold style={{ color: '#0D8CE6' }}>
        Show Merge Fields
      </TextLabel>
    </Flex>
  );
}

export function HideTokenButton({ onClick }: ButtonProps) {
  return (
    <Flex
      align="center"
      onClick={onClick}
      style={{
        backgroundColor: 'white',
        borderRadius: '9999px',
        padding: '8px 12px',
        // boxShadow: '0 0 4px 0 rgba(0,0,0,0.16)',
        cursor: 'pointer',
      }}
    >
      <Box mr={1}>
        <Image src={HideTokenIcon} />
      </Box>
      <TextLabel fz12px semibold style={{ color: '#0D8CE6' }}>
        Hide Merge Fields
      </TextLabel>
    </Flex>
  );
}

export function DeleteButton({ onClick }: ButtonProps) {
  return (
    <ButtonLabel>
      <Image className="jsa-delete-icon" src={deleteImage} onClick={onClick} />
    </ButtonLabel>
  );
}

const TokenButtonWrapper = styled.button`
  padding: 6px;
  color: #969696;
  background-color: #eff1fc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11px;
  border: none;
  &:hover {
    background-color: #edeef7;
  }
  &:active {
    background-color: #eff1fc;
  }
  outline: none;
`;
export function TokenButton({ onClick, token }: ButtonProps & { token: ReactNode }) {
  return <TokenButtonWrapper onClick={onClick}>{token}</TokenButtonWrapper>;
}

interface QuickActionButtonProps extends ButtonProps {
  icon?: string;
  hint?: string;
  height?: string;
  'data-testid'?: string;
}

/**
 * @deprecated move/embed into component file instead
 */
export function QuickActionButton(props: QuickActionButtonProps) {
  const { title, icon, onClick, disabled, hint, height } = props;
  return (
    <Tooltip title={hint}>
      <Flex
        style={{
          padding: '11px 24px',
          borderRadius: '100px',
          backgroundColor: '#ffffff',
          boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.1)',
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.4 : 1,
          height: '44px',
        }}
        align="center"
        mr={icon ? 2 : 0}
        onClick={disabled ? undefined : onClick}
        data-testid={props['data-testid']}
      >
        {icon && (
          <Box mr={2}>
            <Image src={icon} height={height} />
          </Box>
        )}
        <TextLabel semibold>{title}</TextLabel>
      </Flex>
    </Tooltip>
  );
}

export function AddFileButton({ children, onClick }: ButtonProps) {
  return (
    <AntdButton type="primary" size="large">
      <Flex align="center" onClick={onClick}>
        <Box mr={1}>
          <Image src={fileAddIcon} />
        </Box>{' '}
        {children}
      </Flex>
    </AntdButton>
  );
}

export const CircularButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  display: grid;
  place-items: center;
  background: #fff;
  color: ${({ theme }) => theme.colors.gray600};
  svg {
    width: 20px;
  }
`;

export const NoStyleButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

/**
 * @deprecated move into component file or folder instead
 */
export function DownloadFilesButton({
  onClick,
  title,
  disabled,
  content,
  loading,
}: ButtonProps & {
  content?: ReactNode;
  loading?: boolean;
}) {
  // loading refers to when the button is clicked, the button turns into a spin
  // loadingStatus refers to the loading status of the button itself
  return (
    <Tooltip title={content} trigger={disabled ? 'hover' : undefined} placement="bottom">
      <AntdButton
        style={{
          padding: '0 8px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '207px',
          height: '33px',
          lineHeight: '21px',
          border: '1px solid #D9D9D9',
          background: 'white',
          borderRadius: '4px',
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.4 : 1,
          display: 'flex',
        }}
        onClick={disabled ? undefined : onClick}
        loading={loading}
      >
        <Flex align="center" justify="center">
          {loading ? '' : <Image src={downloadDocumentsIcon} />}
          {title && (
            <TextLabel color="gray1000" style={{ marginLeft: '4px', fontSize: '14px' }}>
              {title}
            </TextLabel>
          )}
        </Flex>
      </AntdButton>
    </Tooltip>
  );
}

/**
 * @deprecated move/embed into component file instead
 */
export function DownloadFileButton({ onClick, tooltip }: ButtonProps & { tooltip: string }) {
  return (
    <Flex mr="5px">
      <Tooltip title={tooltip ?? 'Download pdf'}>
        <AntdButton
          style={{
            padding: '0 8px',
            justifyContent: 'center',
            alignItems: 'center',
            height: '32px',
            lineHeight: '21px',
            border: '1px solid #D9D9D9',
            background: 'white',
            borderRadius: '4px',
          }}
          onClick={onClick}
        >
          <Flex align="center" justify="center">
            <Image src={downloadDocumentsIcon} />
          </Flex>
        </AntdButton>
      </Tooltip>
    </Flex>
  );
}

/**
 * @deprecated move/embed into component file instead
 */
export function ResendButton({ onClick }: ButtonProps) {
  return (
    <Flex ml="5px">
      <Tooltip title="Resend this stage">
        <AntdButton
          data-testid="eo_module_qre_resend_btn"
          style={{
            padding: '0 8px',
            justifyContent: 'center',
            alignItems: 'center',
            height: '32px',
            lineHeight: '21px',
            border: '1px solid #D9D9D9',
            background: 'white',
            borderRadius: '4px',
          }}
          onClick={onClick}
        >
          <Flex align="center" justify="center">
            <Image src={resendIcon} />
          </Flex>
        </AntdButton>
      </Tooltip>
    </Flex>
  );
}

/**
 * @deprecated move/embed into component file instead
 */
export function ViewButton({ onClick, title, type }: Omit<ButtonProps, 'type'> & { type?: ButtonType }) {
  return (
    <AntdButton onClick={onClick} type={type} style={{ height: '32px', display: 'flex', alignItems: 'center' }}>
      <Flex align="center" justify="center">
        {title && (
          <TextLabel color={type === 'primary' ? 'white' : 'gray1000'} style={{ fontSize: 14 }}>
            {title}
          </TextLabel>
        )}
      </Flex>
    </AntdButton>
  );
}

/**
 * @deprecated move/embed into component file instead
 */
export function AddSignerButton({ onClick }: ButtonProps) {
  return (
    <NoStyleButton onClick={onClick}>
      <Flex style={{ marginTop: '10px' }}>
        <Image src={plusIcon} />
        <TextLabel color="blue800" ml="8px" fz12px>
          Add company signer
        </TextLabel>
      </Flex>
    </NoStyleButton>
  );
}

/**
 * DON'T add buttons here unless it's reused in two different components with no relationship
 * Please do as below if you don't know how to distinguish
 * 1. If it's only used once,
 *    1.1. Please add the button component you want in your component file
 *    1.2. or even embed the JSX structure in your component (don't even need to write a button component)
 *
 * 2. If it's reused in multiple components from the same feature,
 *    2.1. Please just do 1.1. in one component and reuse in other components
 *    2.2. or create a separate button component file in the same folder
 *    2.3. or convert the whole feature into a folder-component and do 2.2
 *
 * 3. If it's reused in multiple components from different features,
 *    you can create a button component here and reuse it, but remember to
 *    3.1. Remove all business related logic
 *    3.2. Reuse ButtonProps from ws-components and override some props if necessary
 *
 */
