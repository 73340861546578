import request from '../utils/request';
import { APIHOST } from '../config';

export const fetchCompanyCalendarsPath = '/v1/calendars/company_calendars';
export const fetchAvailabilitiesPath = '/v1/calendars/availabilities';
export const calendarMeetingsOfDatePath = '/v1/calendars/meetings';

export async function fetchAvailabilities(data) {
  const { calendarIds, start_time, end_time, timeZone } = data;
  return request('GET', APIHOST + fetchAvailabilitiesPath, {
    start_time: start_time.toISOString(),
    end_time: end_time ? end_time.toISOString() : null,
    calendar_ids: calendarIds,
    time_zone: timeZone,
  });
}

export async function fetchMeetingsOfDate(payload) {
  const { date, calendarIds, timeZone } = payload;
  return request('GET', APIHOST + calendarMeetingsOfDatePath, {
    date: date.toISOString(),
    calendar_ids: calendarIds,
    time_zone: timeZone,
  });
}
