const COLOR = {
  GRAY: '#71717A',
  BLACK: '#27272A',
  BLUE: '#3FABF8',
  WHITE: '#FFFFFF',
  LINK: '#3F51B5',
  EDUCATION: '#F4F4F5',
  WASH: '#F3F5F9',
};

export default COLOR;
