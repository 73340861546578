import type { History } from 'history';
import __ from 'lodash';
import { routerRedux } from 'dva';
import { all } from 'redux-saga/effects';
import pathToRegexp from 'path-to-regexp';
import queryString from 'qs';
import { wsLocalStorage, wsSessionStorage } from '@helloworld1812/ws-storage';

import type { AppDispatch } from '@/types/app/models';
import { isInPasswordFlowOrLoginFlow, isInSetPasswordFlow } from '@/utils';
import constants from '@/utils/constants';
import BroadcastChannel from '@/utils/broadcast-channel';
import { AnalyticsGroup } from '@/utils/analytics';
import { appendSearchParams, extractSearchParam } from '@/utils/url';
import { DEPLOY_ENV, ENABLE_MFE } from '@/config';
import { logout, requestUpgrade, getUpgradeDetails, logSignupEvent, fetchAvailableCompanyStaffs } from '@/services/app';
import { fetchUserInfo, fetchAppSettings, fetchPositions, getResetPwdToken, fetchCompanies } from '@/services/login';
import * as locationServices from '@/services/locations';
import * as brandServices from '@/services/brands';
import { fetchIntegrations } from '@/services/integrations';
import * as userOnboardingServices from '@/services/user-onboarding';
import { updateLoginShortMessage } from '@/services/loginShortMessages';
import { fetchEnabledBetaFeatures } from '@/services/betaFeature';
import { fetchGrowthbookFeatures } from '@/services/growthbook';
import { fetchAvailabilities } from '@/services/improved-schedule';
import * as companyStaffService from '@/services/companyStaff';
import { showFixedLocationsPaywall } from '@/components/FixedLocationsPaywall';
import { customMessage as message } from '@/components/CustomMessage';
import { downloadCustomReport } from '@/services/analytics/reports';

let lastPathname;
let lastSearch;

async function verifyWSToken() {
  const response = await fetchUserInfo();

  if (response.error) {
    return false;
  }

  return true;
}

export default {
  namespace: 'app',

  state: {
    user: null,
    redirectUrl: null,
    company: null,
    companies: [],
    functionModules: [],
    enabledBetaFeatures: [],
    growthbookFeatures: null,
    permittedLocations: [],
    employerTaxInfos: [],
    permittedDepartments: [],
    permittedPositions: [],
    formQuestionTypeIdConfig: {
      identityRegexes: [],
    },
    integrations: [],
    availableCompanyStaffs: [],
    availableCompanyStaffsRegardlessOfLocationAndDepartment: [],
    notReadyToHireLocations: [],
    permittedBrandsWithLocations: [], // eg. [{ id: 1, name: 'Brand 1', isOpened: false, groupLocations: { groups: [], selectedGroup: '', locations: [{id: '', name: '', positions: [] }] }}]
    industries: [],
  },

  reducers: {
    increaseAvailablePositionCount(state, { payload }) {
      const { increasement } = payload;
      const company = {
        ...state.company,
        ...(state.company.trial && {
          available_positions_count: state.company.available_positions_count + increasement,
        }),
      };
      return { ...state, company };
    },

    updateUserPhoneNumber(state, { payload }) {
      const { phoneNumber, regionNumber, globalPhoneNumber } = payload;
      const user = {
        ...state.user,
        region_number: regionNumber,
        phone: phoneNumber,
        global_phone_number_for_sms: globalPhoneNumber,
      };
      return {
        ...state,
        user,
      };
    },

    updatePositionItem(state, { payload }) {
      const { updates } = payload;
      const { permittedLocations: locations = [] } = state;
      const newLocations = locations.map((location) => {
        const { positions = [] } = location;
        const containUpdatePosition = __.find(positions, { digest_key: updates.digest_key });
        if (!containUpdatePosition) return location;
        const updatedPositions = positions.map((position) => {
          if (position.id !== updates.id) return position;
          return { ...position, ...updates };
        });
        return { ...location, positions: updatedPositions };
      });

      return {
        ...state,
        permittedLocations: newLocations,
      };
    },
    changeCheckrPackageStatusSuccess(state, { payload }) {
      const { company_checker_package: checkrPackage } = payload;
      const { integrations } = state;
      const checkrIntegration = __.find(integrations, {
        nickname: constants.INTEGRATIONS_NICKNAME.CHECKR,
      });
      const packages = checkrIntegration.target.company_checker_packages.map((item) => {
        if (item.id !== checkrPackage.id) return item;
        return checkrPackage;
      });

      const updatedCheckrIntegration = {
        ...checkrIntegration,
        target: {
          ...checkrIntegration.target,
          company_checker_packages: packages,
        },
      };

      const updatedIntegrations = integrations.map((integration) => {
        if (integration.nickname !== constants.INTEGRATIONS_NICKNAME.CHECKR) return integration;
        return updatedCheckrIntegration;
      });

      return {
        ...state,
        integrations: updatedIntegrations,
      };
    },

    setupIntegrations(state, { payload }) {
      const { integrations } = payload;
      return {
        ...state,
        integrations,
      };
    },

    setupAppSettings(state, { payload }) {
      const {
        merge_tags: mergeTags = {},
        identity_regexes: identityRegexes = [],
        validate_rules: questionValidateRules,
        text_to_apply_phone_numbers: textToApplyPhoneNumbers = [],
        share: sharePositionSettings,
        industries = [],
        custom_category_brands: customCategoryBrands = [],
      } = payload;
      const formQuestionTypeIdConfig = {
        identityRegexes,
      };
      return {
        ...state,
        mergeTags,
        formQuestionTypeIdConfig,
        questionValidateRules,
        textToApplyPhoneNumbers,
        sharePositionSettings,
        industries,
        customCategoryBrands,
      };
    },

    setupPermittedLocations(state, { payload }) {
      const { locations: permittedLocations = [] } = payload;
      return {
        ...state,
        permittedLocations,
      };
    },

    setupPermittedBrandsWithLocations(state, { payload }) {
      const { permittedBrandsWithLocations = [] } = payload;
      return {
        ...state,
        permittedBrandsWithLocations,
      };
    },

    setupPermittedBrandsWithGroups(state, { payload }) {
      const { brands = [] } = payload;
      const permittedBrandsWithLocations = brands.map((item) => {
        const {
          id,
          name,
          nickname,
          location_groups: groups = [],
          selected_group: selectedGroup,
          selected_group_locations: locations,
        } = item;

        return {
          id,
          name,
          nickname,
          groupLocations: {
            groups,
            selectedGroup,
            locations,
          },
        };
      });

      return {
        ...state,
        permittedBrandsWithLocations,
      };
    },

    setupPermittedLocationsOfBrand(state, { payload }) {
      const { brand } = payload;
      const { permittedBrandsWithLocations: brands } = state;
      const permittedBrandsWithLocations = brands.map((item) => {
        if (item.id !== brand.id) return item;

        return {
          ...item,
          groupLocations: {
            ...item.groupLocations,
            selectedGroup: brand.selectedGroup,
            ...brand.groupLocations,
          },
        };
      });

      return {
        ...state,
        permittedBrandsWithLocations,
      };
    },

    setupPermittedPositionsOfLocation(state, { payload }) {
      const { id, positions } = payload;
      const { permittedBrandsWithLocations: brands } = state;
      const permittedBrandsWithLocations = brands.map((brand) => {
        const { groupLocations = {} } = brand;
        const { locations = [] } = groupLocations;
        const newLocations = locations.map((location) => {
          return id === location.id ? { ...location, positions } : { ...location };
        });

        return {
          ...brand,
          groupLocations: {
            ...groupLocations,
            locations: newLocations,
          },
        };
      });

      return {
        ...state,
        permittedBrandsWithLocations,
      };
    },

    setupEmployerTaxInfos(state, { payload }) {
      const { employerTaxInfos = [] } = payload;
      return {
        ...state,
        employerTaxInfos,
      };
    },

    notReadyToHireLocations(state, { payload: { notReadyToHireLocations } }) {
      return {
        ...state,
        notReadyToHireLocations,
      };
    },

    setupPermittedDepartments(state, { payload }) {
      const { departments: permittedDepartments } = payload;
      return {
        ...state,
        permittedDepartments,
      };
    },

    setupPermittedPositions(state, { payload }) {
      const { positions: permittedPositions } = payload;
      return {
        ...state,
        permittedPositions,
      };
    },

    setupUserInfo(state, { payload }) {
      const { user } = payload;
      const { company, email } = user;
      const { function_modules: functionModules } = company;
      if (DEPLOY_ENV === 'production') {
        (async function setupSentry() {
          const { setUser } = await import('@sentry/react');
          setUser({ email });
        })();
      }

      return { ...state, user, company, functionModules };
    },

    setupLocations(state, { payload }) {
      return { ...state, locations: payload };
    },

    setupUser(state, { payload }) {
      wsLocalStorage.setWsToken(payload.token);
      return { ...state, user: payload };
    },

    setupBetaFeatures(state, { payload }) {
      const enabledBetaFeatures = Object.keys(payload).filter((beta) => {
        return payload[beta];
      });
      return {
        ...state,
        enabledBetaFeatures,
      };
    },

    setupGrowthbookFeatures(state, { payload }) {
      const growthbookFeatures = payload;
      return {
        ...state,
        growthbookFeatures,
      };
    },

    companyInfoUpdated(state, { payload }) {
      const { company } = payload;
      return {
        ...state,
        company,
      };
    },

    changeUserPopUpNotifications(state, { payload }) {
      const { popUpNotifications } = payload;
      const { user } = state;
      return {
        ...state,
        user: { ...user, pop_up_notifications: popUpNotifications },
      };
    },

    updateDesktopUserOnboardingStep(state, { payload }) {
      // payload will be an object: { user_onboarding_desktop_step: 'next_step' }
      const user = {
        ...state.user,
        ...payload,
      };
      return {
        ...state,
        user,
      };
    },

    fetchCompaniesSuccess(state, { payload }) {
      const { companies } = payload;
      return {
        ...state,
        companies,
      };
    },

    fetchAvailableCompanyStaffsSuccess(state, { payload }) {
      const { availableCompanyStaffs } = payload;
      return {
        ...state,
        availableCompanyStaffs,
      };
    },

    receiveAvailableCompanyStaffsRegardlessOfLocationAndDepartment(state, { payload }) {
      const availableCompanyStaffsRegardlessOfLocationAndDepartment = payload;
      return {
        ...state,
        availableCompanyStaffsRegardlessOfLocationAndDepartment,
      };
    },

    setupUserAvailability(state, { payload }) {
      const availabilities = payload?.length > 0 ? payload[0].availabilities : [];
      return {
        ...state,
        user: {
          ...state.user,
          calendar: {
            ...state.user.calendar,
            availabilities,
          },
        },
      };
    },

    updateCompanyBrand(state, { payload }) {
      const { brand = {} } = payload;
      const { company = {} } = state;
      const { brands: oldBrands = [] } = company;
      const newBrands = oldBrands.map((item) => (item.id === brand.id ? brand : { ...item }));
      return {
        ...state,
        company: { ...company, brands: newBrands },
      };
    },
  },

  subscriptions: {
    setup({ dispatch, history }: { dispatch: AppDispatch; history: History }) {
      history.listen((location) => {
        const { pathname, search } = location;
        if (pathname + search !== lastPathname + lastSearch) {
          lastPathname = pathname;
          lastSearch = search;
          // don't need to record this, because this URL is redirect from /positions
          // TODO: This is a place we can optimize in our App, to make the URL more determinate
          if (window.location.href.indexOf('"/positions?location=all"') !== -1) return;

          // At login page and base route,
          // there is no user id, and anonymous tracking has been disabled for segment.
          // It must be skipped, otherwise it runs up Segment costs
          if (window.location.href.indexOf('/login') !== -1) return;
          if (pathname === '/') return;

          // Do nothing for applicants page, since the URL change is messy,
          // and will fire multiple times during the process of loading position applicants
          // TODO: tidy up the logic of URL changing
          if (pathToRegexp('/applicants/:city/:position/:digestkey').test(pathname)) return;

          // When user is not logged in, skip the tracking
          if (!__.isEmpty(wsLocalStorage.getWsToken())) {
            window.analytics.page({ url: window.location.href, path: history.location.pathname });
          }
        }
      });

      if (history.location.pathname?.indexOf('/success-login') !== -1) {
        const params = queryString.parse(history.location.search, { ignoreQueryPrefix: true });
        const isSSO = params.sso === 'true';
        wsLocalStorage.setWsToken(params.auth_token || 'cant find token', isSSO);
        // Clear out the previous redirect url
        wsSessionStorage.removeRedirectUrl();
        wsLocalStorage.setItem('WS_NEED_TO_SELECT_COMPANY', 'undefined');
        const bc = BroadcastChannel('multi-login-detector');
        bc.postMessage('multi-login-detecteed');
        dispatch({ type: 'startup' });
        return;
      }

      const token = wsLocalStorage.getWsToken();

      if (token != null && token !== 'undefined') {
        verifyWSToken().then((tokenValid) => {
          // fetch data for app startup
          if (tokenValid) {
            const redirectUrl = extractSearchParam(window.location.href, 'redirect');

            if (redirectUrl != null) {
              wsSessionStorage.setRedirectUrl(redirectUrl);
            }

            return dispatch({ type: 'startup' });
          }
        });
      } else {
        // display the exact page if it's part of login flow and password reset flow
        if (isInPasswordFlowOrLoginFlow(history.location.pathname)) {
          return;
        }

        if (isInSetPasswordFlow(history.location.pathname)) {
          return;
        }

        if (history.location.pathname.indexOf('/redirect') !== -1) {
          return;
        }

        if (wsSessionStorage.getRedirectUrl() == null) {
          if (!(__.isEqual(history.location.pathname, '/') || __.isEqual(history.location.pathname, '/login'))) {
            wsSessionStorage.setRedirectUrl(window.location.href);
          }
        }

        if (!__.isEqual(history.location.pathname, '/login')) {
          dispatch(routerRedux.push('/login'));
        }
      }
    },
  },

  effects: {
    // use { _ } to skip the first parameter, more: https://stackoverflow.com/q/32518615
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    *logout({ _ }, { put, call }) {
      wsLocalStorage.resetWsToken();
      wsLocalStorage.setItem('WS_NEED_TO_SELECT_COMPANY', 'undefined');
      wsSessionStorage.removeRedirectUrl();
      yield put(routerRedux.push('/login'));

      yield put({ type: 'webSocket/unSubscriptions' });

      yield call(logout);
    },

    *fetchIntegrations({ _ }, { put, call }) {
      // fetch integration setttings
      const integrationsResponse = yield call(fetchIntegrations);
      if (integrationsResponse.error) throw integrationsResponse.error;
      yield put({ type: 'setupIntegrations', payload: integrationsResponse.data });
    },

    *fetchUserInfo({ _ }, { put, call }) {
      const userInfoResponse = yield call(fetchUserInfo);
      if (userInfoResponse.error) {
        throw userInfoResponse.error;
      }
      yield put({ type: 'setupUserInfo', payload: userInfoResponse.data });
      return userInfoResponse.data;
    },

    *fetchUserAvailabilities({ payload }, { put, call }) {
      const response = yield call(fetchAvailabilities, payload);

      yield put({ type: 'setupUserAvailability', payload: response.data });
    },

    *fetchEnabledBetaFeaturesAndAvailableAccountPermissions({ payload }, { call, put }) {
      const { companyId, userId } = payload;
      const enabledBetaFeaturesResponse = yield call(fetchEnabledBetaFeatures, {
        id: companyId,
      });
      if (enabledBetaFeaturesResponse.error) throw enabledBetaFeaturesResponse.error;

      yield put({
        type: 'setupBetaFeatures',
        payload: enabledBetaFeaturesResponse.data.betas,
      });
    },

    fetchGrowthbookFeaturesData: [
      function* fetch({ _payload }, { call, put }) {
        const growthbookFeaturesResponse = yield call(fetchGrowthbookFeatures);

        yield put({
          type: 'setupGrowthbookFeatures',
          payload: growthbookFeaturesResponse.data,
        });
      },
      { type: 'takeLatest' },
    ],

    *startup({ _ }, { put, call }) {
      const needSelectCompany = wsLocalStorage.getItem('WS_NEED_TO_SELECT_COMPANY');
      if (needSelectCompany && needSelectCompany !== 'undefined') {
        yield put(
          routerRedux.push({
            pathname: '/select-company',
          }),
        );
        return;
      }

      // fetch user info
      const userInfoResponse = yield call(fetchUserInfo);
      if (userInfoResponse.error) {
        throw userInfoResponse.error;
      }

      yield put({ type: 'setupUserInfo', payload: userInfoResponse.data });

      yield put({ type: 'webSocket/subscriptions' });

      const { user } = userInfoResponse.data;

      const {
        name,
        email,
        company,
        company_role,
        company_admin: companyAdmin,
        id,
        created_at: createdAt,
        uuid,
        user_id,
      } = user;

      const { brands = [], name: companyName, id: companyId, tier } = company;

      wsLocalStorage.setWsCompanyId(companyId);

      const companySize = brands.reduce(
        (acc: number, curr: { location_counts: number }) => curr?.location_counts ?? 0 + acc,
        0,
      );

      if (window.Beamer != null) {
        window.Beamer.init();

        window.Beamer.update({
          user_email: email,
          user_id,
          company_role: company_role.name,
          company_tier: tier,
          company_name: companyName,
          company_size: companySize,
        });
      } else {
        console.error('window.Beamer is nil! Make sure Beamer script is loaded correctly.');
      }

      // Segment analytics
      analytics.identify(`${user_id}`, {
        company_id: companyId,
        createdAt,
        email,
        uuid,
      });

      AnalyticsGroup(company);

      // fetch permitted locations
      yield put({ type: 'fetchInitPermittedLocations' });
      // yield put({ type: 'fetchInitPermittedGroupLocations' });
      yield put({ type: 'fetchInitPermittedBrandsWithLocations' });

      // fetch permitted departments
      yield put({ type: 'fetchInitPermittedDepartments' });

      // fetch app related settings
      // fetch integration setttings
      // fetch schedule configuration
      const actions = [call(fetchAppSettings), call(fetchIntegrations)];

      const [appSettingsResponse, integrationsResponse] = yield all(actions);
      if (appSettingsResponse.error) throw appSettingsResponse;
      yield put({ type: 'setupAppSettings', payload: appSettingsResponse.data });
      if (integrationsResponse.error) throw integrationsResponse;
      yield put({ type: 'setupIntegrations', payload: integrationsResponse.data });

      yield put({
        type: 'fetchEnabledBetaFeaturesAndAvailableAccountPermissions',
        payload: { companyId: company.id, userId: user.id },
      });

      yield put({
        type: 'fetchGrowthbookFeaturesData',
        payload: {},
      });

      // App start up redirect sequence

      // 3. Check if user need to reset password
      const [resetPwdResponse] = yield all([call(getResetPwdToken)]);
      const resetToken = resetPwdResponse?.data?.reset_password_token;

      if (resetToken && user.reset_password_required) {
        yield put(routerRedux.replace(`/set-password/${resetToken}`));
        return;
      }

      if (
        !user.is_super_admin &&
        (user.user_onboarding_desktop_step === 'notification' || user.user_onboarding_desktop_step === 'download_app')
      ) {
        // force redirect to '/user-onboarding' if user not finish the on boarding flow
        yield put(routerRedux.push({ pathname: '/user-onboarding' }));
        return;
      }

      // redirect if previous redirect url is present
      let previousUrl = wsSessionStorage.getRedirectUrl();
      if (previousUrl != null) {
        // Don't redirect to login page
        if (!ENABLE_MFE && !previousUrl.includes('/login')) {
          // Used for HR -> ER redirection temporarily (until MFE phase 2)
          const authToken = wsLocalStorage.getWsToken();
          const wsCompanyId = wsLocalStorage.getWsCompanyId();

          if (authToken != null) {
            previousUrl = appendSearchParams(previousUrl, 'auth', authToken);
          }

          if (wsCompanyId != null) {
            previousUrl = appendSearchParams(previousUrl, 'ws_company_id', wsCompanyId);
          }

          window.location.replace(previousUrl);
        }

        wsSessionStorage.removeRedirectUrl();
      }
      // redirect if page is at login page
      else if (
        window.location.href.includes('/login') ||
        window.location.href.includes('/success-login') ||
        window.location.href.includes('/redirect?type=auth_portal')
      ) {
        window.location.replace('/');
      }
    },

    *fetchPermittedPositionsOfLocation({ payload }, { put, call }) {
      const { id } = payload;
      const res = yield call(locationServices.fetchPermittedPositionsOfLocation, payload);
      if (res.error) throw res.error;
      yield put({
        type: 'setupPermittedPositionsOfLocation',
        payload: {
          id,
          positions: res.data.positions,
        },
      });
      if (payload && payload.onSuccess) payload.onSuccess();
      return res;
    },

    *fetchPermittedLocationsOfBrand({ payload = {} }, { call, put, select }) {
      const { nickname, filter_group: filterGroup } = payload;
      const { permittedBrandsWithLocations: brands = [] } = yield select((state) => state.app);
      let selectedBrand;
      if (nickname) {
        selectedBrand = __.find(brands, (item) => item.nickname === nickname);
      } else {
        selectedBrand = brands[0];
      }

      const { groupLocations = {} } = selectedBrand;
      const { groups = [] } = groupLocations;
      let selectedGroup = filterGroup || groupLocations.selectedGroup || groups[0] || '';
      const isGroupExisting = groups.indexOf(selectedGroup) >= 0;
      if (!isGroupExisting) selectedGroup = groups[0] || '';

      const fetchLocationsOfBrandPayload = { nickname: selectedBrand.nickname, filter_group: selectedGroup };

      const res = yield call(brandServices.fetchLocationsOfBrand, fetchLocationsOfBrandPayload);
      if (res.error) throw res.error;

      const locations = res.data.locations || [];

      const newBrand = {
        ...selectedBrand,
        groupLocations: {
          ...selectedBrand.groupLocations,
          selectedGroup,
          locations,
        },
      };

      yield put({
        type: 'setupPermittedLocationsOfBrand',
        payload: { brand: newBrand },
      });

      const [firstLocation] = locations;
      if (firstLocation) {
        yield put({ type: 'fetchPermittedPositionsOfLocation', payload: { id: firstLocation.id } });
      }

      if (payload && payload.onSuccess) payload.onSuccess();
      return res;
    },

    *fetchPermittedBrandsWithGroups({ payload = {} }, { call, put }) {
      const res = yield call(brandServices.fetchBrandsWithLocationGroups, payload);
      if (res.error) throw res.error;
      const { brands = [] } = res.data;

      yield put({
        type: 'setupPermittedBrandsWithGroups',
        payload: { brands },
      });

      if (payload && payload.onSuccess) payload.onSuccess();
      return res;
    },

    *fetchInitPermittedBrandsWithLocations({ payload }, { call, put }) {
      return yield put({ type: 'fetchPermittedBrandsWithGroups' });
    },

    *fetchPermittedLocations({ payload }, { put, call }) {
      const res = yield call(locationServices.fetchPermittedLocations, payload);
      if (res.error) throw res.error;
      yield put({
        type: 'setupPermittedLocations',
        payload: {
          locations: res.data.locations,
        },
      });
      if (payload && payload.onSuccess) payload.onSuccess();
      return res;
    },

    *fetchInitPermittedLocations({ payload }, { call, put }) {
      const res = yield call(locationServices.fetchPermittedLocations, { filter_group: '' });
      if (res.error) throw res.error;
      yield put({
        type: 'setupPermittedLocations',
        payload: {
          locations: res.data.locations,
        },
      });
      if (payload && payload.onSuccess) payload.onSuccess();
      return res;
    },

    *fetchPermittedDepartments({ payload }, { put, call }) {
      // fetch permitted departments
      const permittedDepartmentsResponse = yield call(locationServices.fetchPermittedDepartments);
      if (permittedDepartmentsResponse.error) throw permittedDepartmentsResponse.error;
      yield put({ type: 'setupPermittedDepartments', payload: permittedDepartmentsResponse.data });
      if (payload && payload.onSuccess) payload.onSuccess();
    },

    *fetchInitPermittedDepartments({ payload }, { put }) {
      const res = yield put({ type: 'fetchPermittedDepartments' });
      if (payload && payload.onSuccess) payload.onSuccess();
      return res;
    },

    *fetchPermittedPositions({ payload = {} }, { put, call }) {
      const res = yield call(fetchPositions, payload);
      if (res.error) throw res.error;
      yield put({ type: 'setupPermittedPositions', payload: res.data });
      if (payload && payload.onSuccess) payload.onSuccess();
      return res;
    },

    *fetchInitPermittedPositions({ payload }, { put }) {
      const params = {
        page: 1,
        per_page: 10000,
        show_simple_list: true,
        status: ['published', 'closed', 'pending', 'deleted'],
      };
      const res = yield put({ type: 'fetchPermittedPositions', payload: params });
      if (payload && payload.onSuccess) payload.onSuccess();
      return res;
    },

    *createLocation({ payload }, { call, select }) {
      const response = yield call(locationServices.createLocation, payload);
      if (response.error) {
        if (response.error.errorCode === 50031) {
          const app = yield select((state) => state.app);
          showFixedLocationsPaywall(app.company.paid_locations_count);
        }
        throw response.error;
      }
      if (payload.onSuccess) {
        payload.onSuccess(response.data);
      }
      return response;
    },

    *createLocations({ payload }, { call }) {
      const response = yield call(locationServices.createLocations, payload);
      if (response.error) {
        if (response.error.errorCode === 50031) {
          message.error(response.error.message);
        }
        throw response.error;
      }
      if (payload.onSuccess) {
        payload.onSuccess(response.data);
      }
      return response;
    },

    *createOrUpdateLocations({ payload }, { call }) {
      const response = yield call(locationServices.createOrUpdateLocations, payload);
      if (response.error) {
        if (response.error.errorCode === 50031) {
          message.error(response.error.message);
        }
        throw response.error;
      }
      if (payload.onSuccess) {
        payload.onSuccess(response.data);
      }
      return response;
    },

    *updateLocation({ payload }, { call }) {
      const response = yield call(locationServices.updateLocation, payload);
      if (response.error) throw response.error;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
      return response;
    },

    *deleteLocation({ payload }, { call }) {
      const response = yield call(locationServices.deleteLocation, payload);
      if (response.error) throw response.error;
      if (payload && payload.onSuccess) payload.onSuccess();
    },

    *createDepartment({ payload }, { call }) {
      const response = yield call(locationServices.createDepartment, payload);
      if (response.error) throw response.error;
      if (payload.onSuccess) {
        payload.onSuccess(response.data);
      }
    },

    *updateDepartment({ payload }, { call }) {
      const response = yield call(locationServices.updateDepartment, payload);
      if (response.error) throw response.error;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },

    *deleteDepartment({ payload }, { call }) {
      const response = yield call(locationServices.deleteDepartment, payload);
      if (response.error) throw response.error;
      if (payload && payload.onSuccess) payload.onSuccess();
    },
    *queryNextUserOnboardingStep({ payload }, { call, put }) {
      const res = yield call(userOnboardingServices.queryNextUserOnboardingStep, payload);
      if (res.error) throw res.error;
      yield put({
        type: 'updateDesktopUserOnboardingStep',
        payload: res.data,
      });
      return res.data;
    },

    *sendSMSDownloadAppLink({ payload }, { call }) {
      const res = yield call(userOnboardingServices.sendSMSDownloadLink, payload);
      if (res.error) throw res.error;
    },
    *updateLoginShortMessage({ payload }, { call }) {
      yield call(updateLoginShortMessage, payload);
    },
    refreshUserInfo: [
      function* fetch({ _ }, { put, call }) {
        const userInfoResponse = yield call(fetchUserInfo);
        if (userInfoResponse.error) throw userInfoResponse.error;
        if (userInfoResponse.error) {
          throw userInfoResponse.error;
        }
        yield put({ type: 'setupUserInfo', payload: userInfoResponse.data });
      },
      { type: 'takeLatest' },
    ],

    *requestUpgrade({ payload }, { call }) {
      const res = yield call(requestUpgrade, payload);
      if (res.error) throw res.error;
      return res.data;
    },

    *getUpgradeDetails({ payload }, { call }) {
      const res = yield call(getUpgradeDetails, payload);
      if (res.error) throw res.error;
      return res.data;
    },

    *logSignupEvent({ payload }, { call }) {
      const res = yield call(logSignupEvent, payload);
      if (res.error) throw res.error;
    },

    *fetchCompanies({ _ }, { call, put }) {
      const res = yield call(fetchCompanies);
      if (res.error) throw res.error;
      yield put({
        type: 'fetchCompaniesSuccess',
        payload: { companies: res.data.companies },
      });
    },

    *fetchAvailableCompanyStaffs({ payload }, { call, put }) {
      const res = yield call(fetchAvailableCompanyStaffs, payload);
      if (res.error) throw res.error;

      const availableCompanyStaffs = res.data.permitted_staffs;

      yield put({
        type: 'fetchAvailableCompanyStaffsSuccess',
        payload: { availableCompanyStaffs },
      });

      return availableCompanyStaffs;
    },

    *fetchAvailableCompanyStaffsRegardlessOfLocationAndDepartment({ payload }, { call, put }) {
      const res = yield call(companyStaffService.fetchCompanyStaffs, {
        page: 1,
        perPage: 1000,
        status: 'active',
        sort_key: 'created_at',
        order: 'desc',
      });

      if (res.error) {
        throw res.error;
      }

      const companyStaffs = res.data.company_staffs;

      yield put({
        type: 'receiveAvailableCompanyStaffsRegardlessOfLocationAndDepartment',
        payload: companyStaffs,
      });

      return companyStaffs;
    },

    *fetchAvailableEmployerTaxInfos({ payload }, { call, put }) {
      const res = yield call(locationServices.fetchEmployerTaxInfos);
      if (res.error) throw res.error;
      yield put({
        type: 'setupEmployerTaxInfos',
        payload: {
          employerTaxInfos: res.data.employer_tax_infos,
        },
      });
      return res;
    },

    *fetchCustomReportDownloadUrl({ payload }, { call }) {
      const response = yield call(downloadCustomReport, payload);
      if (response.error) throw response.error;
      return response.data;
    },

    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
};
