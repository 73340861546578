import { APIHOST } from '@/config';
import request from '@/utils/request';

export async function getCorrectSubmission() {
  const path = '/v1/onboarding/e_verify/hr/quiz_submissions/correct_submission';
  return request('GET', APIHOST + path);
}

export async function getQuestions() {
  const path = '/v1/onboarding/e_verify/hr/quiz_submissions/questions';
  return request('GET', APIHOST + path);
}

export async function submitAnswers(payload) {
  const path = '/v1/onboarding/e_verify/hr/quiz_submissions';
  return request('POST', APIHOST + path, payload);
}
