import request from '../utils/request';
import { APIHOST } from '../config';

export async function requestUpgrade(payload) {
  const { pageURL, requestType, phoneNumber, paywallInfo } = payload;
  const path = `${APIHOST}/v1/upgrade_requests`;
  return request('POST', path, {
    page_url: pageURL,
    upgrade_request: {
      request_type: requestType,
      ...(phoneNumber && { phone_number: phoneNumber }),
    },
    ...paywallInfo,
  });
}

export async function getUpgradeDetails(payload) {
  const { pageURL, paywallInfo } = payload;
  const path = `${APIHOST}/v1/upgrade_requests/new`;
  return request('POST', path, { page_url: pageURL, ...paywallInfo });
}

export async function logSignupEvent(payload) {
  const anonymousId = window.localStorage.getItem('WS_ANONYMOUS_ID');
  const path = `${APIHOST}/v1/segment_tracks/visit_self_serve_sign_up`;
  return request('POST', path, { ...payload, ...(anonymousId && { anonymous_id: anonymousId }) });
}

export async function fetchAvailableCompanyStaffs({ locationId, departmentId }) {
  const path = `${APIHOST}/v1/permitted_staffs`;
  return request('GET', path, { location_id: locationId, department_id: departmentId });
}

export async function logout() {
  const path = `${APIHOST}/v1/sessions/sign_out`;
  return request('POST', path);
}
