import * as services from '../../services/employeeOnboarding';

const initialState = {};

const reducers = {};

const effects = {
  *updateI9FormSubmission({ payload }, { call }) {
    const response = yield call(services.updateI9FormSubmission, payload);
    if (response.error) throw response.error;

    return response.data;
  },
  *generateSignUrl({ payload }, { call }) {
    const response = yield call(services.generateI9SignUrl, payload);
    return response;
  },
  *markSigned({ payload }, { call }) {
    const response = yield call(services.markI9SignedUrl, payload);
    return response;
  },
};

export default {
  namespace: 'i9',
  state: initialState,
  reducers,
  effects,
};
