import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Text, colors, FlexBox, Box } from '@helloworld1812/ws-components';
import { Radio, Input, Select, Form, Space, RadioChangeEvent } from 'antd';
import { LabeledValue } from 'antd/es/select';
import moment from 'moment';
import { isArray, uniq } from 'lodash';

import { QuestionOptionType, FormSubmissionQuestionType } from '@/routes/employee-onboarding/process-setup/interface';
import StyledIntlTelInput from '@/components/intl-tel-input';

import { getStates } from '../../modules/custom-form/utils';
import identityConfigData from '../identity-config';
import AdpFileNumber from '../../modules/adp/adp-file-number';
import DatePicker from '../../../start-onboarding/common/date-picker';
import addButton from '../../../../../assets/add-answer-button.svg';
import { DeleteButton } from '../../modules/adp/adp-home-org-unit';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { Option } = Select;

const TextDefault = styled(Text).attrs({ variant: 'bodyDefault' })`
  color: ${colors.gray800};
  white-space: nowrap;
`;

const TextSecondary = styled(Text)`
  color: ${colors.gray600};
`;

const optionalText = (isRequired: boolean) => {
  return isRequired ? '' : ' - Optional';
};

export const TYPES = {
  single_question: 'single question',
  group_question: 'group questions',
  extendable_group_question: 'extendable_group_question',
};

export const getComponentType = (question: FormSubmissionQuestionType) => {
  if (question.question_type === 'adp_phone_number') return TYPES.single_question;
  const { fields, max_items } = question;
  if (fields === undefined) return TYPES.single_question;
  if (fields && max_items === undefined) return TYPES.group_question;
  if (fields && max_items) return TYPES.extendable_group_question;
  return undefined;
};

export type OptionWithCodeList = {
  codeListTitle: string;
  listItems: QuestionOptionType[];
};

export interface QuestionProps {
  name: string;
  title: string;
  type: string;
  onChange: ({ name, value, fieldKey }: { name: string; value: unknown; fieldKey?: string }) => void;
  isRequired?: boolean;
  options?: QuestionOptionType[] | OptionWithCodeList;
  hideTitle?: boolean;
  errorMessage?: string | React.ReactNode;
  deletable?: boolean;
  onDeleteRow?: () => void;
  dateFormat?: string;
  categoryType?: string;
  isRequiredCategoryValueExisted?: boolean;
  requiredCategoryTitle?: string;
  noDataError?: string | React.ReactNode;
  [x: string]: unknown;
}

export function Question(props: QuestionProps) {
  const {
    title,
    type,
    isRequired = false,
    errorMessage,
    deletable,
    onDeleteRow,
    dateFormat,
    categoryType,
    isRequiredCategoryValueExisted,
    requiredCategoryTitle,
    noDataError,
    hideTitle = false,
    ...restProps
  } = props;
  let formControl;
  let formElement;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (type === 'adp_country_code') return null;
  if (type === 'adp_area_code') return null;

  const onChangeFnMultSelect = (codeValue: string | number, checked: boolean) => {
    let value = [...props.value];
    if (checked) {
      value = uniq([...value, codeValue]);
    } else {
      value = value.filter((v) => v !== codeValue);
    }
    props.onChange({ name: props.name, value });
  };

  const reformatSSN = (val: string) => {
    let newVal = val;
    newVal = newVal.replace(/\D/g, '');
    newVal = newVal.replace(/^(\d{3})/, '$1-');
    newVal = newVal.replace(/-(\d{2})/, '-$1-');
    newVal = newVal.replace(/(\d)-(\d{4}).*/, '$1-$2');
    return newVal;
  };

  const { options } = props;
  let currentOptions: QuestionOptionType[] = [];
  if (options && !isArray(options) && options.codeListTitle) {
    currentOptions = options.listItems;
  } else if (options && isArray(options)) {
    currentOptions = options;
  }

  switch (type) {
    case 'adp_phone_number':
      formControl = (
        <FormItem>
          <StyledIntlTelInput
            {...props}
            disabled={props.disabled}
            onPhoneNumberChange={(status: never, value: string, countryCode: { [key: string]: string[] }) => {
              let newValue = value;
              if (newValue.indexOf('+') === -1) {
                newValue = `+${countryCode.dialCode}${value}`;
              }
              props.onChange({ name: props.name, value: newValue });
            }}
          />
        </FormItem>
      );
      break;
    case 'date':
      formControl = (
        <FormItem>
          <DatePicker
            format={dateFormat}
            disabled={props.disabled}
            defaultValue={props.value && moment(props.value)}
            onChange={(value) => {
              props.onChange({ name: props.name, value: value.format(dateFormat) });
            }}
          />
        </FormItem>
      );
      break;
    case 'dropdown':
      const { optionLabelDescriptionFirst } = props;

      let filterOptions: QuestionOptionType[] = [];
      if (isRequiredCategoryValueExisted && currentOptions && currentOptions.length > 0 && currentOptions[0].category) {
        filterOptions = currentOptions.filter((op) => op.category === props.requiredCategoryValue);
      }
      if (filterOptions.length !== 0) {
        currentOptions = filterOptions;
      }
      formControl = (
        <FormItem>
          <Space direction="vertical">
            <Select
              defaultValue={props.value === '' ? null : props.value}
              placeholder={`Select a ${title}`}
              disabled={props.disabled}
              onChange={(e) => {
                props.onChange({ name: props.name, value: e });
              }}
            >
              {currentOptions &&
                currentOptions.map((option, idx) => (
                  <Option value={option.codeValue || option.code} key={idx}>
                    {optionLabelDescriptionFirst
                      ? option.description ||
                        option.name ||
                        option.shortName ||
                        option.longName ||
                        option.valueDescription ||
                        option.code
                      : option.shortName ||
                        option.longName ||
                        option.valueDescription ||
                        option.name ||
                        option.description ||
                        option.code}
                  </Option>
                ))}
            </Select>
          </Space>
        </FormItem>
      );
      break;
    case 'searchable_dropdown':
      const { disabled: searchableDisabled } = props;
      let searchableOptions = props.options;
      const newOptions: LabeledValue[] = [];

      let filteredOptions: QuestionOptionType[] = [];
      if (isRequiredCategoryValueExisted && searchableOptions && searchableOptions.length > 0) {
        filteredOptions = searchableOptions.filter((op: { foreignKey: unknown }) => {
          return op.foreignKey === props.requiredCategoryValue || op.foreignKey === null;
        });
      }
      if (filteredOptions.length > 0) {
        searchableOptions = filteredOptions;
      }

      if (searchableOptions && isArray(searchableOptions) && newOptions) {
        searchableOptions.forEach((option) => {
          newOptions.push({
            value: option.codeValue,
            label: option.shortName,
          });
        });
      }

      formControl = (
        <FormItem>
          <Space direction="vertical">
            <Select
              defaultValue={props.value}
              disabled={searchableDisabled}
              options={newOptions}
              filterOption={(input, option) => {
                const optionLabel = (option?.label || '') as string;
                return optionLabel.toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
              onChange={(e) => {
                props.onChange({ name: props.name, value: e });
              }}
            />
          </Space>
        </FormItem>
      );
      break;
    case 'short_text':
      formControl = (
        <FormItem>
          <Space direction="vertical">
            <Input
              {...props}
              type="text"
              onChange={(e) => {
                props.onChange({ name: props.name, value: e.target.value });
              }}
            />
          </Space>
        </FormItem>
      );
      break;
    case 'optional_short_text':
      formControl = <AdpFileNumber {...props} />;
      break;
    case 'long_text':
      formControl = (
        <textarea
          {...props}
          rows={5}
          onChange={(e) => {
            props.onChange({ name: props.name, value: e.target.value });
          }}
        />
      );
      break;
    case 'attachment':
      const { value: attachmentValue, ...rest } = props;
      const fileLink = attachmentValue.pdf_url || attachmentValue.url;
      const fileName = attachmentValue.pdf_file_name || attachmentValue.file_name || 'uploaded file';
      if (fileLink && props.disabled) {
        formControl = (
          <a href={fileLink} target="_blank" rel="noreferrer">
            {fileName}
          </a>
        );
      } else {
        formControl = (
          <input
            {...rest}
            type="file"
            style={{
              ...(props.disabled && { opacity: 0.5 }),
            }}
            onChange={(e) => {
              props.onChange({
                name: props.name,
                value: {
                  [props.name]: e.target.value,
                  attachment: e.target.files && e.target.files[0],
                },
              });
            }}
          />
        );
      }
      break;
    case 'multiple_selection':
      formControl = (
        <FormItem>
          <Space direction="vertical">
            <FlexBox flexDirection="column">
              {currentOptions.map((option) => {
                const checked = props.value.indexOf(option.codeValue) !== -1;
                return (
                  <FlexBox key={option.codeValue}>
                    <FlexBox alignItems="center">
                      <Input
                        {...props}
                        type="checkbox"
                        value={option.codeValue}
                        onChange={(e) => {
                          onChangeFnMultSelect(option.codeValue, e.target.checked);
                        }}
                        checked={checked}
                      />
                      <Box ml={1} />
                      {option.shortName}
                    </FlexBox>
                  </FlexBox>
                );
              })}
            </FlexBox>
          </Space>
        </FormItem>
      );
      break;
    case 'long_multiple_selection':
      formControl = (
        <FormItem>
          <Space direction="vertical">
            <FlexBox flexDirection="column">
              {currentOptions.map((option) => {
                const checked = props.value.indexOf(option.codeValue) !== -1;
                return (
                  <FlexBox key={option.codeValue} style={{ marginBottom: '15px' }}>
                    <FlexBox>
                      <Input
                        style={{ height: '22px' }}
                        {...props}
                        type="checkbox"
                        value={option.codeValue}
                        onChange={(e) => {
                          onChangeFnMultSelect(option.codeValue, e.target.checked);
                        }}
                        checked={checked}
                      />
                      <Box ml={1} />
                      {option.shortName}
                    </FlexBox>
                  </FlexBox>
                );
              })}
            </FlexBox>
          </Space>
        </FormItem>
      );
      break;
    case 'multiple_choice':
      formControl = (
        <FormItem>
          <Space direction="vertical">
            <RadioGroup value={props.value}>
              <Space direction="vertical">
                {currentOptions.map((option) => (
                  <Radio
                    {...props}
                    value={option.codeValue}
                    key={option.codeValue}
                    onChange={(e: RadioChangeEvent) => {
                      if (e.target.checked) {
                        props.onChange({
                          name: props.name,
                          value: option.codeValue,
                        });
                      }
                    }}
                  >
                    {option.shortName}
                  </Radio>
                ))}
              </Space>
            </RadioGroup>
          </Space>
        </FormItem>
      );
      break;
    case 'long_multiple_choice':
      formControl = (
        <FormItem>
          <FlexBox flexDirection="column">
            {currentOptions.map((option) => {
              return (
                <FlexBox key={option.codeValue} style={{ marginBottom: '15px' }}>
                  <FlexBox>
                    <Input
                      style={{ height: '22px' }}
                      {...props}
                      type="radio"
                      checked={props.value === option.codeValue}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props.onChange({
                            name: props.name,
                            value: option.codeValue,
                          });
                        }
                      }}
                    />
                    <Box ml={1} />
                    {option.shortName}
                  </FlexBox>
                </FlexBox>
              );
            })}
          </FlexBox>
        </FormItem>
      );
      break;
    case 'telephone':
      formControl = (
        <StyledIntlTelInput
          {...props}
          onPhoneNumberChange={(status: never, value: string, countryCode: { [key: string]: string[] }) => {
            let newValue = value;
            if (newValue.indexOf('+') !== -1) {
              newValue = newValue.replace(`+${countryCode.dialCode}`, '');
            }
            props.onChange({ name: props.name, value: newValue });
          }}
        />
      );
      break;
    case 'us_telephone':
      formControl = (
        <Input
          {...props}
          onChange={(value) => {
            props.onChange({ name: props.name, value });
          }}
        />
      );
      break;
    case 'email':
      formControl = (
        <Input
          {...props}
          type="text"
          onChange={(e) => {
            props.onChange({ name: props.name, value: e.target.value });
          }}
        />
      );
      break;
    case 'identity':
      const { identityType, countryCode } = props;
      switch (identityType) {
        case 'dl':
          let { value: identityValue } = props;
          identityValue = identityValue || {};
          const { state_code: stateCode, [props.name]: licenseValue } = identityValue;
          const states = getStates(countryCode, identityType, identityConfigData);
          formControl = (
            <FlexBox>
              <Select
                {...props}
                options={currentOptions}
                value={stateCode}
                onChange={(e) => {
                  props.onChange({ name: `${props.name}[state_code]`, value: e });
                }}
              >
                <option disabled selected value={undefined} style={{ display: 'none' }}>
                  Select state
                </option>
                {states.map((s: unknown) => {
                  return (
                    <option key={s.state_code} value={s.state_code}>
                      {s.state_name}
                    </option>
                  );
                })}
              </Select>
              <Box mr={10} />
              <Input
                type="text"
                {...restProps}
                value={licenseValue}
                onChange={(e) => {
                  props.onChange({ name: props.name, value: e.target.value });
                }}
              />
            </FlexBox>
          );
          break;
        case 'ssn':
          formControl = isEditing ? (
            <Input
              type="text"
              {...restProps}
              onChange={(e) => {
                const val = e.target.value.replace(/\D/g, '').substr(0, 9);
                props.onChange({ name: props.name, value: val });
              }}
              onBlur={() => {
                setIsEditing(!isEditing);
              }}
            />
          ) : (
            <Input
              type="text"
              value={reformatSSN(props.value)}
              onFocus={() => {
                setIsEditing(!isEditing);
              }}
            />
          );

          break;
        case 'nric':
          formControl = (
            <Input
              type="text"
              {...restProps}
              onChange={(e) => {
                props.onChange({ name: props.name, value: e.target.value });
              }}
            />
          );
          break;
        default:
          break;
      }
      break;
    case 'text_heading':
      formElement = (
        <FlexBox
          flexDirection="column"
          className="form-text-heading"
          mb={20}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      );
      break;
    case 'image':
      const { attachment } = props;
      formElement = (
        <FlexBox mb={20} maxWidth="100%">
          <img style={{ maxWidth: '100%' }} src={attachment.url} alt={attachment.title} />
        </FlexBox>
      );
      break;
    case 'iframe':
      const { iframeURL } = props;
      const isYoutubeIframe = iframeURL.indexOf('youtube.com') !== -1;
      formElement = (
        <FlexBox mb={20} style={{ maxWidth: '100%' }} flexDirection="column">
          <div className="form-embed-wrapper">
            <div
              className={classNames({
                'form-youtube-iframe': isYoutubeIframe,
              })}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: iframeURL }}
            />
          </div>
        </FlexBox>
      );
      break;
    case 'embed_youtube':
      const { content } = props;
      const youtubeURL = `https://www.youtube.com/embed/${getYoutubeID(content)}`;
      formElement = (
        <FlexBox mb={20} style={{ maxWidth: '100%' }} flexDirection="column">
          <div className="form-embed-wrapper">
            <div className="form-youtube-iframe">
              <iframe
                title={title}
                width="560"
                height="315"
                src={youtubeURL}
                frameBorder={0}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </FlexBox>
      );
      break;
    case 'link':
      formElement = (
        <FlexBox mb={20} style={{ maxWidth: '100%' }} flexDirection="column">
          <a href={props.content} target="_blank" rel="noreferrer">
            {title}
          </a>
        </FlexBox>
      );
      break;
    default:
      formControl = (
        <div>
          Cannot render question {title}({type})
        </div>
      );
  }

  if (formElement) {
    return formElement;
  }

  return (
    <div>
      {!hideTitle && (
        <FlexBox>
          <FlexBox justifyContent="space-between" alignItems="center" width={1} mb="7px">
            <FlexBox>
              <TextDefault>{title}</TextDefault>
              <TextSecondary>&nbsp;{optionalText(isRequired)}</TextSecondary>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
      {errorMessage && errorMessage !== 'This is a mandatory field.' && (
        <Text style={{ color: 'red' }}>{errorMessage}</Text>
      )}
      <FlexBox flexDirection="column" style={{ position: 'relative' }}>
        {noDataError && isRequired && (
          <FormItem>
            <Space direction="vertical">
              <Text color="red600">{`${noDataError}`}</Text>
            </Space>
          </FormItem>
        )}
        {noDataError && !isRequired && (
          <FormItem>
            <Space direction="vertical">
              <Text>{`${noDataError}`}</Text>
            </Space>
          </FormItem>
        )}
        {!noDataError && categoryType && !isRequiredCategoryValueExisted && isRequired && (
          <FormItem>
            <Space direction="vertical">
              <Text color="red600">{`Please select ${requiredCategoryTitle} first`}</Text>
            </Space>
          </FormItem>
        )}
        {!noDataError && categoryType && !isRequiredCategoryValueExisted && !isRequired && (
          <FormItem>
            <Space direction="vertical">
              <Text
                color="gray700"
                style={{ marginTop: '-5px' }}
                fontSize="12px"
              >{`Select ${requiredCategoryTitle} first`}</Text>
            </Space>
          </FormItem>
        )}
        <FlexBox style={{ alignItems: 'center', gap: '12px' }}>
          {!noDataError && (!categoryType || (categoryType && isRequiredCategoryValueExisted)) && formControl}
          {deletable && (
            <DeleteButton
              onClick={(e) => {
                e.preventDefault();
                if (onDeleteRow) {
                  onDeleteRow();
                }
              }}
              style={{ marginBottom: '24px' }}
            >
              Remove
            </DeleteButton>
          )}
        </FlexBox>
      </FlexBox>
    </div>
  );
}

export function QuestionGroup({
  children,
  extendable,
  onExtend,
}: {
  children: unknown;
  extendable: boolean;
  onExtend?: () => void;
}) {
  let newChildren = children;
  if (children.props && children.props.children) {
    newChildren = children.props.children;
  }
  return (
    <>
      {newChildren}
      {extendable && (
        <FlexBox onClick={onExtend} alignItems="center" mt="-12px" mb={20} style={{ cursor: 'pointer', gap: '8px' }}>
          <img src={addButton} alt="add button" width={20} />
          <Text color="#3F51B5" fontSize="12px" style={{ marginLeft: '8px' }}>
            Add another answer
          </Text>
        </FlexBox>
      )}
    </>
  );
}

const getYoutubeID = (url: string) => {
  const newUrl: string[] = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return newUrl[2] !== undefined ? newUrl[2].split(/[^0-9a-z_-]/i)[0] : newUrl[0];
};
