import { CancelToken } from 'axios';

import * as services from '../services/companyDocuments';
// import constants from '../utils/constants';

const initialState = {
  documentsList: [],
  documentsListPagination: {
    total: 1,
    current: 1,
    pageSize: 10,
  },
  documentsListDefaultSearch: {
    page: 1,
    perPage: 10,
    sortKey: 'name',
    sortOrder: '',
  },
};

const reducers = {
  fetchDocumentsListSuccess(state, { payload }) {
    const { responseData, search } = payload;
    const { company_document_templates: documents } = responseData;
    const pagination = {
      total: responseData.total_pages * search.perPage,
      current: responseData.current_page,
      pageSize: search.perPage,
    };

    return {
      ...state,
      documentsList: documents,
      documentsListPagination: pagination,
    };
  },
};

const effects = {
  fetchDocumentsList: [
    function* fn({ payload }, { call, cancelled }) {
      const source = CancelToken.source();
      try {
        const response = yield call(services.fetchDocumentsList, payload, source.token);
        if (response.error) throw response.error;
        return response.data;
      } finally {
        if (yield cancelled()) {
          source.cancel('Operation canceled by the consecutive request.');
        }
      }
    },
    { type: 'takeLatest' },
  ],

  *fetchAllDocuments({ payload }, { call }) {
    const response = yield call(services.fetchAllDocuments);
    if (response.error) throw response.error;
    return response.data;
  },

  *getCompanyDocumentTemplate({ payload }, { call }) {
    const response = yield call(services.getCompanyDocumentTemplate, payload);
    if (response.error) throw response.error;
    return response.data;
  },

  *prepareCompanyDocumentTemplate({ payload }, { call }) {
    const response = yield call(services.prepareCompanyDocumentTemplate, payload);
    if (response.error) throw response.error;
    return response.data;
  },

  *createCompanyDocumentTemplate({ payload }, { call }) {
    const response = yield call(services.createCompanyDocumentTemplate, payload);
    if (response.error) throw response.error;
  },

  *updateCompanyDocumentTemplate({ payload }, { call }) {
    const response = yield call(services.updateCompanyDocumentTemplate, payload);
    if (response.error) throw response.error;
  },

  *deleteCompanyDocumentTemplate({ payload }, { call }) {
    const response = yield call(services.deleteCompanyDocumentTemplate, payload);
    if (response.error) throw response.error;
  },
};

export default {
  namespace: 'companyDocuments',
  state: initialState,
  reducers,
  effects,
};
