import { MANAGE_TEAM_RECORD } from '@/constants/permission';
import { getManageTeamRecordPermission } from '@/utils/permission';
import { useAppSelector } from '@/hooks/models';

function useHasFullTeamRecordAccess() {
  const appState = useAppSelector((state) => state.app);
  return getManageTeamRecordPermission(appState) === MANAGE_TEAM_RECORD.FULL_ACCESS;
}

export { useHasFullTeamRecordAccess };
