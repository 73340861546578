import * as services from '@/services/e-verify-quiz';

export const initialState = {
  correctSubmission: {},
  questions: [],
  passed: undefined,
  correct_question_count: undefined,
};

export const reducers = {
  resetAnswersResult(state) {
    return {
      ...state,
      correct_question_count: undefined,
    };
  },

  updateCorrectSubmission(state, { payload }) {
    const { correct_submission: correctSubmission } = payload;
    return {
      ...state,
      correctSubmission,
    };
  },

  updateQuestions(state, { payload }) {
    const { questions } = payload;
    return {
      ...state,
      questions,
    };
  },

  updateAnswersResult(state, { payload }) {
    return {
      ...state,
      passed: payload.passed,
      correct_question_count: payload.correct_question_count,
    };
  },
};

export const effects = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  *getCorrectSubmission({ payload = {} }, { call, put }) {
    const response = yield call(services.getCorrectSubmission);
    if (response.error) throw response.error;
    yield put({
      type: 'updateCorrectSubmission',
      payload: response.data,
    });
    return response.data;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  *getQuestions({ payload = {} }, { call, put }) {
    const response = yield call(services.getQuestions);
    if (response.error) throw response.error;
    yield put({
      type: 'updateQuestions',
      payload: response.data,
    });
  },

  *submitAnswers({ payload = {} }, { call, put }) {
    const response = yield call(services.submitAnswers, payload);
    if (response.error) throw response.error;
    yield put({
      type: 'updateAnswersResult',
      payload: response.data,
    });
    return response.data;
  },
};

export default {
  namespace: 'eVerifyQuiz',
  state: initialState,
  reducers,
  effects,
};
